import * as React from 'react';
import styled from 'styled-components';
import { Colors, Text } from '../../../ui';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { months } from '../../../utils/dateUtils';
import { SCREEN_SIZES } from '../../../resources/screenSize';

const NotificationContainer = styled.div`
  padding: 8px;
  ${props => props.isSmallerScreen ? '' : 'margin-right: 16px;'}
  margin-bottom: 24px;
  background-color: ${Colors.WHITE};
  border-radius: 4px;
`;

const NotificationTitle = styled(Text).attrs({
  variant: 'h3',
  textColor: Colors.BLUE_1
})`
  line-height: normal;
  font-size: 16px;
  ${props => props.isSmallerScreen ? 'margin-top: 14px;' : ''}
`;

const NotificationContent = styled.div`
  display: flex;
  gap: 16px;
  align-content: flex-start;
`;

const NotificationDate = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80px;
  height: 66px;
  background: ${Colors.BLUE_2};
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 14px;
  line-height: 1;
`;

const NotificationMonth = styled.div`
  text-align: center;
  width: 80px;
  height: 14px;
  font-size: 14px;
  padding-top: 8px;
`;

const NotificationDay = styled.div`
  text-align: center;
  width: 80px;
  height: 22px;
  font-size: 22px;
  font-weight: bold;
  padding-top: 4px;
`;

const NotificationYear = styled.div`
  text-align: right;
  width: 80px;
  height: 16px;
  font-size: 10px;
  color: ${Colors.GRAY_50};
  padding: 4px;
`;

const RawHtmlContainer = styled.div`
`;

const IntersectionTracker = styled.hr`
  width: 100%;
  border: 1px solid ${Colors.BLACK};
  visibility: hidden;
`;

class MyNotificationsListPageItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.intersecionRef = React.createRef();
  }

  componentDidMount() {
    this.observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.props.onItemViewed({
          notificationId: this.props.dataItem?.id
        });
      }
    });
    this.observer.observe(this.intersecionRef.current);
  }

  componentWillUnmount() {
    this.observer.unobserve(this.intersecionRef.current);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const { title, text, visibleFrom } = this.props.dataItem;
    const date = new Date(visibleFrom);
    const day = this.getDayNumber(date.getDate());
    const month = this.getLocalizedMonth(date.getMonth() - 1, localizationService);
    const year = date.getFullYear();
    const isSmallerScreen = window.innerWidth < SCREEN_SIZES.XS;
    const notificationContent = isSmallerScreen
      ? (
        <>
          <NotificationContent>
            <NotificationDate>
              <NotificationMonth>{month}</NotificationMonth>
              <NotificationDay>{day}</NotificationDay>
              <NotificationYear>{year}</NotificationYear>
            </NotificationDate>
            <NotificationTitle isSmallerScreen={isSmallerScreen}>{title}</NotificationTitle>
          </NotificationContent>
          <RawHtmlContainer dangerouslySetInnerHTML={{ __html: text }}></RawHtmlContainer>
        </>
      ) : (
        <>
          <NotificationTitle>{title}</NotificationTitle>
          <NotificationContent>
            <NotificationDate>
              <NotificationMonth>{month}</NotificationMonth>
              <NotificationDay>{day}</NotificationDay>
              <NotificationYear>{year}</NotificationYear>
            </NotificationDate>
            <RawHtmlContainer dangerouslySetInnerHTML={{ __html: text }}></RawHtmlContainer>
          </NotificationContent>
        </>
      );

    return (
      <NotificationContainer isSmallerScreen={isSmallerScreen}>
        {notificationContent}
        <IntersectionTracker ref={this.intersecionRef}/>
      </NotificationContainer>
    );
  }

  getLocalizedMonth(index, localizationService) {
    return localizationService.toLanguageString(`monthsShort.${months[index]}`);
  }

  getDayNumber(number) {
    if (number < 10) {
      return `0${number}`;
    }
    return number.toString();
  }
}

registerForLocalization(MyNotificationsListPageItem);

export default MyNotificationsListPageItem;