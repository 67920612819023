import axios from 'axios';
import CompetencySubcompetencySelectPageActionTypes from './competency-subcompetency-select-page-action-types';
import { formatNumber } from '../../../utils';

export const loadCompetencySubcompetencySelectPage = (payload) => async(dispatch, getState, { api }) => {
  const [competency, subcompetencies] = await Promise.all([
    api.get(`api/competencies/${payload.competencyId}`),
    api.get(`api/competencies/${payload.competencyId}/subcompetencies`)
  ]);
  dispatch({
    type: CompetencySubcompetencySelectPageActionTypes.LOAD_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START,
    payload: {
      competencyId: payload.competencyId,
      studyProgramId: competency.studyProgramId,
      subcompetencyIds: subcompetencies?.data?.map(x => (x.id))
    }
  });
  dispatch(findCompetencySubcompetencySelectPage(payload));
};

export const findCompetencySubcompetencySelectPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencySubcompetencySelectPageActionTypes.FIND_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START });
  const state = getState().competencySubcompetencySelectPage;
  const query = {
    skip: payload?.skip ?? state.skip,
    limit: payload?.take ?? state.take,
    studyProgramId: state.studyProgramId,
    keyword: state.filter?.keyword
  };
  try {
    state.availableSubcompetenciesCancelToken?.cancel();
    state.availableSubcompetenciesCancelToken = axios.CancelToken.source();
    const subcompetencies = await api.get(`api/subcompetencies`, query, state.availableSubcompetenciesCancelToken.token);
    subcompetencies?.data?.map(subcompetency => ({
      ...subcompetency,
      isSelected: state.selectedIds?.includes(subcompetency.id)
    }));
    dispatch({
      type: CompetencySubcompetencySelectPageActionTypes.FIND_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_END,
      payload: {
        subcompetencies: subcompetencies
          ?
          subcompetencies.data?.map(subcompetency => ({
            ...subcompetency,
            isDisabled: state.subcompetencyIds?.includes(subcompetency.id),
            isSelected: state.subcompetencyIds?.includes(subcompetency.id) ? true : state.selectedAll ? true : state.selectedIds?.includes(subcompetency.id)
          }))
          : [],
        total: subcompetencies ? subcompetencies.total : 0,
        skip: query.skip,
        take: query.limit
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencySubcompetencySelectPageActionTypes.FIND_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_END });
      throw error;
    }
  }
};

export const filterCompetencySubcompetencySelectPage = (payload) => (dispatch) => {
  dispatch({
    type: CompetencySubcompetencySelectPageActionTypes.FILTER_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE,
    payload: payload
  });
  dispatch(findCompetencySubcompetencySelectPage());
};

export const clearCompetencySubcompetencySelectPage = () => (dispatch) => {
  dispatch({
    type: CompetencySubcompetencySelectPageActionTypes.FILTER_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE,
    payload: {}
  });
  dispatch(findCompetencySubcompetencySelectPage());
};

export const selectCompetencySubcompetencySelectPage = (payload) => (dispatch, getState) => {
  const state = getState().competencySubcompetencySelectPage;
  let selectedIds = [...state.selectedIds];
  const availableSubcompetencies = state.availableSubcompetencies?.map(subcompetency => {
    let isSelected = subcompetency.isSelected;
    if (subcompetency.id === payload.id) {
      const competencyIncludes = state.subcompetencyIds?.includes(subcompetency.id);
      isSelected = competencyIncludes ? true : !isSelected;
      if (!competencyIncludes) {
        if (isSelected) {
          if (selectedIds.indexOf(subcompetency.id) === -1) {
            selectedIds.push(subcompetency.id);
          }
        } else {
          const index = selectedIds.indexOf(subcompetency.id);
          if (index !== -1) {
            selectedIds.splice(index, 1);
          }
        }
      }
    }
    return {
      ...subcompetency,
      isSelected: isSelected
    };
  });
  dispatch({
    type: CompetencySubcompetencySelectPageActionTypes.SELECT_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE,
    payload: {
      availableSubcompetencies: availableSubcompetencies,
      selectedIds: selectedIds
    }
  });
};

export const selectAllCompetencySubcompetencySelectPage = () => (dispatch, getState) => {
  const state = getState().competencySubcompetencySelectPage;
  const availableSubcompetencies = state.availableSubcompetencies?.map(subcompetency => ({
    ...subcompetency,
    isSelected: state.subcompetencyIds?.includes(subcompetency.id) ? true : !state.selectedAll
  }));
  const selectedIds = availableSubcompetencies.filter(subcompetency => subcompetency.isSelected == true).map(subcompetency => subcompetency.id);
  dispatch({
    type: CompetencySubcompetencySelectPageActionTypes.SELECT_ALL_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE,
    payload: {
      availableSubcompetencies: availableSubcompetencies,
      selectedIds
    }
  });
};

export const addSelectedCompetencySubcompetencySelectPage = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().competencySubcompetencySelectPage;
  dispatch({
    type: CompetencySubcompetencySelectPageActionTypes.ADD_SELECTED_SUBCOMPETENCIES_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START
  });
  let errorMessage = null;
  let successMessage = null;
  let errorsCount = 0;
  let subcompetencyIds = null;
  let totalCount = 0;
  try {
    const apiModel = state.selectedIds.filter(x => !state.subcompetencyIds?.includes(x)).map(item => ({
      subcompetencyId: item,
      competencyId: state.competencyId
    }));
    if (apiModel?.length > 0) {
      totalCount = apiModel.length;
      const result = await api.post(`api/competencies/${state.competencyId}/subcompetencies`, apiModel);
      if (result) {
        errorsCount = result?.filter(x => x.statusCode === 400 || x.body?.errors?.length > 0)?.length || 0;
      }
    }
    const subcompetencies = await api.get(`api/competencies/${payload.competencyId}/subcompetencies`);
    subcompetencyIds = subcompetencies?.data?.map(x => (x.id));
  } finally {
    errorMessage = null;
    if (errorsCount === 0) {
      successMessage = `${formatSubcompetencyMessage(totalCount, payload.localizationService)} ${payload.localizationService.toLanguageString(`systemMessages.isSuccessfullyAdded`)}`;
    } else {
      const successfulCarriersCount = totalCount - errorsCount;
      if (successfulCarriersCount > 0) {
        errorMessage = `${formatSubcompetencyMessage(successfulCarriersCount, payload.localizationService)} ${payload.localizationService.toLanguageString(`systemMessages.isSuccessfullyAdded`)} 
        ${formatSubcompetencyMessage(errorsCount, payload.localizationService)} ${payload.localizationService.toLanguageString(`systemMessages.isNotAddedBecauseOfErrors`)}`;
      } else {
        errorMessage = `${formatSubcompetencyMessage(errorsCount, payload.localizationService)} ${payload.localizationService.toLanguageString(`systemMessages.isNotAddedBecauseOfErrors`)}`;
      }
    }
    dispatch({
      type: CompetencySubcompetencySelectPageActionTypes.ADD_SELECTED_SUBCOMPETENCIES_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_END,
      payload: {
        errorMessage: errorMessage,
        successMessage: successMessage,
        subcompetencyIds: subcompetencyIds
      }
    });
    dispatch(filterCompetencySubcompetencySelectPage());
  }
};

const formatSubcompetencyMessage = (value, localizationService) => {
  return formatNumber(value, { unitSingular: localizationService.toLanguageString(`competency.subcompetency`), unitPlural: localizationService.toLanguageString(`competency.subcompetencies`) });
};

export const cleanupCompetencySubcompetencySelectPage = () => (dispatch) => {
  dispatch({ type: CompetencySubcompetencySelectPageActionTypes.CLEANUP_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE });
};
