import CompetencyRequestSummaryPageActionTypes from '../actions/competency-request-summary-page-action-types';

const initialState = {
  loading: false,
  competencyRequest: null,
  competencyRequestCancelToken: null,
  isReturnDialogVisible: false,
  isGrantConfirmDialogVisible: false,
  signingRedirectUrl: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencyRequestSummaryPageActionTypes.LOAD_COMPETENCY_REQUEST_SUMMARY_START:
      return {
        ...state,
        loading: true
      };
    case CompetencyRequestSummaryPageActionTypes.LOAD_COMPETENCY_REQUEST_SUMMARY_END:
      return {
        ...state,
        loading: false,
        competencyRequest: action.payload.competencyRequest
      };
    case CompetencyRequestSummaryPageActionTypes.EXPAND_COMPETENCY_REQUEST_SUMMARY_SURVEY_QUESTION:
      return {
        ...state,
        competencyRequest: action.payload.competencyRequest
      };
    case CompetencyRequestSummaryPageActionTypes.TOGGLE_COMPETENCY_REQUEST_RETURN_DIALOG:
      return {
        ...state,
        isReturnDialogVisible: !state.isReturnDialogVisible
      };
    case CompetencyRequestSummaryPageActionTypes.TOGGLE_COMPETENCY_GRANT_CONFIRM_DIALOG:
      return {
        ...state,
        isGrantConfirmDialogVisible: !state.isGrantConfirmDialogVisible
      };
    case CompetencyRequestSummaryPageActionTypes.CLEAR_COMPETENCY_REQUEST_SUMMARY_PAGE:
      return initialState;
    case CompetencyRequestSummaryPageActionTypes.INIT_SIGNING_START:
      return state;
    case CompetencyRequestSummaryPageActionTypes.INIT_SIGNING_END:
      return {
        ...state,
        signingRedirectUrl: action.payload.signingRedirectUrl
      };
    default:
      return state;
  }
};