import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, StackLayout, WrapLayout, DropDown, TextBox } from '../../../ui';
import { filterApprovalRequests, clearApprovalRequestsFilter } from '../actions/approval-request-list-page-actions';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import styled from 'styled-components';
import { STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS } from '../../../resources/studyPlanCycleStudentApprovingUserStatus';
import { getStudyPlanCycleStudentApprovingUserStatusColor } from '../../../utils/studyPlanCycleStudentApprovingUserStatusColors';

const DropdownItemContainer = styled.div`
  height: 24px;
  padding-top: 8px;
`;

const StyledBadge = styled(Badge)`
  opacity: 75%
`;

class ApprovalRequestListFilter extends React.PureComponent {

  constructor(props) {
    super(props);
    this.statusDropdownItemRender = this.statusDropdownItemRender.bind(this);
  }

  render() {
    const { filter, clearApprovalRequestsFilter, filterApprovalRequests } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterApprovalRequests}
        onClear={clearApprovalRequestsFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='status'
                width='340px'
                component={DropDown}
                itemRender={this.statusDropdownItemRender}
                label={localizationService.toLanguageString('approvalRequest.status')}
                textField='text'
                dataItemKey='value'
                data={this.getStatuses()}
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }

  getStatuses() {
    const localizationService = provideLocalizationService(this);
    return Object.entries(STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS).map(element => ({
      value: element[1],
      text: localizationService.toLanguageString(`studyPlanCycleStudentApprovingUserStatus.${element[1]}`)
    }));
  }

  statusDropdownItemRender(li, itemProps) {
    const itemChildren = (
      <DropdownItemContainer>
        <BadgeContainer>
          <StyledBadge
            shape='rectangle'
            size='large'
            themeColor={getStudyPlanCycleStudentApprovingUserStatusColor(itemProps.dataItem.value)}
            position='outside'
            align={{ vertical: 'top', horizontal: 'end' }}
          >
            {li.props.children}
          </StyledBadge>
        </BadgeContainer>
      </DropdownItemContainer>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }
}

const mapStateToProps = state => ({
  filter: state.approvalRequestListPage.filter
});

const mapDispatchToProps = dispatch => ({
  filterApprovalRequests: (payload) => dispatch(filterApprovalRequests(payload)),
  clearApprovalRequestsFilter: () => dispatch(clearApprovalRequestsFilter())
});

registerForLocalization(ApprovalRequestListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalRequestListFilter);