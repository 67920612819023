import { Field } from '@progress/kendo-react-form';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ComboBox, FilterForm, SelectListPage, StackLayout, TextBox, WrapLayout, DropDown } from '../../../ui';
import { Grid, GridSize, InlineBadge } from '../../shared/components';
import {
  addSelectedStudyPlanCycleStudentSelectPage,
  findStudyPlanCycleStudentSelectPage,
  loadStudyPlanCycleStudentSelectPage,
  selectStudyPlanCycleStudent,
  selectAllStudyPlanCycleStudentSelectPage,
  selectStudyPlanCycleStudentSelectPage,
  filterStudyPlanCycleStudentSelectPage,
  clearStudyPlanCycleStudentSelectPage
} from '../actions';
import { cleanupStudyPlanCycleStudentSelectPage, closeStudyPlanCycleStudentSelectPageSaveDialog, openStudyPlanCycleStudentSelectPageSaveDialog } from '../actions/study-plan-cycle-student-select-page-actions';
import { withRouter } from '../../../components/withRouter';
import FormDialog from '../../../ui/components/application/form-dialog';
import { getStudentStatusColor } from '../../../utils/studentStatusColors';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import styled from 'styled-components';
import { STUDENT_STATUSES } from '../../../resources/studentStatus';

const DropdownItemContainer = styled.div`
  height: 24px;
  padding-top: 8px;
`;

const StyledBadge = styled(Badge)`
  opacity: 75%;
  font-size: small;
`;

class StudyPlanCycleStudentSelectPage extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    await this.props.loadStudyPlanCycleStudentSelectPage({
      studyPlanId: this.props.params.studyPlanId,
      studyPlanCycleId: this.props.params.studyPlanCycleId
    }, localizationService);
  }

  componentWillUnmount() {
    this.props.cleanupStudyPlanCycleStudentSelectPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const {
      availableStudentStatuses
    } = this.props;
    return (
      <>
        <SelectListPage
          id='study-plan-cycle-student-select'
          errorMessage={this.props.errorMessage}
          successMessage={this.props.successMessage}
          breadcrumbItems={[
            {
              id: 'study-plans',
              text: localizationService.toLanguageString('StudyPlanCycle.studyPlans')
            },
            {
              id: 'study-plan',
              text: localizationService.toLanguageString('StudyPlanCycle.studyPlan')
            },
            {
              id: 'study-plan-cycle',
              text: localizationService.toLanguageString('StudyPlanCycle.studyPlanCycle'),
              disabled: false
            },
            {
              id: 'study-plan-cycle-student-select',
              text: localizationService.toLanguageString('StudyPlanCycle.students'),
              disabled: true
            }
          ]}
          onBackClick={this.handleBackClick}
          onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
          filter={
            <FilterForm
              initialValues={this.props.initialValues}
              onSubmit={this.props.filterStudyPlanCycleStudentSelectPage}
              onClear={this.props.clearStudyPlanCycleStudentSelectPage}
              localizationService={localizationService}
              title={localizationService.toLanguageString('grid.filterTitle')}
              uniqueKey={JSON.stringify(this.props.initialValues)}
              render={() => {
                return (
                  <WrapLayout>
                    <Field
                      name='student'
                      width='340px'
                      label={localizationService.toLanguageString('StudyPlanCycleStudent.student')}
                      component={TextBox}
                    />
                    <Field
                      name='status'
                      width='340px'
                      label={localizationService.toLanguageString('StudyPlanCycleStudent.status')}
                      component={DropDown}
                      textField='name'
                      dataItemKey='id'
                      data={availableStudentStatuses}
                      itemRender={this.statusDropdownItemRender}
                    />
                    <Field
                      name='year'
                      width='340px'
                      label={localizationService.toLanguageString('StudyPlanCycleStudent.year')}
                      component={ComboBox}
                      textField='name'
                      dataItemKey='id'
                      data={this.props.availableStudyYears}
                    />
                  </WrapLayout>
                );
              }}
            >
            </FilterForm>
          }
        >
          <Grid
            loading={this.props.loading}
            data={this.props.availableStudents}
            skip={this.props.skip}
            take={this.props.take}
            pageSize={this.props.take}
            total={this.props.total}
            onPageChange={this.handlePageChange}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: 'multiple'
            }}
            dataItemKey='id'
            selectedField='isSelected'
            onSelectionChange={this.handleSelect}
            onHeaderSelectionChange={this.handleSelectAll}
            columns={[
              {
                field: 'isSelected',
                width: '50px',
                headerSelectionValue: this.props.selectedAll,
                cell: this.renderSelected
              },
              {
                field: 'displayName',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('StudyPlanCycleStudent.student')
              },
              {
                field: 'status',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('StudyPlanCycleStudent.status'),
                cell: ({ className, dataItem }) =>
                  <td className={className}>
                    <InlineBadge themeColor={getStudentStatusColor(dataItem.status)}>
                      {localizationService.toLanguageString(`studentStatus.${dataItem.status}`)}
                    </InlineBadge>
                  </td>
              },
              {
                field: 'year',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('StudyPlanCycleStudent.year'),
                minGridWidth: GridSize.small
              },
              {
                field: 'isAlreadyAdded',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('StudyPlanCycleStudent.isAlreadyAdded'),
                cell: (e) => this.renderIsAlreadyAdded(e, localizationService)
              }
            ]}
          >
            <GridToolbar>
              <Button
                type='button'
                themeColor='primary'
                onClick={this.props.openStudyPlanCycleStudentSelectPageSaveDialog}
                disabled={!this.props.availableStudents?.some(student => student.isSelected)}
              >
                {localizationService.toLanguageString('StudyPlanCycleStudent.addWithManager')}
              </Button>
              <Button
                type='button'
                themeColor='primary'
                onClick={this.handleSubmit}
                disabled={!this.props.availableStudents?.some(student => student.isSelected)}
              >
                {localizationService.toLanguageString('StudyPlanCycleStudent.addWithoutManager')}
              </Button>
            </GridToolbar>
          </Grid>
        </SelectListPage>
        {this.props.savingSelectedStudents && this.renderLoader(localizationService)}
        {this.props.isSaveDialogVisible &&
          <FormDialog
            title={localizationService.toLanguageString('StudyPlanCycleStudent.saveDialogTitle')}
            width={'500px'}
            onSubmit={this.handleSubmit}
            initialValues={{ cycleManager:
              this.props.availableCycleManagers?.length === 1
                ? { id: this.props.availableCycleManagers[0].id, name: this.props.availableCycleManagers[0].name }
                : null
            }}
            onCancel={this.props.closeStudyPlanCycleStudentSelectPageSaveDialog}
            confirmButtonText={localizationService.toLanguageString('buttons.add')}
            closeButtonText={localizationService.toLanguageString('buttons.close')}
            validator={(values) => this.handleSaveDialogValidate(values, localizationService)}
            render={() => (
              <StackLayout>
                <Field
                  name='cycleManager'
                  component={ComboBox}
                  label={localizationService.toLanguageString('StudyPlanCycleStudent.cycleManager')}
                  data={this.props.availableCycleManagers}
                  textField='name'
                  valueField='id'
                />
              </StackLayout>
            )}
          />
        }
      </>
    );
  }

  statusDropdownItemRender(li, itemProps) {
    let color;
    switch (itemProps.dataItem.id) {
      case STUDENT_STATUSES.STUDYING:
        color = 'primary';
        break;
      case STUDENT_STATUSES.TEMPORARILY_NOT_STUDYING:
        color = 'warning';
        break;
      case STUDENT_STATUSES.REMOVED_FROM_LIST:
        color = 'error';
        break;
      case STUDENT_STATUSES.GRADUATED:
        color = 'success';
        break;
      default:
        color = 'inverse';
        break;
    }
    const itemChildren = (
      <DropdownItemContainer>
        <BadgeContainer>
          <StyledBadge rounded={'medium'} size={'large'} themeColor={color} position={'outside'} align={{ vertical: 'top', horizontal: 'end' }}>
            {li.props.children}
          </StyledBadge>
        </BadgeContainer>
      </DropdownItemContainer>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }

  renderSelected(e) {
    return (
      <td role='gridcell'>
        <Checkbox checked={e.dataItem.isAlreadyAdded || e.dataItem.isSelected} disabled={e.dataItem.isAlreadyAdded} onChange={() => e.selectionChange(e)} />
      </td>
    );
  }

  renderIsAlreadyAdded(e, localizationService) {
    return (
      <td role='gridcell'>{e.dataItem.isAlreadyAdded ? localizationService.toLanguageString('common.yes') : localizationService.toLanguageString('common.no')}</td>
    );
  }

  async handlePageChange(e) {
    await this.props.findStudyPlanCycleStudentSelectPage({ skip: e.page.skip, take: e.page.take });
  }

  handleSelect(e) {
    if (e.dataItem) {
      this.props.selectStudyPlanCycleStudentSelectPage(e.dataItem);
    }
  }

  handleSelectAll() {
    this.props.selectAllStudyPlanCycleStudentSelectPage();
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-plans') {
      this.props.navigate('/study_plans');
    } else if (e.id === 'study-plan') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/overview`);
    } else if (e.id === 'study-plan-cycle') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/cycles/${this.props.params.studyPlanCycleId}/overview`);
    }
  }

  handleSubmit(values) {
    const localizationService = provideLocalizationService(this);
    this.props.addSelectedStudyPlanCycleStudentSelectPage({ localizationService, cycleManagerId: values?.cycleManager?.id });
  }

  renderLoader(localizationService) {
    const loadingPanel = (
      <div className='k-loading-mask'>
        <span className='k-loading-text'>{localizationService.toLanguageString('custom.loading')}</span>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
    const gridContent = document && document.querySelector('#study-plan-cycle-student-select');
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }

  handleSaveDialogValidate(values, localizationService) {
    const errors = {};
    if (!values.cycleManager?.id) {
      errors.cycleManager = localizationService.toLanguageString('validation.required');
    }
    return errors;
  }
}

registerForLocalization(StudyPlanCycleStudentSelectPage);

const mapStateToProps = state => ({
  loading: state.studyPlanCycleStudentSelectPage.loading,
  availableStudents: state.studyPlanCycleStudentSelectPage.availableStudents,
  availableStudyYears: state.studyPlanCycleStudentSelectPage.availableStudyYears,
  skip: state.studyPlanCycleStudentSelectPage.skip,
  take: state.studyPlanCycleStudentSelectPage.take,
  total: state.studyPlanCycleStudentSelectPage.total,
  selectedAll: state.studyPlanCycleStudentSelectPage.selectedAll,
  savingSelectedStudents: state.studyPlanCycleStudentSelectPage.savingSelectedStudents,
  errorMessage: state.studyPlanCycleStudentSelectPage.errorMessage,
  successMessage: state.studyPlanCycleStudentSelectPage.successMessage,
  isSaveDialogVisible: state.studyPlanCycleStudentSelectPage.isSaveDialogVisible,
  availableCycleManagers: state.studyPlanCycleStudentSelectPage.availableCycleManagers,
  availableStudentStatuses: state.studyPlanCycleStudentSelectPage.availableStudentStatuses
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanCycleStudentSelectPage: (payload, localizationService) => dispatch(loadStudyPlanCycleStudentSelectPage(payload, localizationService)),
  findStudyPlanCycleStudentSelectPage: (payload) => dispatch(findStudyPlanCycleStudentSelectPage(payload)),
  selectStudyPlanCycleStudent: (payload) => dispatch(selectStudyPlanCycleStudent(payload)),
  selectStudyPlanCycleStudentSelectPage: (payload) => dispatch(selectStudyPlanCycleStudentSelectPage(payload)),
  selectAllStudyPlanCycleStudentSelectPage: (payload) => dispatch(selectAllStudyPlanCycleStudentSelectPage(payload)),
  addSelectedStudyPlanCycleStudentSelectPage: (payload) => dispatch(addSelectedStudyPlanCycleStudentSelectPage(payload)),
  openStudyPlanCycleStudentSelectPageSaveDialog: () => dispatch(openStudyPlanCycleStudentSelectPageSaveDialog()),
  closeStudyPlanCycleStudentSelectPageSaveDialog: () => dispatch(closeStudyPlanCycleStudentSelectPageSaveDialog()),
  cleanupStudyPlanCycleStudentSelectPage: () => dispatch(cleanupStudyPlanCycleStudentSelectPage()),
  filterStudyPlanCycleStudentSelectPage: (payload) => dispatch(filterStudyPlanCycleStudentSelectPage(payload)),
  clearStudyPlanCycleStudentSelectPage: () => dispatch(clearStudyPlanCycleStudentSelectPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleStudentSelectPage));