import SurveyListPageActionTypes from './survey-list-page-action-types';
import axios from 'axios';
import { sortingToStringWithSubstitutes } from '../../../utils/objectUtils';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadSurveyListPage = (payload) => async(dispatch, getState) => {
  dispatch(findSurveys(payload));
  dispatch(searchSurveysFilterStudyPrograms());
};

export const findSurveys = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: SurveyListPageActionTypes.LOAD_SURVEY_LIST_START });
  const state = getState().surveyListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToStringWithSubstitutes(state.sorting, [
      { originalName: 'studyProgramName', substituteName: 'StudyProgram' },
      { originalName: 'code', substituteName: 'Code' },
      { originalName: 'type', substituteName: 'Type' },
      { originalName: 'name', substituteName: 'Name' }
    ]),
    keyword: state.filter?.keyword,
    studyProgramId: state?.filter?.studyProgram?.id,
    extendedSearch: true
  };
  try {
    state.surveysCancelToken?.cancel();
    state.surveysCancelToken = axios.CancelToken.source();
    const surveys = await api.get(`api/surveys`, query, state.surveysCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.COMPETENCIES_WRITE);
    dispatch({
      type: SurveyListPageActionTypes.LOAD_SURVEY_LIST_END,
      payload: {
        surveys: surveys ? surveys.data : [],
        total: surveys ? surveys.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take,
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: SurveyListPageActionTypes.LOAD_SURVEY_LIST_END });
      throw error;
    }
  }
};

export const filterSurveys = (payload) => (dispatch) => {
  dispatch({
    type: SurveyListPageActionTypes.FILTER_SURVEY_LIST,
    payload: payload
  });
  dispatch(findSurveys());
};

export const sortSurveys = (payload) => (dispatch) => {
  dispatch({
    type: SurveyListPageActionTypes.SORT_SURVEY_LIST,
    payload: payload
  });
  dispatch(findSurveys());
};

export const clearSurveysFilter = () => (dispatch) => {
  dispatch({
    type: SurveyListPageActionTypes.FILTER_SURVEY_LIST,
    payload: {}
  });
  dispatch(findSurveys());
};

export const openSurveyConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: SurveyListPageActionTypes.TOGGLE_SURVEY_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeSurveyConfirmDialog = () => async(dispatch) => {
  dispatch({ type: SurveyListPageActionTypes.TOGGLE_SURVEY_LIST_CONFIRM_DIALOG });
};

export const removeSurveyConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().surveyListPage;
  if (state.selectedSurveyId > 0) {
    dispatch({ type: SurveyListPageActionTypes.LOAD_SURVEY_LIST_START });
    try {
      await api.remove(`api/surveys/${state.selectedSurveyId}`);
      dispatch(loadSurveyListPage());
    } catch (error) {
      dispatch({ type: SurveyListPageActionTypes.LOAD_SURVEY_LIST_END });
    }
  }
  dispatch({ type: SurveyListPageActionTypes.TOGGLE_SURVEY_LIST_CONFIRM_DIALOG });
};

export const searchSurveysFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: SurveyListPageActionTypes.SEARCH_SURVEY_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().surveyListPage;
  try {
    state.filterStudyProgramsCancelToken?.cancel();
    state.filterStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsCancelToken.token);
    dispatch({
      type: SurveyListPageActionTypes.SEARCH_SURVEY_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: SurveyListPageActionTypes.SEARCH_SURVEY_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};