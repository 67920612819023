import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import { Colors, ComboBox, DropDown, FormPage, NumericBox, Text, TextArea } from '../../../ui';
import { loadCompetencySurveyQuestionFormPage, saveCompetencySurveyQuestion, loadSubcompetencies, setSubcompetencyMatchFlag, clearCompetencySurveyQuestionFormPage } from '../actions/competency-survey-question-form-page-actions';
import { SURVEY_QUESTION_TYPES } from '../../../resources/surveyQuestionType';
import { withRouter } from '../../../components/withRouter';
import { Error } from '@progress/kendo-react-labels';
import { SURVEY_TYPES } from '../../../resources/surveyType';
import { EmptyFormatter } from '../../shared/components';

class CompetencySurveyQuestionFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSubcompetencyChange = this.handleSubcompetencyChange.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.competencyId && this.props.params?.competencySurveyId || this.props.params.surveyId) {
      const localizationService = provideLocalizationService(this);
      await this.props.loadCompetencySurveyQuestionFormPage({
        competencyId: this.props.params.competencyId,
        competencySurveyId: this.props.params.competencySurveyId,
        surveyQuestionId: this.props.params.surveyQuestionId,
        surveyId: this.props.params.surveyId,
        localizationService
      });
    }
  }

  componentWillUnmount() {
    this.props.clearCompetencySurveyQuestionFormPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('competency.surveyQuestion')}
        breadcrumbItems={this.getBreadcrumbs()}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        renderBackButton={true}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.competencySurveyQuestion}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <>
            <Field
              name='number'
              label={localizationService.toLanguageString('competency.number')}
              min={1}
              component={NumericBox}
              width='320px'
            />
            <Text textColor={Colors.GRAY_50} variant='caption'>{`${localizationService.toLanguageString('competency.greatestExistingQuestionNumber')}: ${EmptyFormatter(this.props.greatestExistingSurveyQuestionNumber)}`}</Text>
            <Field
              name='surveyQuestionType'
              label={localizationService.toLanguageString('competency.type')}
              component={DropDown}
              data={this.getSurveyQuestionTypes()}
              textField='text'
              valueField='value'
              width='320px'
            />
            <Field
              name='questionText'
              label={localizationService.toLanguageString('competency.question')}
              component={TextArea}
              autoSize={true}
              width='320px'
            />
            <Field
              name='subcompetency'
              label={localizationService.toLanguageString('competency.subcompetency')}
              component={ComboBox}
              loading={this.props.availableSubcompetenciesLoading}
              data={this.props.availableSubcompetencies}
              onFilter={e => this.props.loadSubcompetencies({ ...e, competencyId: this.props.params.competencyId, surveyId: this.props.params.surveyId })}
              onChange={this.handleSubcompetencyChange}
              textField='name'
              valueField='id'
              width='320px'
            />
            {!this.props.isSubcompetencyMatching &&
              <Error>{localizationService.toLanguageString('competency.subcompetencyMismatch')}</Error>
            }
            <Field
              name='questionNote'
              label={localizationService.toLanguageString('competency.note')}
              component={TextArea}
              autoSize={true}
              width='320px'
            />
          </>
        )}
      />
    );
  }

  handleSubcompetencyChange() {
    this.props.setSubcompetencyMatchFlag({ value: true });
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.number) {
      errors.number = localizationService.toLanguageString('validation.required');
    }
    if (!values.questionText) {
      errors.questionText = localizationService.toLanguageString('validation.required');
    }
    if (!values.surveyQuestionType) {
      errors.surveyQuestionType = localizationService.toLanguageString('validation.required');
    }
    if (!values.subcompetency) {
      errors.subcompetency = localizationService.toLanguageString('validation.required');
    }
    if (!modified.number && !modified.questionText && !modified.surveyQuestionType && !modified.subcompetency) {
      if (validationResult?.errors?.number) {
        errors.number = validationResult?.errors?.number;
      }
      if (validationResult?.errors?.questionText) {
        errors.questionText = validationResult?.errors?.questionText;
      }
      if (validationResult?.errors?.type) {
        errors.surveyQuestionType = validationResult?.errors?.type;
      }
      if (validationResult?.errors?.subcompetencyId) {
        errors.subcompetency = validationResult?.errors?.subcompetencyId;
      }
    }
    return errors;
  }

  getSurveyQuestionTypes() {
    const localizationService = provideLocalizationService(this);
    const surveyQuestionTypes = [{
      value: SURVEY_QUESTION_TYPES.TEXT,
      text: localizationService.toLanguageString('surveyQuestionType.Text')
    }];

    if (this.props.competencySurveyQuestion?.surveyType === SURVEY_TYPES.SURVEY_360) {
      surveyQuestionTypes.push({
        value: SURVEY_QUESTION_TYPES.NUMERIC_ZERO_THROUGH_FIVE,
        text: localizationService.toLanguageString('surveyQuestionType.NumericZeroThroughFive')
      });
    } else {
      surveyQuestionTypes.push({
        value: SURVEY_QUESTION_TYPES.NUMERIC_ONE_THROUGH_FIVE,
        text: localizationService.toLanguageString('surveyQuestionType.NumericOneThroughFive')
      });
    }

    return surveyQuestionTypes;
  }

  getBreadcrumbs() {
    const breadcrumbs = [];
    const localizationService = provideLocalizationService(this);

    if (this.props.params.surveyId > 0) {
      breadcrumbs.push({
        id: 'surveys',
        text: localizationService.toLanguageString('competency.surveys')
      });
    } else {
      breadcrumbs.push(
        {
          id: 'competencies',
          text: localizationService.toLanguageString('competency.tieredCompetencies')
        }, {
          id: 'competency',
          text: localizationService.toLanguageString('competency.tieredCompetency')
        }
      );
    }

    breadcrumbs.push(
      {
        id: 'survey',
        text: localizationService.toLanguageString('competency.survey')
      }, {
        id: 'surveyQuestion',
        text: localizationService.toLanguageString('competency.surveyQuestion'),
        disabled: true
      }
    );

    return breadcrumbs;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'competencies') {
      this.props.navigate('/competencies');
    }
    if (e.id === 'competency') {
      this.props.navigate(`/competencies/${this.props.params.competencyId}/overview`);
    }
    if (e.id === 'survey') {
      if (this.props.params.surveyId > 0) {
        this.props.navigate(`/surveys/${this.props.params.surveyId}/overview`);
      } else {
        this.props.navigate(`/competencies/${this.props.params.competencyId}/surveys/${this.props.params.competencySurveyId}/overview`);
      }
    }
    if (e.id === 'surveys') {
      this.props.navigate('/surveys');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveCompetencySurveyQuestion({ ...payload, competencyId: this.props.params.competencyId, competencySurveyId: this.props.params.competencySurveyId });
  }
}

registerForLocalization(CompetencySurveyQuestionFormPage);

const mapStateToProps = state => ({
  loading: state.competencySurveyQuestionFormPage.loading,
  competencySurveyQuestion: state.competencySurveyQuestionFormPage.competencySurveyQuestion,
  isSubcompetencyMatching: state.competencySurveyQuestionFormPage.isSubcompetencyMatching,
  greatestExistingSurveyQuestionNumber: state.competencySurveyQuestionFormPage.greatestExistingSurveyQuestionNumber,
  validationResult: state.competencySurveyQuestionFormPage.validationResult,
  availableSubcompetencies: state.competencySurveyQuestionFormPage.availableSubcompetencies,
  availableSubcompetenciesLoading: state.competencySurveyQuestionFormPage.availableSubcompetenciesLoading
});

const mapDispatchToProps = dispatch => ({
  loadCompetencySurveyQuestionFormPage: (payload) => dispatch(loadCompetencySurveyQuestionFormPage(payload)),
  saveCompetencySurveyQuestion: (payload) => dispatch(saveCompetencySurveyQuestion(payload)),
  loadSubcompetencies: (payload) => dispatch(loadSubcompetencies(payload)),
  setSubcompetencyMatchFlag: (payload) => dispatch(setSubcompetencyMatchFlag(payload)),
  clearCompetencySurveyQuestionFormPage: () => dispatch(clearCompetencySurveyQuestionFormPage())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompetencySurveyQuestionFormPage));