import StudentCompetencyListActionTypes from './student-competency-list-action-types';
import axios from 'axios';
import { sortingToString } from '../../../../utils/objectUtils';
import { CYCLE_STUDENT_STATUS } from '../../../../resources/studyPlanCycleDepartmentStudentStatus';

export const loadStudentCompetencyListPage = (payload) => async(dispatch, getState) => {
  dispatch(findStudentCompetencies(payload));
};

export const findStudentCompetencies = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencyListActionTypes.LOAD_STUDENT_COMPETENCIES_LIST_START });
  const states = getState();
  const state = states.studentCompetencyList;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToString(state.sorting),
    keyword: state.filter?.keyword,
    studentId: payload.studentId
  };
  try {
    state.studentCompetenciesCancelToken?.cancel();
    state.studentCompetenciesCancelToken = axios.CancelToken.source();

    const studentCompetencies = await api.get(`api/my_competencies`, query, state.studentCompetenciesCancelToken.token);

    dispatch({
      type: StudentCompetencyListActionTypes.LOAD_STUDENT_COMPETENCIES_LIST_END,
      payload: {
        studentCompetencies: studentCompetencies ? studentCompetencies.data.map(element => ({ ...element, expanded: false })) : [],
        total: studentCompetencies ? studentCompetencies.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencyListActionTypes.LOAD_STUDENT_COMPETENCIES_LIST_END });
      throw error;
    }
  }
};

export const filterStudentCompetencies = (payload, params) => (dispatch) => {
  dispatch({
    type: StudentCompetencyListActionTypes.FILTER_STUDENT_COMPETENCIES_LIST,
    payload: payload
  });
  dispatch(findStudentCompetencies(params));
};

export const sortStudentCompetencies = (payload, params) => (dispatch) => {
  dispatch({
    type: StudentCompetencyListActionTypes.SORT_STUDENT_COMPETENCIES_LIST,
    payload: payload
  });
  dispatch(findStudentCompetencies(params));
};

export const clearStudentCompetenciesFilter = (params) => (dispatch) => {
  dispatch({
    type: StudentCompetencyListActionTypes.FILTER_STUDENT_COMPETENCIES_LIST,
    payload: {}
  });
  dispatch(findStudentCompetencies(params));
};

export const loadStudentCompetenciesListDetails = ({ dataItem }) => async(dispatch, getState, { api }) => {
  const state = getState().studentCompetencyList;
  try {
    state.studentCompetenciesCancelToken?.cancel();
    state.studentCompetenciesCancelToken = axios.CancelToken.source();

    const [subcompetencies, competencyCycles, studentCycles] = await Promise.all([
      api.get(`api/competencies/${dataItem.id}/subcompetencies`, state.studentCompetenciesCancelToken.token),
      api.get(`api/competencies/${dataItem.id}/cycles`, state.studentCompetenciesCancelToken.token),
      api.get(`api/students/${dataItem.studentId}/cycles`, state.studentCompetenciesCancelToken.token)
    ]);

    const studentCompetencyCycles = competencyCycles?.data?.map(competencyCycle => {
      const status = studentCycles?.find(studentCycle => studentCycle.cycleId === competencyCycle.cycleId)?.status || CYCLE_STUDENT_STATUS.NOT_STARTED;
      return { ...competencyCycle, status };
    });

    dispatch({
      type: StudentCompetencyListActionTypes.LOAD_STUDENT_COMPETENCIES_LIST_DETAILS,
      payload: {
        studentCompetencies: getState().studentCompetencyList.studentCompetencies?.map(element => {
          if (element.id === dataItem.id) {
            return {
              ...element,
              loading: false,
              subcompetencies: subcompetencies?.data ?? [],
              cycles: studentCompetencyCycles ?? []
            };
          }
          return element;
        })
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencyListActionTypes.LOAD_STUDENT_COMPETENCIES_LIST_DETAILS });
      throw error;
    }
  }
};

export const changeExpandState = (dataItem) => (dispatch, getState) => {
  const studentCompetencies = getState().studentCompetencyList.studentCompetencies;
  const updateStudentCompetencies = studentCompetencies.map(element => {
    if (element.id === dataItem.id) {
      element.expanded = !dataItem.expanded;
    }
    return element;
  });
  dispatch({
    type: StudentCompetencyListActionTypes.CHANGE_STUDENT_COMPETENCIES_LIST_EXPAND_STATE,
    payload: { studentCompetencies: updateStudentCompetencies }
  });
};