import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import { FormPage, TextBox } from '../../../ui';
import { loadStudyProgramSkillAbilityFormPage, saveStudyProgramSkillAbility } from '../actions/study-program-skill-ability-form-page-actions';
import { withRouter } from '../../../components/withRouter';

class StudyProgramSkillAbilityFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyProgramId) {
      await this.props.loadStudyProgramSkillAbilityFormPage({
        studyProgramId: this.props.params.studyProgramId,
        studyProgramSkillAbilityId: this.props.params.studyProgramSkillAbilityId
      });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);

    return (
      <FormPage
        title={localizationService.toLanguageString('studyProgram.studyProgramSkillAbility')}
        breadcrumbItems={[
          {
            id: 'study-programs',
            text: localizationService.toLanguageString('studyProgram.studyPrograms')
          },
          {
            id: 'study-program',
            text: localizationService.toLanguageString('studyProgram.studyProgram')
          },
          {
            id: 'study-program-skill-ability',
            text: localizationService.toLanguageString('studyProgram.studyProgramSkillAbility'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.studyProgramSkillAbility}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <Field
            name='name'
            label={localizationService.toLanguageString('studyProgram.name')}
            component={TextBox}
            width='320px'
          />
        )}
      />
    );
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.name) {
      errors.name = localizationService.toLanguageString('validation.required');
    }
    if (!modified.name) {
      if (validationResult?.errors?.name) {
        errors.name = validationResult?.errors?.name;
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-programs') {
      this.props.navigate('/study_programs');
    }
    if (e.id === 'study-program') {
      this.props.navigate(`/study_programs/${this.props.params.studyProgramId}/overview`);
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveStudyProgramSkillAbility(payload);
  }
}

registerForLocalization(StudyProgramSkillAbilityFormPage);

const mapStateToProps = state => ({
  loading: state.studyProgramSkillAbilityFormPage.loading,
  studyProgramSkillAbility: state.studyProgramSkillAbilityFormPage.studyProgramSkillAbility,
  validationResult: state.studyProgramSkillAbilityFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadStudyProgramSkillAbilityFormPage: (payload) => dispatch(loadStudyProgramSkillAbilityFormPage(payload)),
  saveStudyProgramSkillAbility: (payload) => dispatch(saveStudyProgramSkillAbility(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyProgramSkillAbilityFormPage));
