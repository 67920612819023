import axios from 'axios';
import { StudyPlanCycleStudentListActionTypes } from '..';

export const loadStudyPlanCycleStudentList = ({ studyPlanId, studyPlanCycleId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleStudentListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_LIST_START });
  const state = getState().studyPlanCycleStudentList;
  try {
    state.studyPlanCycleStudentsCancelToken?.cancel();
    state.studyPlanCycleStudentsCancelToken = axios.CancelToken.source();
    const [ studyPlanCycleStudents, studyPlanCycleManagers ] = await Promise.all([
      api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students`, null, state.studyPlanCycleStudentsCancelToken.token),
      api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/managers`, null, state.studyPlanCycleStudentsCancelToken.token)
    ]);
    dispatch({
      type: StudyPlanCycleStudentListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_LIST_END,
      payload: {
        studyPlanCycleStudents: studyPlanCycleStudents?.data?.map(item => ({
          ...item,
          isSelected: false
        })),
        studyPlanCycleManagers
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleStudentListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_LIST_END });
      throw error;
    }
  }
};

export const openStudyPlanCycleStudentListConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyPlanCycleStudentListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_STUDENT_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeStudyPlanCycleStudentListConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleStudentListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_STUDENT_LIST_CONFIRM_DIALOG });
};

export const removeStudyPlanCycleStudentListConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleStudentList;
  let ids = null;
  if (state.selectedIds?.length > 0) {
    ids = state.selectedIds;
  } else if (state.selectedStudyPlanCycleStudentId > 0) {
    ids = [state.selectedStudyPlanCycleStudentId];
  }
  await api.remove(`api/study_plans/${payload.studyPlanId}/cycles/${payload.studyPlanCycleId}/students`, ids);
  dispatch({ type: StudyPlanCycleStudentListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_STUDENT_LIST_CONFIRM_DIALOG });
  dispatch(loadStudyPlanCycleStudentList({ studyPlanId: payload.studyPlanId, studyPlanCycleId: payload.studyPlanCycleId }));
};

export const openStudyPlanCycleStudentListChangeManagerDialog = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleStudentListActionTypes.OPEN_STUDY_PLAN_CYCLE_STUDENT_LIST_CHANGE_CYCLE_MANAGER_DIALOG });
};

export const closeStudyPlanCycleStudentListChangeManagerDialog = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleStudentListActionTypes.CLOSE_STUDY_PLAN_CYCLE_STUDENT_LIST_CHANGE_CYCLE_MANAGER_DIALOG });
};

export const saveStudyPlanCycleStudentListCycleManager = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleStudentListActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_LIST_CYCLE_MANAGER_START });
  const state = getState().studyPlanCycleStudentList;
  const { dialogFormData, studyPlanId, studyPlanCycleId, selectedIds } = payload;
  const apiModel = state.studyPlanCycleStudents?.filter(student => selectedIds?.includes(student.id))?.map(student => ({
    id: student.id,
    studentId: student.studentId,
    cycleManagerId: dialogFormData.cycleManager?.id,
    studyPlanCycleId: student.studyPlanCycleId,
    status: student.status
  }));
  let errorsCount = 0;
  let resultErrors = [];
  try {
    const result = await api.put(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students`, apiModel);
    if (result) {
      resultErrors = result.filter(x => x.statusCode === 400 || x.body?.errors?.length > 0);
      errorsCount = resultErrors.length || 0;
    }
  } finally {
    let errorMessage = null;
    if (errorsCount > 0) {
      const concatenatedErrorMessages = resultErrors.map(resultError => {
        const residentsFullName = resultError.body.errors.find(error => error.propertyName === 'residentsFullName').message;
        const filteredErrors = resultError.body.errors.filter(error => error.propertyName !== 'residentsFullName');
        return `${residentsFullName}: ${filteredErrors.map(error => error.message).join('; ').concat('.')}`;
      }).join('\r\n');
      errorMessage = `${payload.localizationService.toLanguageString(`StudyPlanCycleStudent.failedToChangeManager`)} ${errorsCount}.\r\n${concatenatedErrorMessages}`;
    }
    dispatch(loadStudyPlanCycleStudentList({ studyPlanId, studyPlanCycleId }));
    dispatch({
      type: StudyPlanCycleStudentListActionTypes.SAVE_STUDY_PLAN_CYCLE_STUDENT_LIST_CYCLE_MANAGER_END,
      payload: {
        validationResult: { errorMessage }
      }
    });
  }
};

export const selectStudyPlanCycleStudentList = (payload) => (dispatch, getState) => {
  const state = getState().studyPlanCycleStudentList;
  const selectedIds = [];
  const selectedStudents = [];
  let studyPlanCycleStudents = [];
  let selectedAll = true;
  if (!state.studyPlanCycleStudents) {
    selectedAll = false;
  }
  studyPlanCycleStudents = state.studyPlanCycleStudents.map(student => {
    let isSelected = student.isSelected;
    if (student.id === payload.id) {
      isSelected = !isSelected;
    }
    if (isSelected) {
      selectedIds.push(student.id);
      selectedStudents.push({
        id: student.id,
        studentId: student.studentId
      });
    } else {
      selectedAll = false;
    }
    return {
      ...student,
      isSelected
    };
  });
  dispatch({
    type: StudyPlanCycleStudentListActionTypes.SELECT_STUDY_PLAN_CYCLE_STUDENT_LIST,
    payload: {
      studyPlanCycleStudents,
      selectedIds,
      selectedStudents,
      selectedAll
    }
  });
};

export const selectAllStudyPlanCycleStudentList = () => (dispatch, getState) => {
  const state = getState().studyPlanCycleStudentList;
  const selectedIds = [];
  const selectedStudents = [];
  const studyPlanCycleStudents = state.studyPlanCycleStudents?.map(student => {
    const isSelected = !state.selectedAll;
    if (isSelected) {
      selectedIds.push(student.id);
      selectedStudents.push({
        id: student.id,
        studentId: student.studentId
      });
    }
    return {
      ...student,
      isSelected
    };
  });
  dispatch({
    type: StudyPlanCycleStudentListActionTypes.SELECT_ALL_STUDY_PLAN_CYCLE_STUDENT_LIST,
    payload: {
      studyPlanCycleStudents,
      selectedIds,
      selectedStudents
    }
  });
};