export default {
  LOAD_STUDENT_COMPETENCY_OVERVIEW_PAGE_START: 'LoadStudentCompetencyOverviewPageStart',
  LOAD_STUDENT_COMPETENCY_OVERVIEW_PAGE_END: 'LoadStudentCompetencyOverviewPageEnd',
  LOAD_STUDENT_COMPETENCY_AVAILABLE_COORDINATORS_START: 'LoadStudentCompetencyAvailableCoordinatorsStart',
  LOAD_STUDENT_COMPETENCY_AVAILABLE_COORDINATORS_END: 'LoadStudentCompetencyAvailableCoordinatorsEnd',
  SELECT_STUDENT_COMPETENCY_OVERVIEW_PAGE_TAB: 'SelectStudentCompetencyOverviewPageTab',

  TOGGLE_STUDENT_COMPETENCY_OVERVIEW_PAGE_SUMMARY_EXPANDED: 'ToggleStudentCompetencyOverviewPageSummaryExpanded',
  TOGGLE_STUDENT_COMPETENCY_SUBMIT_DIALOG: 'toggleStudentCompetencySubmitDialog',
  TOGGLE_STUDENT_COMPETENCY_REQUEST_EVALUATION_DIALOG: 'ToggleStudentCompetencyRequestEvaluationDialog',
  SUBMIT_STUDENT_COMPETENCY_START: 'SubmitStudentcompetencyStart',
  SUBMIT_STUDENT_COMPETENCY_END: 'SubmitStudentcompetencyEnd',
  TOGGLE_STUDENT_COMPETENCY_QR_CODE_DIALOG: 'ToggleStudentCompetencyQrCodeDialog',
  SET_STUDENT_COMPETENCY_SHOW_QR_CODE_SETTING: 'SetStudentCompetencyShowQrCodeSetting',
  GET_COMPETENCY_REPORT_START: 'GetCompetencyReportStart',
  GET_COMPETENCY_REPORT_END: 'GetCompetencyReportEnd'
};