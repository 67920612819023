import React from 'react';
import styled from 'styled-components';
import { StackLayout, Text, Colors, TextStyles, LoadingPanel } from '../../../../ui';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization, provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { DateFormatter, TimeFormatter } from '../../components/Formatter';

const TheoreticalPartPageContainer = styled.div`
  margin-bottom: 76px;
`;
const TheoreticalPartContainer = styled.div`
  border: 1px solid lightgrey;
  background-color: #fff;
`;
const TheoreticalPartTitleContainer = styled.div`
  text-align: left;
  padding: 8px;
  padding-bottom: 0;
`;
const TheoreticalPartNumber = styled.div`
  padding: 8px;
  padding-top 0;
  text-align: left;
`;
const ButtonContainer = styled.div`
  padding: 8px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const AddButton = styled(Button)`
  height: 32px;  
`;
const TheoreticalPartText = styled(Text)`
  ${TextStyles.caption}
  color: ${Colors.GRAY_50};
`;

const HyperLink = styled.a`
  display: inline-block;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(0, 0, 238, 1) !important;
  &:visited {
    color: rgba(85, 26, 139, 1) !important;
  }
`;

class StudentCycleTheoreticalPartPage extends React.Component {

  constructor() {
    super();
  };

  async componentDidMount() {
    await this.props.onLoadStudentCycleTheoreticalParts();
  }

  render() {
    const { theoreticalParts } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <TheoreticalPartPageContainer>
        {this.props.loading && <LoadingPanel/>}
        <StackLayout>
          {theoreticalParts?.map((theoreticalPart) => (
            <TheoreticalPartContainer key={theoreticalPart.id}>
              <TheoreticalPartTitleContainer><strong>{localizationService.toLanguageString('cycleTheoreticalPartType.' + theoreticalPart.type)}: </strong>{theoreticalPart.studyProgramTheoreticalPartName}</TheoreticalPartTitleContainer>
              <StackLayout orientation='horizontal'>
                <TheoreticalPartNumber>
                  <TheoreticalPartText>
                    {DateFormatter(theoreticalPart.plannedDate, intlService)} {TimeFormatter(theoreticalPart.plannedTime, intlService)} {theoreticalPart.fullName}
                    <br />
                    {theoreticalPart.link && <HyperLink href={theoreticalPart.link} target='_blank' rel='noreferrer'>{localizationService.toLanguageString('studentCycleTheoreticalPart.linkToMoreInformation')}</HyperLink>}
                    <br />
                    {theoreticalPart.location}
                  </TheoreticalPartText>
                </TheoreticalPartNumber>
                <ButtonContainer>
                  {this.renderStatusCell(theoreticalPart, localizationService)}
                </ButtonContainer>
              </StackLayout>
            </TheoreticalPartContainer>
          ))}
        </StackLayout>
      </TheoreticalPartPageContainer>
    );
  }

  renderStatusCell(dataItem, localizationService) {
    return (
      dataItem.completed
        ? <Text>{localizationService.toLanguageString(`studentCycleTheoreticalPart.${dataItem.completedAfterCycleSubmission ? `completedAfterCycleSubmission` : `completed`}`)}</Text>
        :
        (this.props.readOnly
          ? <Text>{localizationService.toLanguageString(`studentCycleTheoreticalPart.incomplete`)}</Text>
          :
          <AddButton
            onClick={() => this.props.openRegisterTheoreticalPartDialog({ selectedTheoreticalPart: dataItem })}
            themeColor='primary'
          >
            {localizationService.toLanguageString('studentCycleTheoreticalPart.haveCompleted')}
          </AddButton>
        )
    );
  }
}

registerForLocalization(StudentCycleTheoreticalPartPage);
registerForIntl(StudentCycleTheoreticalPartPage);

export default StudentCycleTheoreticalPartPage;