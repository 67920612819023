export function formatNumber(value, options) {
  if (value !== null && value !== undefined) {
    const unit = (value === 1 ? options?.unitSingular : options?.unitPlural) || options?.unit;
    const formattedValue = new Intl.NumberFormat().format(value);
    return unit
      ? `${formattedValue} ${unit}`
      : formattedValue;
  }
  return null;
}

export function toRomanNumeral(number) {
  if (typeof number !== 'number') {
    return null;
  }

  if (number < 1 || number > 3999) {
    return 'N/A';
  }

  const romanNumerals = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
  let result = '';

  for (let key in romanNumerals) {
    while (number >= romanNumerals[key]) {
      result += key;
      number -= romanNumerals[key];
    }
  }

  return result;
}