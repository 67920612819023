import ApprovalRequestListPageActionTypes from './approval-request-list-page-action-types';
import axios from 'axios';

export const loadApprovalRequestListPage = () => async(dispatch) => {
  dispatch(findApprovalRequests());
};

export const findApprovalRequests = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: ApprovalRequestListPageActionTypes.LOAD_APPROVAL_REQUEST_LIST_START });
  const state = getState().approvalRequestListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    status: state.filter?.status?.value,
    keyword: state.filter?.keyword
  };
  try {
    state.approvalRequestsCancelToken?.cancel();
    state.approvalRequestsCancelToken = axios.CancelToken.source();
    const approvalRequests = await api.get(`api/approval_requests`, query, state.approvalRequestsCancelToken.token);
    dispatch({
      type: ApprovalRequestListPageActionTypes.LOAD_APPROVAL_REQUEST_LIST_END,
      payload: {
        approvalRequests: approvalRequests?.data?.map(item => ({
          ...item,
          isSelected: false
        })),
        total: approvalRequests ? approvalRequests.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: ApprovalRequestListPageActionTypes.LOAD_APPROVAL_REQUEST_LIST_END });
      throw error;
    }
  }
};

export const filterApprovalRequests = (payload) => (dispatch) => {
  dispatch({
    type: ApprovalRequestListPageActionTypes.FILTER_APPROVAL_REQUEST_LIST,
    payload: payload
  });
  dispatch(findApprovalRequests());
};

export const clearApprovalRequestsFilter = () => (dispatch) => {
  dispatch({
    type: ApprovalRequestListPageActionTypes.FILTER_APPROVAL_REQUEST_LIST,
    payload: {}
  });
  dispatch(findApprovalRequests());
};