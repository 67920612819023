import { pagingSettings } from '../../../utils/GridPagingUtils';
import UserListPageActionTypes from '../actions/user-list-page-action-types';

const initialState = {
  loading: false,
  users: null,
  usersCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: null,
  userStatuses: [],
  userRoles: [],
  isConfirmDialogVisible: false,
  selectedUserId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserListPageActionTypes.LOAD_USER_LIST_START:
      return {
        ...state,
        loading: true
      };
    case UserListPageActionTypes.LOAD_USER_LIST_END:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take,
        canWrite: action.payload.canWrite
      };
    case UserListPageActionTypes.FILTER_USER_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case UserListPageActionTypes.LOAD_USER_LIST_USER_STATUSES:
      return {
        ...state,
        userStatuses: action?.payload?.userStatuses
      };
    case UserListPageActionTypes.LOAD_USER_LIST_USER_ROLES:
      return {
        ...state,
        userRoles: action?.payload?.userRoles
      };
    case UserListPageActionTypes.TOGGLE_USER_CONFIRM_DIALOG:
      return {
        ...state,
        selectedUserId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};