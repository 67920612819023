import { Skeleton } from '@progress/kendo-react-indicators';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import React from 'react';
import { connect } from 'react-redux';
import { StudyPlanCycleStudentDepartmentsList } from '.';
import { withRouter } from '../../../components/withRouter';
import { OverviewPage, StackLayout } from '../../../ui';
import { getStudentCycleStatusColor } from '../../../utils/studyPlanCycleDepartmentStudentStatusColors';
import { InlineBadge, ValueItem } from '../../shared/components';
import { loadStudyPlanCycleStudentOverviewPage } from '../actions';

class StudyPlanCycleStudentOverviewPage extends React.PureComponent {
  constructor() {
    super();
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleBreadcrumClick = this.handleBreadcrumClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyPlanCycleStudentOverviewPage({
      studyPlanId: this.props.params.studyPlanId,
      studyPlanCycleId: this.props.params.studyPlanCycleId,
      studyPlanCycleStudentId: this.props.params.studyPlanCycleStudentId
    });
  }

  render() {
    const { loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : this.props.studyPlanCycleStudent?.studentFullName
        }
        breadcrumbItems={[
          {
            id: 'study-plans',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlans')
          },
          {
            id: 'study-plan',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlan')
          },
          {
            id: 'study-plan-cycle',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlanCycle')
          },
          {
            id: 'study-plan-cycle-student',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlanCycleStudent'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.status')}
              labelWidth='108px'
              loading={loading}
            >
              {this.props.studyPlanCycleStudent &&
                <InlineBadge themeColor={getStudentCycleStatusColor(this.props.studyPlanCycleStudent.status)}>
                  {localizationService.toLanguageString(`studyPlanCycleDepartmentStudentStatus.${this.props.studyPlanCycleStudent.status}`)}
                </InlineBadge>}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.cycle')}
              labelWidth='108px'
              loading={loading}
            >
              {this.props.studyPlanCycleStudent?.cycleName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.credits')}
              labelWidth='108px'
              loading={loading}
            >
              {this.props.studyPlanCycleStudent?.credits}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.cycleManager')}
              labelWidth='108px'
              loading={loading}
            >
              {this.props.studyPlanCycleStudent?.cycleManagerFullName}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={this.props.selectedTab}>
            <TabStripTab title={localizationService.toLanguageString('StudyPlanCycleStudentOverviewPage.studentDepartments')}/>
          </TabStrip>
        }>
        {this.props.selectedTab === 0 &&
          <StudyPlanCycleStudentDepartmentsList
            studyPlanId={this.props.params.studyPlanId}
            studyPlanCycleId={this.props.params.studyPlanCycleId}
            studyPlanCycleStudentId={this.props.params.studyPlanCycleStudentId}
          />
        }
      </OverviewPage>
    );
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleBreadcrumClick(e) {
    if (e.id === 'study-plans') {
      this.props.navigate('/study_plans');
    } else if (e.id === 'study-plan') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/overview`);
    } else if (e.id === 'study-plan-cycle') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/cycles/${this.props.params.studyPlanCycleId}/overview`);
    }
  }
}

const mapStateToProps = (state) => ({
  loading: state.studyPlanCycleStudentOverviewPage.loading,
  studyPlanId: state.studyPlanCycleStudentOverviewPage.studyPlanId,
  studyPlanCycleId: state.studyPlanCycleStudentOverviewPage.studyPlanCycleId,
  studyPlanCycleStudentId: state.studyPlanCycleStudentOverviewPage.studyPlanCycleStudentId,
  studyPlanCycleStudent: state.studyPlanCycleStudentOverviewPage.studyPlanCycleStudent,
  studyPlanCycleStudentDepartments: state.studyPlanCycleStudentOverviewPage.studyPlanCycleStudentDepartments,
  selectedTab: state.studyPlanCycleStudentOverviewPage.selectedTab
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyPlanCycleStudentOverviewPage: (payload) => dispatch(loadStudyPlanCycleStudentOverviewPage(payload))
});

registerForLocalization(StudyPlanCycleStudentOverviewPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleStudentOverviewPage));