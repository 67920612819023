import React from 'react';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '../../../ui';

const RemoveButton = styled(Button)`
  max-width: 33px
`;

export default class FormArrayItem extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StackLayout
        orientation='horizontal'
        valign='bottom'
        columnGap='16px'
      >
        {this.props.children}
        <RemoveButton
          type='button'
          onClick={() => this.props.fields.remove(this.props.index)}
          icon='delete'
          disabled={this.props.disabled}
        />
      </StackLayout>
    );
  }
}