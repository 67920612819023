import CycleSkillAbilityFormPageActionTypes from '../actions/cycle-skill-ability-form-page-action-types';

const initialState = {
  loading: false,
  studyProgramId: null,
  availableCycleSkillAbilitiesLoading: false,
  availableCycleSkillAbilitiesCancelToken: null,
  availableCycleSkillAbilities: [],
  cycleSkillAbility: {},
  saveSuccess: false,
  validationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        studyProgramId: action.payload?.studyProgramId,
        cycleSkillAbility: action.payload ? action.payload.cycleSkillAbility : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_AVAILABLE_SKILL_ABILITIES_START:
      return {
        ...state,
        availableCycleSkillAbilitiesLoading: true
      };
    case CycleSkillAbilityFormPageActionTypes.LOAD_CYCLE_SKILL_ABILITY_AVAILABLE_SKILL_ABILITIES_END:
      return {
        ...state,
        availableCycleSkillAbilitiesLoading: false,
        availableCycleSkillAbilities: action.payload ? action.payload.availableCycleSkillAbilities : []
      };
    default:
      return state;
  }
};