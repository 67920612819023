import * as React from 'react';
import styled from 'styled-components';
import { LoadingPanel, StackLayout, Colors, Text } from '../../../../ui';
import { SCREEN_SIZES as ScreenSizes } from '../../../../resources/screenSize';
import { Button, FloatingActionButton } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import StudentCycleCaseItem from './student-cycle-case-item';

const EmptyCaseContainer = styled.div`
  background-color: ${Colors.WHITE};
  margin-bottom: 76px;
  text-align: left;
  padding: 8px;
  border: 1px solid ${Colors.GRAY_20};
`;

const CasesPageContainer = styled.div`
  margin-bottom: 76px;
`;

const AddCaseButton = styled(Button)`
  margin-right: ${props => props.$isSmallerScreen ? '8px' : '0'};
`;

const AddButtonContainer = styled.div`
  margin: 16px 0px;
  text-align: right;
`;

const Spacer = styled.div`
  height: 16px;
`;

export default class StudentCycleCasesPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      x1: null,
      x2: null
    };
    this.casesPageContainerRef = React.createRef();
    this.adjustButtonOffset = this.adjustButtonOffset.bind(this);
  };

  async componentDidMount() {
    await this.props.onLoadStudentCycleCases();
    window.addEventListener('resize', this.adjustButtonOffset);
    this.adjustButtonOffset();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustButtonOffset);
  }

  render() {
    const isSmallerScreen = window.innerWidth < ScreenSizes.XS;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <CasesPageContainer ref={this.casesPageContainerRef}>
          {this.props.loading && <LoadingPanel/>}
          {!this.props.readOnly &&
            <div>
              <FloatingActionButton
                hidden={this.props.totalNew === 0 || this.props.loading}
                rounded='large'
                icon='check'
                size='small'
                shape='rectangle'
                text={localizationService.toLanguageString('buttons.register')}
                themeColor='primary'
                alignOffset={{
                  x: this.state.x1,
                  y: 68
                }}
                align={{
                  vertical: 'top',
                  horizontal: 'end'
                }}
                onClick={this.props.onRegisterCasesDialogOpen}
              />
              <FloatingActionButton
                hidden={this.props.totalNew === 0 || this.props.loading}
                rounded='large'
                size='small'
                shape='rectangle'
                text={localizationService.toLanguageString('buttons.cancel')}
                themeColor='light'
                alignOffset={{
                  x: this.state.x2,
                  y: 68
                }}
                align={{
                  vertical: 'top',
                  horizontal: 'end'
                }}
                onClick={this.props.onClear}
              />
            </div>
          }
          {this.props.data?.length > 0 || this.props.additionalData?.length > 0
            ? <StackLayout>
              {this.props.data?.map((cycleCase, cycleCaseIndex) => (
                <StudentCycleCaseItem
                  key={cycleCase.id}
                  name={cycleCase.name}
                  readOnly={this.props.readOnly}
                  isExpanded={cycleCase.isExpanded}
                  isAdditional={false}
                  history={cycleCase.history}
                  diagnosesArray={cycleCase.diagnosesArray}
                  studentCases={cycleCase.studentCases}
                  newCases={cycleCase.newCases}
                  required={cycleCase.required}
                  onCaseExpand={() => this.props.onCaseExpand(cycleCase.id, false)}
                  onIncrease={() => this.props.onIncrease(cycleCase.id, false)}
                  onEditDialogOpen={(historyCaseIndex) => this.props.onEditDialogOpen(cycleCaseIndex, historyCaseIndex, false)}
                  onDeleteDialogOpen={(historyCaseIndex) => this.props.onDeleteDialogOpen(cycleCaseIndex, historyCaseIndex, false)}
                  userId={this.props.userId}
                  cycleManagerOverview={this.props.cycleManagerOverview}
                />
              ))}
              {!this.props.readOnly &&
                <AddButtonContainer>
                  <AddCaseButton onClick={this.props.onOpenAdditionalCaseAddDialog} $isSmallerScreen={isSmallerScreen}>{localizationService.toLanguageString('studentCycle.addCaseFromAnotherCycle')}</AddCaseButton>
                </AddButtonContainer>
              }
              {this.props.readOnly &&
                <Spacer/>
              }
              {this.props.additionalData?.map((cycleCase, cycleCaseIndex) => (
                <StudentCycleCaseItem
                  key={cycleCase.id}
                  name={cycleCase.name}
                  cycleName={cycleCase.cycleName}
                  readOnly={this.props.readOnly}
                  isExpanded={cycleCase.isExpanded}
                  isAdditional={true}
                  history={cycleCase.history}
                  diagnosesArray={cycleCase.diagnosesArray}
                  studentCases={cycleCase.studentCases}
                  newCases={cycleCase.newCases}
                  required={cycleCase.required}
                  onCaseExpand={() => this.props.onCaseExpand(cycleCase.id, true)}
                  onIncrease={() => this.props.onIncrease(cycleCase.id, true)}
                  onEditDialogOpen={(historyCaseIndex) => this.props.onEditDialogOpen(cycleCaseIndex, historyCaseIndex, true)}
                  onDeleteDialogOpen={(historyCaseIndex) => this.props.onDeleteDialogOpen(cycleCaseIndex, historyCaseIndex, true)}
                  onCaseDelete={() => this.props.onCycleCaseDeleteDialogOpen(cycleCaseIndex)}
                  userId={this.props.userId}
                  cycleManagerOverview={this.props.cycleManagerOverview}
                />
              ))}
            </StackLayout>
            : <StackLayout>
              <EmptyCaseContainer>
                <Text textColor={Colors.GRAY_50} variant='caption'>{localizationService.toLanguageString('studentCycle.noAssignedCasesMessage')}</Text>
              </EmptyCaseContainer>
            </StackLayout>}
        </CasesPageContainer>
      </>
    );
  }

  adjustButtonOffset() {
    const offsetValues = this.calculateFloatingButtonOffset();
    this.setState({ ...this.state, x1: offsetValues.x1, x2: offsetValues.x2 });
  }

  calculateFloatingButtonOffset() {
    const isSmallerScreen = window.innerWidth < ScreenSizes.XS;
    const casesPageContainerRightCorner = this.casesPageContainerRef?.current?.getBoundingClientRect().right;
    const buttonOffsetBase = window.innerWidth - casesPageContainerRightCorner;
    const x1 = isSmallerScreen ? 8 : buttonOffsetBase + 8;
    const x2 = x1 + 128;
    return { x1, x2 };
  }
}

registerForLocalization(StudentCycleCasesPage);