import competencyOverviewPageActionTypes from '../actions/competency-overview-page-action-types';

const initialState = {
  loading: false,
  competency: null,
  competencyCancelToken: null,
  selectedTab: 0,
  canWrite: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case competencyOverviewPageActionTypes.LOAD_COMPETENCY_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case competencyOverviewPageActionTypes.LOAD_COMPETENCY_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        competency: action.payload?.competency,
        canWrite: action.payload?.canWrite
      };
    case competencyOverviewPageActionTypes.SELECT_COMPETENCY_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    default:
      return state;
  }
};