import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field, FieldArray } from '@progress/kendo-react-form';
import { loadAvailableTheoreticalParts, loadStudyPlanCycleTheoreticalPartFormPage, saveStudyPlanCycleTheoreticalPart, loadAvailableLecturers, clearStore } from '../actions';
import { CheckBox, ComboBox, DatePicker, DropDown, FormPage, Text, TextBox, WrapLayout, TimePicker } from '../../../ui';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';
import { Error } from '@progress/kendo-react-labels';
import colors from '../../../ui/components/shared/colors';

const STUDY_PROGRAMS_FIELD_NAME = 'studyPrograms';

const StudyProgramsContainer = styled(WrapLayout).attrs(props => ({
  orientation: 'vertical',
  rowgap: '2px',
  width: '340px'
}))`
  margin-top: 2px;
`;

const StudyProgramsTitle = styled(Text).attrs(props => ({
  variant: 'h6',
  textColor: props.isValid ? props.theme.gray80 : '#f31700',
  truncate: true
}))`
  margin-top: 20px;
`;

class StudyPlanCycleTheoreticalPartFormPage extends React.PureComponent {
  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderStudyPrograms = this.renderStudyPrograms.bind(this);
    this.handleCycleTheoreticalPartChange = this.handleCycleTheoreticalPartChange.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyPlanId) {
      const localizationService = provideLocalizationService(this);
      await this.props.loadStudyPlanCycleTheoreticalPartFormPage({
        studyPlanId: this.props.params.studyPlanId,
        studyPlanCycleId: this.props.params.studyPlanCycleId,
        studyPlanCycleTheoreticalPartId: this.props.params.studyPlanCycleTheoreticalPartId,
        localizationService
      });
      await this.props.loadAvailableTheoreticalParts({ localizationService });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('studyPlanCycleTheoreticalPart.formTitle')}
        breadcrumbItems={[
          {
            id: 'study-plans',
            text: localizationService.toLanguageString('studyPlanCycleTheoreticalPart.studyPlans')
          },
          {
            id: 'study-plan',
            text: localizationService.toLanguageString('studyPlanCycleTheoreticalPart.studyPlan')
          },
          {
            id: 'study-plan-cycle',
            text: localizationService.toLanguageString('studyPlanCycleTheoreticalPart.studyPlanCycle')
          },
          {
            id: 'study-plan-cycle-theoretical-part',
            text: localizationService.toLanguageString('studyPlanCycleTheoreticalPart.studyPlanCycleTheoreticalPart'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.studyPlanCycleTheoreticalPart}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={(renderFormProps) => (
          <>
            <Field
              name='cycleTheoreticalPart'
              label={localizationService.toLanguageString('studyPlanCycleTheoreticalPart.theoreticalPart')}
              component={ComboBox}
              loading={this.props.availableTheoreticalPartsLoading}
              data={this.props.availableTheoreticalParts}
              onFilter={e => this.props.loadAvailableTheoreticalParts({ ...e, localizationService })}
              onChange={e => this.handleCycleTheoreticalPartChange(e, renderFormProps)}
              textField='studyProgramTheoreticalPartName'
              valueField='id'
              width='340px'
            />
            <Field
              name='lecturer'
              label={localizationService.toLanguageString('studyPlanCycleTheoreticalPart.lecturer')}
              disabled={!this.props.cycleTheoreticalPartId && true}
              component={DropDown}
              loading={this.props.availableLecturersLoading}
              data={this.props.availableLecturers}
              textField='fullName'
              valueField='id'
              width='340px'
            />
            <Field
              name='plannedDate'
              label={localizationService.toLanguageString('studyPlanCycleTheoreticalPart.plannedDate')}
              component={DatePicker}
              width='340px'
            />
            <Field
              name='plannedTime'
              label={localizationService.toLanguageString('studyPlanCycleTheoreticalPart.plannedTime')}
              component={TimePicker}
              width='340px'
            />
            <Field
              name='link'
              label={localizationService.toLanguageString('studyPlanCycleTheoreticalPart.link')}
              component={TextBox}
              width='340px'
            />
            <Field
              name='location'
              label={localizationService.toLanguageString('studyPlanCycleTheoreticalPart.location')}
              component={TextBox}
              width='340px'
            />
            <FieldArray
              name={STUDY_PROGRAMS_FIELD_NAME}
              component={this.renderStudyPrograms}
            />
          </>
        )}
      />
    );
  }

  handleCycleTheoreticalPartChange(e, renderFormProps) {
    const studyPlanCycleTheoreticalPart = {
      plannedDate: renderFormProps.valueGetter('plannedDate'),
      plannedTime: renderFormProps.valueGetter('plannedTime'),
      link: renderFormProps.valueGetter('link'),
      location: renderFormProps.valueGetter('location'),
      [STUDY_PROGRAMS_FIELD_NAME]: renderFormProps.valueGetter(STUDY_PROGRAMS_FIELD_NAME)
    };
    this.props.loadAvailableLecturers({ studyPlanCycleTheoreticalPart, cycleTheoreticalPartId: e.value?.id, ...e });
  }

  renderStudyPrograms(fieldArrayRenderProps) {
    const { validationMessage, value } = fieldArrayRenderProps;
    const localizationService = provideLocalizationService(this);
    return (
      <StudyProgramsContainer>
        <StudyProgramsTitle isValid={validationMessage == null}>{localizationService.toLanguageString('studyPlanCycleTheoreticalPart.studyPrograms')}</StudyProgramsTitle>
        <Text variant={'caption'} textColor={colors.GRAY_40}>{localizationService.toLanguageString('studyPlanCycleTheoreticalPart.studyProgramChecklistInfo')}</Text>
        {validationMessage && <Error>{validationMessage}</Error>}
        {value?.map((item, index) => {
          const fieldName = STUDY_PROGRAMS_FIELD_NAME + '[' + index + ']';
          return (
            <div key={index}>
              <Field
                name={`${fieldName}.isChecked`}
                label={item.studyProgramName}
                component={CheckBox}
                marginTop='0px'
              />
            </div>
          );
        })}
      </StudyProgramsContainer>
    );
  }

  handleValidate(values, localizationService, validationResult, modified, touched) {
    const errors = {};
    if (!values.cycleTheoreticalPart) {
      errors.cycleTheoreticalPart = localizationService.toLanguageString('validation.required');
    }
    if (!values.plannedDate && values.plannedTime) {
      errors.plannedDate = localizationService.toLanguageString('validation.dateIsRequiredWithTime');
    }
    if (!values.plannedTime && values.plannedDate) {
      errors.plannedTime = localizationService.toLanguageString('validation.timeIsRequiredWithDate');
    }
    if (values.link && !values.link.includes('http')) {
      errors.link = localizationService.toLanguageString('studyPlanCycleTheoreticalPart.invalidLink');
    }
    if (values.studyPrograms
        && values.studyPrograms.every(element => element.isChecked === false)
        && values.studyPrograms.find((element, index) => touched[`${STUDY_PROGRAMS_FIELD_NAME}[${index}].isChecked`])) {
      errors[STUDY_PROGRAMS_FIELD_NAME] = localizationService.toLanguageString('validation.atLeastOneRequired');
    }
    if (!modified.cycleTheoreticalPart && !modified.plannedDate && !modified.plannedTime && !modified.link && !modified.location) {
      if (validationResult?.errors?.cycleTheoreticalPartName) {
        errors.cycleTheoreticalPart = validationResult?.errors?.cycleTheoreticalPartName;
      }
      if (validationResult?.errors?.plannedDate) {
        errors.plannedDate = validationResult?.errors?.plannedDate;
      }
      if (validationResult?.errors?.plannedTime) {
        errors.plannedTime = validationResult?.errors?.plannedTime;
      }
      if (validationResult?.errors?.link) {
        errors.link = validationResult?.errors?.link;
      }
      if (validationResult?.errors?.location) {
        errors.location = validationResult?.errors?.location;
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-plans') {
      this.props.navigate('/study_plans');
    }
    if (e.id === 'study-plan') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/overview`);
    }
    if (e.id === 'study-plan-cycle') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/cycles/${this.props.params.studyPlanCycleId}/overview`);
    }
  }

  handleSubmit(payload) {
    this.props.saveStudyPlanCycleTheoreticalPart(payload);
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  componentWillUnmount() {
    this.props.clearStore();
  }
}

const mapStateToProps = state => ({
  loading: state.studyPlanCycleTheoreticalPartFormPage.loading,
  cycle: state.studyPlanCycleTheoreticalPartFormPage.cycle,
  studyPlanId: state.studyPlanCycleTheoreticalPartFormPage.studyPlanId,
  studyPlanCycleId: state.studyPlanCycleTheoreticalPartFormPage.studyPlanCycleId,
  cycleTheoreticalPartId: state.studyPlanCycleTheoreticalPartFormPage.cycleTheoreticalPartId,
  studyPlanCycleTheoreticalPart: state.studyPlanCycleTheoreticalPartFormPage.studyPlanCycleTheoreticalPart,
  managers: state.studyPlanCycleTheoreticalPartFormPage.managers,
  availableTheoreticalParts: state.studyPlanCycleTheoreticalPartFormPage.availableTheoreticalParts,
  availableTheoreticalPartsLoading: state.studyPlanCycleTheoreticalPartFormPage.availableTheoreticalPartsLoading,
  availableLecturers: state.studyPlanCycleTheoreticalPartFormPage.availableLecturers,
  availableLecturersLoading: state.studyPlanCycleTheoreticalPartFormPage.availableLecturersLoading,
  validationResult: state.studyPlanCycleTheoreticalPartFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanCycleTheoreticalPartFormPage: (payload) => dispatch(loadStudyPlanCycleTheoreticalPartFormPage(payload)),
  saveStudyPlanCycleTheoreticalPart: (payload) => dispatch(saveStudyPlanCycleTheoreticalPart(payload)),
  loadAvailableTheoreticalParts: (payload) => dispatch(loadAvailableTheoreticalParts(payload)),
  loadAvailableLecturers: (payload) => dispatch(loadAvailableLecturers(payload)),
  clearStore: () => dispatch(clearStore())
});

registerForLocalization(StudyPlanCycleTheoreticalPartFormPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleTheoreticalPartFormPage));