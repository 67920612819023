import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid } from '../../shared/components';
import { loadStudyProgramListPage, findStudyPrograms, sortStudyPrograms } from '../actions/study-program-list-page-actions';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';
import { Colors, StackLayout, Text, WrapLayout } from '../../../ui';

const CycleCountCell = styled.td`
  ${props => props.bold && `font-weight: bold !important;`}
`;

class StudyProgramList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.renderName = this.renderName.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyProgramListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={this.props.loading}
        data={this.props.studyPrograms}
        skip={this.props.skip}
        take={this.props.take}
        total={this.props.total}
        sort={this.props.sorting}
        onExpandChange={this.handleExpandChange}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSort}
        onRowClick={this.handleRowClick}
        columns={[
          {
            field: 'name',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studyProgram.studyProgram'),
            cell: this.renderName
          }, {
            field: 'credits',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('studyProgram.credits'),
            minGridWidth: GridSize.medium
          }, {
            field: 'durationInYears',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('studyProgram.years'),
            minGridWidth: GridSize.medium
          }, {
            field: 'cycleCount',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('studyProgram.cycleCount'),
            minGridWidth: GridSize.large,
            cell: ({ dataItem, className }) =>
              <CycleCountCell className={className} bold={dataItem.cycleStudyProgramCount > 0}>
                {dataItem.cycleCount + dataItem.cycleStudyProgramCount}
              </CycleCountCell>
          }
        ]}
      >
        {this.props.canWrite &&
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddClicked}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>
        }
      </Grid>
    );
  }

  renderName(e) {
    const { className, dataItem } = e;
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <StackLayout rowGap='2px'>
          {dataItem.name}
          <WrapLayout rowGap='0' columnGap='0'>
            <Text textColor={Colors.GRAY_50} variant='caption'>
              {dataItem.code}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  handleSort(e) {
    this.props.sortStudyPrograms(e.sort);
  }

  async handlePageChange(e) {
    await this.props.findStudyPrograms({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    this.props.navigate(`/study_programs/${e.dataItem.id}/overview`);
  }

  handleAddClicked() {
    this.props.navigate('/study_programs/create');
  }

  handleExpandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };
}

const mapStateToProps = state => ({
  loading: state.studyProgramListPage.loading,
  studyPrograms: state.studyProgramListPage.studyPrograms,
  skip: state.studyProgramListPage.skip,
  take: state.studyProgramListPage.take,
  total: state.studyProgramListPage.total,
  sorting: state.studyProgramListPage.sorting,
  canWrite: state.studyProgramListPage.canWrite
});

const mapDispatchToProps = dispatch => ({
  loadStudyProgramListPage: () => dispatch(loadStudyProgramListPage()),
  findStudyPrograms: (payload) => dispatch(findStudyPrograms(payload)),
  sortStudyPrograms: (payload) => dispatch(sortStudyPrograms(payload))
});

registerForLocalization(StudyProgramList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyProgramList));