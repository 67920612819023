import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { StudentCompetencyCertificateView } from '../../shared/student-competency';

class MyCompetencyCertificateView extends React.Component {

  render() {
    return (
      <StudentCompetencyCertificateView/>
    );
  }
}

registerForLocalization(MyCompetencyCertificateView);

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyCompetencyCertificateView));