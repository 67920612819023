import axios from 'axios';
import StudentCompetencySurveyListActionTypes from './student-competency-survey-list-action-types';
import { COMPETENCY_SURVEY_STUDENT_STATUS } from '../../../../resources/competencySurveyStudentStatus';

export const loadStudentCompetencySurveyList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCompetencySurveyListActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_LIST_START });
  const states = getState();
  const state = states.studentCompetencySurveyList;
  try {
    state.studentCompetencySurveysCancelToken?.cancel();
    state.studentCompetencySurveysCancelToken = axios.CancelToken.source();
    const competencySurveys = await api.get(`api/competencies/${payload.competencyId}/surveys`, state.studentCompetencySurveysCancelToken.token);
    let surveys = [];

    if (competencySurveys) {
      surveys = await Promise.all(competencySurveys.data?.map(async(element) => {
        const url = `api/competencies/${element.competencyId}/surveys/${element.surveyId}/students/${payload.studentId}`;
        const studentCompetencySurveyStudents = await api.get(url, state.studentCompetencySurveysCancelToken.token);
        let pendingEvaluationCount = 0;
        let completedEvaluationCount = 0;
        studentCompetencySurveyStudents?.data?.map(element => {
          switch (element.status) {
            case COMPETENCY_SURVEY_STUDENT_STATUS.SUBMITTED:
              pendingEvaluationCount++;
              break;
            case COMPETENCY_SURVEY_STUDENT_STATUS.EVALUATED:
              completedEvaluationCount++;
              break;
          }
        });
        return { ...element, pendingEvaluationCount, completedEvaluationCount };
      }));
    }

    dispatch({
      type: StudentCompetencySurveyListActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_LIST_END,
      payload: surveys
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCompetencySurveyListActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_LIST_END });
      throw error;
    }
  }
};