import axios from 'axios';
import StudyPlanFormPageActionTypes from './study-plan-form-page-action-types';
import ValidationError from '../../../utils/validation-error';

export const loadStudyPlanFormPage = ({ studyPlanId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_START });
  try {
    if (studyPlanId > 0) {
      const studyPlan = await api.get(`api/study_plans/${studyPlanId}`);
      dispatch({
        type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_END,
        payload: {
          studyPlan: {
            ...studyPlan,
            studyProgram: studyPlan.studyProgramId ? { id: studyPlan.studyProgramId, name: studyPlan.studyProgramName } : null
          }
        }
      });
    } else {
      dispatch({ type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_END });
    }
  } catch (error) {
    dispatch({ type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_END });
    throw error;
  }
  dispatch(loadStudyPrograms());
};

export const loadStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_STUDY_PROGRAMS_START });
  try {
    const state = getState().studyPlanFormPage;
    state.studyProgramsCancelToken?.cancel();
    state.studyProgramsCancelToken = axios.CancelToken.source();
    const query = {
      skip: 0,
      limit: 50,
      keyword: payload?.keyword
    };
    const studyPrograms = await api.get(`api/study_programs`, query, state.studyProgramsCancelToken.token);
    dispatch({
      type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_STUDY_PROGRAMS_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    dispatch({
      type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_STUDY_PROGRAMS_END
    });
    throw error;
  }
};

export const saveStudyPlan = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_START });
  const state = getState().studyPlanFormPage;
  const apiModel = {
    ...payload,
    studyProgramId: payload.studyProgram ? payload.studyProgram.id : null
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/study_plans/${id}`, apiModel);
    } else {
      id = await api.post('api/study_plans', apiModel);
    }
    dispatch({
      type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_END,
      payload: {
        studyPlan: {
          ...state.studyPlan,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_END,
      payload: {
        validationResult,
        studyPlan: state.studyPlan
      }
    });
  }
};