import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid, InlineBadge } from '../../shared/components';
import { loadUserListPage, findUsers, openUserConfirmDialog, closeUserConfirmDialog, removeUserConfirmDialog, loadUserRoles, loadUserStatuses } from '../actions/user-list-page-actions';
import { withRouter } from '../../../components/withRouter';
import { getUserStatusColor } from '../../../utils/userStatusColors';
import { StackLayout } from '@progress/kendo-react-layout';
import { Colors, WrapLayout, Text } from '../../../ui';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { GridToolbar } from '@progress/kendo-react-grid';

const NoWrapCell = styled.div`
  white-space: pre;
`;

const CheckIcon = styled.span`
  color: green;
`;

class UserList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.renderName = this.renderName.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    await this.props.loadUserListPage({ localizationService });
    this.props.loadUserRoles(localizationService);
    this.props.loadUserStatuses(localizationService);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={this.props.loading}
          data={this.props.users}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          onPageChange={this.handlePageChange}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'fullName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('User.person'),
              sortable: false,
              cell: this.renderName
            }, {
              field: 'type',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('User.role'),
              minGridWidth: GridSize.small,
              sortable: false,
              cell: this.renderRoles
            }, {
              field: 'externalUser',
              className: 'align-center',
              headerClassName: 'align-center',
              title: localizationService.toLanguageString('User.externalUserAbbreviation'),
              minGridWidth: GridSize.small,
              width: '100',
              cell: (e) =>
                <td className={e.className} onClick={() => this.handleRowClick(e)}>
                  {e.dataItem.isExternal ? <CheckIcon className='k-icon k-i-check' /> : ''}
                </td>
            }, {
              field: 'employeeUser',
              className: 'align-center',
              headerClassName: 'align-center',
              title: localizationService.toLanguageString('User.employee'),
              minGridWidth: GridSize.small,
              width: '100',
              cell: (e) =>
                <td className={e.className} onClick={() => this.handleRowClick(e)}>
                  {e.dataItem.isEmployee ? <CheckIcon className='k-icon k-i-check' /> : ''}
                </td>
            }, {
              field: 'status',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('User.status'),
              sortable: false,
              cell: (e) =>
                <td className={e.className}>
                  <InlineBadge themeColor={getUserStatusColor(e.dataItem.status)}>
                    {localizationService.toLanguageString(`userStatus.${e.dataItem.status}`)}
                  </InlineBadge>
                </td>
            }
          ]}
          actions={
            [{
              icon: 'delete',
              onClick: this.handleRemoveClick
            }]
          }
        >
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddClick}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('User.userDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderRoles = (props) => {
    const localizationService = provideLocalizationService(this);
    let roles = [];
    props.dataItem.roles.forEach((element, index) => {
      roles.push(localizationService.toLanguageString(`roleType.${element}`));
    });

    return (
      <td className={props.className} onClick={() => this.handleRowClick(props)}>
        <NoWrapCell>
          {roles.join(', ')}
        </NoWrapCell>
      </td>
    );
  }

  renderName(e) {
    const { className, dataItem } = e;
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <StackLayout rowGap='2px' orientation='vertical'>
          {dataItem.fullName}
          <WrapLayout rowGap='0' columnGap='0'>
            <Text textColor={Colors.GRAY_50} variant='caption'>
              {(dataItem.stampNumber != null ? `${dataItem.stampNumber} ` : ``) +
                (dataItem.email != null ? `${dataItem.email}` : ``)}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  async handlePageChange(e) {
    await this.props.findUsers({ skip: e.page.skip, take: e.page.take });
  }

  handleAddClick() {
    this.props.navigate('/users/create');
  }

  handleRowClick(e) {
    this.props.navigate(`/users/${e.dataItem.id}/overview`);
  }

  handleRemoveClick(e) {
    this.props.openUserConfirmDialog(e.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeUserConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeUserConfirmDialog({
      userId: this.props.userId
    });
  }
}

const mapStateToProps = state => ({
  loading: state.userListPage.loading,
  users: state.userListPage.users,
  skip: state.userListPage.skip,
  take: state.userListPage.take,
  total: state.userListPage.total,
  isConfirmDialogVisible: state.userListPage.isConfirmDialogVisible
});

const mapDispatchToProps = dispatch => ({
  loadUserListPage: (payload) => dispatch(loadUserListPage(payload)),
  findUsers: (payload) => dispatch(findUsers(payload)),
  openUserConfirmDialog: (payload) => dispatch(openUserConfirmDialog(payload)),
  closeUserConfirmDialog: (payload) => dispatch(closeUserConfirmDialog(payload)),
  removeUserConfirmDialog: (payload) => dispatch(removeUserConfirmDialog(payload)),
  loadUserRoles: (localizationService) => dispatch(loadUserRoles(localizationService)),
  loadUserStatuses: (localizationService) => dispatch(loadUserStatuses(localizationService))
});

registerForLocalization(UserList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserList));