import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { connect } from 'react-redux';
import { loadAvailableSkillAbilities, loadCycleSkillAbilityFormPage, saveCycleSkillAbility } from '..';
import { ComboBox, FormPage, NumericBox, StackLayout } from '../../../ui';
import { Field } from '@progress/kendo-react-form';
import { withRouter } from '../../../components/withRouter';

class CycleSkillAbilityFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.cycleId) {
      await this.props.loadCycleSkillAbilityFormPage({
        cycleId: this.props.params.cycleId,
        cycleSkillAbilityId: this.props.params.cycleSkillAbilityId
      });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('cycle.cycleSkill')}
        breadcrumbItems={[
          {
            id: 'cycles',
            text: localizationService.toLanguageString('cycle.cycles')
          },
          {
            id: 'cycle',
            text: localizationService.toLanguageString('cycle.cycle')
          },
          {
            id: 'cycle-skill-ability',
            text: localizationService.toLanguageString('cycle.cycleSkill'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.cycleSkillAbility}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <>
            <Field
              name='studyProgramSkillAbility'
              label={localizationService.toLanguageString('cycle.skill')}
              component={ComboBox}
              allowCustom={true}
              loading={this.props.availableCycleSkillAbilitiesLoading}
              data={this.props.availableCycleSkillAbilities}
              onFilter={this.props.loadAvailableSkillAbilities}
              textField='name'
              textValue='id'
              width='345px'
            />
            <StackLayout orientation='horizontal'>
              <Field
                name='minRequiredLevelA'
                label={localizationService.toLanguageString('cycle.minSkillRequiredLevelA')}
                component={NumericBox}
                minValue={0}
                width='110px'
              />
              <Field
                name='minRequiredLevelB'
                label={localizationService.toLanguageString('cycle.minSkillRequiredLevelB')}
                component={NumericBox}
                minValue={0}
                width='110px'
              />
              <Field
                name='minRequiredLevelC'
                label={localizationService.toLanguageString('cycle.minSkillRequiredLevelC')}
                component={NumericBox}
                minValue={0}
                width='110px'
              />
            </StackLayout>
          </>
        )}
      />
    );
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};

    if (!values.studyProgramSkillAbility) {
      errors.studyProgramSkillAbility = localizationService.toLanguageString('validation.required');
    }

    if (values.minRequiredLevelA == null) {
      errors.minRequiredLevelA = localizationService.toLanguageString('validation.required');
    } else if (values.minRequiredLevelA < 0) {
      errors.minRequiredLevelA = localizationService.toLanguageString('validation.negativeValue');
    }

    if (values.minRequiredLevelB == null) {
      errors.minRequiredLevelB = localizationService.toLanguageString('validation.required');
    } else if (values.minRequiredLevelB < 0) {
      errors.minRequiredLevelB = localizationService.toLanguageString('validation.negativeValue');
    }

    if (values.minRequiredLevelC == null) {
      errors.minRequiredLevelC = localizationService.toLanguageString('validation.required');
    } else if (values.minRequiredLevelC < 0) {
      errors.minRequiredLevelC = localizationService.toLanguageString('validation.negativeValue');
    }

    if (values.minRequiredLevelA + values.minRequiredLevelB + values.minRequiredLevelC <= 0) {
      const error = localizationService.toLanguageString('cycle.validationMinSkillsLevel');
      errors.minRequiredLevelA = error;
      errors.minRequiredLevelB = error;
      errors.minRequiredLevelC = error;
    }

    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'cycles') {
      this.props.navigate('/cycles');
    }
    if (e.id === 'cycle') {
      this.props.navigate(`/cycles/${this.props.params.cycleId}/overview`);
    }
  }

  handleSubmit(payload) {
    this.props.saveCycleSkillAbility(payload);
  }

  handleCancel() {
    this.props.navigate(-1);
  }
}

registerForLocalization(CycleSkillAbilityFormPage);

const mapStateToProps = state => ({
  loading: state.cycleSkillAbilityFormPage.loading,
  availableCycleSkillAbilitiesLoading: state.cycleSkillAbilityFormPage.availableCycleSkillAbilitiesLoading,
  availableCycleSkillAbilities: state.cycleSkillAbilityFormPage.availableCycleSkillAbilities,
  cycleSkillAbility: state.cycleSkillAbilityFormPage.cycleSkillAbility,
  validationResult: state.cycleSkillAbilityFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadCycleSkillAbilityFormPage: (payload) => dispatch(loadCycleSkillAbilityFormPage(payload)),
  saveCycleSkillAbility: (payload) => dispatch(saveCycleSkillAbility(payload)),
  loadAvailableSkillAbilities: (payload) => dispatch(loadAvailableSkillAbilities(payload))
});

registerForLocalization(CycleSkillAbilityFormPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleSkillAbilityFormPage));