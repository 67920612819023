import axios from 'axios';
import StudyProgramCaseListActionTypes from './study-program-case-list-action-types';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadStudyProgramCaseList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramCaseListActionTypes.LOAD_STUDY_PROGRAM_CASE_LIST_START });
  const state = getState().studyProgramCaseList;
  try {
    state.studyProgramCasesCancelToken?.cancel();
    state.studyProgramCasesCancelToken = axios.CancelToken.source();
    const studyProgramCases = await api.get(`api/study_programs/${payload.studyProgramId}/cases`, null, state.studyProgramCasesCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.STUDY_PROGRAMS_WRITE);
    dispatch({
      type: StudyProgramCaseListActionTypes.LOAD_STUDY_PROGRAM_CASE_LIST_END,
      payload: {
        studyProgramCases: studyProgramCases ? studyProgramCases.data : [],
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyProgramCaseListActionTypes.LOAD_STUDY_PROGRAM_CASE_LIST_END });
      throw error;
    }
  }
};

export const openStudyProgramCaseConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyProgramCaseListActionTypes.TOGGLE_STUDY_PROGRAM_CASE_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeStudyProgramCaseConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramCaseListActionTypes.TOGGLE_STUDY_PROGRAM_CASE_LIST_CONFIRM_DIALOG });
};

export const removeStudyProgramCaseConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyProgramCaseList;
  if (state.selectedStudyProgramCaseId > 0) {
    dispatch({ type: StudyProgramCaseListActionTypes.LOAD_STUDY_PROGRAM_CASE_LIST_START });
    try {
      await api.remove(`api/study_programs/${payload.studyProgramId}/cases/${state.selectedStudyProgramCaseId}`);
      dispatch(loadStudyProgramCaseList({ studyProgramId: payload.studyProgramId }));
    } catch (error) {
      dispatch({ type: StudyProgramCaseListActionTypes.LOAD_STUDY_PROGRAM_CASE_LIST_END });
    }
  }
  dispatch({ type: StudyProgramCaseListActionTypes.TOGGLE_STUDY_PROGRAM_CASE_LIST_CONFIRM_DIALOG });
};