import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import { FormPage, ComboBox, YearPicker } from '../../../ui';
import { loadStudyPlanFormPage, saveStudyPlan, loadStudyPrograms } from '../actions/study-plan-form-page-actions';
import { withRouter } from '../../../components/withRouter';

class StudyPlanFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyPlanId) {
      await this.props.loadStudyPlanFormPage({
        studyPlanId: this.props.params.studyPlanId
      });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('StudyPlan.studyPlan')}
        breadcrumbItems={[
          {
            id: 'study-plans',
            text: localizationService.toLanguageString('StudyPlan.studyPlans')
          },
          {
            id: 'study-plan',
            text: localizationService.toLanguageString('StudyPlan.studyPlan'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.studyPlan}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <>
            <Field
              name='year'
              label={localizationService.toLanguageString('StudyPlan.year')}
              component={YearPicker}
              width='180px'
            />
            <Field
              name='studyProgram'
              label={localizationService.toLanguageString('StudyPlan.studyProgram')}
              component={ComboBox}
              loading={this.props.studyProgramsLoading}
              data={this.props.studyPrograms}
              onFilter={this.props.loadStudyPrograms}
              textField='name'
              valueField='id'
              width='320px'
            />
          </>
        )}
      />
    );
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.year) {
      errors.year = localizationService.toLanguageString('validation.required');
    }
    if (!values.studyProgram) {
      errors.studyProgram = localizationService.toLanguageString('validation.required');
    }
    if (!modified.studyProgram && !modified.year) {
      if (validationResult?.errors?.studyProgramId) {
        errors.studyProgram = validationResult?.errors?.studyProgramId;
      }
      if (validationResult?.errors?.year) {
        errors.year = validationResult?.errors?.year;
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-plans') {
      this.props.navigate('/study_plans');
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveStudyPlan(payload);
  }
}

registerForLocalization(StudyPlanFormPage);

const mapStateToProps = state => ({
  loading: state.studyPlanFormPage.loading,
  studyPlan: state.studyPlanFormPage.studyPlan,
  studyPrograms: state.studyPlanFormPage.studyPrograms,
  studyProgramsLoading: state.studyPlanFormPage.studyProgramsLoading,
  validationResult: state.studyPlanFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanFormPage: (payload) => dispatch(loadStudyPlanFormPage(payload)),
  saveStudyPlan: (payload) => dispatch(saveStudyPlan(payload)),
  loadStudyPrograms: (payload) => dispatch(loadStudyPrograms(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanFormPage));
