export const STUDENT_CYCLE_OVERVIEW_DIALOG_TYPES = {
  ADD_SKILL: 'addSkill',
  ADD_CASE: 'addCase',
  REGISTER_SKILL: 'registerSkill',
  REGISTER_CASE: 'registerCase',
  EDIT_SKILL: 'editSkill',
  EDIT_CASE: 'editCase',
  DELETE_SKILL: 'deleteSkill',
  DELETE_ADDITIONAL_SKILL: 'deleteAdditionalSkill',
  DELETE_CYCLE_SKILL: 'deleteCycleSkill',
  DELETE_CASE: 'deleteCase',
  DELETE_ADDITIONAL_CASE: 'deleteAdditionalCase',
  DELETE_CYCLE_CASE: 'deleteCycleCase'
};