import * as React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import styled from 'styled-components';

const Container = styled.fieldset`
  margin-top: ${props => props?.marginTop ?? '30px'} !important;
`;

const CheckBox = (props) => {
  const { valid, validationMessage, marginTop, ...rest } = props;
  return (
    <Container className={'k-form-fieldset'} marginTop={marginTop}>
      <Checkbox
        {...rest}
        valid={valid}
        validationMessage={validationMessage}
      />
      {!valid && <Error>{validationMessage}</Error>}
    </Container>
  );
};

export default CheckBox;