import StudyPlanCycleListActionTypes from '../actions/study-plan-cycle-list-action-types';

const initialState = {
  loading: false,
  studyPlanCycles: null,
  studyPlanCyclesCancelToken: null,
  isConfirmDialogVisible: false,
  selectedStudyPlanCycleId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleListActionTypes.LOAD_STUDY_PLAN_CYCLE_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleListActionTypes.LOAD_STUDY_PLAN_CYCLE_LIST_END:
      return {
        ...state,
        studyPlanCycles: action.payload,
        loading: false
      };
    case StudyPlanCycleListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedStudyPlanCycleId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};
