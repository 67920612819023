import { provideIntlService, provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import styled from 'styled-components';
import { Colors, TextStyles } from '../../../../ui';
import { Button } from '@progress/kendo-react-buttons';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { DateFormatter } from '../../components';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SCREEN_SIZES } from '../../../../resources/screenSize';
import { STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS } from '../../../../resources/studyPlanCycleStudentApprovingUserStatus';
import { getStudyPlanCycleStudentApprovingUserStatusColorCode } from '../../../../utils/studyPlanCycleStudentApprovingUserStatusColorCodes';

const SquareButton = styled(Button)`
  width: 32px;
  height: 32px;
`;

const CommandButton = styled(Button).attrs({
  fillMode: 'solid'
})`
  margin: 2px;
  box-shadow: none;
  width: 32px;
  height: 32px;
  background-color: ${props => props.theme.gray15};
`;

const SkillContainer = styled.div`
  border: 0px solid ${Colors.GRAY_20};
  border-bottom-width: 0px;
  background-color: ${props => props.isAdditional ? '#eee' : '#fff'};
`;

const SkillTitleContainer = styled.div`
  text-align: left;
  padding: 8px;
  border: 1px solid ${Colors.GRAY_20};
  border-bottom-width: 0px;
  cursor: pointer;
  display: flex;
`;

const SkillTitleTextContainer = styled.div`
  width: 100%;
`;

const SkillHistoryContainer = styled.div`
  width: 100%;
`;

const SkillHistoryRow = styled.div`
  text-align: left;
  border: 1px solid ${Colors.GRAY_20};
  border-bottom-width: 0;
  display: flex;
`;

const SkillHistoryTextContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const CenteredTextContainer = styled(SkillHistoryTextContainer)`
  text-align: center;
`;

const SkillHistoryText = styled.span`
  ${TextStyles.caption}
  color: ${Colors.GRAY_50};
  padding-left: 8px;
`;

const SkillHistoryLevelText = styled.span`
  ${TextStyles.h6}
  width: 20px;
`;

const SkillHistoryButtonGroup = styled.div`
  width: 90px;
  height: 36px;
  white-space: nowrap;
`;

const SkillLevel = styled.span`
  font-weight: bold;
  padding-right: 8px;
  vertical-align: middle;
`;

const SkillNumbers = styled.span`
  padding-right: 8px;
  vertical-align: middle;
`;

const CompletedSkillNumbers = styled.span`
  color: ${props =>props.isCompleted ? Colors.BLACK : Colors.ERROR_40}
`;

const SkillNumbersTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const SkillNumberTableCell = styled.td`
  padding: 4px;
  text-align: center;
  border: 1px solid lightgrey;
  border-top-width: 1;
  width: 33.3%;
`;

const AdditionalSkillText = styled.p`
  ${TextStyles.caption}
  color: ${Colors.GRAY_50};
  padding: 4px 0 0 20px;
  margin: 0;
`;

const BadgeIndicator = styled(Badge).attrs({
  size: 'small'
})`
  z-index: 0;
`;

const TooltipContainer = styled(Tooltip)`
  width: 12rem;
`;

const StatusDisplay = styled.div`
  margin-right: 6px;
  width: 6px;
  background: ${props => props.statusColor};
`;

export default class StudentCycleSkillItem extends React.Component {

  constructor() {
    super();
    this.getColor = this.getColor.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    const isSmallerScreen = window.innerWidth < SCREEN_SIZES.MD;
    const countHistoryByLevelForApprovingUsers = level =>
      this.props.history?.reduce((accumulator, skill) => skill.level === level && skill.managerUserId === this.props.userId && skill.approvalStatus === STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.SUBMITTED ? ++accumulator : accumulator, 0) ?? 0;
    const ALevelSkillCountForApproval = countHistoryByLevelForApprovingUsers('A');
    const BLevelSkillCountForApproval = countHistoryByLevelForApprovingUsers('B');
    const CLevelSkillCountForApproval = countHistoryByLevelForApprovingUsers('C');

    return (
      <SkillContainer>
        <SkillTitleContainer onClick={this.props.onSkillExpand}>
          <SkillTitleTextContainer>
            <div><span className={this.props.isExpanded ? 'k-icon k-i-arrow-60-down' : 'k-icon k-i-arrow-60-right'}></span> {this.props.name}</div>
            {this.props.isAdditional &&
              <AdditionalSkillText>{this.props.cycleName}</AdditionalSkillText>
            }
          </SkillTitleTextContainer>
          {!this.props.readOnly && this.props.isAdditional &&
            <SquareButton onClick={(event) => { event.stopPropagation(); this.props.onSkillDelete(); }}><span className='k-icon k-i-trash'></span></SquareButton>
          }
        </SkillTitleContainer>
        <SkillHistoryContainer hidden={!this.props.isExpanded}>
          {(!this.props.history || this.props.history.length === 0) &&
            <SkillHistoryRow>
              <CenteredTextContainer>
                <SkillHistoryText>{localizationService.toLanguageString('grid.noRecords')}</SkillHistoryText>
              </CenteredTextContainer>
            </SkillHistoryRow>
          }
          {this.props.history?.map((historySkill, historySkillIndex) => (
            <SkillHistoryRow key={historySkill.id}>
              <StatusDisplay statusColor={this.getColor(historySkill)} title={this.getTitle(historySkill)}/>
              <SkillHistoryTextContainer>
                <SkillHistoryLevelText>
                  {historySkill.level}
                </SkillHistoryLevelText>
                <SkillHistoryText>{historySkill.managerUserFullName} {DateFormatter(historySkill.date, intlService)} ({historySkill.medicalHistoryNumber})</SkillHistoryText>
              </SkillHistoryTextContainer>
              {!this.props.readOnly &&
                <SkillHistoryButtonGroup>
                  <CommandButton onClick={() => this.props.onEditDialogOpen(historySkillIndex)}>
                    <span className='k-icon k-i-pencil'></span>
                  </CommandButton>
                  <CommandButton onClick={() => this.props.onDeleteDialogOpen(historySkillIndex)}>
                    <span className='k-icon k-i-trash'></span>
                  </CommandButton>
                </SkillHistoryButtonGroup>
              }
            </SkillHistoryRow>
          ))}
        </SkillHistoryContainer>
        <SkillNumbersTable>
          <tbody>
            <tr>
              <SkillNumberTableCell>
                <TooltipContainer anchorElement='pointer' position={isSmallerScreen ? 'right' : 'top'}>
                  <SkillLevel title={localizationService.toLanguageString('studentCycle.levelATooltip')}>A</SkillLevel>
                  <BadgeContainer>
                    <SkillNumbers>
                      <CompletedSkillNumbers isCompleted={!this.props.readOnly || this.props.levelA + this.props.newLevelA >= this.props.requiredA}>{this.props.levelA + this.props.newLevelA}</CompletedSkillNumbers>/{this.props.requiredA}
                      {this.props.readOnly && ALevelSkillCountForApproval > 0 && <BadgeIndicator>{ALevelSkillCountForApproval}</BadgeIndicator>}
                    </SkillNumbers>
                  </BadgeContainer>
                  {!this.props.readOnly &&
                    <SquareButton onClick={() => this.props.onIncrease('A')}>
                      <span className='k-icon k-i-plus'></span>
                      {this.props.newLevelA > 0 && <BadgeIndicator>+{this.props.newLevelA}</BadgeIndicator>}
                    </SquareButton>
                  }
                </TooltipContainer>
              </SkillNumberTableCell>
              <SkillNumberTableCell>
                <TooltipContainer anchorElement='pointer' position='top'>
                  <SkillLevel title={localizationService.toLanguageString('studentCycle.levelBTooltip')}>B</SkillLevel>
                  <BadgeContainer>
                    <SkillNumbers>
                      <CompletedSkillNumbers isCompleted={!this.props.readOnly || this.props.levelB + this.props.newLevelB >= this.props.requiredB}>{this.props.levelB + this.props.newLevelB}</CompletedSkillNumbers>/{this.props.requiredB}
                      {this.props.readOnly && BLevelSkillCountForApproval > 0 && <BadgeIndicator>{BLevelSkillCountForApproval}</BadgeIndicator>}
                    </SkillNumbers>
                  </BadgeContainer>
                  {!this.props.readOnly &&
                    <SquareButton onClick={() => this.props.onIncrease('B')}>
                      <span className='k-icon k-i-plus'></span>
                      {this.props.newLevelB > 0 && <BadgeIndicator>+{this.props.newLevelB}</BadgeIndicator>}
                    </SquareButton>
                  }
                </TooltipContainer>
              </SkillNumberTableCell>
              <SkillNumberTableCell>
                <TooltipContainer anchorElement='pointer' position={isSmallerScreen ? 'left' : 'top'}>
                  <SkillLevel title={localizationService.toLanguageString('studentCycle.levelCTooltip')}>C</SkillLevel>
                  <BadgeContainer>
                    <SkillNumbers>
                      <CompletedSkillNumbers isCompleted={!this.props.readOnly || this.props.levelC + this.props.newLevelC >= this.props.requiredC}>{this.props.levelC + this.props.newLevelC}</CompletedSkillNumbers>/{this.props.requiredC}
                      {this.props.readOnly && CLevelSkillCountForApproval > 0 && <BadgeIndicator>{CLevelSkillCountForApproval}</BadgeIndicator>}
                    </SkillNumbers>
                  </BadgeContainer>
                  {!this.props.readOnly &&
                    <SquareButton onClick={() => this.props.onIncrease('C')}>
                      <span className='k-icon k-i-plus'></span>
                      {this.props.newLevelC > 0 && <BadgeIndicator>+{this.props.newLevelC}</BadgeIndicator>}
                    </SquareButton>
                  }
                </TooltipContainer>
              </SkillNumberTableCell>
            </tr>
          </tbody>
        </SkillNumbersTable>
      </SkillContainer>
    );
  }

  getColor(skill) {
    if (this.props.cycleManagerOverview || this.props.userId === skill.managerUserId) {

      if (skill.approvedOn) {
        return getStudyPlanCycleStudentApprovingUserStatusColorCode(STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.APPROVED);
      }

      return getStudyPlanCycleStudentApprovingUserStatusColorCode(skill.approvalStatus);
    }

    return null;
  }

  getTitle(skill) {
    if (this.props.cycleManagerOverview || this.props.userId === skill.managerUserId) {
      const localizationService = provideLocalizationService(this);
      if (skill.approvedOn) {
        return localizationService.toLanguageString(`studyPlanCycleStudentApprovingUserStatus.${STUDY_PLAN_CYCLE_STUDENT_APPROVING_USER_STATUS.APPROVED}`);
      }
      if (skill.approvalStatus) {
        return localizationService.toLanguageString(`studyPlanCycleStudentApprovingUserStatus.${skill.approvalStatus}`);
      }
    }

    return null;
  }
}

registerForLocalization(StudentCycleSkillItem);
registerForIntl(StudentCycleSkillItem);