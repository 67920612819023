import React from 'react';
import { DecimalFormatter } from './Formatter';

export default class GridDateTimeCell extends React.PureComponent {

  render() {
    const value = this.props.dataItem[this.props.field]
      ? DecimalFormatter(this.props.dataItem[this.props.field])
      : '';
    return (
      <td className={this.props.className }>{value}</td>
    );
  }

}
