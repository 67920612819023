import { pagingSettings } from '../../../utils/GridPagingUtils';
import SurveyListPageActionTypes from '../actions/survey-list-page-action-types';

const initialState = {
  loading: false,
  surveys: null,
  surveysCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,
  canWrite: false,
  isConfirmDialogVisible: false,
  selectedSurveyId: null,

  filterStudyProgramsLoading: false,
  filterStudyPrograms: null,
  filterStudyProgramsCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SurveyListPageActionTypes.LOAD_SURVEY_LIST_START:
      return {
        ...state,
        loading: true
      };
    case SurveyListPageActionTypes.LOAD_SURVEY_LIST_END:
      return {
        ...state,
        loading: false,
        surveys: action.payload.surveys,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take,
        canWrite: action.payload.canWrite
      };
    case SurveyListPageActionTypes.FILTER_SURVEY_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case SurveyListPageActionTypes.SORT_SURVEY_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case SurveyListPageActionTypes.TOGGLE_SURVEY_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedSurveyId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    case SurveyListPageActionTypes.SEARCH_SURVEY_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoading: true
      };
    case SurveyListPageActionTypes.SEARCH_SURVEY_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoading: false,
        filterStudyPrograms: action?.payload?.studyPrograms ?? []
      };
    default:
      return state;
  }
};