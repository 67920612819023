import StudentCycleListActionTypes from '../actions/student-cycles-action-types';

const initialState = {
  loading: false,
  studentCycles: null,
  studentCyclesCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCycleListActionTypes.LOAD_STUDENT_CYCLES_START:
      return {
        ...state,
        loading: true
      };
    case StudentCycleListActionTypes.LOAD_STUDENT_CYCLES_END:
      return {
        ...state,
        studentCycles: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
