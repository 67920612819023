export default {
  LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_START: 'LoadStudyPlanCycleStudentFormPageStart',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_END: 'LoadStudyPlanCycleStudentFormPageEnd',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_STUDY_YEARS: 'LoadStudyPlanCycleStudentFormPageAvailableStudyYears',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_STATUSES: 'LoadStudyPlanCycleStudentFormPageAvailableStatuses',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_DEPARTMENTS_START: 'LoadStudyPlanCycleStudentFormPageAvailableDepartmentsStart',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_AVAILABLE_DEPARTMENTS_END: 'LoadStudyPlanCycleStudentFormPageAvailableDepartmentsEnd',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_DEPARTMENT_STUDENTS: 'LoadStudyPlanCycleStudentFormPageDepartmentStudentIds',
  SAVE_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_START: 'LoadStudyPlanCycleStudentFormPageStart',
  SAVE_STUDY_PLAN_CYCLE_STUDENT_FORM_PAGE_END: 'LoadStudyPlanCycleStudentFormPageEnd'
};