import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Grid } from '../../shared/components';
import { Text } from '../../../ui';
import { loadCycleCaseDiagnosisList, openCycleCaseDiagnosisConfirmDialog, closeCycleCaseDiagnosisConfirmDialog, removeCycleCaseDiagnosisConfirmDialog, openCycleCaseDiagnosisTlkDialog, closeCycleCaseDiagnosisTlkDialog } from '../actions/cycle-case-diagnosis-list-actions';
import { withRouter } from '../../../components/withRouter';
import { CycleCaseDiagnosisTlkDialog } from '.';

class CycleCaseDiagnosisList extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCycleCaseDiagnosisList({ cycleId: this.props.cycleId, cycleCaseId: this.props.cycleCaseId });
  }

  render() {
    const { cycleCaseDiagnosis, loading, isConfirmDialogVisible, isAddDialogVisible } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={cycleCaseDiagnosis}
          isPageable={false}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'code',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('cycle.caseDiagnosisCode')
            }, {
              field: 'name',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('cycle.caseDiagnosis')
            }
          ]}
          actions={[{
            icon: 'delete',
            onClick: this.handleRemoveClick
          }]}
        >
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddClick}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>
        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('cycle.cycleCaseDiagnosisDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
        {isAddDialogVisible &&
          <CycleCaseDiagnosisTlkDialog
            onClose={this.props.closeCycleCaseDiagnosisTlkDialog}
            cycleCaseId={this.props.cycleCaseId}
            cycleId={this.props.cycleId}
          />}
      </>
    );
  }

  handleAddClick() {
    this.props.openCycleCaseDiagnosisTlkDialog();
  }

  handleRemoveClick(e) {
    this.props.openCycleCaseDiagnosisConfirmDialog(e.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeCycleCaseDiagnosisConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCycleCaseDiagnosisConfirmDialog({
      cycleId: this.props.cycleId,
      cycleCaseId: this.props.cycleCaseId
    });
  }
}

const mapStateToProps = (state) => ({
  cycleCaseDiagnosis: state.cycleCaseDiagnosisList.cycleCaseDiagnosis,
  loading: state.cycleCaseDiagnosisList.loading,
  isConfirmDialogVisible: state.cycleCaseDiagnosisList.isConfirmDialogVisible,
  isAddDialogVisible: state.cycleCaseDiagnosisList.isAddDialogVisible
});

const mapDispatchToProps = (dispatch) => ({
  loadCycleCaseDiagnosisList: (payload) => dispatch(loadCycleCaseDiagnosisList(payload)),
  openCycleCaseDiagnosisConfirmDialog: (payload) => dispatch(openCycleCaseDiagnosisConfirmDialog(payload)),
  closeCycleCaseDiagnosisConfirmDialog: (payload) => dispatch(closeCycleCaseDiagnosisConfirmDialog(payload)),
  removeCycleCaseDiagnosisConfirmDialog: (payload) => dispatch(removeCycleCaseDiagnosisConfirmDialog(payload)),
  openCycleCaseDiagnosisTlkDialog: () => dispatch(openCycleCaseDiagnosisTlkDialog()),
  closeCycleCaseDiagnosisTlkDialog: (payload) => dispatch(closeCycleCaseDiagnosisTlkDialog(payload))
});

registerForIntl(CycleCaseDiagnosisList);
registerForLocalization(CycleCaseDiagnosisList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleCaseDiagnosisList));