import React, { PureComponent } from 'react';
import styled from 'styled-components';

const ErrorMessageContainer = styled.div`
  white-space: pre-line;
`;

export default class ErrorMessage extends PureComponent {

  render() {
    return (
      <ErrorMessageContainer className={'k-messagebox k-messagebox-error'}>{this.props.children}</ErrorMessageContainer>
    );
  }

}