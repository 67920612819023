import StudentOverviewPageActionTypes from './student-overview-page-action-types';
import axios from 'axios';

export const loadStudentOverviewPage = (studentId) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentOverviewPageActionTypes.LOAD_STUDENT_OVERVIEW_PAGE_START });
  const state = getState().studentOverviewPage;
  try {
    state.studentCancelToken?.cancel();
    state.studentCancelToken = axios.CancelToken.source();
    const student = await api.get(`api/students/${studentId}`, null, state.studentCancelToken.token);
    dispatch({
      type: StudentOverviewPageActionTypes.LOAD_STUDENT_OVERVIEW_PAGE_END,
      payload: student
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentOverviewPageActionTypes.LOAD_STUDENT_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const selectStudentOverviewPageTab = (payload) => async(dispatch) => {
  dispatch({
    type: StudentOverviewPageActionTypes.SELECT_STUDENT_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};