import MyStudiesListListActionTypes from '../actions/my-studies-cycle-list-action-types';

const initialState = {
  loading: false,
  myCycles: null,
  myStudiesCancelToken: null,
  sorting: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MyStudiesListListActionTypes.LOAD_MY_STUDIES_CYCLE_LIST_START:
      return {
        ...state,
        loading: true
      };
    case MyStudiesListListActionTypes.LOAD_MY_STUDIES_CYCLE_LIST_END:
      return {
        ...state,
        myCycles: action.payload,
        loading: false
      };
    case MyStudiesListListActionTypes.SORT_MY_STUDIES_CYCLE_LIST:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
