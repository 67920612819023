import React from 'react';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

export default class LoadingPanel extends React.Component {
  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <div className='k-loading-mask'>
        <span className='k-loading-text'>{localizationService.toLanguageString('custom.loading')}</span>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
  }
}

registerForLocalization(LoadingPanel);