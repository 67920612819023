import StudyPlanOverviewPageActionTypes from './study-plan-overview-page-action-types';
import ValidationError from '../../../utils/validation-error';
import axios from 'axios';

export const loadStudyPlanOverviewPage = (studyPlanId) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanOverviewPageActionTypes.LOAD_STUDY_PLAN_OVERVIEW_PAGE_START });
  const state = getState().studyPlanOverviewPage;
  try {
    state.studyPlanCancelToken?.cancel();
    state.studyPlanCancelToken = axios.CancelToken.source();
    const studyPlan = await api.get(`api/study_plans/${studyPlanId}`, null, state.studyPlanCancelToken.token);
    dispatch({
      type: StudyPlanOverviewPageActionTypes.LOAD_STUDY_PLAN_OVERVIEW_PAGE_END,
      payload: studyPlan
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanOverviewPageActionTypes.LOAD_STUDY_PLAN_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const approveStudyPlan = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyPlanOverviewPageActionTypes.APPROVE_STUDY_PLAN_START });
  const state = getState().studyPlanFormPage;
  const apiModel = {
    ...payload,
    isApproved: true
  };
  try {
    await api.put(`api/study_plans/${payload.id}`, apiModel);
    dispatch({
      type: StudyPlanOverviewPageActionTypes.APPROVE_STUDY_PLAN_END,
      payload: {
        studyPlan: {
          ...state.studyPlan,
          isApproved: true
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: StudyPlanOverviewPageActionTypes.APPROVE_STUDY_PLAN_END,
      payload: {
        validationResult,
        studyPlan: state.studyPlan
      }
    });
  }
};