import cycleCaseOverviewPageActionTypes from '../actions/cycle-case-overview-page-action-types';

const initialState = {
  loading: false,
  cycleCase: null,
  cycleCaseCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case cycleCaseOverviewPageActionTypes.LOAD_CYCLE_CASE_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case cycleCaseOverviewPageActionTypes.LOAD_CYCLE_CASE_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        cycleCase: action.payload
      };
    default:
      return state;
  }
};