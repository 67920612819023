import axios from 'axios';
import { CYCLE_STUDENT_STATUS } from '../../../resources/studyPlanCycleDepartmentStudentStatus';
import { STUDY_YEARS } from '../../../resources/studyYears';
import StudyPlanCycleStudentSelectPageActionTypes from './study-plan-cycle-student-select-page-action-types';
import { STUDENT_STATUSES } from '../../../resources/studentStatus';

export const loadStudyPlanCycleStudentSelectPage = (payload, localizationService) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleStudentSelectPage;
  try {
    state.studyPlanCycleStudentsSelectCancelToken?.cancel();
    state.studyPlanCycleStudentsSelectCancelToken = axios.CancelToken.source();
    const [studyPlan, students, cycleManagers] = await Promise.all([
      api.get(`/api/study_plans/${payload.studyPlanId}`, null, state.studyPlanCycleStudentsSelectCancelToken.token),
      api.get(`/api/study_plans/${payload.studyPlanId}/cycles/${payload.studyPlanCycleId}/students`, null, state.studyPlanCycleStudentsSelectCancelToken.token),
      api.get(`/api/study_plans/${payload.studyPlanId}/cycles/${payload.studyPlanCycleId}/managers`, null, state.studyPlanCycleStudentsSelectCancelToken.token)
    ]);
    dispatch({
      type: StudyPlanCycleStudentSelectPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START,
      payload: {
        studyPlanId: payload.studyPlanId,
        studyPlanCycleId: payload.studyPlanCycleId,
        studyProgramId: studyPlan.studyProgramId,
        studyPlanCycleStudentIds: students?.data ? Object.assign({}, ...students?.data?.map(student => ({ [student.studentId]: true }))) : {},
        availableCycleManagers: cycleManagers?.map(manager => ({ id: manager.id, name: manager.userFullName }))
      }
    });
    dispatch(loadAvailableStudyYears());
    dispatch(findStudyPlanCycleStudentSelectPage(payload));
    dispatch(loadStudyStatuses(localizationService));
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const findStudyPlanCycleStudentSelectPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleStudentSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START });
  const state = getState().studyPlanCycleStudentSelectPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    studyProgramId: state.studyProgramId,
    student: state.filter?.student,
    year: state.filter?.year?.id,
    status: state.filter?.status?.id
  };
  try {
    state.studyPlanCycleStudentsSelectCancelToken?.cancel();
    state.studyPlanCycleStudentsSelectCancelToken = axios.CancelToken.source();
    const students = await api.get(`/api/students`, query, state.studyPlanCycleStudentsSelectCancelToken.token);
    dispatch({
      type: StudyPlanCycleStudentSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_END,
      payload: {
        availableStudents: students
          ? students.data?.map(student => ({
            ...student,
            isAlreadyAdded: state.studyPlanCycleStudentIds[student.id] === true,
            isSelected: state.selectedAll
          }))
          : [],
        total: students ? students.total : 0,
        skip: query.skip,
        take: query.limit
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleStudentSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_END });
      throw error;
    }
  }
};

export const filterStudyPlanCycleStudentSelectPage = (payload) => (dispatch) => {
  dispatch({
    type: StudyPlanCycleStudentSelectPageActionTypes.FILTER_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE,
    payload
  });
  dispatch(findStudyPlanCycleStudentSelectPage());
};

export const clearStudyPlanCycleStudentSelectPage = () => (dispatch) => {
  dispatch({
    type: StudyPlanCycleStudentSelectPageActionTypes.FILTER_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE,
    payload: {}
  });
  dispatch(findStudyPlanCycleStudentSelectPage());
};

export const selectStudyPlanCycleStudent = (payload) => (dispatch, getState) => {
  dispatch({
    type: StudyPlanCycleStudentSelectPageActionTypes.SELECT_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE,
    payload: {
      selectItemId: payload.id
    }
  });
};

export const selectStudyPlanCycleStudentSelectPage = (payload) => (dispatch, getState) => {
  const state = getState().studyPlanCycleStudentSelectPage;
  const availableStudents = state.availableStudents?.map(student => {
    let isSelected = student.isSelected;
    if (student.id === payload.id) {
      isSelected = !isSelected;
    }
    return {
      ...student,
      isSelected: isSelected
    };
  });
  dispatch({
    type: StudyPlanCycleStudentSelectPageActionTypes.SELECT_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE,
    payload: {
      availableStudents
    }
  });
};

export const selectAllStudyPlanCycleStudentSelectPage = (payload) => (dispatch, getState) => {
  const state = getState().studyPlanCycleStudentSelectPage;
  const availableStudents = state.availableStudents?.map(student => ({
    ...student,
    isSelected: student.isAlreadyAdded ? false : !state.selectedAll
  }));
  dispatch({
    type: StudyPlanCycleStudentSelectPageActionTypes.SELECT_ALL_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE,
    payload: {
      availableStudents
    }
  });
};

export const addSelectedStudyPlanCycleStudentSelectPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleStudentSelectPageActionTypes.ADD_SELECTED_STUDENTS_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START });
  const state = getState().studyPlanCycleStudentSelectPage;
  let errorMessage = null;
  let successMessage = null;
  let errorsCount = 0;
  let studyPlanCycleStudentIds = null;
  let totalCount = 0;
  let resultErrors = [];
  try {
    const apiModel = state.availableStudents.filter(x => x.isSelected).map(student => ({
      studentId: student.id,
      studyPlanCycleId: state.studyPlanCycleId,
      status: CYCLE_STUDENT_STATUS.NOT_STARTED,
      cycleManagerId: payload?.cycleManagerId
    }));
    if (apiModel?.length > 0) {
      totalCount = apiModel.length;
      const result = await api.post(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/students`, apiModel);
      if (result) {
        const addedStudentIds = result?.map(item => parseInt(item.headers?.find(e => e.name === 'StudentId')?.value));
        const availableStudents = state.availableStudents.map(student => ({
          ...student,
          isSelected: addedStudentIds.includes(student.id) ? false : student.isSelected,
          isAlreadyAdded: addedStudentIds.includes(student.id) ? true : student.isAlreadyAdded
        }));
        dispatch({
          type: StudyPlanCycleStudentSelectPageActionTypes.SELECT_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE,
          payload: {
            availableStudents
          }
        });
        resultErrors = result.filter(x => x.statusCode === 400 || x.body?.errors?.length > 0);
        errorsCount = resultErrors.length || 0;
      }
    }
    const studyPlanCycleStudents = await api.get(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/students`);
    studyPlanCycleStudentIds = studyPlanCycleStudents ? Object.assign({}, ...studyPlanCycleStudents?.data?.map(x => ({ [x.studentId]: true }))) : {};
  } finally {
    errorMessage = null;
    if (errorsCount === 0) {
      successMessage = `${payload.localizationService.toLanguageString(`systemMessages.successfullyAddedRecords`)} ${totalCount}.`;
    } else {
      const successfullCarriersCount = totalCount - errorsCount;
      if (successfullCarriersCount > 0) {
        successMessage = `${payload.localizationService.toLanguageString(`systemMessages.successfullyAdded`)} ${successfullCarriersCount}`;
      }

      const concatenatedErrorMessages = resultErrors.map(resultError => {
        const residentsFullName = resultError.body.errors.find(error => error.propertyName === 'residentsFullName').message;
        const filteredErrors = resultError.body.errors.filter(error => error.propertyName !== 'residentsFullName');
        return `${residentsFullName}: ${filteredErrors.map(error => error.message).join('; ').concat('.')}`;
      }).join('\r\n');
      errorMessage = `${payload.localizationService.toLanguageString(`StudyPlanCycleStudent.failedToAddStudents`)} ${errorsCount}.\r\n${concatenatedErrorMessages}`;
    }
    dispatch({
      type: StudyPlanCycleStudentSelectPageActionTypes.ADD_SELECTED_STUDENTS_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_END,
      payload: {
        errorMessage,
        successMessage,
        studyPlanCycleStudentIds
      }
    });
  }
};

export const loadAvailableStudyYears = () => (dispatch) => {
  const availableStudyYears = Object.keys(STUDY_YEARS)
    .map(key => ({
      id: key,
      name: key
    }));
  dispatch({
    type: StudyPlanCycleStudentSelectPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_STUDY_YEARS,
    payload: {
      availableStudyYears
    }
  });
};

export const loadStudyStatuses = (localizationService) => async(dispatch, getState) => {
  const statuses = Object.keys(STUDENT_STATUSES)
    .map(key => ({
      id: STUDENT_STATUSES[key],
      name: localizationService.toLanguageString('studentStatus.' + STUDENT_STATUSES[key])
    }));
  dispatch({
    type: StudyPlanCycleStudentSelectPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_STUDENT_STATUSES,
    payload: {
      availableStudentStatuses: statuses
    }
  });
};

export const openStudyPlanCycleStudentSelectPageSaveDialog = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleStudentSelectPageActionTypes.OPEN_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_SAVE_DIALOG });
};

export const closeStudyPlanCycleStudentSelectPageSaveDialog = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleStudentSelectPageActionTypes.CLOSE_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_SAVE_DIALOG });
};

export const cleanupStudyPlanCycleStudentSelectPage = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleStudentSelectPageActionTypes.CLEANUP_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE });
};