import StudyPlanCycleTheoreticalPartListActionTypes from '../actions/study-plan-cycle-theoretical-part-list-action-types';

const initialState = {
  loading: false,
  studyPlanCycleTheoreticalParts: null,
  studyPlanCycleTheoreticalPartsCancelToken: null,
  isConfirmDialogVisible: false,
  selectedStudyPlanCycleTheoreticalPartId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleTheoreticalPartListActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleTheoreticalPartListActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_LIST_END:
      return {
        ...state,
        studyPlanCycleTheoreticalParts: action.payload,
        loading: false
      };
    case StudyPlanCycleTheoreticalPartListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_THEORETICAL_PART_CONFIRM_DIALOG:
      return {
        ...state,
        selectedStudyPlanCycleTheoreticalPartId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};
