import { Field } from '@progress/kendo-react-form';
import * as React from 'react';
import { StackLayout, TextArea } from '../../../ui';
import FormDialog from '../../../ui/components/application/form-dialog';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';

class CompetencyRequestReturnForm extends React.PureComponent {
  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormDialog
        title={localizationService.toLanguageString('competencyRequest.returnDialogTitle')}
        onSubmit={this.props.onSubmit}
        onCancel={this.props.onCancel}
        validator={this.validateReturnCompetencyDialog}
        confirmButtonText={localizationService.toLanguageString('buttons.return')}
        closeButtonText={localizationService.toLanguageString('buttons.close')}
        minWidth={320}
        render={() => (
          <StackLayout>
            <Field
              name='comment'
              label={localizationService.toLanguageString('competencyRequest.comment')}
              component={TextArea}
            />
          </StackLayout>
        )}
      />
    );
  };

  validateReturnCompetencyDialog(values, localizationService, validationResult, modified) {
    const maxCommentLength = 250;
    const errors = {};
    if (values.comment && values.comment.length > maxCommentLength) {
      errors.comment = `${localizationService.toLanguageString('validation.exceedsMaximumLength')} (${maxCommentLength})`;
    }
    if (!modified.comment) {
      if (validationResult?.errors?.comment) {
        errors.comment = validationResult?.errors?.comment;
      }
    }
    return errors;
  }
}

registerForLocalization(CompetencyRequestReturnForm);

export default CompetencyRequestReturnForm;