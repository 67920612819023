import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { loadSurveyFormPage, saveSurvey } from '../actions/my-studies-survey-form-page-actions';
import { FormPage, StackLayout, WrapLayout, Text, Colors, Rating, TextArea } from '../../../ui';
import { Field } from '@progress/kendo-react-form';
import styled from 'styled-components';
import { DecimalFormatter, EmptyFormatter, ValueItem } from '../../shared/components';
import { SURVEY_QUESTION_TYPES } from '../../../resources/surveyQuestionType';
import { withRouter } from '../../../components/withRouter';
import { SURVEY_TYPES } from '../../../resources/surveyType';

const Container = styled(WrapLayout)`
  display: grid;
`;

const StyledRatingField = styled(Field)`
  margin-bottom: 0.75rem;
`;

const StyledTextAreaField = styled(StyledRatingField)`
  margin-left: 4px;
`;

const DescriptionText = styled(Text).attrs({
})`
  margin-bottom: 4px;
`;

const QuestionNoteText = styled(Text).attrs({
})`
  margin-bottom: 4px;
  margin-left: 4px;
`;

class MyStudiesSurveyFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderSurveyQuestions = this.renderSurveyQuestions.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleFormValues = this.handleFormValues.bind(this);
    this.state = {
      evaluationAverage: 0
    };
  }

  async componentDidMount() {
    if (this.props.params?.studyPlanCycleStudentId) {
      await this.props.loadSurveyFormPage({
        studyPlanCycleStudentId: this.props.params.studyPlanCycleStudentId,
        surveyType: this.props.params.surveyType,
        managerUserId: this.props.params.managerUserId,
        surveyAnswerStudentId: this.props.params.surveyAnswerStudentId
      });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const { survey, loading, managersSurvey } = this.props;
    return (
      <FormPage
        title={(() => {
          switch (survey?.surveyType) {
            case SURVEY_TYPES.RESIDENT_EVALUATION_SURVEY:
              return localizationService.toLanguageString('survey.residentEvaluation');
            case SURVEY_TYPES.MANAGER_EVALUATION_SURVEY:
              return localizationService.toLanguageString('survey.managerEvaluation');
            case SURVEY_TYPES.CYCLE_EVALUATION_SURVEY:
              return localizationService.toLanguageString('survey.cycleEvaluation');
            default:
              return localizationService.toLanguageString('survey.survey');
          }
        })()}
        breadcrumbItems={this.getBreadcrumbItems()}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        renderBackButton={true}
        onBackClick={this.handleBackClick}
        validationResult={this.props.validationResult}
        loading={loading}
        localizationService={localizationService}
        initialValues={survey}
        onSubmit={this.handleSubmit}
        submitButtonLabel={localizationService.toLanguageString('buttons.evaluate')}
        onCancel={this.handleCancel}
        cancelButtonLabel={this.props.preview ? localizationService.toLanguageString('buttons.close') : localizationService.toLanguageString('buttons.cancel')}
        validator={this.handleValidate}
        formValueHandler={this.handleFormValues}
        hideSaveButton={this.props.preview}
        render={() => (
          <StackLayout orientation='vertical' width='320px'>
            <ValueItem
              label={localizationService.toLanguageString('survey.cycle')}
              labelWidth='96px'
              loading={loading}
              truncateValue={false}
            >
              {`${survey?.cycleCode} ${survey?.cycleTitle}`}
            </ValueItem>
            {managersSurvey &&
              <ValueItem
                label={localizationService.toLanguageString('survey.resident')}
                labelWidth='96px'
                loading={loading}
              >
                {survey?.studentName}
              </ValueItem>
            }
            <ValueItem
              label={localizationService.toLanguageString('survey.credits')}
              labelWidth='96px'
              loading={loading}
            >
              {survey?.cycleCredits}
            </ValueItem>
            {survey?.subjectsName &&
              <ValueItem
                label={localizationService.toLanguageString('survey.manager')}
                labelWidth='96px'
                loading={loading}
              >
                {survey.subjectsName}
              </ValueItem>
            }
            <DescriptionText variant='caption' width='320px' textColor={Colors.ERROR_40}>
              {survey?.surveyDescription}
            </DescriptionText>
            <Text variant='caption' textColor={Colors.GRAY_50}>
              {survey?.surveyNote}
            </Text>
            {survey?.surveyQuestions.map((element, index) => this.renderSurveyQuestions(element, index))}
            {survey?.surveyType === SURVEY_TYPES.RESIDENT_EVALUATION_SURVEY &&
              <ValueItem
                label={localizationService.toLanguageString('survey.grade')}
                labelWidth='55px'
                loading={loading}
              >
                {this.props.preview
                  ? `${EmptyFormatter(DecimalFormatter(survey.evaluation, 0))}`
                  : `${DecimalFormatter(this.state.evaluationAverage, 0)} (${DecimalFormatter(this.state.evaluationAverage)})`
                }
              </ValueItem>
            }
          </StackLayout>
        )}
      />
    );
  }

  renderSurveyQuestions(e, index) {
    const fieldName = 'surveyAnswers[' + e.number + ']';
    return (
      <Container key={index} orientation='vertical' rowGap='0'>
        <StackLayout orientation='vertical' rowGap='0'>
          <Text variant='subtitle2' textColor={Colors.GRAY_80}>
            {`${e.number}. ${e.questionText}`}
          </Text>
        </StackLayout>
        {e.type === SURVEY_QUESTION_TYPES.NUMERIC_ONE_THROUGH_TEN &&
          <StyledRatingField
            name={fieldName}
            component={Rating}
            disabled={this.props.preview}
          />
        }
        {e.type === SURVEY_QUESTION_TYPES.TEXT &&
          <StyledTextAreaField
            name={fieldName}
            component={TextArea}
            autoSize={true}
            width='300px'
            disabled={this.props.preview}
          />
        }
        {e.questionNote &&
          <QuestionNoteText variant='caption' width='320px' textColor={Colors.GRAY_50}>
            {e.questionNote}
          </QuestionNoteText>
        }
      </Container>
    );
  }

  handleFormValues(values) {
    let sum = 0;
    let count = 0;
    values?.surveyAnswers?.map(element => {
      if (typeof element === 'number') {
        sum += element;
        element && count++;
      }
    });
    this.setState({ evaluationAverage: sum / count >= 0 ? sum / count : 0 });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'my-residents') {
      this.props.navigate('/managed_students');
    }
    if (e.id === 'surveys') {
      this.props.navigate(-1);
    }
  }

  handleValidate(values, localizationService, validationResult, modified) {
    let errors = {};
    for (let i = 0; i < values?.surveyQuestions?.length; i++) {
      if (values.surveyQuestions[i].type === SURVEY_QUESTION_TYPES.NUMERIC_ONE_THROUGH_TEN
          && values.surveyAnswers[values.surveyQuestions[i].number] == null) {
        errors = { ...errors, ['surveyAnswers[' + values.surveyQuestions[i].number + ']']: localizationService.toLanguageString('validation.required') };
      }
    }
    return errors;
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveSurvey({
      ...payload,
      studyPlanCycleStudentId: this.props.params.studyPlanCycleStudentId,
      surveyType: this.props.params.surveyType,
      departmentId: this.props.params.departmentId
    });
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  getBreadcrumbItems() {
    const localizationService = provideLocalizationService(this);
    const breadcrumbItems = [];
    const { survey, managersSurvey } = this.props;
    let textValue = null;

    if (managersSurvey) {
      breadcrumbItems.push({
        id: 'my-residents',
        text: localizationService.toLanguageString('survey.myResidents')
      });
    }

    switch (survey?.surveyType) {
      case SURVEY_TYPES.RESIDENT_EVALUATION_SURVEY:
        textValue = localizationService.toLanguageString('survey.residentCycle');
        break;
      default:
        textValue = localizationService.toLanguageString('survey.cycleCompletion');
        break;
    }
    breadcrumbItems.push({ id: 'surveys', text: textValue });

    switch (survey?.surveyType) {
      case SURVEY_TYPES.RESIDENT_EVALUATION_SURVEY:
        textValue = localizationService.toLanguageString('survey.residentEvaluationSurvey');
        break;
      case SURVEY_TYPES.MANAGER_EVALUATION_SURVEY:
        textValue = localizationService.toLanguageString('survey.managerEvaluationSurvey');
        break;
      case SURVEY_TYPES.CYCLE_EVALUATION_SURVEY:
        textValue = localizationService.toLanguageString('survey.completedCycleQualityEvaluation');
        break;
      default:
        textValue = localizationService.toLanguageString('survey.survey');
        break;
    }
    breadcrumbItems.push({ id: 'survey', text: textValue, disabled: true });
    return breadcrumbItems;
  }
}

const mapStateToProps = state => ({
  loading: state.myStudiesSurveyFormPage.loading,
  survey: state.myStudiesSurveyFormPage.survey,
  validationResult: state.myStudiesSurveyFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadSurveyFormPage: (payload) => dispatch(loadSurveyFormPage(payload)),
  saveSurvey: (payload) => dispatch(saveSurvey(payload))
});

registerForLocalization(MyStudiesSurveyFormPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyStudiesSurveyFormPage));