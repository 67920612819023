import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { DatePicker, FormPage, MultiSelect, StackLayout, TextBox, TextEditor } from '../../../ui';
import { clearNotificationFormPage, loadAvailableStudyPrograms, loadNotificationFormPage, saveNotificationFormPage } from '../actions';
import { Field } from '@progress/kendo-react-form';
import { EditorUtils } from '@progress/kendo-react-editor';

class NotificationFormPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFilterAvailableStudyPrograms = this.handleFilterAvailableStudyPrograms.bind(this);
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    await this.props.loadNotificationFormPage({
      notificationId: this.props.params?.notificationId,
      localizationService
    });
  }

  componentWillUnmount() {
    this.props.clearNotificationFormPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const editor = React.createRef();
    return (
      <FormPage
        title={localizationService.toLanguageString('notifications.notification')}
        breadcrumbItems={[
          {
            id: 'notifications',
            text: localizationService.toLanguageString('notifications.notifications')
          },
          {
            id: 'notification',
            text: localizationService.toLanguageString('notifications.notification'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onSubmit={(payload) => this.handleSubmit(payload, editor)}
        onCancel={this.handleCancel}
        validator={(values, localizationService, validationResult, modified) => this.handleValidate(values, localizationService, validationResult, modified, editor)}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.notification}
        localizationService={localizationService}
        render={(formRenderProps) => {
          const studyProgramSelected = formRenderProps.valueGetter('studyPrograms')?.length > 0;
          const studyYearSelected = formRenderProps.valueGetter('studyYears')?.length > 0;
          const studyStatusSelected = formRenderProps.valueGetter('studyStatuses')?.length > 0;
          return (
            <StackLayout>
              <Field
                name='studyPrograms'
                label={localizationService.toLanguageString(studyProgramSelected ? 'notifications.studyPrograms' : 'notifications.studyProgramsAll')}
                component={MultiSelect}
                width='480px'
                data={this.props.availableStudyPrograms}
                loading={this.props.availableStudyProgramsLoading}
                filterable={true}
                onFilterChange={this.handleFilterAvailableStudyPrograms}
                dataItemKey='studyProgramId'
                textField='studyProgramName'
              />
              <Field
                name='studyYears'
                label={localizationService.toLanguageString(studyYearSelected ? 'notifications.studyYears' : 'notifications.studyYearsAll')}
                component={MultiSelect}
                width='480px'
                data={this.props.studyYears}
                dataItemKey='value'
                textField='text'
              />
              <Field
                name='studyStatuses'
                label={localizationService.toLanguageString(studyStatusSelected ? 'notifications.studyStatuses' : 'notifications.studyStatusesAll')}
                component={MultiSelect}
                width='480px'
                data={this.props.studyStatuses}
                dataItemKey='value'
                textField='text'
              />
              <Field
                name='visibleFrom'
                label={localizationService.toLanguageString('notifications.visibleFrom')}
                component={DatePicker}
                width='320px'
              />
              <Field
                name='title'
                label={localizationService.toLanguageString('notifications.title')}
                component={TextBox}
                width='320px'
              />
              <Field
                name='text'
                label={localizationService.toLanguageString('notifications.text')}
                component={TextEditor}
                fieldRef={editor}
              />
            </StackLayout>
          );
        }}
      />
    );
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'notifications') {
      this.props.navigate('/notifications');
    }
  }

  handleSubmit(payload, editor) {
    const view = editor.current?.view;
    if (view) {
      payload.text = EditorUtils.getHtml(view.state);
    }
    this.props.saveNotificationFormPage(payload);
  }

  handleCancel() {
    this.props.navigate('/notifications');
  }

  handleValidate(values, localizationService, validationResult, modified, editorRef) {
    const editorView = editorRef?.current?.view;
    const editorText = editorView ? EditorUtils.getHtml(editorView.state) : null;
    const errors = {};
    if (!values.title) {
      errors.title = localizationService.toLanguageString('validation.required');
    } else if (values.title.length > 500) {
      errors.title = localizationService.toLanguageString('validation.exceedsMaximumLength');
    }
    if (!values.text) {
      errors.text = localizationService.toLanguageString('validation.required');
    } else if (editorText?.length > 15000) {
      errors.text = localizationService.toLanguageString('validation.exceedsMaximumLength');
    }
    if (!values.visibleFrom) {
      errors.visibleFrom = localizationService.toLanguageString('validation.required');
    }

    return errors;
  }

  async handleFilterAvailableStudyPrograms(e) {
    await this.props.loadAvailableStudyPrograms({ keyword: e.filter.value });
  }
}

const mapStateToProps = state => ({
  loading: state.notificationFormPage.loading,
  notification: state.notificationFormPage.notification,
  validationResult: state.notificationFormPage.validationResult,
  availableStudyPrograms: state.notificationFormPage.availableStudyPrograms,
  availableStudyProgramsLoading: state.notificationFormPage.availableStudyProgramsLoading,
  studyYears: state.notificationFormPage.studyYears,
  studyStatuses: state.notificationFormPage.studyStatuses
});

const mapDispatchToProps = dispatch => ({
  loadNotificationFormPage: (payload) => dispatch(loadNotificationFormPage(payload)),
  saveNotificationFormPage: (payload) => dispatch(saveNotificationFormPage(payload)),
  loadAvailableStudyPrograms: (payload) => dispatch(loadAvailableStudyPrograms(payload)),
  clearNotificationFormPage: () => dispatch(clearNotificationFormPage())
});

registerForLocalization(NotificationFormPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationFormPage));