export default {
  START_APPLICATION_LOADING: 'StartApplicationLoading',
  END_APPLICATION_LOADING: 'EndApplicationLoading',
  UPDATE_USER_INFO: 'UpdateUserInfo',
  ERROR: 'Error',
  HANDLE_STUDY_PROGRAM_CHANGE: 'HandleStudyProgramChange',
  UPDATE_USER_MENU_ITEMS: 'UpdateUserMenuItems',
  UPDATE_USER_DRAWER_ITEMS: 'UpdateUserDrawerItems',
  UPDATE_REQUESTS_HUB_DATA: 'UpdateRequestsHubData',
  SET_REQUESTS_HUB_CONNECTION: 'SetRequestsHubConnection'
};