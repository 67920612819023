import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService } from '@progress/kendo-react-intl';
import { GridSize, Grid, Loader, DateFormatter, TimeFormatter } from '../../shared/components';
import { loadTheoreticalPartListPage, findTheoreticalParts, sortTheoreticalParts, changeExpandState } from '../actions/theoretical-part-list-page-actions';
import { THEORETICAL_PARTS } from '../../../resources/theoreticalPartType';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';
import { Text, WrapLayout } from '../../../ui';
import colors from '../../../ui/components/shared/colors';
import { StackLayout } from '@progress/kendo-react-layout';

const DetailsContainer = styled.div`
  min-height: 40px;
  padding: 8px 0 8px 0;
  position: relative;
`;

const DetailsWrapper = styled.div`
  width: 320px;
`;

const ExpandCell = styled.td`
  padding-left: 16px !important;
  padding-right: 0px !important;
  cursor: pointer;
`;

const TextContainer = styled.div`
  width: 340px;
`;

const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 4px;
  margin-top: 8px;
`;

const FlexContainer = styled.div`
  margin-left: 8px;
  display: flex;
`;

const HyperLink = styled.a`
  font-weight: bold;
  display: inline-block;
  color: #0968C4 !important;
  &:visited {
    color: #551A8B !important;
  }
`;

class TheoreticalPartList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.renderDetails = this.renderDetails.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.renderName = this.renderName.bind(this);
    this.renderPlannedDate = this.renderPlannedDate.bind(this);
  }

  async componentDidMount() {
    await this.props.loadTheoreticalPartListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <Grid
        loading={this.props.loading}
        data={this.props.theoreticalParts}
        skip={this.props.skip}
        take={this.props.take}
        total={this.props.total}
        sort={this.props.sorting}
        onExpandChange={this.handleExpandChange}
        expandField='expanded'
        detail={this.renderDetails}
        onPageChange={this.handlePageChange}
        onSortChange={this.handleSort}
        cellRender={this.renderCell}
        onRowClick={this.handleRowClick}
        columns={[
          {
            field: 'name',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('theoreticalPart.name'),
            sortable: false,
            cell: (e) => this.renderName(e, localizationService)
          }, {
            field: 'plannedDate',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('theoreticalPart.isPlanned'),
            cell: (e => this.renderPlannedDate(e, intlService)),
            minGridWidth: GridSize.small
          }, {
            field: 'lecturer',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('theoreticalPart.lecturer'),
            sortable: false,
            minGridWidth: GridSize.medium
          }
        ]}
      />
    );
  }

  renderName(e, localizationService) {
    const { dataItem, className } = e;
    let resolvedValue = '';
    switch (dataItem.type) {
      case THEORETICAL_PARTS.LECTURE:
        resolvedValue = localizationService.toLanguageString('cycleTheoreticalPartType.Lecture');
        break;
      case THEORETICAL_PARTS.SEMINAR:
        resolvedValue = localizationService.toLanguageString('cycleTheoreticalPartType.Seminar');
        break;
      default:
        resolvedValue = localizationService.toLanguageString('theoreticalPart.unknownTypeError'); ;
        break;
    }
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <StackLayout rowGap='2px' orientation='vertical'>
          {`${resolvedValue}: ${dataItem.name}`}
          <WrapLayout rowGap='0' columnGap='0'>
            <Text textColor={colors.GRAY_50} variant='caption'>
              {`${dataItem.studyProgramName}: ${dataItem.cycleName}`}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  renderPlannedDate(e, intlService) {
    const { dataItem, className } = e;
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <StackLayout rowGap='2px' orientation='vertical'>
          {dataItem.plannedDate ?
            DateFormatter(new Date(dataItem.plannedDate), intlService).replaceAll('-', '\u2011')
            : ''}
          <WrapLayout rowGap='0' columnGap='0'>
            <Text textColor={colors.GRAY_50} variant='caption'>
              {dataItem.plannedTime ?
                TimeFormatter(new Date(dataItem.plannedTime), intlService)
                : ''}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  renderCell(tdElement, cellProps) {
    if (cellProps.rowType === 'data') {
      if (cellProps.field === 'expanded') {
        return (
          <ExpandCell onClick={(e) => {
            this.handleExpandChange(cellProps);
            e.stopPropagation();
          }}>
            <a
              className={`k-icon k-i-${cellProps.dataItem.expanded ? 'collapse' : 'expand'}`}
            />
          </ExpandCell>
        );
      }
    }

    return tdElement;
  };

  renderDetails(props) {
    const { dataItem } = props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <DetailsContainer>
        {dataItem.loading ? <Loader/> :
          <WrapLayout>
            <DetailsWrapper>
              <FlexContainer>
                <FlexItem>
                  <TextContainer>
                    <>
                      <Text variant='subtitle2'>{localizationService.toLanguageString('theoreticalPart.location')}: </Text>
                      {dataItem.location
                        ? <Text variant={'caption'}>{dataItem.location}</Text>
                        : <Text variant={'caption'} textColor={colors.GRAY_30}>{localizationService.toLanguageString('custom.notSpecified')}</Text>}
                    </>
                  </TextContainer>
                  <TextContainer>
                    <Text variant='subtitle2'>{localizationService.toLanguageString('theoreticalPart.isPlanned')}: </Text>
                    {dataItem.plannedDate
                      ? <Text variant='caption'>{`${DateFormatter(new Date(dataItem.plannedDate), intlService).replaceAll('-', '\u2011')} ${TimeFormatter(new Date(dataItem.plannedTime), intlService)}`}</Text>
                      : <Text variant={'caption'} textColor={colors.GRAY_30}>{localizationService.toLanguageString('custom.notSpecified')}</Text>}
                  </TextContainer>
                  <TextContainer>
                    <Text variant='subtitle2'>{localizationService.toLanguageString('theoreticalPart.lecturer')}: </Text>
                    {dataItem.lecturer
                      ? <Text variant='caption'>{dataItem.lecturer}</Text>
                      : <Text variant={'caption'} textColor={colors.GRAY_30}>{localizationService.toLanguageString('custom.notSpecified')}</Text>}
                  </TextContainer>
                  <TextContainer>
                    {dataItem.externalUrl ?
                      <HyperLink href={dataItem.externalUrl} target='_blank' rel='noreferrer'>
                        {localizationService.toLanguageString('theoreticalPart.linkToMoreInformation')}
                      </HyperLink>
                      : ''}
                  </TextContainer>
                </FlexItem>
              </FlexContainer>
            </DetailsWrapper>
          </WrapLayout>
        }
      </DetailsContainer>
    );
  };

  handleExpandChange(e) {
    const { dataItem } = e;
    this.props.changeExpandState(dataItem);
  };

  handleSort(e) {
    this.props.sortTheoreticalParts(e.sort);
  }

  async handlePageChange(e) {
    await this.props.findTheoreticalParts({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    const { userIsAdministrator, userAdministratedStudyPrograms } = this.props;
    if (userIsAdministrator || userAdministratedStudyPrograms.length > 0) {
      this.props.navigate(`/theoretical_parts/${e.dataItem.id}/overview`);
    }
  }
}

const mapStateToProps = state => ({
  loading: state.theoreticalPartListPage.loading,
  theoreticalParts: state.theoreticalPartListPage.theoreticalParts,
  skip: state.theoreticalPartListPage.skip,
  take: state.theoreticalPartListPage.take,
  total: state.theoreticalPartListPage.total,
  sorting: state.theoreticalPartListPage.sorting,
  userIsAdministrator: state.app.userIsAdministrator,
  userAdministratedStudyPrograms: state.app.userAdministratedStudyPrograms
});

const mapDispatchToProps = dispatch => ({
  loadTheoreticalPartListPage: () => dispatch(loadTheoreticalPartListPage()),
  findTheoreticalParts: (payload) => dispatch(findTheoreticalParts(payload)),
  sortTheoreticalParts: (payload) => dispatch(sortTheoreticalParts(payload)),
  changeExpandState: payload => dispatch(changeExpandState(payload))
});

registerForLocalization(TheoreticalPartList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TheoreticalPartList));