import CompetencyListPageActionTypes from './competency-list-page-action-types';
import axios from 'axios';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';
import { sortingToStringWithSubstitutes } from '../../../utils/objectUtils';

export const loadCompetencyListPage = (payload) => async(dispatch, getState) => {
  const state = getState().competencyListPage;
  dispatch(findCompetencies());
  if (!state.filterCompetenciesComboBox) {
    dispatch(searchCompetenciesFilterStudyPrograms());
  }
};

export const findCompetencies = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyListPageActionTypes.LOAD_COMPETENCY_LIST_START });
  const state = getState().competencyListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    keyword: state.filter?.keyword,
    studyProgramId: state.filter?.studyProgram?.id,
    sorting: sortingToStringWithSubstitutes(state.sorting, [
      { originalName: 'studyProgramName', substituteName: 'StudyProgram' },
      { originalName: 'abbreviation', substituteName: 'Abbreviation' },
      { originalName: 'name', substituteName: 'Name' }
    ])
  };
  try {
    state.competenciesCancelToken?.cancel();
    state.competenciesCancelToken = axios.CancelToken.source();
    const competencies = await api.get(`api/competencies`, query, state.competenciesCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.COMPETENCIES_WRITE);
    dispatch({
      type: CompetencyListPageActionTypes.LOAD_COMPETENCY_LIST_END,
      payload: {
        competencies: competencies ? competencies.data : [],
        total: competencies ? competencies.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take,
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyListPageActionTypes.LOAD_COMPETENCY_LIST_END });
      throw error;
    }
  }
};

export const filterCompetencies = (payload) => (dispatch) => {
  dispatch({
    type: CompetencyListPageActionTypes.FILTER_COMPETENCY_LIST,
    payload: payload
  });
  dispatch(findCompetencies());
};

export const clearCompetenciesFilter = () => (dispatch) => {
  dispatch({
    type: CompetencyListPageActionTypes.FILTER_COMPETENCY_LIST,
    payload: {}
  });
  dispatch(findCompetencies());
};

export const searchCompetenciesFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyListPageActionTypes.SEARCH_COMPETENCY_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().competencyListPage;
  try {
    state.filterCompetenciesComboBoxCancelToken?.cancel();
    state.filterCompetenciesComboBoxCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get(`api/study_programs`, query, state.filterCompetenciesComboBoxCancelToken.token);
    dispatch({
      type: CompetencyListPageActionTypes.SEARCH_COMPETENCY_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyListPageActionTypes.SEARCH_COMPETENCY_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};

export const openCompetencyConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: CompetencyListPageActionTypes.TOGGLE_COMPETENCY_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCompetencyConfirmDialog = () => async(dispatch) => {
  dispatch({ type: CompetencyListPageActionTypes.TOGGLE_COMPETENCY_LIST_CONFIRM_DIALOG });
};

export const removeCompetencyConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().competencyListPage;
  if (state.selectedCompetencyId > 0) {
    dispatch({ type: CompetencyListPageActionTypes.LOAD_COMPETENCY_LIST_START });
    try {
      await api.remove(`api/competencies/${state.selectedCompetencyId}`);
      dispatch(loadCompetencyListPage());
    } catch (error) {
      dispatch({ type: CompetencyListPageActionTypes.LOAD_COMPETENCY_LIST_END });
    }
  }
  dispatch({ type: CompetencyListPageActionTypes.TOGGLE_COMPETENCY_LIST_CONFIRM_DIALOG });
};

export const sortCompetencies = (payload) => (dispatch) => {
  dispatch({
    type: CompetencyListPageActionTypes.SORT_COMPETENCY_LIST,
    payload: payload
  });
  dispatch(findCompetencies());
};