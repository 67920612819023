import { EVALUATOR_ROLES } from '../resources/EvaluatorRoles';

export const getEvaluatorRole = (role, localizationService) => {
  switch (role) {
    case EVALUATOR_ROLES.LECTURER:
      return localizationService.toLanguageString('evaluatorRoles.Lecturer');
    case EVALUATOR_ROLES.MANAGER:
      return localizationService.toLanguageString('evaluatorRoles.Manager');
    case EVALUATOR_ROLES.NURSE:
      return localizationService.toLanguageString('evaluatorRoles.Nurse');
    case EVALUATOR_ROLES.PACIENT:
      return localizationService.toLanguageString('evaluatorRoles.Pacient');
    case EVALUATOR_ROLES.RESIDENT:
      return localizationService.toLanguageString('evaluatorRoles.Resident');
    default:
      return localizationService.toLanguageString('evaluatorRoles.Manager');
  }
};