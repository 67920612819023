export default {
  LOAD_COMPETENCY_CYCLE_SELECT_PAGE_START: 'LoadCompetencyCycleSelectPageStart',
  FIND_COMPETENCY_CYCLE_SELECT_PAGE_START: 'FindCompetencyCycleSelectPageStart',
  FIND_COMPETENCY_CYCLE_SELECT_PAGE_END: 'FindCompetencyCycleSelectPageEnd',
  FILTER_COMPETENCY_CYCLE_SELECT_PAGE: 'FilterCompetencyCycleSelectPage',
  SELECT_COMPETENCY_CYCLE_SELECT_PAGE: 'SelectCompetencyCycleSelectPage',
  SELECT_ALL_COMPETENCY_CYCLE_SELECT_PAGE: 'SelectAllCompetencyCycleSelectPage',
  ADD_SELECTED_CYCLES_COMPETENCY_CYCLE_SELECT_PAGE_START: 'AddSelectedCyclesCompetencyCycleSelectPageStart',
  ADD_SELECTED_CYCLES_COMPETENCY_CYCLE_SELECT_PAGE_END: 'AddSelectedCyclesFindCompetencyCycleSelectPageEnd',
  CLEANUP_COMPETENCY_CYCLE_SELECT_PAGE: 'CleanupCompetencyCycleSelectPage'
};