import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { StudentCompetencySubmittedSurveyList } from '../../shared/student-competency';

class MyCompetencySubmittedSurveyList extends React.Component {

  render() {
    return (
      <StudentCompetencySubmittedSurveyList studentId={this.props.studentId}/>
    );
  }
}

const mapStateToProps = (state) => ({
  studentId: state.app.currentStudies?.studentId
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyCompetencySubmittedSurveyList));
