import StudyProgramTheoreticalPartFormPageActionTypes from './study-program-theoretical-part-form-page-action-types';
import ValidationError from '../../../utils/validation-error';

export const loadStudyProgramTheoreticalPartFormPage = ({ studyProgramId, studyProgramTheoreticalPartId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_START });
  try {
    const studyProgram = await api.get(`api/study_programs/${studyProgramId}`);
    if (studyProgramTheoreticalPartId > 0) {
      const studyProgramTheoreticalPart = await api.get(`api/study_programs/${studyProgramId}/theoretical_parts/${studyProgramTheoreticalPartId}`);
      dispatch({
        type: StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_END,
        payload: {
          studyProgramId: studyProgram.id,
          studyProgramTheoreticalPart: studyProgramTheoreticalPart
        }
      });
    } else if (studyProgramId) {
      dispatch({
        type: StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_END,
        payload: {
          studyProgramId: studyProgram.id,
          studyProgramTheoreticalPart: {
            studyProgramId: studyProgram.id
          }
        }
      });
    }
  } catch (error) {
    dispatch({ type: StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_END });
    throw error;
  }
};

export const saveStudyProgramTheoreticalPart = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_START });
  const state = getState().studyProgramTheoreticalPartFormPage;
  const apiModel = {
    ...payload
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/study_programs/${payload.studyProgramId}/theoretical_parts/${id}`, apiModel);
    } else {
      id = await api.post(`api/study_programs/${payload.studyProgramId}/theoretical_parts`, apiModel);
    }
    dispatch({
      type: StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_END,
      payload: {
        studyProgramTheoreticalPart: {
          ...state.studyProgramTheoreticalPart,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_END,
      payload: {
        validationResult,
        studyProgramTheoreticalPart: state.studyProgramTheoreticalPart
      }
    });
  }
};
