export default {
  FILTER_THEORETICAL_PART_LIST: 'FilterTheoreticalPartList',
  SORT_THEORETICAL_PART_LIST: 'SortTheoreticalPartList',
  LOAD_THEORETICAL_PART_LIST_START: 'LoadTheoreticalPartListStart',
  LOAD_THEORETICAL_PART_LIST_END: 'LoadTheoreticalPartListEnd',
  SEARCH_THEORETICAL_PART_LIST_FILTER_CYCLE_START: 'SearchTheoreticalPartListFilterCycleStart',
  SEARCH_THEORETICAL_PART_LIST_FILTER_CYCLE_END: 'SearchTheoreticalPartListFilterCycleEnd',
  SEARCH_THEORETICAL_PART_LIST_FILTER_USER_START: 'SearchTheoreticalPartListFilterUserStart',
  SEARCH_THEORETICAL_PART_LIST_FILTER_USER_END: 'SearchTheoreticalPartListFilterUserEnd',
  LOAD_THEORETICAL_PART_LIST_THEORETICAL_PART_TYPES: 'LoadTheoreticalPartListTheoreticalPartTypes',
  SEARCH_THEORETICAL_PART_LIST_FILTER_STUDY_PROGRAM_START: 'SearchTheoreticalPartListFilterStudyProgramStart',
  SEARCH_THEORETICAL_PART_LIST_FILTER_STUDY_PROGRAM_END: 'SearchTheoreticalPartListFilterStudyProgramEnd',
  ACTIVATE_THEORETICAL_PART_LIST_FILTER_CYCLE_LIST: 'ActivateTheoreticalPartListFilterCycleList',
  DEACTIVATE_THEORETICAL_PART_LIST_FILTER_CYCLE_LIST: 'DeactivateTheoreticalPartListFilterCycleList',
  CHANGE_THEORETICAL_PART_LIST_EXPAND_STATE: 'ChangeTheoreticalPartListExpandState'
};