import StudyPlanFormPageActionTypes from '../actions/study-plan-form-page-action-types';

const initialState = {
  loading: false,
  studyPlan: null,
  studyPrograms: null,
  studyProgramsLoading: false,
  studyProgramsCancelToken: null,
  saveSuccess: false,
  validationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        studyPlan: action.payload ? action.payload.studyPlan : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_STUDY_PROGRAMS_START:
      return {
        ...state,
        studyProgramsLoading: true
      };
    case StudyPlanFormPageActionTypes.LOAD_STUDY_PLAN_FORM_PAGE_STUDY_PROGRAMS_END:
      return {
        ...state,
        studyProgramsLoading: false,
        studyPrograms: action.payload ? action.payload.studyPrograms : null
      };
    default:
      return state;
  }
};