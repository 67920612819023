import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { loadMyStudiesCycleList, sortMyStudiesCyclesList } from '../actions/my-studies-cycle-list-actions';
import { Grid, GridSize, GridDateCell, InlineBadge } from '../../shared/components';
import { getStudentCycleStatusColor } from '../../../utils/studyPlanCycleDepartmentStudentStatusColors';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const NoWrapContainer = styled.div`
  white-space: pre-wrap;
`;

const GroupHeaderStatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const GroupHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

class MyStudiesCycleList extends React.Component {
  constructor(props) {
    super(props);
    this.expandChange = this.expandChange.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentDidMount() {
    this.props.loadMyStudiesCycleList(this.props.studentId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studentId !== this.props.studentId) {
      this.props.loadMyStudiesCycleList(this.props.studentId);
    }
  }

  render() {
    const { myCycles, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={loading}
        data={myCycles}
        expandField='expanded'
        group={[{ field: 'year' }]}
        onExpandChange={this.expandChange}
        cellRender={this.renderCell}
        onRowClick={this.handleRowClick}
        sort={this.props.sorting}
        onSortChange={this.handleSort}
        rowRender={(tr, props) => {
          if (tr && props.rowType === 'groupHeader') {
            return React.cloneElement(tr, { className: '' });
          }
          return tr;
        }}
        isPageable={false}
        columns={[
          {
            field: 'cycle',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('myStudies.cycle'),
            sortable: false
          }, {
            field: 'status',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('myStudies.status'),
            cell: (e) => this.renderStatus(e, localizationService),
            sortable: false
          }, {
            field: 'dateFrom',
            className: 'align-center',
            headerClassName: 'align-center',
            title: localizationService.toLanguageString('myStudies.dateFrom'),
            minGridWidth: GridSize.large,
            cell: GridDateCell
          }, {
            field: 'dateTo',
            className: 'align-center',
            headerClassName: 'align-center',
            title: localizationService.toLanguageString('myStudies.dateTo'),
            minGridWidth: GridSize.large,
            cell: GridDateCell
          }, {
            field: 'cycleCredits',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('myStudies.credits'),
            minGridWidth: GridSize.medium,
            sortable: false
          }
        ]}
      />
    );
  }

  handleSort(e) {
    this.props.sortMyStudiesCyclesList(e.sort);
  }

  renderCell(td, props) {
    if (td && td.props.children && props.rowType === 'groupHeader') {
      const localizationService = provideLocalizationService(this);
      const studyYear = props.dataItem.value == null
        ? `${localizationService.toLanguageString('myStudies.studyYear')} ${localizationService.toLanguageString('myStudies.notSpecify')}`
        : `${props.dataItem.value} ${localizationService.toLanguageString('myStudies.studyYear')}`;
      const children = (
        <GroupHeaderContainer>
          {td.props.children.props.children[0]}
          <GroupHeaderStatsContainer>
            <NoWrapContainer>{`${studyYear}: `}</NoWrapContainer>
            <NoWrapContainer>{`${localizationService.toLanguageString('myStudies.assignedCredits')} - ${props.dataItem.assignedCredits}, `}</NoWrapContainer>
            <NoWrapContainer>{`${localizationService.toLanguageString('myStudies.finishedCredits')} - ${props.dataItem.finishedCredits}`}</NoWrapContainer>
          </GroupHeaderStatsContainer>
        </GroupHeaderContainer>
      );
      return React.cloneElement(td, { ...td.props, colSpan: 3 }, children);
    }
    return td;
  }

  renderStatus(e, localizationService) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {dataItem.status &&
          <InlineBadge themeColor={getStudentCycleStatusColor(dataItem.status)}>
            {localizationService.toLanguageString('studyPlanCycleDepartmentStudentStatus.' + dataItem.status)}
          </InlineBadge>
        }
      </td>
    );
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  handleRowClick(e) {
    if (e.dataItem?.id) {
      this.props.navigate(`/my_studies/cycle/${e.dataItem.id}`);
    }
  }
}

const mapStateToProps = (state) => ({
  myCycles: state.myStudiesCycleList.myCycles,
  loading: state.myStudiesCycleList.loading,
  sorting: state.myStudiesCycleList.sorting,
  studentId: state.app.currentStudies?.studentId
});

const mapDispatchToProps = (dispatch) => ({
  loadMyStudiesCycleList: (payload) => dispatch(loadMyStudiesCycleList(payload)),
  sortMyStudiesCyclesList: (payload) => dispatch(sortMyStudiesCyclesList(payload))
});

registerForLocalization(MyStudiesCycleList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyStudiesCycleList));
