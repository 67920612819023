import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid } from '../../shared/components';
import { loadSurveyListPage, findSurveys, sortSurveys, openSurveyConfirmDialog, closeSurveyConfirmDialog, removeSurveyConfirmDialog } from '../actions/survey-list-page-actions';
import { canWriteStudyProgram } from '../../common/actions';
import { withRouter } from '../../../components/withRouter';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Colors, StackLayout, Text, WrapLayout } from '../../../ui';

class SurveyList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.renderName = this.renderName.bind(this);
  }

  async componentDidMount() {
    await this.props.loadSurveyListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={this.props.loading}
          data={this.props.surveys}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          sort={this.props.sorting}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSort}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'code',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.code')
            }, {
              field: 'name',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.name'),
              cell: this.renderName
            }, {
              field: 'studyProgramName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competency.studyProgram'),
              minGridWidth: GridSize.large
            }
          ]}
          actions={this.props.canWrite && [
            {
              icon: 'delete',
              onClick: this.handleRemoveClick,
              disabledOn: (e) => {
                return !(canWriteStudyProgram(e.dataItem.studyProgramId, this.props.app));
              }
            }
          ]}
        >
          {this.props.canWrite &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClicked}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('competency.surveyDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderName(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <StackLayout rowGap='2px'>
          {dataItem.name}
          <WrapLayout rowGap='0' columnGap='0'>
            <Text textColor={Colors.GRAY_50} variant='caption'>
              {`${localizationService.toLanguageString(`competency.questionCount`)}: ${dataItem.questionCount}`}
            </Text>
          </WrapLayout>
        </StackLayout>
      </td>
    );
  }

  handleSort(e) {
    this.props.sortSurveys(e.sort);
  }

  async handlePageChange(e) {
    await this.props.findSurveys({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    if (this.props.canWrite && canWriteStudyProgram(e.dataItem.studyProgramId, this.props.app)) {
      this.props.navigate(`/surveys/${e.dataItem.id}/overview`);
    }
  }

  handleAddClicked() {
    this.props.navigate(`/surveys/create`);
  }

  handleRemoveClick(row) {
    this.props.openSurveyConfirmDialog(row.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeSurveyConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeSurveyConfirmDialog();
  }
}

const mapStateToProps = state => ({
  loading: state.surveyListPage.loading,
  surveys: state.surveyListPage.surveys,
  skip: state.surveyListPage.skip,
  take: state.surveyListPage.take,
  total: state.surveyListPage.total,
  sorting: state.surveyListPage.sorting,
  canWrite: state.surveyListPage.canWrite,
  isConfirmDialogVisible: state.surveyListPage.isConfirmDialogVisible,
  app: state.app
});

const mapDispatchToProps = dispatch => ({
  loadSurveyListPage: () => dispatch(loadSurveyListPage()),
  findSurveys: (payload) => dispatch(findSurveys(payload)),
  sortSurveys: (payload) => dispatch(sortSurveys(payload)),
  openSurveyConfirmDialog: (payload) => dispatch(openSurveyConfirmDialog(payload)),
  closeSurveyConfirmDialog: () => dispatch(closeSurveyConfirmDialog()),
  removeSurveyConfirmDialog: () => dispatch(removeSurveyConfirmDialog())
});

registerForLocalization(SurveyList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SurveyList));