import axios from 'axios';
import CycleCaseDiagnosisListActionTypes from './cycle-case-diagnosis-list-action-type';

export const loadCycleCaseDiagnosisList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleCaseDiagnosisListActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_LIST_START });
  const state = getState().cycleCaseDiagnosisList;
  try {
    state.cycleCaseDiagnosisCancelToken?.cancel();
    state.cycleCaseDiagnosisCancelToken = axios.CancelToken.source();
    const cycleCaseDiagnosis = await api.get(`api/cycles/${payload.cycleId}/cases/${payload.cycleCaseId}/diagnosis`);
    dispatch({
      type: CycleCaseDiagnosisListActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_LIST_END,
      payload: {
        cycleCaseDiagnosis: cycleCaseDiagnosis?.data,
        cycleId: payload.cycleId,
        cycleCaseId: payload.cycleCaseId
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CycleCaseDiagnosisListActionTypes.LOAD_CYCLE_CASE_DIAGNOSIS_LIST_END });
      throw error;
    }
  }
};

export const openCycleCaseDiagnosisConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: CycleCaseDiagnosisListActionTypes.TOGGLE_CYCLE_CASE_DIAGNOSIS_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCycleCaseDiagnosisConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleCaseDiagnosisListActionTypes.TOGGLE_CYCLE_CASE_DIAGNOSIS_LIST_CONFIRM_DIALOG });
};

export const removeCycleCaseDiagnosisConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().cycleCaseDiagnosisList;
  if (state.selectedCycleCaseDiagnosisId > 0) {
    await api.remove(`api/cycles/${payload.cycleId}/cases/${payload.cycleCaseId}/diagnosis/${state.selectedCycleCaseDiagnosisId}`);
    dispatch(loadCycleCaseDiagnosisList({ cycleId: payload.cycleId, cycleCaseId: payload.cycleCaseId }));
  }
  dispatch({ type: CycleCaseDiagnosisListActionTypes.TOGGLE_CYCLE_CASE_DIAGNOSIS_LIST_CONFIRM_DIALOG });
};

export const openCycleCaseDiagnosisTlkDialog = () => async(dispatch) => {
  dispatch({
    type: CycleCaseDiagnosisListActionTypes.OPEN_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG
  });
};

export const closeCycleCaseDiagnosisTlkDialog = (payload) => async(dispatch, getState) => {
  const state = getState().cycleCaseDiagnosisList;
  dispatch({ type: CycleCaseDiagnosisListActionTypes.CLOSE_CYCLE_CASE_DIAGNOSIS_TLK_DIALOG });
  dispatch(loadCycleCaseDiagnosisList({ cycleId: state.cycleId, cycleCaseId: state.cycleCaseId }));
};