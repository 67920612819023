import React, { PureComponent } from 'react';

export default class OpenBookIcon extends PureComponent {
  render() {
    return (
      <svg
        height='24'
        width='24'
        viewBox='0 0 445 445'
        style={this.props.style}
      >
        <g>
          <g>
            <path d='M432.5,105.461h-24.191v-1.337c0-5.249-3.278-9.939-8.21-11.741c-29.977-10.955-60.576-16.51-90.945-16.51
			c-28.925,0-58.052,5.049-86.652,14.992c-28.604-9.942-57.732-14.992-86.656-14.992c-30.371,0-60.969,5.555-90.943,16.51
			c-4.93,1.802-8.209,6.491-8.209,11.74v1.337H12.5c-6.903,0-12.5,5.596-12.5,12.5v238.667c0,6.904,5.597,12.5,12.5,12.5h420
			c6.902,0,12.5-5.596,12.5-12.5V117.961C445,111.058,439.402,105.461,432.5,105.461z M383.309,113.019v188.064
			c-24.57-7.276-49.442-10.957-74.16-10.957s-49.584,3.679-74.147,10.955V113.018c24.567-8.063,49.471-12.144,74.152-12.144
			C333.834,100.874,358.738,104.956,383.309,113.019z M61.691,113.018c24.568-8.063,49.471-12.144,74.152-12.144
			c24.681,0,49.586,4.082,74.156,12.145v188.064c-24.571-7.276-49.443-10.957-74.161-10.957c-24.717,0-49.584,3.679-74.147,10.955
			V113.018L61.691,113.018z M25,130.461h11.691v187.913c0,4.081,1.992,7.905,5.337,10.244c2.129,1.487,4.635,2.256,7.164,2.256
			c1.444,0,2.896-0.25,4.289-0.759c27.219-9.946,54.928-14.989,82.357-14.989c24.678,0,49.584,4.097,74.161,12.157v16.845h-185
			L25,130.461L25,130.461z M420,344.128H235v-16.846c24.568-8.062,49.471-12.156,74.147-12.156c27.43,0,55.144,5.043,82.371,14.989
			c3.835,1.4,8.108,0.841,11.453-1.498c3.345-2.34,5.336-6.163,5.336-10.244V130.461H420V344.128z'/>
            <path d='M99.5,172.001h65.332c6.903,0,12.5-5.596,12.5-12.5s-5.597-12.5-12.5-12.5H99.5c-6.903,0-12.5,5.596-12.5,12.5
			S92.597,172.001,99.5,172.001z'/>
            <path d='M177.332,229.501c0-6.903-5.597-12.5-12.5-12.5H99.5c-6.903,0-12.5,5.597-12.5,12.5s5.597,12.5,12.5,12.5h65.332
			C171.735,242.001,177.332,236.404,177.332,229.501z'/>
            <path d='M280.168,172.001H345.5c6.902,0,12.5-5.596,12.5-12.5s-5.598-12.5-12.5-12.5h-65.332c-6.902,0-12.5,5.596-12.5,12.5
			S273.266,172.001,280.168,172.001z'/>
            <path d='M280.168,242.001H345.5c6.902,0,12.5-5.597,12.5-12.5s-5.598-12.5-12.5-12.5h-65.332c-6.902,0-12.5,5.597-12.5,12.5
			S273.266,242.001,280.168,242.001z'/>
          </g>
        </g>
      </svg>
    );
  }
}