import axios from 'axios';
import ValidationError from '../../../utils/validation-error';
import StudyPlanCycleDepartmentFormPageActionTypes from './study-plan-cycle-department-form-page-action-types';

export const loadStudyPlanCycleDepartmentFormPage = ({ studyPlanId, studyPlanCycleId, studyPlanCycleDepartmentId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_START });
  try {
    if (studyPlanCycleDepartmentId > 0) {
      const studyPlanCycleDepartment = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/departments/${studyPlanCycleDepartmentId}`);
      dispatch({
        type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_END,
        payload: {
          studyPlanId,
          studyPlanCycleId,
          studyPlanCycleDepartment: {
            ...studyPlanCycleDepartment,
            department: studyPlanCycleDepartment.departmentId ? { id: studyPlanCycleDepartment.departmentId, name: studyPlanCycleDepartment.departmentName } : null,
            managers: studyPlanCycleDepartment.studentsManagers?.map(item => ({
              id: item.id,
              userId: item.userId,
              name: item.userName
            }))
          }
        }
      });
    } else {
      const studyPlanCycle = await api.get(`/api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}`);
      dispatch({
        type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_END,
        payload: {
          studyPlanId,
          studyPlanCycleId,
          studyPlanCycleDepartment: {
            studyPlanCycleId,
            cycleCredits: studyPlanCycle?.cycleCredits
          }
        }
      });
    }
  } catch (error) {
    dispatch({ type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_END });
    throw error;
  }
  dispatch(loadAvailableDepartments());
};

export const saveStudyPlanCycleDepartment = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_START });
  const state = getState().studyPlanCycleDepartmentFormPage;
  const apiModel = {
    id: payload.id,
    studyPlanCycleId: payload.studyPlanCycleId,
    credits: payload.credits,
    departmentId: payload.department?.id,
    minStudentCount: payload.minStudentCount,
    maxStudentCount: payload.maxStudentCount,
    studentsManagers: payload.managers?.map(item => ({
      id: item.id,
      studyPlanCycleDepartmentId: payload?.id,
      userId: item.userId
    }))
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/departments/${id}`, apiModel);
    } else {
      id = await api.post(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/departments`, apiModel);
    }
    dispatch({
      type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_END,
      payload: {
        studyPlanId: state.studyPlanId,
        studyPlanCycleId: state.studyPlanCycleId,
        studyPlanCycleDepartment: state.studyPlanCycleDepartment,
        studyPlanCycleDepartment: {
          ...state.studyPlanCycleDepartment,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_END,
      payload: {
        studyPlanId: state.studyPlanId,
        studyPlanCycleId: state.studyPlanCycleId,
        studyPlanCycleDepartment: state.studyPlanCycleDepartment,
        validationResult
      }
    });
  }
};

export const loadAvailableDepartments = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_DEPARTMENTS_START });
  try {
    const state = getState().studyPlanCycleDepartmentFormPage;
    const query = {
      keyword: payload?.keyword
    };
    state.availableDepartmentsCancelToken?.cancel();
    state.availableDepartmentsCancelToken = axios.CancelToken.source();
    const departments = await api.get(`/api/departments`, query, state.availableDepartmentsCancelToken.token);
    dispatch({
      type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_DEPARTMENTS_END,
      payload: {
        availableDepartments: departments?.data
      }
    });
  } catch (error) {
    dispatch({ type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_DEPARTMENTS_END });
    throw error;
  }
};

export const loadAvailableManagers = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleDepartmentFormPage;
  let managers = [...state.managers];
  managers[payload.index] = {
    loading: true,
    availableManagers: managers[payload.index]?.availableManagers ? [...managers[payload.index].availableManagers] : []
  };
  dispatch({
    type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_USERS_START,
    payload: {
      managers
    }
  });
  try {
    const cancellationTokenKey = `managers[${payload.index}]`;
    state.cancellationTokenSources.get(cancellationTokenKey)?.cancel();
    state.cancellationTokenSources.set(cancellationTokenKey, axios.CancelToken.source());
    const query = {
      keyword: payload.keyword,
      skip: 0,
      limit: 50
    };
    const users = await api.get(`api/users_search`, query, state.cancellationTokenSources.get(cancellationTokenKey).token);
    managers = [...state.managers];
    managers[payload.index] = {
      loading: false,
      availableManagers: users ? users.data?.map(user => ({ userId: user.id, name: user.name + ' ' + user.surname })) : []
    };
    dispatch({
      type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_USERS_END,
      payload: {
        managers
      }
    });
  } catch (error) {
    dispatch({ type: StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_USERS_END });
    throw error;
  }
};

export const changeWarningStatus = (dataItem) => (dispatch, getState) => {
  dispatch({
    type: StudyPlanCycleDepartmentFormPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_DEPARTMENT_WARNING_STATUS,
    payload: dataItem
  });
};