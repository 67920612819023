
import React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Grid as KendoGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

const CommandButton = styled(Button).attrs({
  fillMode: 'solid'
})`
  margin-left: 4px;
  box-shadow: none;
  background-color: ${props => props.theme.gray15};
`;

const ButtonsCell = styled.td`
  overflow: visible !important;
`;

class Grid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
    this.gridRef = React.createRef();
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.renderCommandCell = this.renderCommandCell.bind(this);
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  render() {
    const { isPageable = true, columns, children, actions, loading, ...rest } = this.props;

    return (
      <div>
        <div ref={this.gridRef}>
          <KendoGrid
            scrollable='none'
            sortable
            pageable={isPageable ? { pageSizes: false, buttonCount: 3 } : false}
            expandField='expanded'
            {...rest}
          >
            {children}
            {columns && columns.map((column, index) => {
              if (!column.minGridWidth || column.minGridWidth <= this.state.width) {
                return <Column {...column} key={index} />;
              }
              return null;
            })}
            {actions && actions.length > 0 && <Column cell={this.renderCommandCell} width={`${actions.length * 58}px`} sortable={false}/>}
          </KendoGrid>
        </div>
        {loading && <GridLoadingPanel/>}
      </div>
    );
  }

  renderCommandCell(e) {
    if (e.rowType === 'groupHeader') {
      return null;
    }
    const buttons = (
      <>
        {this.props.actions.map((action, index) =>{
          if (!action.renderOn || action.renderOn(e)) {
            return (
              <CommandButton
                key={index}
                icon={action.icon}
                onClick={() => action.onClick(e)}
                disabled={action.disabledOn && action.disabledOn(e)}
                title={action.renderTitle && action.renderTitle(e)}
              >
                {action.label}
              </CommandButton>
            );
          }
        })}
      </>
    );
    return (
      this.props.renderActionsCell?.(buttons, e) ||
      <ButtonsCell className='align-right command-cell'>
        {buttons}
      </ButtonsCell>
    );
  }

  updateWindowWidth() {
    this.setState({ width: this.gridRef.current.offsetWidth });
  }
}

class GridLoadingPanel extends React.Component {
  render() {
    const localizationService = provideLocalizationService(this);
    const loadingPanel = (
      <div className='k-loading-mask'>
        <span className='k-loading-text'>{localizationService.toLanguageString('custom.loading')}</span>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
    const gridContent = document && document.querySelector('.k-grid');
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}

registerForLocalization(GridLoadingPanel);

export default Grid;
