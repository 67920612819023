import axios from 'axios';
import { NotificationFormPageActiontypes } from '.';
import ValidationError from '../../../utils/validation-error';
import { STUDY_YEARS } from '../../../resources/studyYears';
import { STUDENT_STATUSES } from '../../../resources/studentStatus';

export const loadNotificationFormPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_START });
  try {
    const { notificationId, localizationService } = payload;
    const [ notification, studyPrograms ] = await Promise.all([
      notificationId ? await await api.get(`api/notifications/${notificationId}`) : null,
      await api.get('api/study_programs')
    ]);
    const studyYears = Object.keys(STUDY_YEARS).map(key => ({
      value: key,
      text: STUDY_YEARS[key]
    }));
    const studyStatuses = Object.keys(STUDENT_STATUSES).map(key => ({
      value: STUDENT_STATUSES[key],
      text: localizationService.toLanguageString(`studentStatus.${STUDENT_STATUSES[key]}`)
    }));
    dispatch({
      type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_END,
      payload: {
        notification: notification ?
          {
            ...notification,
            createdOn: new Date(notification.createdOn),
            visibleFrom: new Date(notification.visibleFrom),
            studyYears: notification.studyYears?.map(item => ({
              value: item.toString(),
              text: STUDY_YEARS[item.toString()]
            })),
            studyStatuses: notification.studyStatuses?.map(item => ({
              value: item,
              text: localizationService.toLanguageString(`studentStatus.${item}`)
            }))
          } : null,
        availableStudyPrograms: studyPrograms?.data?.map(item => ({
          studyProgramId: item.id,
          studyProgramName: item.name
        })),
        studyYears,
        studyStatuses
      }
    });
  } catch (error) {
    dispatch({ type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_END });
    throw error;
  }
};

export const loadAvailableStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_STUDY_PROGRAMS_START });
  const state = getState().notificationFormPage;
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  try {
    state.availableStudyProgramsCancelToken?.cancel();
    state.availableStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.availableStudyProgramsCancelToken.token);
    dispatch({
      type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_STUDY_PROGRAMS_END,
      payload: {
        availableStudyPrograms: studyPrograms?.data?.map(item => ({
          studyProgramId: item.id,
          studyProgramName: item.name
        }))
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_STUDY_PROGRAMS_END });
      throw error;
    }
  }
};

export const saveNotificationFormPage = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_START });
  const state = getState().notificationFormPage;
  const apiModel = {
    ...payload,
    createdOn: payload.createdOn ?? new Date(),
    studyYears: payload.studyYears?.map(item => item.value),
    studyStatuses: payload.studyStatuses?.map(item => item.value)
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/notifications/${id}`, apiModel);
    } else {
      id = await api.post(`api/notifications`, apiModel);
    }
    dispatch({
      type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_END,
      payload: {
        notification: {
          ...payload,
          id
        }
      }
    });
    history.push('/notifications');
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_END,
      payload: {
        validationResult,
        notification: state.notification
      }
    });
  }
};

export const clearNotificationFormPage = () => async(dispatch) => {
  dispatch({ type: NotificationFormPageActiontypes.CLEAR_NOTIFICATIONS_FORM_PAGE });
};