import StudentListPageActionTypes from './student-list-page-action-types';
import axios from 'axios';
import { sortingToString } from '../../../utils/objectUtils';
import { STUDENT_STATUSES } from '../../../resources/studentStatus';

export const loadStudentListPage = (payload) => async(dispatch, getState) => {
  const state = getState().studentListPage;
  dispatch(findStudents());
  if (!state.filterStudyPrograms) {
    dispatch(searchStudentsFilterStudyPrograms());
  }
};

export const findStudents = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentListPageActionTypes.LOAD_STUDENT_LIST_START });
  const state = getState().studentListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToString(state.sorting),
    studyProgramId: state.filter?.studyProgram?.id,
    student: state.filter?.student,
    year: state.filter?.year,
    status: state.filter?.studentStatus?.id
  };
  try {
    state.studentsCancelToken?.cancel();
    state.studentsCancelToken = axios.CancelToken.source();
    const students = await api.get(`api/students`, query, state.studentsCancelToken.token);
    dispatch({
      type: StudentListPageActionTypes.LOAD_STUDENT_LIST_END,
      payload: {
        students: students ? students.data : [],
        total: students ? students.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentListPageActionTypes.LOAD_STUDENT_LIST_END });
      throw error;
    }
  }
};

export const filterStudents = (payload) => (dispatch) => {
  dispatch({
    type: StudentListPageActionTypes.FILTER_STUDENT_LIST,
    payload: payload
  });
  dispatch(findStudents());
};

export const sortStudents = (payload) => (dispatch) => {
  dispatch({
    type: StudentListPageActionTypes.SORT_STUDENT_LIST,
    payload: payload
  });
  dispatch(findStudents());
};

export const clearStudentsFilter = () => (dispatch) => {
  dispatch({
    type: StudentListPageActionTypes.FILTER_STUDENT_LIST,
    payload: {}
  });
  dispatch(findStudents());
};

export const searchStudentsFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentListPageActionTypes.SEARCH_STUDENT_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().studentListPage;
  try {
    state.filterStudyProgramsCancelToken?.cancel();
    state.filterStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsCancelToken.token);
    dispatch({
      type: StudentListPageActionTypes.SEARCH_STUDENT_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentListPageActionTypes.SEARCH_STUDENT_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};

export const loadStudyStatuses = (localizationService) => async(dispatch, getState) => {
  const statuses = Object.keys(STUDENT_STATUSES)
    .map(key => ({
      id: STUDENT_STATUSES[key],
      name: localizationService.toLanguageString('studentStatus.' + STUDENT_STATUSES[key])
    }));
  dispatch({
    type: StudentListPageActionTypes.LOAD_STUDENT_LIST_STUDENT_TYPES,
    payload: {
      studentStatuses: statuses
    }
  });
};