import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import { FormPage, TextBox, ComboBox, TextArea } from '../../../ui';
import {
  loadCompetencyFormPage,
  saveCompetency,
  loadStudyPrograms,
  cancelCompetencyFormPage
} from '../actions/competency-form-page-actions';
import { withRouter } from '../../../components/withRouter';

class CompetencyFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCompetencyFormPage({ competencyId: this.props.params.competencyId });
  }

  componentWillUnmount() {
    this.props.cancelCompetencyFormPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('competency.formTitle')}
        breadcrumbItems={[
          {
            id: 'competencies',
            text: localizationService.toLanguageString('competency.tieredCompetencies')
          },
          {
            id: 'competency',
            text: localizationService.toLanguageString('competency.tieredCompetency'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        renderBackButton={true}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.competency}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <>
            <Field
              name='abbreviation'
              label={localizationService.toLanguageString('competency.abbreviation')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='name'
              label={localizationService.toLanguageString('competency.name')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='description'
              label={localizationService.toLanguageString('competency.description')}
              id='description'
              component={TextArea}
              autoSize={true}
              width='320px'
            />
            <Field
              name='studyProgram'
              label={localizationService.toLanguageString('competency.studyProgram')}
              component={ComboBox}
              loading={this.props.availableStudyProgramsLoading}
              data={this.props.availableStudyPrograms}
              onFilter={this.props.loadStudyPrograms}
              textField='name'
              valueField='id'
              width='320px'
            />
          </>
        )}
      />
    );
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    const maxNameLength = 500;
    const maxAbbreviationLength = 20;
    const maxDescriptionLength = 500;
    if (!values.name) {
      errors.name = localizationService.toLanguageString('validation.required');
    } else if (values.name.length > maxNameLength) {
      errors.name = `${localizationService.toLanguageString('validation.exceedsMaximumLength')}(${maxNameLength})`;
    }
    if (!values.abbreviation) {
      errors.abbreviation = localizationService.toLanguageString('validation.required');
    } else if (values.abbreviation.length > maxAbbreviationLength) {
      errors.abbreviation = `${localizationService.toLanguageString('validation.exceedsMaximumLength')}(${maxAbbreviationLength})`;
    }
    if (!values.studyProgram) {
      errors.studyProgram = localizationService.toLanguageString('validation.required');
    }
    if (values.description && values.description.length > maxDescriptionLength) {
      errors.description = `${localizationService.toLanguageString('validation.exceedsMaximumLength')}(${maxDescriptionLength})`;
    }
    if (!modified.name && !modified.studyProgram && !modified.abbreviation && !modified.description) {
      if (validationResult?.errors?.name) {
        errors.name = validationResult?.errors?.name;
      }
      if (validationResult?.errors?.studyProgramId) {
        errors.studyProgram = validationResult?.errors?.studyProgramId;
      }
      if (validationResult?.errors?.abbreviation) {
        errors.abbreviation = validationResult?.errors?.abbreviation;
      }
      if (validationResult?.errors?.description) {
        errors.description = validationResult?.errors?.description;
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'competencies') {
      this.props.navigate('/competencies');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveCompetency(payload);
  }
}

registerForLocalization(CompetencyFormPage);

const mapStateToProps = state => ({
  loading: state.competencyFormPage.loading,
  competency: state.competencyFormPage.competency,
  availableStudyPrograms: state.competencyFormPage.availableStudyPrograms,
  availableStudyProgramsLoading: state.competencyFormPage.availableStudyProgramsLoading,
  validationResult: state.competencyFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadCompetencyFormPage: (payload) => dispatch(loadCompetencyFormPage(payload)),
  saveCompetency: (payload) => dispatch(saveCompetency(payload)),
  loadStudyPrograms: (payload) => dispatch(loadStudyPrograms(payload)),
  cancelCompetencyFormPage: () => dispatch(cancelCompetencyFormPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompetencyFormPage));