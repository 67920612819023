import StudyProgramFormPageActionTypes from '../actions/study-program-form-page-action-types';

const initialState = {
  loading: false,
  studyProgram: null,
  saveSuccess: false,
  validationResult: null,

  availableDivisionsLoading: false,
  availableDivisionsCancelToken: null,
  availableDivisions: [],

  coordinators: [],
  administrators: [],
  committeeMembers: [],

  cancellationTokenSources: new Map()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        studyProgram: action.payload ? action.payload.studyProgram : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COORDINATORS_START:
      return {
        ...state,
        coordinators: action.payload?.coordinators ?? []
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COORDINATORS_END:
      return {
        ...state,
        coordinators: action.payload?.coordinators ?? []
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_ADMINISTRATORS_START:
      return {
        ...state,
        administrators: action.payload?.administrators ?? []
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_ADMINISTRATORS_END:
      return {
        ...state,
        administrators: action.payload?.administrators ?? []
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COMMITTEE_MEMBERS_START:
      return {
        ...state,
        committeeMembers: action.payload?.committeeMembers ?? []
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_COMMITTEE_MEMBERS_END:
      return {
        ...state,
        committeeMembers: action.payload?.committeeMembers ?? []
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_DIVISIONS_START:
      return {
        ...state,
        availableDivisionsLoading: true
      };
    case StudyProgramFormPageActionTypes.LOAD_STUDY_PROGRAM_AVAILABLE_DIVISIONS_END:
      return {
        ...state,
        availableDivisionsLoading: false,
        availableDivisions: action.payload ? action.payload.divisions : null
      };
    default:
      return state;
  }
};