import * as React from 'react';
import { ListPage } from '../../../ui';
import { MySurveyListFilter, MySurveyList } from './index';

class MySurveyListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<MySurveyListFilter/>}>
        <MySurveyList/>
      </ListPage>
    );
  }
}

export default MySurveyListPage;