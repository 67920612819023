import { pagingSettings } from '../../../utils/GridPagingUtils';
import ApprovalRequestListPageActionTypes from '../actions/approval-request-list-page-action-types';

const initialState = {
  loading: false,
  approvalRequests: null,
  approvalRequestsCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ApprovalRequestListPageActionTypes.LOAD_APPROVAL_REQUEST_LIST_START:
      return {
        ...state,
        loading: true
      };
    case ApprovalRequestListPageActionTypes.LOAD_APPROVAL_REQUEST_LIST_END:
      return {
        ...state,
        loading: false,
        approvalRequests: action.payload.approvalRequests,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case ApprovalRequestListPageActionTypes.FILTER_APPROVAL_REQUEST_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    default:
      return state;
  }
};