import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Field, FieldArray } from '@progress/kendo-react-form';
import { FormPage, ComboBox, WrapLayout, StackLayout, Text } from '../../../ui';
import { Button } from '@progress/kendo-react-buttons';
import {
  loadAvailableTheoreticalParts,
  loadCycleTheoreticalPartFormPage,
  loadTheoreticalPartTypes,
  saveCycleTheoreticalPart,
  loadAvailableTeachers
} from '..';
import { openUserSearchDialog } from '../../users/actions/user-search-dialog-actions';
import { withRouter } from '../../../components/withRouter';

const TeachersContainer = styled(WrapLayout).attrs(props => ({
  orientation: 'vertical',
  rowgap: '2px',
  width: '35px'
}))`
  margin-top: 2px;
`;

const TeachersTitle = styled(Text).attrs(props => ({
  variant: 'h6',
  textColor: props.theme.gray80,
  truncate: true
}))`
  margin-top: 20px;
`;

const DeleteButton = styled(Button).attrs(props => ({
  type: 'button',
  icon: 'delete'
}))`
  margin-top: 28px;
`;

class CycleTheoreticalPartFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderTeachers = this.renderTeachers.bind(this);
    this.handleLoadAvailableTeachers = this.handleLoadAvailableTeachers.bind(this);
    this.handleOpenUserSearchDialog = this.handleOpenUserSearchDialog.bind(this);
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);

    if (this.props.params?.cycleId) {
      await this.props.loadCycleTheoreticalPartFormPage({
        cycleId: this.props.params.cycleId,
        cycleTheoreticalPartId: this.props.params.cycleTheoreticalPartId,
        localizationService
      });
    }

    await this.props.loadTheoreticalPartTypes(localizationService);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('cycle.cycleTheoreticalPart')}
        breadcrumbItems={[
          {
            id: 'cycles',
            text: localizationService.toLanguageString('cycle.cycles')
          },
          {
            id: 'cycle',
            text: localizationService.toLanguageString('cycle.cycle')
          },
          {
            id: 'cycle-theoretical-part',
            text: localizationService.toLanguageString('cycle.cycleTheoreticalPart'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.cycleTheoreticalPart}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <>
            <Field
              name='studyProgramTheoreticalPart'
              label={localizationService.toLanguageString('cycle.theoreticalPartName')}
              component={ComboBox}
              loading={this.props.availableTheoreticalPartsLoading}
              data={this.props.availableTheoreticalParts}
              onFilter={this.props.loadAvailableTheoreticalParts}
              allowCustom={true}
              textField='name'
              valueField='id'
              width='320px'
            />
            <Field
              name='theoreticalPartType'
              label={localizationService.toLanguageString('cycle.theoreticalPartType')}
              component={ComboBox}
              data={this.props.theoreticalPartTypes}
              textField='name'
              valueField='id'
              width='320px'
            />
            <FieldArray
              name='teachers'
              component={this.renderTeachers}
            >
            </FieldArray>
          </>
        )}
      />
    );
  }

  renderTeachers(fieldArrayRenderProps) {
    const localizationService = provideLocalizationService(this);
    return (
      <TeachersContainer>
        <TeachersTitle>{localizationService.toLanguageString('cycle.theoreticalPartTeachers')}</TeachersTitle>
        {fieldArrayRenderProps.value?.map((item, index) => {
          const fieldName = 'teachers[' + index + ']';
          const teacher = this.props.teachers[index];
          return (
            <StackLayout key={index} orientation='horizontal' align='left' valign='top'>
              <Field
                name={fieldName}
                label={localizationService.toLanguageString('cycle.theoreticalPartTeacher')}
                component={ComboBox}
                loading={teacher?.loading}
                data={teacher?.availableTeachers}
                onFilter={(e) => this.handleLoadAvailableTeachers(e, index)}
                onAddClick={() => this.handleOpenUserSearchDialog(fieldArrayRenderProps, index)}
                textField='text'
                valueField='id'
                width='276px'
              />
              <DeleteButton onClick={() => this.handleRemoveTeacher(index, fieldArrayRenderProps)}/>
            </StackLayout>
          );
        })}
        <WrapLayout orientation='horizontal' align='left'>
          <Button
            type='button'
            icon='plus'
            onClick={() => this.handleAddTeacher(fieldArrayRenderProps)}
          />
        </WrapLayout>
      </TeachersContainer>
    );
  }

  handleOpenUserSearchDialog(fieldArrayRenderProps, index) {
    this.props.openUserSearchDialog({
      onAdd: (payload) => fieldArrayRenderProps.onReplace({ index: index, value: { id: payload.id, text: payload.text } })
    });
  }

  handleLoadAvailableTeachers(e, index) {
    this.props.loadAvailableTeachers({
      keyword: e.keyword,
      index: index
    });
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.studyProgramTheoreticalPart) {
      errors.studyProgramTheoreticalPart = localizationService.toLanguageString('validation.required');
    }
    if (!values.theoreticalPartType) {
      errors.theoreticalPartType = localizationService.toLanguageString('validation.required');
    }
    if (values.teachers) {
      for (let i = 0; i < values.teachers.length; i++) {
        if (!values.teachers[i]?.id) {
          errors[`teachers[${i}]`] = localizationService.toLanguageString('validation.required');
        }
      }
    }
    return errors;
  }

  handleAddTeacher(fieldArrayRenderProps) {
    fieldArrayRenderProps.onPush({
      value: { }
    });
  }

  handleRemoveTeacher(index, fieldArrayRenderProps) {
    fieldArrayRenderProps.onRemove({
      index: index
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'cycles') {
      this.props.navigate('/cycles');
    }
    if (e.id === 'cycle') {
      this.props.navigate(`/cycles/${this.props.params.cycleId}/overview`);
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveCycleTheoreticalPart(payload);
  }
}

registerForLocalization(CycleTheoreticalPartFormPage);

const mapStateToProps = (state) => ({
  loading: state.cycleTheoreticalPartFormPage.loading,
  availableTheoreticalPartsLoading: state.cycleTheoreticalPartFormPage.availableTheoreticalPartsLoading,
  availableTheoreticalParts: state.cycleTheoreticalPartFormPage.availableTheoreticalParts,
  cycleTheoreticalPart: state.cycleTheoreticalPartFormPage.cycleTheoreticalPart,
  theoreticalPartTypes: state.cycleTheoreticalPartFormPage.theoreticalPartTypes,
  validationResult: state.cycleTheoreticalPartFormPage.validationResult,
  teachers: state.cycleTheoreticalPartFormPage.teachers
});

const mapDispatchToProps = (dispatch) => ({
  loadCycleTheoreticalPartFormPage: (payload) => dispatch(loadCycleTheoreticalPartFormPage(payload)),
  saveCycleTheoreticalPart: (payload) => dispatch(saveCycleTheoreticalPart(payload)),
  loadAvailableTheoreticalParts: (payload) => dispatch(loadAvailableTheoreticalParts(payload)),
  loadTheoreticalPartTypes: (localizationService) => dispatch(loadTheoreticalPartTypes(localizationService)),
  loadAvailableTeachers: (payload) => dispatch(loadAvailableTeachers(payload)),
  openUserSearchDialog: (payload) => dispatch(openUserSearchDialog(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleTheoreticalPartFormPage));