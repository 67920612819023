import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, registerForIntl } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout, Text } from '../../../ui';
import { EmptyFormatter, ValueItem } from '../../shared/components';
import { loadCompetencySurveyOverviewPage, selectCompetencySurveyOverviewPageTab, clearCompetencySurveyOverviewPage } from '../actions/competency-survey-overview-page-actions';
import { CompetencySurveyQuestionList } from './';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { withRouter } from '../../../components/withRouter';

class CompetencySurveyOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.competencyId && this.props.params?.competencySurveyId || this.props.params?.surveyId) {
      await this.props.loadCompetencySurveyOverviewPage({
        competencyId: this.props.params.competencyId,
        competencySurveyId: this.props.params.competencySurveyId,
        surveyId: this.props.params.surveyId
      });
    }
  }

  componentWillUnmount() {
    this.props.clearCompetencySurveyOverviewPage();
  }

  render() {
    const { survey, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${EmptyFormatter(survey?.surveyName)}`
        }
        headerActions={
          <>
            <Button
              id='edit-button'
              type='button'
              themeColor='primary'
              onClick={this.handleEditClick}
            >
              {localizationService.toLanguageString('custom.edit')}
            </Button>
          </>
        }
        breadcrumbItems={this.getBreadcrumbItems()}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('competency.surveyType')}
              labelWidth='200px'
              loading={loading}
            >
              {EmptyFormatter(localizationService.toLanguageString(`surveyType.${survey?.surveyType}`))}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('competency.surveyCode')}
              labelWidth='200px'
              loading={loading}
            >
              {EmptyFormatter(survey?.code || survey?.surveyCode)}
            </ValueItem>
            {!this.props.params.surveyId > 0 &&
              <ValueItem
                label={localizationService.toLanguageString('competency.minEvaluationCount')}
                labelWidth='200px'
                loading={loading}
              >
                {EmptyFormatter(survey?.minCount)}
              </ValueItem>
            }
            {survey?.competencyName &&
              <ValueItem
                label={localizationService.toLanguageString('competency.tieredCompetency')}
                labelWidth='200px'
                loading={loading}
              >
                <Text variant='body2'>
                  {survey?.competencyAbbreviation
                    && `${survey.competencyAbbreviation} ${survey?.competencyName}`
                    || survey.competencyName
                  }
                </Text>
              </ValueItem>
            }
            {survey?.studyProgramName &&
              <ValueItem
                label={localizationService.toLanguageString('competency.studyProgram')}
                labelWidth='200px'
                loading={loading}
              >
                {survey.studyProgramName}
              </ValueItem>
            }
          </StackLayout>
        }
        tabs={
          <TabStrip selected={this.props.selectedTab} onSelect={this.handleTabSelect}>
            <TabStripTab title={localizationService.toLanguageString('competency.questions')}/>
          </TabStrip>
        }
      >
        {this.props.selectedTab === 0 &&
          <CompetencySurveyQuestionList
            competencyId={this.props.params.competencyId}
            competencySurveyId={this.props.params.competencySurveyId}
            surveyId={this.props.params.surveyId}
          />
        }
      </OverviewPage>
    );
  }

  getBreadcrumbItems() {
    const breadcrumbs = [];
    const localizationService = provideLocalizationService(this);

    if (this.props.params?.surveyId > 0) {
      breadcrumbs.push({
        id: 'surveys',
        text: localizationService.toLanguageString('competency.surveys')
      });
    } else {
      breadcrumbs.push(
        {
          id: 'competencies',
          text: localizationService.toLanguageString('competency.tieredCompetencies')
        }, {
          id: 'competency',
          text: localizationService.toLanguageString('competency.tieredCompetency')
        }
      );
    }

    breadcrumbs.push({
      id: 'survey',
      text: localizationService.toLanguageString('competency.survey'),
      disabled: true
    });

    return breadcrumbs;
  }

  handleEditClick() {
    if (this.props.params?.surveyId) {
      this.props.navigate(`/surveys/${this.props.params.surveyId}`);
    } else {
      this.props.navigate(`/competencies/${this.props.params.competencyId}/surveys/${this.props.survey.surveyId}`);
    }
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'competencies') {
      this.props.navigate('/competencies');
    }
    if (e.id === 'competency') {
      this.props.navigate(`/competencies/${this.props.params.competencyId}/overview`);
    }
    if (e.id === 'surveys') {
      this.props.navigate(`/surveys`);
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleTabSelect(e) {
    this.props.selectCompetencySurveyOverviewPageTab({
      selected: e.selected
    });
  }
}

registerForLocalization(CompetencySurveyOverviewPage);
registerForIntl(CompetencySurveyOverviewPage);

const mapStateToProps = state => ({
  survey: state.competencySurveyOverviewPage.survey,
  loading: state.competencySurveyOverviewPage.loading,
  selectedTab: state.competencySurveyOverviewPage.selectedTab
});

const mapDispatchToProps = dispatch => ({
  loadCompetencySurveyOverviewPage: (payload) => dispatch(loadCompetencySurveyOverviewPage(payload)),
  selectCompetencySurveyOverviewPageTab: (payload) => dispatch(selectCompetencySurveyOverviewPageTab(payload)),
  clearCompetencySurveyOverviewPage: () => dispatch(clearCompetencySurveyOverviewPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompetencySurveyOverviewPage));