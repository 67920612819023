export default {
  LOAD_STUDY_PLAN_CYCLE_MANAGERS_LIST_START: 'LoadStudyPlanCycleManagersListStart',
  LOAD_STUDY_PLAN_CYCLE_MANAGERS_LIST_END: 'LoadStudyPlanCycleManagersListEnd',
  OPEN_STUDY_PLAN_CYCLE_MANAGERS_LIST_ADD_DIALOG: 'OpenStudyPlanCycleManagersListAddDialog',
  CLOSE_STUDY_PLAN_CYCLE_MANAGERS_LIST_ADD_DIALOG: 'CloseStudyPlanCycleManagersListAddDialog',
  OPEN_STUDY_PLAN_CYCLE_MANAGERS_LIST_DELETE_DIALOG: 'OpenStudyPlanCycleManagersListDeleteDialog',
  CLOSE_STUDY_PLAN_CYCLE_MANAGERS_LIST_DELETE_DIALOG: 'CloseStudyPlanCycleManagersListDeleteDialog',
  LOAD_STUDY_PLAN_CYCLE_AVAILABLE_MANAGERS_START: 'LoadStudyPlanCycleAvailableManagersStart',
  LOAD_STUDY_PLAN_CYCLE_AVAILABLE_MANAGERS_END: 'LoadStudyPlanCycleAvailableManagersEnd',
  ADD_STUDY_PLAN_CYCLE_MANAGER_START: 'AddStudyPlanCycleManagerStart',
  ADD_STUDY_PLAN_CYCLE_MANAGER_END: 'AddStudyPlanCycleManagerEnd'
};