
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: #0000EE;
  textAlign: center;
  textDecoration: underline;
  cursor: pointer;
`;

export default class LinkText extends React.PureComponent {

  render() {
    return (
      <Container { ...this.props}>{this.props.children}</Container>
    );
  }

};

