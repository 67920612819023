import React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Field } from '@progress/kendo-react-form';
import { TextBox, DropDown, FilterForm, WrapLayout, SelectListPage } from '../../../ui';
import { Grid, GridSize } from '../../shared/components';
import {
  loadCompetencyCycleSelectPage,
  findCompetencyCycleSelectPage,
  selectCompetencyCycleSelectPage,
  selectAllCompetencyCycleSelectPage,
  addSelectedCompetencyCycleSelectPage,
  filterCompetencyCycleSelectPage,
  clearCompetencyCycleSelectPage,
  cleanupCompetencyCycleSelectPage
} from '../actions';
import { GridToolbar } from '@progress/kendo-react-grid';
import { CYCLE_TYPES } from '../../../resources/cycleType';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const CycleNameContainer = styled.span`
  font-weight: bolder;
`;

class CompetencyCycleSelectPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.renderType = this.renderType.bind(this);
    this.handleAddSelectedButtonClick = this.handleAddSelectedButtonClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.competencyId) {
      this.props.loadCompetencyCycleSelectPage({ competencyId: this.props.params.competencyId });
    }
  }

  componentWillUnmount() {
    this.props.cleanupCompetencyCycleSelectPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <SelectListPage
          id='competency-cycle-select'
          errorMessage={this.props.errorMessage}
          successMessage={this.props.successMessage}
          breadcrumbItems={[
            {
              id: 'competencies',
              text: localizationService.toLanguageString('competency.tieredCompetencies')
            },
            {
              id: 'competency',
              text: localizationService.toLanguageString('competency.tieredCompetency')
            },
            {
              id: 'cycles',
              text: localizationService.toLanguageString('competency.cycles'),
              disabled: true
            }
          ]}
          onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
          onBackClick={this.handleCancel}
          filter={
            <FilterForm
              initialValues={this.props.filter}
              onSubmit={this.props.filterCompetencyCycleSelectPage}
              onClear={this.props.clearCompetencyCycleSelectPage}
              localizationService={localizationService}
              title={localizationService.toLanguageString('grid.filterTitle')}
              uniqueKey={JSON.stringify(this.props.filter)}
              render={() => (
                <WrapLayout>
                  <Field
                    name='keyword'
                    width='320px'
                    component={TextBox}
                    label={localizationService.toLanguageString('custom.search')}
                  />
                  <Field
                    name='cycleType'
                    label={localizationService.toLanguageString('competency.type')}
                    component={DropDown}
                    data={[
                      {
                        value: CYCLE_TYPES.OPTIONAL,
                        text: localizationService.toLanguageString('cycleType.Optional')
                      }, {
                        value: CYCLE_TYPES.REQUIRED,
                        text: localizationService.toLanguageString('cycleType.Required')
                      }
                    ]}
                    textField='text'
                    valueField='value'
                    width='320px'
                  />
                </WrapLayout>
              )}
            />
          }
        >
          <Grid
            loading={this.props.loading}
            data={this.props.availableCycles}
            skip={this.props.skip}
            take={this.props.take}
            pageSize={this.props.take}
            total={this.props.total}
            onPageChange={this.handlePageChange}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: 'multiple'
            }}
            dataItemKey='id'
            selectedField='isSelected'
            onSelectionChange={this.handleSelect}
            onHeaderSelectionChange={this.handleSelectAll}
            columns={[
              {
                field: 'isSelected',
                width: '50px',
                headerSelectionValue: this.props.selectedAll,
                cell: this.renderSelected
              },
              {
                field: 'name',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('competency.name'),
                cell: ({ dataItem, className }) =>
                  <td className={className}>
                    {this.props.studyProgramId === dataItem.studyProgramId
                      ? `${dataItem.code} ${dataItem.name}`
                      : <CycleNameContainer>{`${dataItem.code} ${dataItem.name} (${dataItem.studyProgramName})`}</CycleNameContainer>
                    }
                  </td>
              },
              {
                field: 'type',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('competency.type'),
                cell: this.renderType,
                minGridWidth: GridSize.large
              },
              {
                field: 'credits',
                className: 'align-right',
                headerClassName: 'align-right',
                title: localizationService.toLanguageString('competency.credits'),
                minGridWidth: GridSize.medium
              }
            ]}
          >
            <GridToolbar>
              <Button
                type='button'
                themeColor='primary'
                onClick={this.handleAddSelectedButtonClick}
              >
                {localizationService.toLanguageString('competency.addSelectedCycles')}
              </Button>
            </GridToolbar>
          </Grid>
        </SelectListPage>
        {this.props.savingSelectedCycles && this.renderLoader(localizationService)}
      </>
    );
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'competencies') {
      this.props.navigate('/competencies');
    }
    if (e.id === 'competency') {
      this.props.navigate(`/competencies/${this.props.params.competencyId}/overview`);
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  async handlePageChange(e) {
    await this.props.findCompetencyCycleSelectPage({ skip: e.page.skip, take: e.page.take });
  }

  handleSelect(e) {
    if (e.dataItem) {
      this.props.selectCompetencyCycleSelectPage(e.dataItem);
    }
  }

  handleSelectAll() {
    this.props.selectAllCompetencyCycleSelectPage();
  }

  renderSelected(e) {
    return (
      <td role='gridcell'>
        <Checkbox disabled={e.dataItem.isDisabled} checked={e.dataItem.isSelected} onChange={() => e.selectionChange(e)} />
      </td>
    );
  }

  renderType(e) {
    const localizationService = provideLocalizationService(this);
    let localizedTypeValue;
    switch (e.dataItem?.type) {
      case CYCLE_TYPES.OPTIONAL:
        localizedTypeValue = localizationService.toLanguageString('cycleType.Optional');
        break;
      case CYCLE_TYPES.REQUIRED:
        localizedTypeValue = localizationService.toLanguageString('cycleType.Required');
        break;
    }
    return <td>{localizedTypeValue}</td>;
  }

  handleAddSelectedButtonClick() {
    const localizationService = provideLocalizationService(this);
    this.props.addSelectedCompetencyCycleSelectPage({ localizationService });
  }

  renderLoader(localizationService) {
    const loadingPanel = (
      <div className='k-loading-mask'>
        <span className='k-loading-text'>{localizationService.toLanguageString('custom.loading')}</span>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
    const gridContent = document && document.querySelector('#competency-cycle-select');
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}

registerForLocalization(CompetencyCycleSelectPage);

const mapStateToProps = state => ({
  loading: state.competencyCycleSelectPage.loading,
  availableCycles: state.competencyCycleSelectPage.availableCycles,
  skip: state.competencyCycleSelectPage.skip,
  take: state.competencyCycleSelectPage.take,
  total: state.competencyCycleSelectPage.total,
  selectedAll: state.competencyCycleSelectPage.selectedAll,
  selectedIds: state.competencyCycleSelectPage.selectedIds,
  savingSelectedCycles: state.competencyCycleSelectPage.savingSelectedCycles,
  errorMessage: state.competencyCycleSelectPage.errorMessage,
  successMessage: state.competencyCycleSelectPage.successMessage,
  filter: state.competencyCycleSelectPage.filter,
  studyProgramId: state.competencyCycleSelectPage.studyProgramId
});

const mapDispatchToProps = dispatch => ({
  loadCompetencyCycleSelectPage: (payload) => dispatch(loadCompetencyCycleSelectPage(payload)),
  findCompetencyCycleSelectPage: (payload) => dispatch(findCompetencyCycleSelectPage(payload)),
  selectCompetencyCycleSelectPage: (payload) => dispatch(selectCompetencyCycleSelectPage(payload)),
  selectAllCompetencyCycleSelectPage: (payload) => dispatch(selectAllCompetencyCycleSelectPage(payload)),
  addSelectedCompetencyCycleSelectPage: (payload) => dispatch(addSelectedCompetencyCycleSelectPage(payload)),
  filterCompetencyCycleSelectPage: (payload) => dispatch(filterCompetencyCycleSelectPage(payload)),
  clearCompetencyCycleSelectPage: () => dispatch(clearCompetencyCycleSelectPage()),
  cleanupCompetencyCycleSelectPage: () => dispatch(cleanupCompetencyCycleSelectPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompetencyCycleSelectPage));