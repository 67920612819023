import MySurveyListPageActionTypes from './my-survey-list-page-action-types';
import axios from 'axios';
import { sortingToStringWithSubstitutes } from '../../../utils/objectUtils';
import { USER_TYPES } from '../../../resources/userTypes';

export const loadMySurveyListPage = (payload) => async(dispatch, getState) => {
  dispatch(findMySurveys(payload));
  dispatch(filterResidentsComboBox());
};

export const findMySurveys = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: MySurveyListPageActionTypes.LOAD_MY_SURVEY_LIST_START });
  const state = getState().mySurveyListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToStringWithSubstitutes(state.sorting, [
      { originalName: 'student', substituteName: 'Name' },
      { originalName: 'surveyType', substituteName: 'Type' },
      { originalName: 'requestDate', substituteName: 'CreatedOn' }
    ]),
    keyword: state.filter?.keyword,
    studentUserId: state.filter?.resident?.id,
    extendedSearch: true,
    status: state.filter?.status?.value
  };
  try {
    state.mySurveysCancelToken?.cancel();
    state.mySurveysCancelToken = axios.CancelToken.source();
    const mySurveys = await api.get(`api/my_surveys`, query, state.mySurveysCancelToken.token);
    dispatch({
      type: MySurveyListPageActionTypes.LOAD_MY_SURVEY_LIST_END,
      payload: {
        mySurveys: mySurveys ? mySurveys.data : [],
        total: mySurveys ? mySurveys.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: MySurveyListPageActionTypes.LOAD_MY_SURVEY_LIST_END });
      throw error;
    }
  }
};

export const filterMySurveys = (payload) => (dispatch) => {
  dispatch({
    type: MySurveyListPageActionTypes.FILTER_MY_SURVEY_LIST,
    payload: payload
  });
  dispatch(findMySurveys());
};

export const sortMySurveys = (payload) => (dispatch) => {
  dispatch({
    type: MySurveyListPageActionTypes.SORT_MY_SURVEY_LIST,
    payload: payload
  });
  dispatch(findMySurveys());
};

export const clearMySurveysFilter = () => (dispatch) => {
  dispatch({
    type: MySurveyListPageActionTypes.FILTER_MY_SURVEY_LIST,
    payload: {}
  });
  dispatch(findMySurveys());
};

export const filterResidentsComboBox = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: MySurveyListPageActionTypes.SEARCH_MY_SURVEY_LIST_RESIDENTS_COMBOBOX_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword,
    type: USER_TYPES.STUDENT
  };
  const state = getState().mySurveyListPage;
  try {
    state.residentsComboBoxCancelToken?.cancel();
    state.residentsComboBoxCancelToken = axios.CancelToken.source();
    const users = await api.get('api/users_search', query, state.residentsComboBoxCancelToken.token);
    dispatch({
      type: MySurveyListPageActionTypes.SEARCH_MY_SURVEY_LIST_RESIDENTS_COMBOBOX_END,
      payload: {
        residents: users?.data?.map(element => (
          {
            id: element.id,
            fullName: element.name + ' ' + element.surname
          }
        ))
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: MySurveyListPageActionTypes.SEARCH_MY_SURVEY_LIST_RESIDENTS_COMBOBOX_END });
      throw error;
    }
  }
};