import { CYCLE_STUDENT_STATUS } from '../resources/studyPlanCycleDepartmentStudentStatus';

export const getStudentCycleStatusColor = (status) => {
  switch (status) {
    case CYCLE_STUDENT_STATUS.NOT_STARTED:
      return 'inverse';
    case CYCLE_STUDENT_STATUS.STARTED:
      return 'primary';
    case CYCLE_STUDENT_STATUS.FINISHED:
      return 'success';
    case CYCLE_STUDENT_STATUS.SUBMITTED:
      return 'warning';
    case CYCLE_STUDENT_STATUS.RETURNED:
      return 'error';
    default:
      return 'inverse';
  }
};