import axios from 'axios';
import StudyPlanCycleManagersListActionTypes from './study-plan-cycle-managers-list-action-types';
import ValidationError from '../../../utils/validation-error';

export const loadStudyPlanCycleManagersList = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleManagersList;
  const { studyPlanId, studyPlanCycleId } = payload;
  dispatch({ type: StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_MANAGERS_LIST_START });
  try {
    state.studyPlanCycleManagersCancelToken?.cancel();
    state.studyPlanCycleManagersCancelToken = axios.CancelToken.source();
    const studyPlanCycleManagers = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/managers`, null, state.studyPlanCycleManagersCancelToken.token);
    dispatch({
      type: StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_MANAGERS_LIST_END,
      payload: {
        studyPlanId,
        studyPlanCycleId,
        studyPlanCycleManagers
      }
    });
    dispatch(searchAvailableCycleManagers());
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_MANAGERS_LIST_END });
      throw error;
    }
  }
};

export const openStudyPlanCycleManagersListAddDialog = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleManagersListActionTypes.OPEN_STUDY_PLAN_CYCLE_MANAGERS_LIST_ADD_DIALOG });
};

export const addStudyPlanCycleManager = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleManagersList;
  const { studyPlanCycleId, studyPlanId } = state;
  const { userId } = payload;
  dispatch({ type: StudyPlanCycleManagersListActionTypes.ADD_STUDY_PLAN_CYCLE_MANAGER_START });
  let validationResult = null;
  try {
    const data = {
      userId,
      studyPlanCycleId
    };
    await api.post(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/managers`, data);
    dispatch(closeStudyPlanCycleManagersListAddDialog());
    dispatch(loadStudyPlanCycleManagersList({
      studyPlanId, studyPlanCycleId
    }));
  } catch (error) {
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    } else if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
  dispatch({
    type: StudyPlanCycleManagersListActionTypes.ADD_STUDY_PLAN_CYCLE_MANAGER_END,
    payload: {
      validationResult
    }
  });
};

export const closeStudyPlanCycleManagersListAddDialog = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleManagersListActionTypes.CLOSE_STUDY_PLAN_CYCLE_MANAGERS_LIST_ADD_DIALOG });
};

export const openStudyPlanCycleManagersDeleteDialog = (payload) => (dispatch) => {
  const { selectedId } = payload;
  dispatch({
    type: StudyPlanCycleManagersListActionTypes.OPEN_STUDY_PLAN_CYCLE_MANAGERS_LIST_DELETE_DIALOG,
    payload: {
      selectedId: selectedId
    }
  });
};

export const deleteStudyPlanCycleManager = () => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleManagersList;
  const { studyPlanId, studyPlanCycleId, selectedId } = state;
  try {
    await api.remove(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/managers/${selectedId}`);
    dispatch(closeStudyPlanCycleManagersDeleteDialog());
    dispatch(loadStudyPlanCycleManagersList({
      studyPlanId, studyPlanCycleId
    }));
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const closeStudyPlanCycleManagersDeleteDialog = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleManagersListActionTypes.CLOSE_STUDY_PLAN_CYCLE_MANAGERS_LIST_DELETE_DIALOG });
};

export const searchAvailableCycleManagers = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_AVAILABLE_MANAGERS_START });
  const state = getState().studyPlanCycleManagersList;
  try {
    state.availableManagersCancelToken?.cancel();
    state.availableManagersCancelToken = axios.CancelToken.source();
    const availableManagers = await api.get(`api/users_search`, { keyword: payload?.keyword, showOnlyEmployees: true, showOnlyActive: true }, state.availableManagersCancelToken.token);
    dispatch({
      type: StudyPlanCycleManagersListActionTypes.LOAD_STUDY_PLAN_CYCLE_AVAILABLE_MANAGERS_END,
      payload: {
        availableManagers: availableManagers?.data?.map((managerA, managerAIndex, array) => {
          const managerDetailsArray = [managerA.email, managerA.stampNumber].filter(element => element);
          const shouldIncludeDetails = array.find((managerB, managerBIndex) => managerAIndex != managerBIndex && managerA.name === managerB.name && managerA.surname === managerB.surname);
          const includedDetails = shouldIncludeDetails && managerDetailsArray.length > 0 ? ` (${managerDetailsArray.join(' ')})` : '';
          return {
            id: managerA.id,
            name: `${managerA.name} ${managerA.surname}${includedDetails}`
          };
        })
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};