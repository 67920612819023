import axios from 'axios';
import CompetencyCycleSelectPageActionTypes from './competency-cycle-select-page-action-types';
import { formatNumber } from '../../../utils';

export const loadCompetencyCycleSelectPage = (payload) => async(dispatch, getState, { api }) => {
  const [ competency, competencyCycles ] = await Promise.all([
    api.get(`api/competencies/${payload.competencyId}`),
    api.get(`api/competencies/${payload.competencyId}/cycles`)
  ]);
  dispatch({
    type: CompetencyCycleSelectPageActionTypes.LOAD_COMPETENCY_CYCLE_SELECT_PAGE_START,
    payload: {
      competencyId: payload.competencyId,
      studyProgramId: competency.studyProgramId,
      assignedCycleIds: competencyCycles?.data?.map(x => (x.cycleId))
    }
  });
  dispatch(findCompetencyCycleSelectPage(payload));
};

export const findCompetencyCycleSelectPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyCycleSelectPageActionTypes.FIND_COMPETENCY_CYCLE_SELECT_PAGE_START });
  const state = getState().competencyCycleSelectPage;
  const query = {
    skip: payload?.skip ?? state.skip,
    limit: payload?.take ?? state.take,
    studyProgramId: state.studyProgramId,
    keyword: state.filter?.keyword,
    cycleType: state.filter?.cycleType?.value,
    showInvalid: state.filter?.showInvalid,
    includeExternalyTaught: true,
    includeUnaffiliated: true
  };
  try {
    state.availableCyclesCancelToken?.cancel();
    state.availableCyclesCancelToken = axios.CancelToken.source();
    const cycles = await api.get(`api/cycles`, query, state.availableCyclesCancelToken.token);
    cycles?.data?.map(cycle => ({
      ...cycle,
      isSelected: state.selectedIds?.includes(cycle.id)
    }));
    dispatch({
      type: CompetencyCycleSelectPageActionTypes.FIND_COMPETENCY_CYCLE_SELECT_PAGE_END,
      payload: {
        cycles: cycles
          ?
          cycles.data?.map(cycle => ({
            ...cycle,
            isDisabled: state.assignedCycleIds?.includes(cycle.id),
            isSelected: state.assignedCycleIds?.includes(cycle.id) ? true : state.selectedAll ? true : state.selectedIds?.includes(cycle.id)
          }))
          : [],
        total: cycles ? cycles.total : 0,
        skip: query.skip,
        take: query.limit
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyCycleSelectPageActionTypes.FIND_COMPETENCY_CYCLE_SELECT_PAGE_END });
      throw error;
    }
  }
};

export const cleanupCompetencyCycleSelectPage = () => (dispatch) => {
  dispatch({ type: CompetencyCycleSelectPageActionTypes.CLEANUP_COMPETENCY_CYCLE_SELECT_PAGE });
};

export const filterCompetencyCycleSelectPage = (payload) => (dispatch) => {
  dispatch({
    type: CompetencyCycleSelectPageActionTypes.FILTER_COMPETENCY_CYCLE_SELECT_PAGE,
    payload: payload
  });
  dispatch(findCompetencyCycleSelectPage());
};

export const clearCompetencyCycleSelectPage = () => (dispatch) => {
  dispatch({
    type: CompetencyCycleSelectPageActionTypes.FILTER_COMPETENCY_CYCLE_SELECT_PAGE,
    payload: {}
  });
  dispatch(findCompetencyCycleSelectPage());
};

export const selectCompetencyCycleSelectPage = (payload) => (dispatch, getState) => {
  const state = getState().competencyCycleSelectPage;
  let selectedIds = [...state.selectedIds];
  const availableCycles = state.availableCycles?.map(cycle => {
    let isSelected = cycle.isSelected;
    if (cycle.id === payload.id) {
      const competencyIncludes = state.assignedCycleIds?.includes(cycle.id);
      isSelected = competencyIncludes ? true : !isSelected;
      if (!competencyIncludes) {
        if (isSelected) {
          if (selectedIds.indexOf(cycle.id) === -1) {
            selectedIds.push(cycle.id);
          }
        } else {
          const index = selectedIds.indexOf(cycle.id);
          if (index !== -1) {
            selectedIds.splice(index, 1);
          }
        }
      }
    }
    return {
      ...cycle,
      isSelected: isSelected
    };
  });
  dispatch({
    type: CompetencyCycleSelectPageActionTypes.SELECT_COMPETENCY_CYCLE_SELECT_PAGE,
    payload: {
      availableCycles: availableCycles,
      selectedIds: selectedIds
    }
  });
};

export const selectAllCompetencyCycleSelectPage = () => (dispatch, getState) => {
  const state = getState().competencyCycleSelectPage;
  const availableCycles = state.availableCycles?.map(cycle => ({
    ...cycle,
    isSelected: state.assignedCycleIds?.includes(cycle.id) ? true : !state.selectedAll
  }));
  const selectedIds = availableCycles.filter(cycle => cycle.isSelected == true).map(cycle => cycle.id);
  dispatch({
    type: CompetencyCycleSelectPageActionTypes.SELECT_ALL_COMPETENCY_CYCLE_SELECT_PAGE,
    payload: {
      availableCycles: availableCycles,
      selectedIds
    }
  });
};

export const addSelectedCompetencyCycleSelectPage = ({ localizationService }) => async(dispatch, getState, { api }) => {
  const state = getState().competencyCycleSelectPage;
  dispatch({
    type: CompetencyCycleSelectPageActionTypes.ADD_SELECTED_CYCLES_COMPETENCY_CYCLE_SELECT_PAGE_START
  });
  let errorMessage = null;
  let successMessage = null;
  let errorsCount = 0;
  let assignedCycleIds = null;
  let totalCount = 0;
  try {
    const apiModel = state.selectedIds.filter(element => !state.assignedCycleIds?.includes(element)).map(element => ({
      cycleId: element,
      competencyId: state.competencyId
    }));
    if (apiModel?.length > 0) {
      totalCount = apiModel.length;
      const result = await api.post(`api/competencies/${state.competencyId}/cycles`, apiModel);
      if (result) {
        errorsCount = result?.filter(x => x.statusCode === 400 || x.body?.errors?.length > 0)?.length || 0;
      }
    }
    const competencyCycles = await api.get(`api/competencies/${state.competencyId}/cycles`);
    assignedCycleIds = competencyCycles?.data?.map(x => (x.cycleId));
  } finally {
    errorMessage = null;
    if (errorsCount === 0) {
      successMessage = `${formatCycleMessage(totalCount, localizationService)} ${localizationService.toLanguageString(`systemMessages.isSuccessfullyAdded`)}`;
    } else {
      const successfulCarriersCount = totalCount - errorsCount;
      if (successfulCarriersCount > 0) {
        errorMessage = `${formatCycleMessage(successfulCarriersCount, localizationService)} ${localizationService.toLanguageString(`systemMessages.isSuccessfullyAdded`)} 
        ${formatCycleMessage(errorsCount, localizationService)} ${localizationService.toLanguageString(`systemMessages.isNotAddedBecauseOfErrors`)}`;
      } else {
        errorMessage = `${formatCycleMessage(errorsCount, localizationService)} ${localizationService.toLanguageString(`systemMessages.isNotAddedBecauseOfErrors`)}`;
      }
    }
    dispatch({
      type: CompetencyCycleSelectPageActionTypes.ADD_SELECTED_CYCLES_COMPETENCY_CYCLE_SELECT_PAGE_END,
      payload: {
        errorMessage: errorMessage,
        successMessage: successMessage,
        assignedCycleIds: assignedCycleIds
      }
    });
    dispatch(filterCompetencyCycleSelectPage());
  }
};

const formatCycleMessage = (value, localizationService) => {
  return formatNumber(
    value,
    {
      unitSingular: localizationService.toLanguageString(`competencies.cycle`),
      unitPlural: localizationService.toLanguageString(`competencies.cycles`)
    }
  );
};