import axios from 'axios';
import UserRequestListActionTypes from './user-request-list-action-types';

export const loadUserRequestList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserRequestListActionTypes.LOAD_USER_REQUEST_LIST_START });
  const state = getState().userRequestList;
  try {
    state.userRequestsCancelToken?.cancel();
    state.userRequestsCancelToken = axios.CancelToken.source();
    const userRequests = await api.get(`api/users/${payload.userId}/requests`, null, state.userRequestsCancelToken.token);
    dispatch({
      type: UserRequestListActionTypes.LOAD_USER_REQUEST_LIST_END,
      payload: userRequests
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: UserRequestListActionTypes.LOAD_USER_REQUEST_LIST_END });
      throw error;
    }
  }
};

export const openUserRequestConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: UserRequestListActionTypes.TOGGLE_USER_REQUEST_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeUserRequestConfirmDialog = () => async(dispatch) => {
  dispatch({ type: UserRequestListActionTypes.TOGGLE_USER_REQUEST_LIST_CONFIRM_DIALOG });
};

export const removeUserRequestConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().userRequestList;
  if (state.selectedCompetencySurveyStudentId > 0) {
    dispatch({ type: UserRequestListActionTypes.LOAD_USER_REQUEST_LIST_START });
    try {
      await api.remove(`api/competency_survey_student/${state.selectedCompetencySurveyStudentId}`);
      dispatch(loadUserRequestList({ userId: payload.userId }));
    } catch (error) {
      dispatch({ type: UserRequestListActionTypes.LOAD_USER_REQUEST_LIST_END });
    }
  }
  dispatch({ type: UserRequestListActionTypes.TOGGLE_USER_REQUEST_LIST_CONFIRM_DIALOG });
};