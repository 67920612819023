import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout } from '../../../ui';
import { StudentCompetencyList } from '../../shared/student-competency';
import { loadStudentOverviewPage, selectStudentOverviewPageTab } from '../actions';
import { StudentCycles } from '.';
import { withRouter } from '../../../components/withRouter';
import { ValueItem } from '../../shared';

const Tabs = styled(TabStrip)`
  & > .k-content.k-state-active {
    display: none;
  }
  & > .k-tabstrip-items-wrapper .k-item::after {
    border-bottom-width: 2px;
  }
`;

class StudentOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studentId) {
      this.props.loadStudentOverviewPage(this.props.params.studentId);
    }
  }

  render() {
    const { student, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }} />
          : student?.fullName
        }
        breadcrumbItems={[
          {
            id: 'students',
            text: localizationService.toLanguageString('student.residents')
          },
          {
            id: 'student',
            text: localizationService.toLanguageString('student.resident'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('student.number')}
              labelWidth='115px'
              loading={loading}
            >
              {student?.number}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('student.studyProgramLabel')}
              labelWidth='115px'
              loading={loading}
            >
              {student?.studyProgram}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('student.year')}
              labelWidth='115px'
              loading={loading}
            >
              {student?.studyYear}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <Tabs selected={this.props.selectedTab} onSelect={this.props.selectStudentOverviewPageTab}>
            <TabStripTab title={localizationService.toLanguageString('student.cycles')} />
            <TabStripTab title={localizationService.toLanguageString('student.competencies')} />
          </Tabs>
        }
      >
        {this.props.selectedTab == 0 && <StudentCycles studentId={this.props.params.studentId}/>}
        {this.props.selectedTab == 1 && <StudentCompetencyList studentId={this.props.params.studentId} onRowClick={(e) => this.props.navigate(`/students/${this.props.params.studentId}/competencies/${e.dataItem.id}/overview`)}/>}
      </OverviewPage>
    );
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'students') {
      this.props.navigate('/students');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(StudentOverviewPage);

const mapStateToProps = state => ({
  student: state.studentOverviewPage.student,
  loading: state.studentOverviewPage.loading,
  selectedTab: state.studentOverviewPage.selectedTab
});

const mapDispatchToProps = dispatch => ({
  loadStudentOverviewPage: (payload) => dispatch(loadStudentOverviewPage(payload)),
  selectStudentOverviewPageTab: (payload) => dispatch(selectStudentOverviewPageTab(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentOverviewPage));
