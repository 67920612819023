import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, WrapLayout, TextBox, StackLayout, ComboBox } from '../../../ui';
import { filterSubcompetencies, clearSubcompetenciesFilter, searchSubcompetenciesFilterStudyPrograms } from '../actions/subcompetency-list-page-actions';

class SubcompetencyListFilter extends React.PureComponent {

  render() {
    const {
      filter,
      clearSubcompetenciesFilter,
      filterSubcompetencies,
      filterStudyProgramsLoading,
      filterStudyPrograms,
      searchSubcompetenciesFilterStudyPrograms
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterSubcompetencies}
        onClear={clearSubcompetenciesFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='studyProgram'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('subcompetency.studyProgram')}
                textField='name'
                dataItemKey='id'
                loading={filterStudyProgramsLoading}
                data={filterStudyPrograms}
                onFilter={searchSubcompetenciesFilterStudyPrograms}
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.subcompetencyListPage.filter,
  filterStudyProgramsLoading: state.subcompetencyListPage.filterStudyProgramsLoading,
  filterStudyPrograms: state.subcompetencyListPage.filterStudyPrograms
});

const mapDispatchToProps = dispatch => ({
  filterSubcompetencies: (payload) => dispatch(filterSubcompetencies(payload)),
  clearSubcompetenciesFilter: () => dispatch(clearSubcompetenciesFilter()),
  searchSubcompetenciesFilterStudyPrograms: (payload) => dispatch(searchSubcompetenciesFilterStudyPrograms(payload))
});

registerForLocalization(SubcompetencyListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(SubcompetencyListFilter);