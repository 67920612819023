import React, { PureComponent } from 'react';

export default class WarningMessage extends PureComponent {

  render() {
    return (
      <div className={'k-messagebox k-messagebox-warning'}>{this.props.children}</div>
    );
  }

}