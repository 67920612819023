import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, NumericBox, TextBox, ComboBox, WrapLayout, DropDown } from '../../../ui';
import { filterStudents, clearStudentsFilter, searchStudentsFilterStudyPrograms, loadStudyStatuses } from '../actions/student-list-page-actions.js';
import { STUDENT_STATUSES } from '../../../resources/studentStatus';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import styled from 'styled-components';

const DropdownItemContainer = styled.div`
  height: 24px;
  padding-top: 8px;
`;

const StyledBadge = styled(Badge)`
  opacity: 75%;
  font-size: small;
`;

class StudentListFilter extends React.PureComponent {
  constructor() {
    super();
    this.statusDropdownItemRender = this.statusDropdownItemRender.bind(this);
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    await this.props.loadStudyStatuses(localizationService);
  }

  render() {
    const {
      filter,
      clearStudentsFilter,
      filterStudents,
      filterStudyPrograms,
      filterStudyProgramsLoading,
      searchStudentsFilterStudyPrograms,
      studentStatuses
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={(values) => filterStudents(values)}
        onClear={() => clearStudentsFilter()}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <Field
              name='studyProgram'
              width='340px'
              component={ComboBox}
              label={localizationService.toLanguageString('student.studyProgram')}
              textField='name'
              dataItemKey='id'
              loading={filterStudyProgramsLoading}
              data={filterStudyPrograms}
              onFilter={searchStudentsFilterStudyPrograms}
            />
            <Field
              name='studentStatus'
              width='340px'
              component={DropDown}
              label={localizationService.toLanguageString('student.status')}
              textField='name'
              dataItemKey='id'
              data={studentStatuses}
              itemRender={this.statusDropdownItemRender}
            />
            <Field
              name='student'
              width='340px'
              component={TextBox}
              label={localizationService.toLanguageString('student.resident')}
            />
            <Field
              name='year'
              width='170px'
              component={NumericBox}
              min={1}
              max={7}
              numberFormat='0'
              label={localizationService.toLanguageString('student.year')}
            />
          </WrapLayout>
        )}
      />
    );
  }

  statusDropdownItemRender(li, itemProps) {
    let color;
    switch (itemProps.dataItem.id) {
      case STUDENT_STATUSES.STUDYING:
        color = 'primary';
        break;
      case STUDENT_STATUSES.TEMPORARILY_NOT_STUDYING:
        color = 'warning';
        break;
      case STUDENT_STATUSES.REMOVED_FROM_LIST:
        color = 'error';
        break;
      case STUDENT_STATUSES.GRADUATED:
        color = 'success';
        break;
      default:
        color = 'inverse';
        break;
    }
    const itemChildren = (
      <DropdownItemContainer>
        <BadgeContainer>
          <StyledBadge rounded={'medium'} size={'large'} themeColor={color} position={'outside'} align={{ vertical: 'top', horizontal: 'end' }}>
            {li.props.children}
          </StyledBadge>
        </BadgeContainer>
      </DropdownItemContainer>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }

}

const mapStateToProps = state => ({
  filter: state.studentListPage.filter,
  filterCyclesLoading: state.studentListPage.filterCyclesLoading,
  filterCyclesSkip: state.studentListPage.filterCyclesSkip,
  filterCyclesTotal: state.studentListPage.filterCyclesTotal,
  filterCyclesKeyword: state.studentListPage.filterCyclesKeyword,
  filterCycles: state.studentListPage.filterCycles,
  filterStudyProgramsLoading: state.studentListPage.filterStudyProgramsLoading,
  filterStudyPrograms: state.studentListPage.filterStudyPrograms,
  studentStatuses: state.studentListPage.studentStatuses
});

const mapDispatchToProps = dispatch => ({
  filterStudents: (payload) => dispatch(filterStudents(payload)),
  clearStudentsFilter: () => dispatch(clearStudentsFilter()),
  searchStudentsFilterStudyPrograms: (payload) => dispatch(searchStudentsFilterStudyPrograms(payload)),
  loadStudyStatuses: (localizationService) => dispatch(loadStudyStatuses(localizationService))
});

registerForLocalization(StudentListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(StudentListFilter);
