import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, ComboBox, StackLayout, WrapLayout, TextBox } from '../../../ui';
import { filterStudyPrograms, clearStudyProgramsFilter, searchStudyProgramsFilterStudyPrograms } from '../actions/study-program-list-page-actions';

class StudyProgramListFilter extends React.PureComponent {

  render() {
    const {
      filter,
      clearStudyProgramsFilter,
      filterStudyPrograms,
      filterStudyProgramsComboBox,
      filterStudyProgramsLoadingComboBox,
      searchStudyProgramsFilterStudyPrograms
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterStudyPrograms}
        onClear={clearStudyProgramsFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='studyProgram'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('studyProgram.studyProgram')}
                textField='name'
                dataItemKey='id'
                loading={filterStudyProgramsLoadingComboBox}
                data={filterStudyProgramsComboBox}
                onFilter={searchStudyProgramsFilterStudyPrograms}
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.studyProgramListPage.filter,
  filterStudyProgramsLoadingComboBox: state.studyProgramListPage.filterStudyProgramsLoadingComboBox,
  filterStudyProgramsComboBox: state.studyProgramListPage.filterStudyProgramsComboBox
});

const mapDispatchToProps = dispatch => ({
  filterStudyPrograms: (payload) => dispatch(filterStudyPrograms(payload)),
  clearStudyProgramsFilter: () => dispatch(clearStudyProgramsFilter()),
  searchStudyProgramsFilterStudyPrograms: (payload) => dispatch(searchStudyProgramsFilterStudyPrograms(payload))
});

registerForLocalization(StudyProgramListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(StudyProgramListFilter);