import axios from 'axios';
import Qs from 'qs';
import ValidationError from './validation-error';
import ForbiddenError from './forbidden-error';
import { loadFromStorage } from './storageUtils';
import * as st from './storageTypes';

axios.defaults.timeout = 30000;
axios.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const SUCCESS = 'success';
const ERROR = 'error';
const CANCELLED = 'cancelled';

const updateDefaultHeaders = headers => {
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    ...headers
  };
};

const updateBearerAccessToken = accessToken => {
  updateDefaultHeaders({
    Authorization: `Bearer ${accessToken}`
  });
};

const setImpersonatedUserHeader = username => {
  updateDefaultHeaders({
    ImpersonateAsUser: username
  });
};

const clearImpersonatedUserHeader = username => {
  if (axios.defaults.headers.common['ImpersonateAsUser']) {
    delete axios.defaults.headers.common['ImpersonateAsUser'];
  }
};

const get = (url, parameters = {}, cancelToken, responseType) => {
  return performRequest({
    cancelToken: cancelToken,
    method: 'get',
    baseURL: '/',
    url,
    responseType,
    params: parameters,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    ignoreCancellation: !cancelToken
  });
};

const post = (url, data, cancelToken, responseType) => {
  return performRequest({
    options: { cancelToken: cancelToken },
    method: 'post',
    url,
    responseType,
    data: data,
    ignoreCancellation: !cancelToken
  });
};

const saveBatch = (url, data, cancelToken, responseType) => {
  return performRequest({
    options: { cancelToken: cancelToken },
    method: 'post',
    url,
    responseType,
    data: data,
    ignoreCancellation: !cancelToken
  });
};

const put = (url, data, cancelToken, responseType) => {
  return performRequest({
    options: { cancelToken: cancelToken },
    method: 'put',
    url,
    responseType,
    data: data,
    ignoreCancellation: !cancelToken
  });
};

const remove = (url, data, cancelToken, responseType) => {
  return performRequest({
    options: { cancelToken: cancelToken },
    method: 'delete',
    url,
    responseType,
    data,
    ignoreCancellation: !cancelToken
  });
};

const performRequest = async options => {
  const accessTokenExpiresOn = loadFromStorage(st.ACCESS_TOKEN_EXPIRES_ON);
  const authorizationService = window.authorizationService;

  if (accessTokenExpiresOn == null || new Date().getTime() > new Date(accessTokenExpiresOn).getTime()) {
    await authorizationService.refreshAccessToken();
  }

  handleDate(options);
  try {
    const response = await axios.request({ ...options });
    if (response.status === 200 || response.status === 201 || response.status === 202) {
      return response.data;
    } if (response.status === 204) {
      return null;
    } else {
      throw response;
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      throw e;
    } else if (e.response.status === 400) {
      let errors = {};
      if (e.response.data && e.response.data.errors) {
        errors = e.response.data.errors.reduce((obj, item) => {
          return {
            ...obj,
            [item.propertyName]: item.message
          };
        }, errors);
      } else {
        errors = null;
      }
      throw (new ValidationError(e.response?.data?.message, errors, e.response.data.message));
    } else if (e.response.status === 401) {
      await authorizationService.redirect();
    } else if (e.response.status === 403) {
      throw new ForbiddenError(e.message);
    }
    throw e.response;
  }
};

const handleDate = options => {
  if (options?.data) {
    if (options.data instanceof Array) {
      for (let i = 0; i < options.data.length; i++) {
        for (let key in options.data[i]) {
          if (options.data[i][key] instanceof Date) {
            options.data[i][key] = toUnlocalizedDate(options.data[i][key]);
          }
        }
      }
    } else if (options.data instanceof Object) {
      for (let key in options.data) {
        if (options.data[key] instanceof Date) {
          options.data[key] = toUnlocalizedDate(options.data[key]);
        }
      }
    }
  }
  return options;
};

const toUnlocalizedDate = date => {
  return date && new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export default {
  get,
  post,
  saveBatch,
  put,
  remove,
  updateDefaultHeaders,
  updateBearerAccessToken,
  setImpersonatedUserHeader,
  clearImpersonatedUserHeader,
  SUCCESS,
  ERROR,
  CANCELLED
};
