import StudyProgramOverviewPageActionTypes from './study-program-overview-page-action-types';
import axios from 'axios';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadStudyProgramOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramOverviewPageActionTypes.LOAD_STUDY_PROGRAM_OVERVIEW_PAGE_START });
  const state = getState().studyProgramOverviewPage;
  try {
    state.studyProgramCancelToken?.cancel();
    state.studyProgramCancelToken = axios.CancelToken.source();
    const studyProgram = await api.get(`api/study_programs/${payload.studyProgramId}`, null, state.studyProgramCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.STUDY_PROGRAMS_WRITE);
    dispatch({
      type: StudyProgramOverviewPageActionTypes.LOAD_STUDY_PROGRAM_OVERVIEW_PAGE_END,
      payload: {
        studyProgram: studyProgram,
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyProgramOverviewPageActionTypes.LOAD_STUDY_PROGRAM_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const selectStudyProgramOverviewPageTab = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyProgramOverviewPageActionTypes.SELECT_STUDY_PROGRAM_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};