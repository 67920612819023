import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import FormDialog from '../../../ui/components/application/form-dialog';
import { ComboBox, StackLayout } from '../../../ui';
import { loadTlks, addTlk } from '../actions/cycle-case-diagnosis-tlk-dialog-actions';
import { Field } from '@progress/kendo-react-form';

class CycleCaseDiagnosisTlkDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleLoadTlks = this.handleLoadTlks.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormDialog
        title={localizationService.toLanguageString('TlkSearchDialog.title')}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onClose}
        validator={this.handleValidation}
        confirmButtonText={localizationService.toLanguageString('buttons.add')}
        closeButtonText={localizationService.toLanguageString('buttons.close')}
        onClose={this.props.onClose}
        minWidth={320}
        render={() => (
          <StackLayout>
            <Field
              name='tlk'
              label={localizationService.toLanguageString('TlkSearchDialog.search')}
              component={ComboBox}
              loading={this.props.availableCycleCaseDiagnosisTlksLoading}
              data={this.props.availableCycleCaseDiagnosisTlks}
              onFilter={this.handleLoadTlks}
              textField='text'
              valueField='id'
            />
          </StackLayout>
        )}/>
    );
  }

  handleValidation(values, localizationService) {
    const errors = {};
    if (!values.tlk) {
      errors.tlk = localizationService.toLanguageString('validation.required');
    }
    return errors;
  }

  renderLoader() {
    const loadingPanel = (
      <div className='k-loading-mask'>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
    const dialogContent = document && document.querySelector('.cycle-case-diagnosis-tlk-dialog');
    return dialogContent
      ? ReactDOM.createPortal(loadingPanel, dialogContent)
      : loadingPanel;
  }

  handleLoadTlks(e) {
    this.props.loadTlks(e);
  }

  handleSubmit(payload) {
    const data = {
      ...payload,
      cycleCaseId: this.props.cycleCaseId,
      cycleId: this.props.cycleId,
      onClose: this.props.onClose
    };
    this.props.addTlk(data);
  }
}

registerForLocalization(CycleCaseDiagnosisTlkDialog);

const mapStateToProps = state => ({
  loading: state.cycleCaseDiagnosisTlkDialog.loading,
  availableCycleCaseDiagnosisTlksLoading: state.cycleCaseDiagnosisTlkDialog.availableCycleCaseDiagnosisTlksLoading,
  availableCycleCaseDiagnosisTlks: state.cycleCaseDiagnosisTlkDialog.availableCycleCaseDiagnosisTlks,
  tlk: state.cycleCaseDiagnosisTlkDialog.tlk,
  onAdd: state.cycleCaseDiagnosisTlkDialog.onAdd
});

const mapDispatchToProps = dispatch => ({
  loadTlks: (payload) => dispatch(loadTlks(payload)),
  addTlk: (payload) => dispatch(addTlk(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CycleCaseDiagnosisTlkDialog);