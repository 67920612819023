export default {
  LOAD_MANAGED_STUDENTS_SKILLS_TAB_START: 'LoadManagedStudentsSkillsTabStart',
  LOAD_MANAGED_STUDENTS_SKILLS_TAB_END: 'LoadManagedStudentsSkillsTabEnd',
  LOAD_MANAGED_STUDENTS_CASES_TAB_START: 'LoadManagedStudentsCasesTabStart',
  LOAD_MANAGED_STUDENTS_CASES_TAB_END: 'LoadManagedStudentsCasesTabEnd',
  LOAD_MANAGED_STUDENTS_THEORETICAL_PARTS_TAB_START: 'LoadManagedStudentsTheoreticalPartsTabStart',
  LOAD_MANAGED_STUDENTS_THEORETICAL_PARTS_TAB_END: 'LoadManagedStudentsTheoreticalPartsTabEnd',
  SELECT_MANAGED_STUDENTS_OVERVIEW_PAGE_TAB: 'SelectManagedStudentOverviewPageTab',
  UPDATE_MANAGED_STUDENTS_OVERVIEW_PAGE_SKILLS: 'UpdateManagedStudentOverviewPageSkills',
  UPDATE_MANAGED_STUDENTS_OVERVIEW_PAGE_CASES: 'UpdateManagedStudentOverviewPageCases',
  OPEN_MANAGED_STUDENTS_OVERVIEW_PAGE_RETURN_DIALOG: 'OpenManagedStudentOverviewPageReturnDialog',
  CLOSE_MANAGED_STUDENTS_OVERVIEW_PAGE_RETURN_DIALOG: 'CloseManagedStudentOverviewPageReturnDialog',
  OPEN_MANAGED_STUDENTS_OVERVIEW_PAGE_APPROVE_DIALOG: 'OpenManagedStudentOverviewPageApproveDialog',
  CLOSE_MANAGED_STUDENTS_OVERVIEW_PAGE_APPROVE_DIALOG: 'CloseManagedStudentOverviewPageApproveDialog',
  CLEAR_MANAGED_STUDENTS_OVERVIEW_PAGE: 'ClearManagedStudentsOverviewPage',
  CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_START: 'ChangeStudyPlanCycleStudentPartStatusStart',
  CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_END: 'ChangeStudyPlanCycleStudentPartStatusEnd'
};