import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout } from '../../../../ui';
import { ValueItem } from '../../components';
import {
  loadStudentCompetencySurveyOverviewPage,
  selectStudentCompetencySurveyOverviewPageTab,
  closeRequestEvaluationDialog,
  openRequestEvaluationDialog,
  clearStudentCompetencySurveyOverviewPage,
  openQrCodeDialog,
  closeQrCodeDialog,
  setShowQrCodeSetting
} from '../../student-competency/actions/student-competency-survey-overview-page-actions';
import StudentCompetencySubmittedSurveyList from './student-competency-submitted-survey-list';
import { StudentCompetencySurveySubmitForm } from '.';
import { loadStudentCompetencySubmittedSurveyList } from '../../student-competency/actions/student-competency-submitted-survey-list-actions';
import FormDialog from '../../../../ui/components/application/form-dialog';
import { QRCode } from '@progress/kendo-react-barcodes';
import { withRouter } from '../../../../components/withRouter';

class StudentCompetencySurveyOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleRequestEvaluationClick = this.handleRequestEvaluationClick.bind(this);
    this.state = {
      surveyLocationAddress: null
    };
  }

  async componentDidMount() {
    if (this.props.params?.competencyId && this.props.params?.competencySurveyId && this.props.studentId) {
      await this.props.loadStudentCompetencySurveyOverviewPage({
        competencyId: this.props.params.competencyId,
        studentId: this.props.studentId,
        competencySurveyId: this.props.params.competencySurveyId
      });
    }
  }

  componentWillUnmount() {
    this.props.clearStudentCompetencySurveyOverviewPage();
  }

  render() {
    const { studentCompetencySurvey, loading } = this.props;
    const localizationService = provideLocalizationService(this);

    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${studentCompetencySurvey?.surveyName}`
        }
        breadcrumbItems={this.props.breadcrumbItems}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('studentCompetency.type')}
              labelWidth='150px'
              loading={loading}
            >
              {localizationService.toLanguageString(`surveyType.${studentCompetencySurvey?.surveyType}`)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studentCompetency.code')}
              labelWidth='150px'
              loading={loading}
            >
              {studentCompetencySurvey?.surveyCode}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studentCompetency.minCount')}
              labelWidth='150px'
              loading={loading}
            >
              {studentCompetencySurvey?.minCount}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studentCompetency.tieredCompetency')}
              labelWidth='150px'
              loading={loading}
            >
              {`${studentCompetencySurvey?.competencyAbbreviation} ${studentCompetencySurvey?.competencyName}`}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={this.props.selectedTab} onSelect={this.handleTabSelect}>
            <TabStripTab title={localizationService.toLanguageString('studentCompetency.submitted')}/>
          </TabStrip>
        }
      >
        {this.props.selectedTab === 0 && studentCompetencySurvey &&
          <StudentCompetencySubmittedSurveyList
            preview={this.props.preview}
            disableRowClick={this.props.preview}
            studentId={studentCompetencySurvey.studentId}
            competencyId={studentCompetencySurvey.competencyId}
            surveyId={studentCompetencySurvey.surveyId}
            studentCompetencyStatus={studentCompetencySurvey.studentCompetencyStatus}
            handleRequestEvaluationClick={this.handleRequestEvaluationClick}
          />
        }
        {this.props.isRequestEvaluationDialogVisible &&
          <StudentCompetencySurveySubmitForm
            onCancel={this.props.closeRequestEvaluationDialog}
            studentId={studentCompetencySurvey.studentId}
            competencySurvey={this.props.studentCompetencySurvey}
            setShowQrCodeSetting={this.props.setShowQrCodeSetting}
            shouldShowQrCode={this.props.shouldShowQrCode}
            afterSubmit={(payload) => {
              this.setState({ surveyLocationAddress: payload.surveyLocationAddress });
              if (payload.surveyLocationAddress) {
                this.props.openQrCodeDialog();
              }
              this.props.loadStudentCompetencySubmittedSurveyList({ competencyId: studentCompetencySurvey.competencyId, surveyId: studentCompetencySurvey.surveyId, studentId: this.props.studentId });
            }}
          />
        }
        {this.props.isQrCodeDialogVisible && this.state.surveyLocationAddress && this.props.shouldShowQrCode &&
          <FormDialog
            title={localizationService.toLanguageString('studentCompetency.qrCode')}
            confirmButtonText={localizationService.toLanguageString('buttons.close')}
            onSubmit={this.props.closeQrCodeDialog}
            onCancel={this.props.closeQrCodeDialog}
            hideCloseButton={true}
            width={'300px'}
            render={() => (
              <QRCode
                value={this.state.surveyLocationAddress}
                errorCorrection='M'
                renderAs={'svg'}
                size={'250px'}
              />
            )}
          />
        }
      </OverviewPage>
    );
  }

  handleRequestEvaluationClick(e) {
    this.props.openRequestEvaluationDialog(e.dataItem);
  }

  handleTabSelect(e) {
    this.props.selectStudentCompetencySurveyOverviewPageTab({
      selected: e.selected
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'myCompetencies') {
      this.props.navigate('/my_competencies');
    }
    if (e.id === 'myCompetency') {
      this.props.navigate(`/my_competencies/${this.props.params.competencyId}/overview`);
    }

    if (e.id === 'students') {
      this.props.navigate('/students');
    }
    if (e.id === 'student') {
      this.props.navigate(`/students/${this.props.params.studentId}/overview`);
    }
    if (e.id === 'studentCompetency') {
      this.props.navigate(`/students/${this.props.params.studentId}/competencies/${this.props.params.competencyId}/overview`);
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(StudentCompetencySurveyOverviewPage);

const mapStateToProps = state => ({
  studentCompetencySurvey: state.studentCompetencySurveyOverview.studentCompetencySurvey,
  loading: state.studentCompetencySurveyOverview.loading,
  selectedTab: state.studentCompetencySurveyOverview.selectedTab,
  isRequestEvaluationDialogVisible: state.studentCompetencySurveyOverview.isRequestEvaluationDialogVisible,
  requestEvaluationDialogValidationResult: state.studentCompetencySurveyOverview.requestEvaluationDialogValidationResult,
  isQrCodeDialogVisible: state.studentCompetencySurveyOverview.isQrCodeDialogVisible,
  shouldShowQrCode: state.studentCompetencySurveyOverview.shouldShowQrCode
});

const mapDispatchToProps = dispatch => ({
  loadStudentCompetencySurveyOverviewPage: (payload) => dispatch(loadStudentCompetencySurveyOverviewPage(payload)),
  selectStudentCompetencySurveyOverviewPageTab: (payload) => dispatch(selectStudentCompetencySurveyOverviewPageTab(payload)),
  closeRequestEvaluationDialog: () => dispatch(closeRequestEvaluationDialog()),
  openRequestEvaluationDialog: (payload) => dispatch(openRequestEvaluationDialog(payload)),
  loadStudentCompetencySubmittedSurveyList: (payload) => dispatch(loadStudentCompetencySubmittedSurveyList(payload)),
  clearStudentCompetencySurveyOverviewPage: (payload) => dispatch(clearStudentCompetencySurveyOverviewPage(payload)),
  openQrCodeDialog: () => dispatch(openQrCodeDialog()),
  closeQrCodeDialog: () => dispatch(closeQrCodeDialog()),
  setShowQrCodeSetting: (payload) => dispatch(setShowQrCodeSetting(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencySurveyOverviewPage));
