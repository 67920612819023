import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService } from '@progress/kendo-react-intl';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout, Text } from '../../../ui';
import { InlineBadge, ValueItem, DateFormatter } from '../../shared/components';
import {
  loadUserOverviewPage,
  selectUserOverviewPageTab,
  changeUserStatus,
  openUserOverviewConfirmDialog,
  closeUserOverviewConfirmDialog,
  impersonateAsUser,
  openImpersonateConfirmDialog,
  closeImpersonateConfirmDialog,
  removeUserOverviewConfirmDialog
} from '../actions/user-overview-page-actions';
import { withRouter } from '../../../components/withRouter';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { UserRequestList } from '../../users/components';
import { USER_STATUS } from '../../../resources/userStatus';
import { SplitButton, Button } from '@progress/kendo-react-buttons';
import { getUserStatusColor } from '../../../utils/userStatusColors';
import styled from 'styled-components';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

const NoWrap = styled(Text).attrs({
  whiteSpace: 'pre',
  variant: 'body2'
})``;

const USER_STATUS_ACTIONS = {
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  LOGINASUSER: 'LogInAsUser',
  DELETE: 'Delete'
};

class UserOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleSplitButtonItemClick = this.handleSplitButtonItemClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleImpersonateClick = this.handleImpersonateClick.bind(this);
    this.handleImpersonateButtonClick = this.handleImpersonateButtonClick.bind(this);
    this.handleConfirmImpersonateDialogClose = this.handleConfirmImpersonateDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.userId) {
      this.props.loadUserOverviewPage({ userId: this.props.params.userId });
    }
  }

  render() {
    const { user, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <>
        <OverviewPage
          title={loading ? <Skeleton shape='text' style={{ width: 300, height: 28 }} /> : `${user?.name} ${user?.surname}`}
          headerActions={
            <>
              <SplitButton
                themeColor={'primary'}
                text={localizationService.toLanguageString('custom.edit')}
                items={this.renderSplitButtonItem(this.props.userIsAdministrator, user?.username, user?.status, localizationService)}
                onButtonClick={this.handleEditClick}
                onItemClick={this.handleSplitButtonItemClick}
              />
            </>
          }
          breadcrumbItems={[
            {
              id: 'users',
              text: localizationService.toLanguageString('User.users')
            },
            {
              id: 'user',
              text: localizationService.toLanguageString('User.user'),
              disabled: true
            }
          ]}
          onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
          onBackClick={this.handleBackClick}
          summary={
            <StackLayout rowGap='8px'>
              <ValueItem
                label={localizationService.toLanguageString('User.stampNumber')}
                labelWidth='160px'
                loading={loading}
              >
                {user?.stampNumber}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('User.role')}
                labelWidth='160px'
                loading={loading}
              >
                {this.renderRoles(user?.roles)}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('User.externalUser')}
                labelWidth='160px'
                loading={loading}
              >
                {localizationService.toLanguageString(`common.${user?.isExternal ? 'yes' : 'no'}`)}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('User.employee')}
                labelWidth='160px'
                loading={loading}
              >
                {localizationService.toLanguageString(`common.${user?.isEmployee ? 'yes' : 'no'}`)}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('User.email')}
                labelWidth='160px'
                loading={loading}
              >
                {user?.email}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('User.firstLogin')}
                labelWidth='160px'
                loading={loading}
              >
                {DateFormatter(user?.firstLogin, intlService)}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('User.lastActionDate')}
                labelWidth='160px'
                loading={loading}
              >
                {DateFormatter(user?.lastOperationDate, intlService)}
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('User.status')}
                labelWidth='160px'
                loading={loading}
              >
                <InlineBadge themeColor={getUserStatusColor(user?.status)}>
                  {user?.status ? localizationService.toLanguageString(`userStatus.${user.status}`) : '-'}
                </InlineBadge>
              </ValueItem>
            </StackLayout>
          }
          tabs={
            <TabStrip selected={this.props.selectedTab} onSelect={this.handleTabSelect}>
              <TabStripTab title={localizationService.toLanguageString('User.requests')} />
            </TabStrip>
          }
        >
          {this.props.selectedTab === 0 && <UserRequestList userId={this.props.params.userId} />}
        </OverviewPage>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('User.userDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
        {this.props.isConfirmImpersonateDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmImpersonateDialogClose}>
            <Text>{localizationService.toLanguageString('User.confirmImpersonateDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleImpersonateButtonClick}>{localizationService.toLanguageString('common.yes')}</Button>
              <Button onClick={this.handleConfirmImpersonateDialogClose}>{localizationService.toLanguageString('common.no')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderSplitButtonItem(isAdministrator, username, status, localizationService) {
    const items = [];
    if (username && isAdministrator) {
      items.push({ id: USER_STATUS_ACTIONS.LOGINASUSER, text: localizationService.toLanguageString('User.logInAs') });
    }
    switch (status) {
      case USER_STATUS.ACTIVE:
        items.push({ id: USER_STATUS_ACTIONS.DEACTIVATE, text: localizationService.toLanguageString('User.deactivate') });
        break;
      case USER_STATUS.DEACTIVATED:
        items.push({ id: USER_STATUS_ACTIONS.ACTIVATE, text: localizationService.toLanguageString('User.activate') });
        break;
      case USER_STATUS.UNAPPROVED:
        items.push({ id: USER_STATUS_ACTIONS.ACTIVATE, text: localizationService.toLanguageString('User.approve') });
        break;
    }
    items.push({ id: USER_STATUS_ACTIONS.DELETE, text: localizationService.toLanguageString('buttons.delete') });
    return items;
  }

  renderRoles = (props) => {
    const localizationService = provideLocalizationService(this);
    const roles = props?.map(element => localizationService.toLanguageString(`roleType.${element}`));

    return (
      <NoWrap>
        {roles?.join(', ')}
      </NoWrap>
    );
  }

  handleSplitButtonItemClick(e) {
    if (e.item.id === USER_STATUS_ACTIONS.ACTIVATE) {
      this.props.changeUserStatus({ newStatus: USER_STATUS.ACTIVE });
    }
    if (e.item.id === USER_STATUS_ACTIONS.DEACTIVATE) {
      this.props.changeUserStatus({ newStatus: USER_STATUS.DEACTIVATED });
    }
    if (e.item.id == USER_STATUS_ACTIONS.LOGINASUSER) {
      this.handleImpersonateClick(this.props.user.id);
    }
    if (e.item.id == USER_STATUS_ACTIONS.DELETE) {
      this.handleRemoveClick(this.props.user.id);
    }
  }

  handleEditClick() {
    this.props.navigate(`/users/${this.props.params.userId}`);
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'users') {
      this.props.navigate('/users');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleTabSelect(e) {
    this.props.selectUserOverviewPageTab({
      selected: e.selected
    });
  }

  handleRemoveClick(userId) {
    this.props.openUserOverviewConfirmDialog(userId);
  }

  handleConfirmDialogClose() {
    this.props.closeUserOverviewConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeUserOverviewConfirmDialog();
  }

  async handleImpersonateClick(userId) {
    await this.props.openImpersonateConfirmDialog({ userId: userId });
  }

  handleImpersonateButtonClick() {
    const localizationService = provideLocalizationService(this);
    this.props.impersonateAsUser({ localizationService });
  }

  handleConfirmImpersonateDialogClose() {
    this.props.closeImpersonateConfirmDialog();
  }
}

registerForLocalization(UserOverviewPage);

const mapStateToProps = state => ({
  user: state.userOverviewPage.user,
  loading: state.userOverviewPage.loading,
  selectedTab: state.userOverviewPage.selectedTab,
  isConfirmDialogVisible: state.userOverviewPage.isConfirmDialogVisible,
  isConfirmImpersonateDialogVisible: state.userOverviewPage.isConfirmImpersonateDialogVisible,
  userIsAdministrator: state.app.userIsAdministrator
});

const mapDispatchToProps = dispatch => ({
  loadUserOverviewPage: (payload) => dispatch(loadUserOverviewPage(payload)),
  selectUserOverviewPageTab: (payload) => dispatch(selectUserOverviewPageTab(payload)),
  changeUserStatus: (payload) => dispatch(changeUserStatus(payload)),
  openUserOverviewConfirmDialog: (payload) => dispatch(openUserOverviewConfirmDialog(payload)),
  closeUserOverviewConfirmDialog: (payload) => dispatch(closeUserOverviewConfirmDialog(payload)),
  openImpersonateConfirmDialog: (payload) => dispatch(openImpersonateConfirmDialog(payload)),
  closeImpersonateConfirmDialog: () => dispatch(closeImpersonateConfirmDialog()),
  impersonateAsUser: (payload) => dispatch(impersonateAsUser(payload)),
  removeUserOverviewConfirmDialog: (payload) => dispatch(removeUserOverviewConfirmDialog(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserOverviewPage));
