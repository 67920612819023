import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, provideIntlService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid, InlineBadge, DateFormatter, GridDateCell } from '../../shared/components';
import { loadApprovalRequestListPage, findApprovalRequests } from '../actions/approval-request-list-page-actions';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';
import { Colors, Text } from '../../../ui';
import { getStudyPlanCycleStudentApprovingUserStatusColor } from '../../../utils/studyPlanCycleStudentApprovingUserStatusColors';

const CellDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

class ApprovalRequestList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadApprovalRequestListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={this.props.loading}
        data={this.props.approvalRequests}
        skip={this.props.skip}
        take={this.props.take}
        total={this.props.total}
        sort={this.props.sorting}
        onPageChange={this.handlePageChange}
        onRowClick={this.handleRowClick}
        columns={[
          {
            field: 'cycle',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('approvalRequest.cycle'),
            sortable: false
          }, {
            field: 'resident',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('approvalRequest.resident'),
            sortable: false
          }, {
            field: 'requestDate',
            className: 'align-center',
            headerClassName: 'align-center',
            title: localizationService.toLanguageString('approvalRequest.requestDate'),
            minGridWidth: GridSize.medium,
            cell: GridDateCell
          }, {
            field: 'status',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('approvalRequest.status'),
            sortable: false,
            minGridWidth: GridSize.small,
            cell: this.renderStatus
          }
        ]}
      />
    );
  }

  renderStatus(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <CellDataContainer>
          {dataItem.status &&
            <InlineBadge themeColor={getStudyPlanCycleStudentApprovingUserStatusColor(dataItem.status)}>
              {localizationService.toLanguageString(`studyPlanCycleStudentApprovingUserStatus.${dataItem.status}`)}
            </InlineBadge>
          }
          {dataItem.requestDate &&
            <Text textColor={Colors.GRAY_50} variant='caption'>{DateFormatter(new Date(dataItem.requestDate), intlService)}</Text>
          }
        </CellDataContainer>
      </td>
    );
  }

  async handlePageChange(e) {
    await this.props.findApprovalRequests({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick({ dataItem }) {
    this.props.navigate(`/approval_requests/${dataItem.studyPlanCycleStudentId}/overview`);
  }
}

const mapStateToProps = state => ({
  loading: state.approvalRequestListPage.loading,
  approvalRequests: state.approvalRequestListPage.approvalRequests,
  skip: state.approvalRequestListPage.skip,
  take: state.approvalRequestListPage.take,
  total: state.approvalRequestListPage.total
});

const mapDispatchToProps = dispatch => ({
  loadApprovalRequestListPage: () => dispatch(loadApprovalRequestListPage()),
  findApprovalRequests: (payload) => dispatch(findApprovalRequests(payload))
});

registerForLocalization(ApprovalRequestList);
registerForIntl(ApprovalRequestList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ApprovalRequestList));