import * as React from 'react';
import { withRouter } from '../../../components/withRouter';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { StudentCycleOverview } from '../../shared/student-cycle';

class StudentCycleOverviewPage extends React.PureComponent {
  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <StudentCycleOverview
        preview
        studentId={this.props.params?.studentId}
        breadcrumbItems={[
          {
            id: 'students',
            text: localizationService.toLanguageString('student.residents')
          },
          {
            id: 'student',
            text: localizationService.toLanguageString('student.resident')
          },
          {
            id: 'studentCycle',
            text: localizationService.toLanguageString('student.cycle'),
            disabled: true
          }
        ]}
      />
    );
  }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

registerForLocalization(StudentCycleOverviewPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCycleOverviewPage));