import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { OverviewPage, StackLayout, Text } from '../../../ui';
import { ValueItem, InlineBadge } from '../../shared/components';
import { loadMyStudiesStudentOverviewPage } from '../actions';
import { MyStudiesCycleList } from '.';
import { withRouter } from '../../../components/withRouter';
import { getStudentStatusColor } from '../../../utils/studentStatusColors';

const Tabs = styled(TabStrip)`
  & > .k-content.k-state-active {
    display: none;
  }
  & > .k-tabstrip-items-wrapper .k-item::after {
    border-bottom-width: 2px;
  }
`;

const ErrorContainer = styled.div`
  margin: 16px;
`;

const MessageItem = styled.div`
  margin: 8px;
`;

class MyStudiesStudentOverviewPage extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.studentId) {
      this.props.loadMyStudiesStudentOverviewPage({ studentId: this.props.studentId });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studentId !== this.props.studentId) {
      this.props.loadMyStudiesStudentOverviewPage({ studentId: this.props.studentId });
    }
  }

  render() {
    const { student, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      this.props.studentId ?
        <OverviewPage
          title={localizationService.toLanguageString('myStudies.myStudies')}
          hideBackButton={true}
          summary={
            <StackLayout rowGap='8px'>
              <ValueItem
                label={localizationService.toLanguageString('myStudies.studyProgramShorthand')}
                labelWidth='108px'
                loading={loading}
              >
                <Text variant='body2' overflowWrap={true}>
                  {`${student?.number} - ${student?.studyProgram}`}
                </Text>
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('myStudies.studyStatus')}
                labelWidth='108px'
                loading={loading}
              >
                <InlineBadge themeColor={getStudentStatusColor(student?.status)}>
                  {localizationService.toLanguageString(`studentStatus.${student?.status}`)}
                </InlineBadge>
              </ValueItem>
              <ValueItem
                label={localizationService.toLanguageString('myStudies.studyYearShorthand')}
                labelWidth='108px'
                loading={loading}
              >
                {student?.studyYear}
              </ValueItem>
            </StackLayout>
          }
          tabs={
            <Tabs selected={0}>
              <TabStripTab title={localizationService.toLanguageString('myStudies.cycles')} />
            </Tabs>
          }
        >
          <MyStudiesCycleList />
        </OverviewPage>
        : <ErrorContainer>
          <h2>{localizationService.toLanguageString('myStudies.noStudiesHeader')}</h2>
          <MessageItem>{localizationService.toLanguageString('myStudies.noStudiesMessage')}</MessageItem>
        </ErrorContainer>
    );
  }
}

registerForLocalization(MyStudiesStudentOverviewPage);

const mapStateToProps = state => ({
  student: state.myStudiesStudentOverviewPage.student,
  loading: state.myStudiesStudentOverviewPage.loading,
  studentId: state.app.currentStudies?.studentId
});

const mapDispatchToProps = dispatch => ({
  loadMyStudiesStudentOverviewPage: (payload) => dispatch(loadMyStudiesStudentOverviewPage(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyStudiesStudentOverviewPage));
