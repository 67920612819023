import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { ComboBox, DatePicker, FormPage, NumericBox, Text, WrapLayout } from '../../../ui';
import { periodsIntersect } from '../../../utils/dateUtils';
import { ValueItem } from '../../shared/components';
import {
  loadStudyPlanCycleStudentFormPage,
  loadStudyPlanCycleStudentFormPageAvailableDepartments,
  loadStudyPlanCycleDepartmentStudents,
  saveStudyPlanCycleStudentFormPage
} from '../actions';

class StudyPlanCycleStudentSelectAddPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    await this.props.loadStudyPlanCycleStudentFormPage({
      studyPlanId: this.props.params.studyPlanId,
      studyPlanCycleId: this.props.params.studyPlanCycleId,
      studyPlanCycleStudentId: this.props.params.studyPlanCycleStudentId,
      studyPlanCycleDepartmentStudentId: this.props.params.studyPlanCycleDepartmentStudentId,
      studyPlanCycleStudentIds: this.props.location.state?.ids,
      localizationService
    });
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={this.props.studyPlanCycleStudentDetails?.studentFullName}
        breadcrumbItems={[
          {
            id: 'study-plans',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlans')
          },
          {
            id: 'study-plan',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlan')
          },
          {
            id: 'study-plan-cycle',
            text: localizationService.toLanguageString('StudyPlanCycle.studyPlanCycle')
          },
          {
            id: 'study-plan-cycle-student-select-add',
            text: localizationService.toLanguageString('StudyPlanCycleStudent.student'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.studyPlanCycleStudentDetails}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <>
            <ValueItem
              label={localizationService.toLanguageString('StudyPlanCycleStudent.cycle')}
              labelWidth='40px'
              loading={this.props.loading}
            >
              <Text variant='body2'>{this.props.studyPlanCycleStudentDetails?.cycleName}</Text>
            </ValueItem>
            <WrapLayout width='340px'>
              <Field
                name='dateFrom'
                label={localizationService.toLanguageString('StudyPlanCycleStudent.dateFrom')}
                component={DatePicker}
                width='160px'
              />
              <Field
                name='dateTo'
                label={localizationService.toLanguageString('StudyPlanCycleStudent.dateTo')}
                component={DatePicker}
                width='160px'
              />
            </WrapLayout>
            <Field
              name='department'
              label={localizationService.toLanguageString('StudyPlanCycleStudent.department')}
              component={ComboBox}
              loading={this.props.availableDepartmentsLoading}
              data={this.props.availableDepartments}
              onFilter={this.props.loadStudyPlanCycleStudentFormPageAvailableDepartments}
              onChange={this.handleDepartmentChange}
              textField='departmentName'
              dataItemKey='id'
              width='334px'
            />
            <Field
              name='credits'
              label={localizationService.toLanguageString('StudyPlanCycleStudent.credits')}
              component={NumericBox}
              min={1}
              width='160px'
            />
            <Field
              name='year'
              label={localizationService.toLanguageString('StudyPlanCycleStudent.year')}
              component={ComboBox}
              data={this.props.availableStudyYears}
              textField='name'
              dataItemKey='id'
              width='160px'
            />
          </>
        )}
      />
    );
  }

  handleBreadcrumbItemClick(e) {
    switch (e.id) {
      case 'study-plans':
        this.props.navigate('/study_plans');
        break;
      case 'study-plan':
        this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/overview`);
        break;
      case 'study-plan-cycle':
        this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/cycles/${this.props.params.studyPlanCycleId}/overview`);
        break;
      default:
        break;
    }
  }

  handleDepartmentChange(e) {
    if (e.value) {
      this.props.loadStudyPlanCycleDepartmentStudents({
        studyPlanCycleDepartmentId: e.value.id
      });
    }
  }

  handleSubmit(values) {
    this.props.saveStudyPlanCycleStudentFormPage(values);
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleValidate(values, localizationService) {
    const errors = {};
    if (!values.dateFrom) {
      errors.dateFrom = localizationService.toLanguageString('validation.required');
    }
    if (!values.dateTo) {
      errors.dateTo = localizationService.toLanguageString('validation.required');
    }
    if (values.dateFrom && values.dateTo && values.dateFrom > values.dateTo) {
      errors.dateFrom = localizationService.toLanguageString('validation.impossibleSequence');
      errors.dateTo = localizationService.toLanguageString('validation.impossibleSequence');
    }
    if (values.dateFrom && values.dateTo && this.props.studyPlanCycleStudentDepartments) {
      const studyPlanCycleDepartmentStudentId = this.props.studyPlanCycleStudentDetails?.id;
      const hasCollisions = this.props.studyPlanCycleStudentDepartments.some(item => studyPlanCycleDepartmentStudentId != item.id && periodsIntersect(values.dateFrom, values.dateTo, item.dateFrom, item.dateTo));
      if (hasCollisions) {
        errors.dateFrom = localizationService.toLanguageString('StudyPlanCycleStudent.studentCyclePeriodCollision');
        errors.dateTo = localizationService.toLanguageString('StudyPlanCycleStudent.studentCyclePeriodCollision');
      }
    }
    if (values.department) {
      if (values.credits > values.department.credits) {
        errors.credits = localizationService.toLanguageString('StudyPlanCycleStudent.exceedingDepartmentCreditsValidation');
      }
      const studyPlanCycleStudentIds = this.props.studyPlanCycleStudentIds?.map(element => element.id) ?? (this.props.studyPlanCycleStudentDetails ? [this.props.studyPlanCycleStudentDetails?.id] : null);
      if (studyPlanCycleStudentIds?.length > 0 && values.dateFrom && values.dateTo) {
        const allStudents = [...studyPlanCycleStudentIds];
        const filteredStudents = this.props.departmentStudents?.filter(student => periodsIntersect(values.dateFrom, values.dateTo, student.dateFrom, student.dateTo))?.map(student => student.studyPlanCycleStudentId);
        if (filteredStudents?.length > 0) {
          filteredStudents.forEach(id => {
            if (!allStudents.includes(id)) {
              allStudents.push(id);
            }
          });
        }
        if (allStudents.length > values.department.maxStudentCount) {
          errors.department = localizationService.toLanguageString('StudyPlanCycleStudent.exceedingDepartmentStudentCountValidation');
        }
      }
    }
    if (values.credits < 0) {
      errors.credits = localizationService.toLanguageString('validation.negativeValue');
    }
    if (!values.credits) {
      errors.credits = localizationService.toLanguageString('validation.required');
    }
    if (!values.year) {
      errors.year = localizationService.toLanguageString('validation.required');
    }
    return errors;
  }
}

registerForLocalization(StudyPlanCycleStudentSelectAddPage);

const mapStateToProps = state => ({
  loading: state.studyPlanCycleStudentFormPage.loading,
  studyPlanCycleStudentDetails: state.studyPlanCycleStudentFormPage.studyPlanCycleStudentDetails,
  studyPlanCycleStudentIds: state.studyPlanCycleStudentFormPage.studyPlanCycleStudentIds,
  studyPlanCycleStudentDepartments: state.studyPlanCycleStudentFormPage.studyPlanCycleStudentDepartments,
  departmentStudents: state.studyPlanCycleStudentFormPage.departmentStudents,
  availableStudyYears: state.studyPlanCycleStudentFormPage.availableStudyYears,
  availableDepartments: state.studyPlanCycleStudentFormPage.availableDepartments,
  validationResult: state.studyPlanCycleStudentFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanCycleStudentFormPage: (payload) => dispatch(loadStudyPlanCycleStudentFormPage(payload)),
  loadStudyPlanCycleStudentFormPageAvailableDepartments: (payload) => dispatch(loadStudyPlanCycleStudentFormPageAvailableDepartments(payload)),
  loadStudyPlanCycleDepartmentStudents: (payload) => dispatch(loadStudyPlanCycleDepartmentStudents(payload)),
  saveStudyPlanCycleStudentFormPage: (payload) => dispatch(saveStudyPlanCycleStudentFormPage(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleStudentSelectAddPage));