import { pagingSettings } from '../../../utils/GridPagingUtils';
import CycleListPageActionTypes from '../actions/cycle-list-page-action-types';

const initialState = {
  loading: false,
  cycles: null,
  cyclesCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: {
    showInvalid: false
  },
  usersStudyPrograms: [],

  filterStudyProgramsLoading: false,
  filterStudyProgramsCancelToken: null,
  filterStudyPrograms: null,
  isConfirmDialogVisible: false,
  selectedCycleId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleListPageActionTypes.LOAD_CYCLE_LIST_START:
      return {
        ...state,
        loading: true
      };
    case CycleListPageActionTypes.LOAD_CYCLE_LIST_END:
      return {
        ...state,
        loading: false,
        cycles: action.payload.cycles,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take,
        usersStudyPrograms: action.payload.usersStudyPrograms
      };
    case CycleListPageActionTypes.FILTER_CYCLE_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case CycleListPageActionTypes.SORT_CYCLE_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case CycleListPageActionTypes.SEARCH_CYCLE_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoading: true
      };
    case CycleListPageActionTypes.SEARCH_CYCLE_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoading: false,
        filterStudyPrograms: action.payload.studyPrograms
      };
    case CycleListPageActionTypes.TOGGLE_CYCLE_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCycleId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};