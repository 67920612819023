import axios from 'axios';
import { sortingToString } from '../../../utils/objectUtils';
import { NotificationsListPageActionTypes } from '.';
import notificationsListPageActionTypes from './notifications-list-page-action-types';

export const loadNotifications = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: NotificationsListPageActionTypes.LOAD_NOTIFICATIONS_LIST_START });
  const state = getState().notificationsListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToString(state.sorting),
    keyword: state.filter?.keyword
  };
  try {
    state.notificationsListPageCancelToken?.cancel();
    state.notificationsListPageCancelToken = axios.CancelToken.source();
    const notifications = await api.get(`api/notifications`, query, state.notificationsListPageCancelToken.token);
    dispatch({
      type: NotificationsListPageActionTypes.LOAD_NOTIFICATIONS_LIST_END,
      payload: {
        notifications: notifications ? notifications.data : [],
        total: notifications ? notifications.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: NotificationsListPageActionTypes.LOAD_NOTIFICATIONS_LIST_END });
      throw error;
    }
  }
};

export const filterNotifications = (payload) => (dispatch) => {
  dispatch({
    type: NotificationsListPageActionTypes.FILTER_NOTIFICATIONS_LIST,
    payload
  });
  dispatch(loadNotifications());
};

export const sortNotifications = (payload) => (dispatch) => {
  dispatch({
    type: NotificationsListPageActionTypes.SORT_NOTIFICATIONS_LIST,
    payload
  });
  dispatch(loadNotifications());
};

export const clearNotificationsFilter = () => (dispatch) => {
  dispatch({
    type: NotificationsListPageActionTypes.FILTER_NOTIFICATIONS_LIST,
    payload: {}
  });
  dispatch(loadNotifications());
};

export const openNotificationDeleteConfirmDialog = (payload) => (dispatch, getState) => {
  dispatch({
    type: NotificationsListPageActionTypes.OPEN_NOTIFICATIONS_DELETE_CONFIRM_DIALOG,
    payload
  });
};

export const closeNotificationDeleteConfirmDialog = () => (dispatch, getState) => {
  dispatch({ type: NotificationsListPageActionTypes.CLOSE_NOTIFICATIONS_DELETE_CONFIRM_DIALOG });
};

export const deleteNotification = () => async(dispatch, getState, { api }) => {
  const state = getState().notificationsListPage;
  if (state.selectedNotificationId > 0) {
    dispatch({ type: notificationsListPageActionTypes.LOAD_NOTIFICATIONS_LIST_START });
    try {
      await api.remove(`api/notifications/${state.selectedNotificationId}`);
      dispatch(loadNotifications());
    } catch (error) {
      dispatch({ type: NotificationsListPageActionTypes.LOAD_NOTIFICATIONS_LIST_END });
    }
  }
  dispatch({ type: NotificationsListPageActionTypes.CLOSE_NOTIFICATIONS_DELETE_CONFIRM_DIALOG });
};