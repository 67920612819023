import { StudyPlanCycleStudentDepartmentsListActionTypes } from '..';

const initialState = {
  loading: false,
  studyPlanId: null,
  studyPlanCycleId: null,
  studyPlanCycleStudentId: null,
  studentDepartments: [],
  isConfirmDialogVisible: false,
  deleteStudyPlanCycleDepartmentStudentId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleStudentDepartmentsListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENTS_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleStudentDepartmentsListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENTS_LIST_END:
      return {
        ...state,
        loading: false,
        studyPlanId: action.payload?.studyPlanId,
        studyPlanCycleId: action.payload?.studyPlanCycleId,
        studyPlanCycleStudentId: action.payload?.studyPlanCycleStudentId,
        studentDepartments: action.payload?.studentDepartments ?? []
      };
    case StudyPlanCycleStudentDepartmentsListActionTypes.OPEN_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DELETE_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmDialogVisible: true,
        deleteStudyPlanCycleDepartmentStudentId: action.payload?.studyPlanCycleDepartmentStudentId
      };
    case StudyPlanCycleStudentDepartmentsListActionTypes.CLOSE_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DELETE_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmDialogVisible: false,
        deleteStudyPlanCycleDepartmentStudentId: null
      };
    case StudyPlanCycleStudentDepartmentsListActionTypes.DELETE_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT:
      return {
        ...state,
        isConfirmDialogVisible: false,
        deleteStudyPlanCycleDepartmentStudentId: null,
        studentDepartments: action.payload?.studentDepartments ?? []
      };
    default:
      return state;
  }
};