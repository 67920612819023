import { ROLE_PERMISSIONS as Permissions } from '../../resources/rolePermissions';

export const MENU_ITEMS = [
  { id: 'study-programs', name: 'studyPrograms', iconSvg: 'studyProgram-icon', route: '/study_programs', childs: ['/study_program'], permission: Permissions.STUDY_PROGRAMS_READ },
  { id: 'cycles', name: 'cycles', iconSvg: 'cycle-icon', route: '/cycles', childs: ['/cycle'], permission: Permissions.CYCLES_READ },
  { id: 'study-plans', name: 'studyPlans', iconSvg: 'studyPlan-icon', route: '/study_plans', childs: ['/study_plan'], permission: Permissions.STUDY_PLANS_READ },
  { id: 'cycle-plans', name: 'cyclePlans', iconSvg: 'cyclePlans-icon', route: '/cycle_plans', childs: ['/cycle_plans'], permission: Permissions.STUDY_PLANS_READ, minwidth: 1024 },
  { id: 'departments', name: 'departments', iconSvg: 'department-icon', route: '/departments', childs: ['/department'], permission: Permissions.DEPARTMENTS_READ },
  { id: 'students', name: 'students', iconSvg: 'studentsList-icon', route: '/students', childs: ['/student'], permission: Permissions.STUDENTS_READ },
  { id: 'theoretical-parts', name: 'theoreticalParts', iconSvg: 'theoreticalPart-icon', route: '/theoretical_parts', childs: ['/theoretical_part'], permission: Permissions.THEORETICAL_PARTS_READ },
  { id: 'my-studies', name: 'myStudies', iconSvg: 'myStudies-icon', route: '/my_studies', childs: ['/my_studies'], permission: Permissions.MY_STUDIES_READ },
  { id: 'my-competencies', name: 'myCompetencies', iconSvg: 'studyProgram-icon', route: '/my_competencies', childs: ['/my_competencies'], permission: Permissions.MY_COMPETENCIES_READ },
  { id: 'requests-group', name: 'requests', iconSvg: 'myResidents-icon', childs: ['/managed_students', '/my_surveys', '/competency_requests'], permission: Permissions.MY_STUDENTS_READ, ['data-expanded']: false },
  { id: 'evaluate-cycle', name: 'evaluateCycle', route: '/managed_students', childs: ['/managed_students'], permission: Permissions.MANAGED_STUDENTS_READ, parentId: 'requests-group' },
  { id: 'approval-requests', name: 'approvalRequests', route: '/approval_requests', childs: ['/approval_requests'], permission: Permissions.APPROVAL_REQUESTS_READ, parentId: 'requests-group' },
  { id: 'my-surveys', name: 'mySurveys', route: '/my_surveys', childs: ['/my_surveys'], permission: Permissions.MY_SURVEYS_READ, parentId: 'requests-group' },
  { id: 'competency-requests', name: 'competencyRequests', route: '/competency_requests', childs: ['/competency_requests'], permission: Permissions.COMPETENCY_REQUESTS_READ, parentId: 'requests-group' },
  { id: 'competencies-group', name: 'competencies', iconSvg: 'competencies-icon', childs: ['/competencies', '/subcompetencies', '/surveys'], permission: Permissions.COMPETENCIES_READ, ['data-expanded']: false },
  { id: 'subcompetencies', name: 'subcompetencies', route: '/subcompetencies', childs: ['/subcompetencies'], permission: Permissions.COMPETENCIES_READ, parentId: 'competencies-group' },
  { id: 'competencies', name: 'competenciesList', route: '/competencies', childs: ['/competencies'], permission: Permissions.COMPETENCIES_READ, parentId: 'competencies-group' },
  { id: 'surveys', name: 'surveys', route: '/surveys', childs: ['/surveys'], permission: Permissions.COMPETENCIES_READ, parentId: 'competencies-group' },
  { id: 'users', name: 'users', iconSvg: 'user-icon', route: '/users', childs: ['/user'], permission: Permissions.USERS_READ },
  { id: 'notifications', name: 'notifications', iconSvg: 'notification-icon', route: '/notifications', childs: ['/notification'], permission: Permissions.NOTIFICATIONS_WRITE }
];