import * as React from 'react';
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { Error, Label } from '@progress/kendo-react-labels';
import styled, { css } from 'styled-components';

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  Undo,
  Redo,
  Link,
  Unlink
} = EditorTools;

const Container = styled.fieldset`
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
`;

const TextEditor = (props) => {
  const { valid, validationMessage, width, label, id, fieldRef, value, ...rest } = props;
  const editor = <Editor
    tools={[
      [Bold, Italic, Underline, Strikethrough],
      [Subscript, Superscript],
      [AlignLeft, AlignCenter, AlignRight, AlignJustify],
      [Indent, Outdent],
      [Undo, Redo],
      [Link, Unlink]
    ]}
    label={label}
    ref={fieldRef}
    id={label ? id : null}
    valid={valid}
    validationMessage={validationMessage}
    value={value ?? ''}
    {...rest}
  />;
  return (
    <Container width={width} className={'k-form-fieldset'}>
      {label
        ?
        <>
          <Label>{label}</Label>
          {editor}
        </>
        :
        editor}
      {!valid && <Error>{validationMessage}</Error>}
    </Container>
  );
};

export default TextEditor;