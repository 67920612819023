import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, ComboBox, WrapLayout, TextBox } from '../../../ui';
import { filterCompetencies, clearCompetenciesFilter, searchCompetenciesFilterStudyPrograms } from '../actions/competency-list-page-actions';

class CompetencyListFilter extends React.PureComponent {
  render() {
    const {
      filter,
      clearCompetenciesFilter,
      filterCompetencies,
      filterCompetenciesComboBox,
      filterCompetenciesComboBoxLoading,
      searchCompetenciesFilterStudyPrograms
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterCompetencies}
        onClear={clearCompetenciesFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <Field
              name='studyProgram'
              width='340px'
              component={ComboBox}
              label={localizationService.toLanguageString('studyProgram.studyProgram')}
              textField='name'
              dataItemKey='id'
              loading={filterCompetenciesComboBoxLoading}
              data={filterCompetenciesComboBox}
              onFilter={searchCompetenciesFilterStudyPrograms}
            />
            <Field
              name='keyword'
              width='340px'
              component={TextBox}
              label={localizationService.toLanguageString('custom.search')}
            />
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.competencyListPage.filter,
  filterCompetenciesComboBoxLoading: state.competencyListPage.filterCompetenciesComboBoxLoading,
  filterCompetenciesComboBox: state.competencyListPage.filterCompetenciesComboBox
});

const mapDispatchToProps = dispatch => ({
  filterCompetencies: (payload) => dispatch(filterCompetencies(payload)),
  clearCompetenciesFilter: () => dispatch(clearCompetenciesFilter()),
  searchCompetenciesFilterStudyPrograms: (payload) => dispatch(searchCompetenciesFilterStudyPrograms(payload))
});

registerForLocalization(CompetencyListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(CompetencyListFilter);