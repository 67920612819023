import StudyPlanCycleOverviewPageActionTypes from './study-plan-cycle-overview-page-action-types';
import axios from 'axios';

export const loadStudyPlanCycleOverviewPage = ({ studyPlanId, studyPlanCycleId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_OVERVIEW_PAGE_START });
  const state = getState().studyPlanCycleOverviewPage;
  try {
    state.studyPlanCycleCancelToken?.cancel();
    state.studyPlanCycleCancelToken = axios.CancelToken.source();
    const studyPlanCycle = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}`, null, state.studyPlanCycleCancelToken.token);
    dispatch({
      type: StudyPlanCycleOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_OVERVIEW_PAGE_END,
      payload: studyPlanCycle
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const selectStudyPlanCycleOverviewPageTab = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyPlanCycleOverviewPageActionTypes.SELECT_STUDY_PLAN_CYCLE_OVERVIEW_PAGE_TAB,
    payload: {
      selectedTab: payload.selectedTab
    }
  });
};