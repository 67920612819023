import MyStudiesSurveyFormPageActionTypes from '../actions/my-studies-survey-form-page-action-types';

const initialState = {
  loading: false,
  myStudiesSurveyFormCancelToken: null,
  survey: null,
  saveSuccess: false,
  validationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MyStudiesSurveyFormPageActionTypes.LOAD_SURVEY_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case MyStudiesSurveyFormPageActionTypes.LOAD_SURVEY_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        survey: action.payload ? action.payload.survey : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    default:
      return state;
  }
};