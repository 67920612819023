import StudentCompetencySurveyOverviewPageActionTypes from '../actions/student-competency-survey-overview-page-action-types';

const initialState = {
  loading: false,
  studentCompetencySurvey: null,
  studentCompetencySurveyCancelToken: null,
  selectedTab: 0,
  isRequestEvaluationDialogVisible: false,
  isQrCodeDialogVisible: false,
  shouldShowQrCode: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCompetencySurveyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case StudentCompetencySurveyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        studentCompetencySurvey: action.payload?.studentCompetencySurvey
      };
    case StudentCompetencySurveyOverviewPageActionTypes.SELECT_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    case StudentCompetencySurveyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_REQUEST_EVALUATION_DIALOG:
      return {
        ...state,
        isRequestEvaluationDialogVisible: !state.isRequestEvaluationDialogVisible
      };
    case StudentCompetencySurveyOverviewPageActionTypes.CLEAR_STUDENT_COMPETENCY_SURVEY_OVERVIEW_PAGE:
      return initialState;
    case StudentCompetencySurveyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_SURVEY_QR_CODE_DIALOG:
      return {
        ...state,
        isQrCodeDialogVisible: action.payload.isQrCodeDialogVisible
      };
    case StudentCompetencySurveyOverviewPageActionTypes.SET_STUDENT_COMPETENCY_SURVEY_SHOW_QR_CODE_SETTING:
      return {
        ...state,
        shouldShowQrCode: action.payload.shouldShowQrCode
      };
    default:
      return state;
  }
};