import ManagedStudentListPageActionTypes from './managed-student-list-page-action-types';
import axios from 'axios';
import { CYCLE_STUDENT_STATUS } from '../../../resources/studyPlanCycleDepartmentStudentStatus';

export const loadManagedStudentListPage = (payload) => async(dispatch, getState) => {
  dispatch(findManagedStudents());
};

export const findManagedStudents = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_START });
  const state = getState().managedStudentListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    status: state.filter?.cycleStudentStatus?.id,
    extendedSearch: true,
    studentUserId: state.filter?.user?.id,
    keyword: state.filter?.keyword
  };
  try {
    state.managedStudentsCancelToken?.cancel();
    state.managedStudentsCancelToken = axios.CancelToken.source();
    const managedStudents = await api.get(`api/managed_students`, query, state.managedStudentsCancelToken.token);
    const managedStudentsList = managedStudents?.data?.map(student => ({
      ...student,
      loading: false,
      studentDepartments: []
    }));
    dispatch({
      type: ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_END,
      payload: {
        managedStudents: managedStudents ? managedStudentsList : [],
        total: managedStudents ? managedStudents.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_END });
      throw error;
    }
  }
};

export const filterManagedStudents = (payload) => (dispatch) => {
  dispatch({
    type: ManagedStudentListPageActionTypes.FILTER_MANAGED_STUDENT_LIST,
    payload: payload
  });
  dispatch(findManagedStudents());
};

export const clearManagedStudentsFilter = () => (dispatch) => {
  dispatch({
    type: ManagedStudentListPageActionTypes.FILTER_MANAGED_STUDENT_LIST,
    payload: {}
  });
  dispatch(findManagedStudents());
};

export const loadCycleStudentStatuses = (localizationService) => async(dispatch, getState) => {
  const types = Object.keys(CYCLE_STUDENT_STATUS)
    .map(key => ({
      id: CYCLE_STUDENT_STATUS[key],
      name: localizationService.toLanguageString('studyPlanCycleDepartmentStudentStatus.' + CYCLE_STUDENT_STATUS[key])
    }));
  dispatch({
    type: ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_STATUSES,
    payload: {
      cycleStudentStatuses: types
    }
  });
};

export const toggleManagedStudentExpand = (payload) => (dispatch, getState) => {
  const state = getState().managedStudentListPage;
  const managedStudents = state.managedStudents?.map((item) => {
    if (item.id === payload.id) {
      item.expanded = !item.expanded;

      if (item.expanded) {
        item.loading = true;
        dispatch(loadExpandedStudentDepartments({ id: item.id }));
      }
    }
    return item;
  });
  dispatch({
    type: ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_END,
    payload: {
      managedStudents,
      total: state.total,
      skip: state.skip,
      take: state.take
    }
  });
};

export const loadExpandedStudentDepartments = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_START });
  const { id } = payload;
  try {
    let managedStudentsCancelToken = getState().managedStudentListPage.managedStudentsCancelToken;
    managedStudentsCancelToken?.cancel();
    managedStudentsCancelToken = axios.CancelToken.source();
    const studentDepartments = await api.get(`api/managed_students/${id}/departments`, null, managedStudentsCancelToken.token);
    const state = getState().managedStudentListPage;
    const managedStudents = state.managedStudents?.map(item => {
      if (item.id === id) {
        item.loading = false;
        item.studentDepartments = studentDepartments;
      }
      return item;
    });
    dispatch({
      type: ManagedStudentListPageActionTypes.LOAD_MANAGED_STUDENT_LIST_END,
      payload: {
        managedStudents,
        total: state.total,
        skip: state.skip,
        take: state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};