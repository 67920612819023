import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, provideIntlService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid, InlineBadge, DateFormatter } from '../../shared/components';
import { loadCompetencyRequestListPage, findCompetencyRequests, selectCompetencyRequestList, selectAllCompetencyRequestList, initMultiSign, toggleGrantConfirmDialog } from '../actions/competency-request-list-page-actions';
import { withRouter } from '../../../components/withRouter';
import { getStudentCompetencyStatusColor } from '../../../utils/studentCompetencyStatusColors';
import styled from 'styled-components';
import { Colors, Text } from '../../../ui';
import { Checkbox } from '@progress/kendo-react-inputs';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { STUDENT_COMPETENCY_STATUS } from '../../../resources/studentCompetencyStatus';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

const CellDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

class CompetencyRequestList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.renderCompetency = this.renderCompetency.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSignClick = this.handleSignClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCompetencyRequestListPage();
  }

  componentWillUnmount() {
    if (this.props.selectedAll) {
      this.props.selectAllCompetencyRequestList();
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={this.props.loading}
          data={this.props.competencyRequests}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          sort={this.props.sorting}
          onPageChange={this.handlePageChange}
          onRowClick={this.handleRowClick}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple'
          }}
          dataItemKey='id'
          selectedField='isSelected'
          onSelectionChange={this.handleSelect}
          columns={[
            {
              field: 'isSelected',
              width: '50px',
              cell: this.renderSelected,
              headerCell: () => (
                <span>
                  <Checkbox checked={this.props.selectedAll} onClick={this.handleSelectAll} />
                </span>
              )
            }, {
              field: 'competency',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competencyRequest.competency'),
              sortable: false,
              cell: this.renderCompetency
            }, {
              field: 'competencyAbbreviation',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competencyRequest.code'),
              sortable: false
            }, {
              field: 'resident',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competencyRequest.resident'),
              sortable: false
            }, {
              field: 'status',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('competencyRequest.status'),
              sortable: false,
              minGridWidth: GridSize.small,
              cell: this.renderStatus
            }
          ]}
        >
          <GridToolbar>
            <Button themeColor='primary' disabled={!this.props.selectedIds || this.props.selectedIds.length == 0} onClick={this.props.toggleGrantConfirmDialog}>
              {localizationService.toLanguageString('competencyRequest.sign')}
            </Button>
          </GridToolbar>
        </Grid>
        {this.props.isGrantConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.props.toggleGrantConfirmDialog}>
            <Text>{localizationService.toLanguageString('competencyRequest.competenciesGrantMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleSignClick}>
                {localizationService.toLanguageString('buttons.yes')}
              </Button>
              <Button onClick={this.props.toggleGrantConfirmDialog}>{localizationService.toLanguageString('buttons.no')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  handleRowClick(e) {
    this.props.navigate(`/competency_requests/${e.dataItem.id}`);
  }

  renderCompetency(e) {
    const { dataItem, className } = e;
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <CellDataContainer>
          {dataItem.competency}
          <Text textColor={Colors.GRAY_50} variant='caption'>{dataItem.studyProgram}</Text>
        </CellDataContainer>
      </td>
    );
  }

  renderStatus(e) {
    const { className, dataItem } = e;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <td className={className} onClick={() => this.handleRowClick(e)}>
        <CellDataContainer>
          {dataItem.status &&
            <InlineBadge themeColor={getStudentCompetencyStatusColor(dataItem.status)}>
              {localizationService.toLanguageString(`studentCompetencyStatus.${dataItem.status}`)}
            </InlineBadge>
          }
          {dataItem.requestDate &&
            <Text textColor={Colors.GRAY_50} variant='caption'>{DateFormatter(new Date(dataItem.requestDate), intlService)}</Text>
          }
        </CellDataContainer>
      </td>
    );
  }

  renderSelected(e) {
    return (
      <td role='gridcell' onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={e.dataItem.isSelected}
          onChange={() => e.selectionChange(e)}
          disabled={e.dataItem.status !== STUDENT_COMPETENCY_STATUS.TO_BE_APPROVED && e.dataItem.status !== STUDENT_COMPETENCY_STATUS.TO_BE_SIGNED}
        />
      </td>
    );
  }

  async handlePageChange(e) {
    await this.props.findCompetencyRequests({ skip: e.page.skip, take: e.page.take });
  }

  handleSelect(e) {
    if (e.dataItem) {
      this.props.selectCompetencyRequestList(e.dataItem);
    }
  }

  handleSelectAll() {
    this.props.selectAllCompetencyRequestList();
  }

  async handleSignClick() {
    this.props.toggleGrantConfirmDialog();
    await this.props.initMultiSign();
    if (this.props.signingRedirectUrl) {
      window.open(this.props.signingRedirectUrl, '_self');
    }
  }
}

const mapStateToProps = state => ({
  loading: state.competencyRequestListPage.loading,
  competencyRequests: state.competencyRequestListPage.competencyRequests,
  skip: state.competencyRequestListPage.skip,
  take: state.competencyRequestListPage.take,
  total: state.competencyRequestListPage.total,
  selectedAll: state.competencyRequestListPage.selectedAll,
  selectedIds: state.competencyRequestListPage.selectedIds,
  signingRedirectUrl: state.competencyRequestListPage.signingRedirectUrl,
  isGrantConfirmDialogVisible: state.competencyRequestListPage.isGrantConfirmDialogVisible
});

const mapDispatchToProps = dispatch => ({
  loadCompetencyRequestListPage: () => dispatch(loadCompetencyRequestListPage()),
  findCompetencyRequests: (payload) => dispatch(findCompetencyRequests(payload)),
  selectCompetencyRequestList: (payload) => dispatch(selectCompetencyRequestList(payload)),
  selectAllCompetencyRequestList: () => dispatch(selectAllCompetencyRequestList()),
  initMultiSign: () => dispatch(initMultiSign()),
  toggleGrantConfirmDialog: () => dispatch(toggleGrantConfirmDialog())
});

registerForLocalization(CompetencyRequestList);
registerForIntl(CompetencyRequestList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompetencyRequestList));