export const saveToStorage = (key, value) => {
  const jsonValue = JSON.stringify(value);
  localStorage.setItem(key, jsonValue);
};

export const loadFromStorage = (key) => {
  const value = localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }

  return null;
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

export const saveToSessionStorage = (key, value) => {
  const jsonValue = JSON.stringify(value);
  sessionStorage.setItem(key, jsonValue);
};

export const loadFromSessionStorage = (key) => {
  const value = sessionStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }

  return null;
};

export const removeFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export default {
  saveToStorage,
  loadFromStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
  loadFromSessionStorage,
  removeFromSessionStorage
};