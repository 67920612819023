import * as React from 'react';
import { ListPage } from '../../../ui';
import NotificationsList from './notifications-list';
import NotificationsListFilter from './notifications-list-filter';

class NotificationsListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<NotificationsListFilter/>}>
        <NotificationsList/>
      </ListPage>
    );
  }
}

export default NotificationsListPage;