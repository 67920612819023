import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridSize } from '../../shared/components';
import { Text } from '../../../ui';
import { loadCycleCaseList, openCycleCaseConfirmDialog, closeCycleCaseConfirmDialog, removeCycleCaseConfirmDialog } from '../actions/cycle-case-list-actions';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const FooterCell = styled.td`
  text-align: right !important;
`;

class CycleCaseList extends React.Component {
  constructor(props) {
    super(props);
    this.expandChange = this.expandChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCycleCaseList({
      cycleId: this.props.cycleId
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.cycleId !== prevProps.cycleId) {
      this.props.loadCycleCaseList({ cycleId: this.props.cycleId });
    }
  }

  render() {
    const {
      cycleCases,
      loading,
      isConfirmDialogVisible,
      cycleCasesTotalDiagnoses,
      cycleCasesTotalMinRequiredNumber,
      canWrite
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={cycleCases}
          isPageable={false}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'studyProgramCaseName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('cycle.name'),
              footerCell: (props) =>
                <td colSpan={props.colSpan} style={props.style} className={props.className}>
                  {`${localizationService.toLanguageString('common.total')}:`}
                </td>
            }, {
              field: 'minRequiredNumber',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('cycle.minCaseRequiredNumber'),
              footerCell: (props) =>
                <FooterCell colSpan={props.colSpan} style={props.style} className={props.className}>
                  {cycleCasesTotalMinRequiredNumber}
                </FooterCell>
            }, {
              field: 'diagnoses',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('cycle.caseDiagnoses'),
              minGridWidth: GridSize.medium,
              footerCell: (props) =>
                <FooterCell colSpan={props.colSpan} style={props.style} className={props.className}>
                  {cycleCasesTotalDiagnoses}
                </FooterCell>,
              cell: ({ dataItem, className }) => (
                <td className={className}>
                  {dataItem.diagnoses ? dataItem.diagnoses.length : 0}
                </td>
              )
            }
          ]}
          actions={
            canWrite ?
              [
                {
                  icon: 'delete',
                  onClick: this.handleRemoveClick,
                  disabledOn: () => this.props.isCopyingInProgress
                }
              ]
              :
              []
          }
        >
          {canWrite &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClick} disabled={this.props.isCopyingInProgress}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }

        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('cycle.cycleCaseDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  handleRowClick(row) {
    this.props.navigate(`/cycles/${this.props.cycleId}/cases/${row.dataItem.id}/overview`);
  }

  handleEditClick(row) {
    this.props.navigate(`/cycles/${this.props.cycleId}/cases/${row.dataItem.id}`);
  }

  handleAddClick() {
    this.props.navigate(`/cycles/${this.props.cycleId}/cases/create`);
  }

  handleRemoveClick(e) {
    this.props.openCycleCaseConfirmDialog(e.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeCycleCaseConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCycleCaseConfirmDialog({
      cycleId: this.props.cycleId
    });
  }
}

const mapStateToProps = (state) => ({
  cycleCases: state.cycleCaseList.cycleCases,
  cycleCasesTotalDiagnoses: state.cycleCaseList.cycleCasesTotalDiagnoses,
  cycleCasesTotalMinRequiredNumber: state.cycleCaseList.cycleCasesTotalMinRequiredNumber,
  loading: state.cycleCaseList.loading,
  isConfirmDialogVisible: state.cycleCaseList.isConfirmDialogVisible
});

const mapDispatchToProps = (dispatch) => ({
  loadCycleCaseList: (payload) => dispatch(loadCycleCaseList(payload)),
  openCycleCaseConfirmDialog: (payload) => dispatch(openCycleCaseConfirmDialog(payload)),
  closeCycleCaseConfirmDialog: () => dispatch(closeCycleCaseConfirmDialog()),
  removeCycleCaseConfirmDialog: (payload) => dispatch(removeCycleCaseConfirmDialog(payload))
});

registerForIntl(CycleCaseList);
registerForLocalization(CycleCaseList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleCaseList));
