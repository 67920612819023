import React from 'react';
import styled from 'styled-components';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { Drawer, DrawerContent, DrawerItem } from '@progress/kendo-react-layout';
import { Colors, Header } from '../..';
import { SCREEN_SIZES as ScreenSizes } from '../../../resources/screenSize';
import { loadFromSessionStorage, saveToSessionStorage } from '../../../utils/storageUtils';
import * as st from '../../../utils/storageTypes';
import { withRouter } from '../../../components/withRouter';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { HEADER_HEIGHT } from '../../../constants';

const Content = styled(DrawerContent)`
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  ${props => props?.minWidth && `min-width: ${props.minWidth}px`};
`;

const ArrowIcon = styled.span`
  position: relative;
  background-color: transparent !important;
`;

const IconContainer = styled.span`
  ${props => props.isChild && 'padding-left: 0px !important;'}
`;

const DrawerComponent = styled(Drawer)`
  ${props => props.minWidth && `min-width: ${props.minWidth}px;`}
  .k-drawer-wrapper {
    ${props => `height: ${props.isSmallerScreen ? `${window.innerHeight - props.headerHeight}px` : 'auto'};`}
  }
  .k-drawer {
    ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}
  }
  .k-overlay {
    ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}
  }
`;

const ItemTitle = styled.div`
  padding-left: 16px !important;
  ${props => props.isChild && `
    font-size: smaller;
    padding-left: 24px !important;
  `}
`;

const DrawerItemComponent = styled(DrawerItem)`
  display: flex;
  align-items: center;
  ${props => props.$isChild && `
    height: 56px;
    ${!props.selected && `background-color: ${Colors.GRAY_10};`}
  `}
`;

const ArrowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const NoIconDiv = styled.div`
  width: 36px;
  height: 18px;
`;

class DrawerRouterContainer extends React.Component {
  constructor(props) {
    super(props);
    const isSmallerScreen = window.innerWidth < ScreenSizes.MD;
    const sessionDrawerExpandedState = loadFromSessionStorage(st.DRAWER_EXPANDED);
    const expanded = isSmallerScreen ? false : sessionDrawerExpandedState ?? true;
    this.state = {
      expanded: expanded,
      isSmallerScreen: isSmallerScreen
    };
    this.resizeWindow = this.resizeWindow.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.renderDrawerItem = this.renderDrawerItem.bind(this);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.resizeWindow);
    this.resizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userId !== this.props.userId) {
      this.props.onFetchRequestsData();
    }
  }

  render() {
    const selected = this.getSelectedItem();
    const localizationService = provideLocalizationService(this);
    let selectedItemMinWidth = selected?.id && this.props.userMenuItems.find(item => item.id === selected.id).minwidth;
    let minWidth;
    const menuItems = this.getMappedItems(selected, localizationService);

    if (selectedItemMinWidth != null) {
      minWidth = this.state.isSmallerScreen ? selectedItemMinWidth : selectedItemMinWidth + 240;
    }

    return (
      <>
        <Header
          onButtonClick={this.handleClick}
          pageTitle={localizationService.toLanguageString(`drawer.${selected?.name ?? 'home'}`)}
          title={localizationService.toLanguageString('custom.title')}
          userEmail={this.props.userEmail}
          userInitials={this.props.userInitials}
          userStudyPrograms={this.props.userStudyPrograms}
          userDrawerItems={this.props.userDrawerItems}
          onUserDrawerItemsUpdated={this.props.onUserDrawerItemsUpdated}
          height={HEADER_HEIGHT}
          position={this.state.isSmallerScreen ? 'fixed' : 'sticky'}
          minWidth={minWidth}
          requestCount={this.getRequestsSum()}
          drawerExpanded={this.state.expanded}
          handleStudyProgramChange={this.props.handleStudyProgramChange}
          currentStudies={this.props.currentStudies}
          isUserImpersonated={this.props.isUserImpersonated}
          onLogoutFromImpersonateClick={this.props.onLogoutFromImpersonateClick}
          onLocaleChange={this.props.onLocaleChange}
          userIsAdministrator={this.props.userIsAdministrator}
          unreadNotificationsCount={this.props.unreadNotificationsCount}
          onNotificationsClick={this.props.onNotificationsClick}
        />
        <DrawerComponent
          expanded={this.state.expanded}
          items={menuItems}
          item={this.renderDrawerItem}
          position={'start'}
          mode={this.state.isSmallerScreen ? 'overlay' : 'push'}
          onOverlayClick={this.handleClick}
          onSelect={this.handleSelect}
          marginTop={this.state?.isSmallerScreen && HEADER_HEIGHT}
          headerHeight={HEADER_HEIGHT}
          minWidth={minWidth}
          isSmallerScreen={this.state.isSmallerScreen}
        >
          <Content minWidth={selectedItemMinWidth}>
            {this.props.children}
          </Content>
        </DrawerComponent>
      </>
    );
  }

  getMappedItems(selected, localizationService) {
    return this.props.userMenuItems.map((item) => {
      const { parentId, ...others } = item;
      const label = localizationService.toLanguageString(`drawer.${item.name}`);
      const tooltip = localizationService.toLanguageString(`drawer.tooltips.${item.name}`);
      if (parentId !== undefined) {
        const parent = this.props.userMenuItems.find(parent => parent.id === parentId);
        return {
          ...others,
          hidden: !parent?.['data-expanded'] ?? true,
          isChild: true,
          text: label,
          title: tooltip ?? label,
          selected: item.id === selected?.id
        };
      }
      return {
        ...item,
        text: label,
        title: tooltip ?? label,
        selected: item.id === selected?.id || item.id === selected?.parentId
      };
    });
  }

  renderDrawerItem(props) {
    const { iconSvg, text, isChild, ...others } = props;
    const arrowDir = props['data-expanded'] ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-right';
    return (
      <DrawerItemComponent $isChild={isChild} {...others}>
        <IconAndTitleContainer>
          <BadgeContainer>
            { iconSvg
              ? <IconContainer className={'k-icon ' + iconSvg} isChild={isChild}/>
              : <NoIconDiv/>
            }
            {(props.id === 'evaluate-cycle' && this.props.cycleEvaluationRequestCount > 0 ||
              props.id === 'my-surveys' && this.props.surveyRequestCount > 0 ||
              props.id === 'competency-requests' && this.props.competencyRequestCount > 0 ||
              props.id === 'approval-requests' && this.props.studentCycleCaseAndSkillApprovalRequestCount > 0 ||
              props.id === 'requests-group' && props['data-expanded'] == false && this.getRequestsSum() > 0) &&
                <Badge themeColor='primary' size='small' rounded='large'>
                  {this.getBadgeValue(props)}
                </Badge>
            }
          </BadgeContainer>
          <ItemTitle className='k-item-text' isChild={isChild}>
            {text}
          </ItemTitle>
        </IconAndTitleContainer>
        {props['data-expanded'] !== undefined && (
          <ArrowContainer>
            <ArrowIcon className={'k-icon ' + arrowDir}/>
          </ArrowContainer>
        )}
      </DrawerItemComponent>
    );
  };

  getBadgeValue(props) {
    switch (props.id) {
      case 'evaluate-cycle':
        return this.props.cycleEvaluationRequestCount;
      case 'my-surveys':
        return this.props.surveyRequestCount;
      case 'competency-requests':
        return this.props.competencyRequestCount;
      case 'approval-requests':
        return this.props.studentCycleCaseAndSkillApprovalRequestCount;
      default:
        return this.getRequestsSum();
    }
  }

  getRequestsSum() {
    return this.props.cycleEvaluationRequestCount
      + this.props.surveyRequestCount
      + this.props.competencyRequestCount
      + this.props.studentCycleCaseAndSkillApprovalRequestCount;
  }

  getSelectedItem() {
    const pathName = window.location.pathname;
    const items = this.props.userMenuItems;
    let currentPath = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].route === pathName) {
        currentPath = items[i];
      } else if (items[i].childs) {
        for (let c = 0; c < items[i].childs.length; c++) {
          if (pathName.startsWith(items[i].childs[c])) {
            currentPath = items[i];
          }
        }
      }
    }
    return currentPath;
  }

  resizeWindow() {
    const selectedItem = this.getSelectedItem();
    const isSmallerScreen = (selectedItem?.minwidth && window.outerWidth || window.innerWidth) < ScreenSizes.MD;
    const expanded = isSmallerScreen ? false : this.state.expanded;
    this.setState({ isSmallerScreen, expanded });
  }

  handleClick() {
    const expanded = !this.state.expanded;
    saveToSessionStorage(st.DRAWER_EXPANDED, expanded);
    this.setState({ expanded: expanded });
  }

  handleSelect(e) {
    const currentItem = e.itemTarget.props;
    const isParent = currentItem['data-expanded'] !== undefined;
    const nextExpanded = !currentItem['data-expanded'];

    if (this.state.isSmallerScreen) {
      saveToSessionStorage(st.DRAWER_EXPANDED, false);
      if (!isParent) {
        this.setState({ expanded: false });
      }
    }

    const updatedItems = this.props.userMenuItems.map(item => {
      const isCurrentItem = currentItem.id === item.id;
      return {
        ...item,
        selected: isCurrentItem,
        ['data-expanded']: isCurrentItem && isParent ? nextExpanded : item['data-expanded']
      };
    });
    this.props.onUserMenuItemsUpdated({ userMenuItems: updatedItems });
    e.itemTarget.props.route && this.props.navigate(e.itemTarget.props.route);
  }
};

registerForLocalization(DrawerRouterContainer);

export default withRouter(DrawerRouterContainer);