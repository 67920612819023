import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridDateCell, GridSize, GridTimeCell } from '../../shared/components';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  loadStudyPlanCycleTheoreticalPartList,
  openStudyPlanCycleTheoreticalPartConfirmDialog,
  closeStudyPlanCycleTheoreticalPartConfirmDialog,
  removeStudyPlanCycleTheoreticalPartConfirmDialog
} from '../actions';
import { Text } from '../../../ui';
import { THEORETICAL_PARTS } from '../../../resources/theoreticalPartType';
import styled from 'styled-components';
import { withRouter } from '../../../components/withRouter';

const TheoreticalPartNameContainer = styled.span`
  font-weight: bolder;
`;

const HyperLink = styled.a`
  display: inline-block;
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: rgba(0, 0, 238, 1) !important;
  text-decoration: underline !important;
  &:visited {
    color: rgba(85, 26, 139, 1) !important;
  }
`;

class StudyPlanCycleTheoreticalPartList extends React.Component {
  constructor(props) {
    super(props);
    this.expandChange = this.expandChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogRemove = this.handleConfirmDialogRemove.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.renderName = this.renderName.bind(this);
    this.renderType = this.renderType.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyPlanCycleTheoreticalPartList({
      studyPlanId: this.props.studyPlanId,
      studyPlanCycleId: this.props.studyPlanCycleId
    });
  }

  render() {
    const { studyPlanCycleTheoreticalParts, loading, isConfirmDialogVisible } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={studyPlanCycleTheoreticalParts}
          isPageable={false}
          columns={[
            {
              field: 'studyProgramTheoreticalPartName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycle.name'),
              cell: this.renderName
            }, {
              field: 'link',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycle.link'),
              minGridWidth: GridSize.large,
              cell: this.renderLink
            }, {
              field: 'plannedDate',
              className: 'align-center',
              headerClassName: 'align-center',
              title: localizationService.toLanguageString('StudyPlanCycle.plannedDate'),
              cell: GridDateCell
            }, {
              field: 'plannedTime',
              className: 'align-center',
              headerClassName: 'align-center',
              title: localizationService.toLanguageString('StudyPlanCycle.plannedTime'),
              cell: GridTimeCell,
              minGridWidth: GridSize.small
            }, {
              field: 'cycleTheoreticalPartType',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycle.type'),
              minGridWidth: GridSize.medium,
              cell: this.renderType
            }
          ]}
          actions={[
            { icon: 'edit', onClick: this.handleEditClick },
            { icon: 'delete', onClick: this.handleRemoveClick }
          ]}
        >
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddClick}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>
        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('StudyPlanCycle.removeTheoreticalPartDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleConfirmDialogRemove}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderName(e) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {parseInt(this.props.studyPlanCycleId) === dataItem.studyPlanCycleId
          ? dataItem.studyProgramTheoreticalPartName
          : <TheoreticalPartNameContainer>{dataItem.studyProgramTheoreticalPartName}</TheoreticalPartNameContainer>}
      </td>
    );
  }

  renderType(e) {
    const { dataItem, className } = e;
    const localizationService = provideLocalizationService(this);
    let resolvedValue = '';
    switch (dataItem.cycleTheoreticalPartType) {
      case THEORETICAL_PARTS.LECTURE:
        resolvedValue = localizationService.toLanguageString('cycleTheoreticalPartType.Lecture');
        break;
      case THEORETICAL_PARTS.SEMINAR:
        resolvedValue = localizationService.toLanguageString('cycleTheoreticalPartType.Seminar');
        break;
      default:
        resolvedValue = localizationService.toLanguageString('theoreticalPart.unknownTypeError'); ;
        break;
    }
    return (
      <td className={className}>
        {resolvedValue}
      </td>
    );
  }

  renderLink(e) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        <HyperLink href={dataItem.link} target='_blank' rel='noreferrer'>{dataItem.link}</HyperLink>
      </td>
    );
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  handleAddClick() {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycles/${this.props.studyPlanCycleId}/theoretical_parts/create`);
  }

  handleEditClick(row) {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycles/${this.props.studyPlanCycleId}/theoretical_parts/${row.dataItem.id}`);
  }

  handleRemoveClick(row) {
    this.props.openStudyPlanCycleTheoreticalPartConfirmDialog(row.dataItem);
  }

  handleConfirmDialogRemove() {
    this.props.removeStudyPlanCycleTheoreticalPartConfirmDialog({
      studyPlanId: this.props.studyPlanId,
      studyPlanCycleId: this.props.studyPlanCycleId
    });
  }

  handleConfirmDialogClose() {
    this.props.closeStudyPlanCycleTheoreticalPartConfirmDialog();
  }
}

const mapStateToProps = (state) => ({
  studyPlanCycleTheoreticalParts: state.studyPlanCycleTheoreticalPartList.studyPlanCycleTheoreticalParts,
  loading: state.studyPlanCycleTheoreticalPartList.loading,
  isConfirmDialogVisible: state.studyPlanCycleTheoreticalPartList.isConfirmDialogVisible
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyPlanCycleTheoreticalPartList: (payload) => dispatch(loadStudyPlanCycleTheoreticalPartList(payload)),
  openStudyPlanCycleTheoreticalPartConfirmDialog: (payload) => dispatch(openStudyPlanCycleTheoreticalPartConfirmDialog(payload)),
  closeStudyPlanCycleTheoreticalPartConfirmDialog: (payload) => dispatch(closeStudyPlanCycleTheoreticalPartConfirmDialog(payload)),
  removeStudyPlanCycleTheoreticalPartConfirmDialog: (payload) => dispatch(removeStudyPlanCycleTheoreticalPartConfirmDialog(payload))
});

registerForIntl(StudyPlanCycleTheoreticalPartList);
registerForLocalization(StudyPlanCycleTheoreticalPartList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleTheoreticalPartList));
