import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { GridSize, Grid } from '../../shared/components';
import {
  loadDepartmentListPage,
  findDepartments,
  sortDepartments,
  openDepartmentConfirmDialog,
  closeDepartmentConfirmDialog,
  removeDepartmentConfirmDialog
} from '../actions/department-list-page-actions';
import { withRouter } from '../../../components/withRouter';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Text } from '../../../ui';

class DepartmentList extends React.PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  async componentDidMount() {
    await this.props.loadDepartmentListPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={this.props.loading}
          data={this.props.departments}
          skip={this.props.skip}
          take={this.props.take}
          total={this.props.total}
          sort={this.props.sorting}
          onExpandChange={this.handleExpandChange}
          onPageChange={this.handlePageChange}
          onSortChange={this.handleSort}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'name',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('department.name')
            }, {
              field: 'code',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('department.code'),
              minGridWidth: GridSize.small
            }
          ]}
          actions={
            this.props.canWrite && [{
              icon: 'delete',
              onClick: this.handleRemoveClick,
              disabledOn: e => !e.dataItem.canBeDeleted
            }]
          }
        >
          {this.props.canWrite &&
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClicked}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          }
        </Grid>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('department.departmentDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  handleSort(e) {
    this.props.sortDepartments(e.sort);
  }

  async handlePageChange(e) {
    await this.props.findDepartments({ skip: e.page.skip, take: e.page.take });
  }

  handleRowClick(e) {
    this.props.navigate(`/departments/${e.dataItem.id}/overview`);
  }

  handleAddClicked() {
    this.props.navigate('/departments/create');
  }

  handleRemoveClick(row) {
    this.props.openDepartmentConfirmDialog(row.dataItem);
  }

  handleConfirmDialogClose() {
    this.props.closeDepartmentConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeDepartmentConfirmDialog({
      departmentId: this.props.departmentId
    });
  }

  handleExpandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };
}

const mapStateToProps = state => ({
  loading: state.departmentListPage.loading,
  departments: state.departmentListPage.departments,
  skip: state.departmentListPage.skip,
  take: state.departmentListPage.take,
  total: state.departmentListPage.total,
  sorting: state.departmentListPage.sorting,
  canWrite: state.departmentListPage.canWrite,
  isConfirmDialogVisible: state.departmentListPage.isConfirmDialogVisible
});

const mapDispatchToProps = dispatch => ({
  loadDepartmentListPage: () => dispatch(loadDepartmentListPage()),
  findDepartments: (payload) => dispatch(findDepartments(payload)),
  sortDepartments: (payload) => dispatch(sortDepartments(payload)),
  openDepartmentConfirmDialog: (payload) => dispatch(openDepartmentConfirmDialog(payload)),
  closeDepartmentConfirmDialog: () => dispatch(closeDepartmentConfirmDialog()),
  removeDepartmentConfirmDialog: (payload) => dispatch(removeDepartmentConfirmDialog(payload))
});

registerForLocalization(DepartmentList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DepartmentList));