import UserSearchDialogActionTypes from '../actions/user-search-dialog-action-types';

const initialState = {
  loading: false,
  availableUsersLoading: false,
  availableUsersCancelToken: null,
  availableUsers: null,
  visible: false,
  onAdd: null,
  userSearchDialogValidationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserSearchDialogActionTypes.LOAD_USER_SEARCH_DIALOG_USERS_START:
      return {
        ...state,
        availableUsersLoading: true
      };
    case UserSearchDialogActionTypes.LOAD_USER_SEARCH_DIALOG_USERS_END:
      return {
        ...state,
        availableUsersLoading: false,
        availableUsers: action.payload ? action.payload.users : null
      };
    case UserSearchDialogActionTypes.OPEN_USER_SEARCH_DIALOG:
      return {
        ...state,
        loading: false,
        visible: true,
        user: null,
        onAdd: action.payload ? action.payload.onAdd : null
      };
    case UserSearchDialogActionTypes.CLOSE_USER_SEARCH_DIALOG:
      return {
        ...state,
        loading: false,
        visible: false,
        user: null,
        onAdd: null,
        userSearchDialogValidationResult: null
      };
    case UserSearchDialogActionTypes.ADD_USER_SEARCH_DIALOG_USER_START:
      return {
        ...state,
        loading: true
      };
    case UserSearchDialogActionTypes.ADD_USER_SEARCH_DIALOG_USER_END:
      return {
        ...state,
        loading: false,
        userSearchDialogValidationResult: action.payload?.userSearchDialogValidationResult
      };
    default:
      return state;
  }
};