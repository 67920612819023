import StudyProgramTheoreticalPartFormPageActionTypes from '../actions/study-program-theoretical-part-form-page-action-types';

const initialState = {
  loading: false,
  studyProgramId: null,
  studyProgramTheoreticalPart: {},
  saveSuccess: false,
  validationResult: null,
  cancellationTokenSources: new Map()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case StudyProgramTheoreticalPartFormPageActionTypes.LOAD_STUDY_PROGRAM_THEORETICAL_PART_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        studyProgramId: action.payload?.studyProgramId,
        studyProgramTheoreticalPart: action.payload ? action.payload.studyProgramTheoreticalPart : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    default:
      return state;
  }
};