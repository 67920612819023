import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { ComboBox, FilterForm, TextBox, WrapLayout } from '../../../ui';
import { filterTheoreticalParts, clearTheoreticalPartsFilter, searchCyclesFilterCycles, searchUsersFilterUsers, loadTheoreticalPartTypes, searchTheoreticalPartFilterStudyPrograms } from '../actions/theoretical-part-list-page-actions';
import { StackLayout } from '@progress/kendo-react-layout';
import { DatePicker } from '../../../ui';

class TheoreticalPartListFilter extends React.PureComponent {

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    await this.props.loadTheoreticalPartTypes(localizationService);
  }

  render() {
    const {
      filter,
      clearTheoreticalPartsFilter,
      filterTheoreticalParts,
      filterCycles,
      filterCyclesLoading,
      filterUsers,
      filterUsersLoading,
      searchUsersFilterUsers,
      theoreticalPartTypes,
      filterStudyProgramsLoading,
      filterStudyPrograms,
      searchTheoreticalPartFilterStudyPrograms
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterTheoreticalParts}
        onClear={clearTheoreticalPartsFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={(renderProps) => (
          <WrapLayout>
            <StackLayout width='340px' orientation='vertical'>
              <Field
                name='studyProgram'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('cycle.studyProgram')}
                textField='name'
                dataItemKey='id'
                loading={filterStudyProgramsLoading}
                data={filterStudyPrograms}
                onFilter={searchTheoreticalPartFilterStudyPrograms}
                onChange={e => this.handleTheoreticalPartChange(e, renderProps)}
              />
              <Field
                name='cycle'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('theoreticalPart.cycle')}
                textField='name'
                dataItemKey='id'
                loading={filterCyclesLoading}
                data={filterCycles}
                onFilter={e => this.handleTheoreticalPartCycleTextChange(e, renderProps)}
                disabled={!this.props.cycleListActive}
              />
              <StackLayout gap='40px'>
                <Field
                  name='dateFrom'
                  width='150px'
                  component={DatePicker}
                  label={localizationService.toLanguageString('theoreticalPart.dateFrom')}
                />
                <Field
                  name='dateTo'
                  width='150px'
                  component={DatePicker}
                  label={localizationService.toLanguageString('theoreticalPart.dateTo')}
                />
              </StackLayout>
            </StackLayout>
            <StackLayout width='340px' orientation='vertical'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('theoreticalPart.name')}
              />
              <Field
                name='user'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('theoreticalPart.lecturer')}
                textField='fullName'
                dataItemKey='id'
                loading={filterUsersLoading}
                data={filterUsers}
                onFilter={searchUsersFilterUsers}
              />
              <Field
                name='theoreticalPartType'
                label={localizationService.toLanguageString('cycle.theoreticalPartType')}
                component={ComboBox}
                data={theoreticalPartTypes}
                textField='name'
                valueField='id'
                width='340px'
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }

  handleTheoreticalPartCycleTextChange(e, renderProps) {
    const studyProgram = renderProps.valueGetter('studyProgram');
    this.props.searchCyclesFilterCycles({ keyword: e?.keyword, studyProgramId: studyProgram?.id });
  }

  handleTheoreticalPartChange(e, renderProps) {
    const theoreticalPart = {
      dateFrom: renderProps.valueGetter('dateFrom'),
      dateTo: renderProps.valueGetter('dateTo'),
      keyword: renderProps.valueGetter('keyword'),
      user: renderProps.valueGetter('user'),
      theoreticalPartType: renderProps.valueGetter('theoreticalPartType'),
      studyProgram: renderProps.valueGetter('studyProgram')
    };
    this.props.searchCyclesFilterCycles({ studyProgramId: theoreticalPart?.studyProgram?.id });
    renderProps.onChange('cycle', { value: {} });
    renderProps.onChange('dateFrom', { value: theoreticalPart.dateFrom });
    renderProps.onChange('dateTo', { value: theoreticalPart.dateTo });
    renderProps.onChange('keyword', { value: theoreticalPart.keyword });
    renderProps.onChange('user', { value: theoreticalPart.user });
    renderProps.onChange('theoreticalPartType', { value: theoreticalPart.theoreticalPartType });
  }
}

const mapStateToProps = state => ({
  filter: state.theoreticalPartListPage.filter,
  filterCyclesLoading: state.theoreticalPartListPage.filterCyclesLoading,
  filterCycles: state.theoreticalPartListPage.filterCycles,
  filterUsersLoading: state.theoreticalPartListPage.filterUsersLoading,
  filterUsers: state.theoreticalPartListPage.filterUsers,
  theoreticalPartTypes: state.theoreticalPartListPage.theoreticalPartTypes,
  filterStudyProgramsLoading: state.theoreticalPartListPage.filterStudyProgramsLoading,
  filterStudyPrograms: state.theoreticalPartListPage.filterStudyPrograms,
  cycleListActive: state.theoreticalPartListPage.cycleListActive
});

const mapDispatchToProps = dispatch => ({
  filterTheoreticalParts: (payload) => dispatch(filterTheoreticalParts(payload)),
  clearTheoreticalPartsFilter: () => dispatch(clearTheoreticalPartsFilter()),
  searchCyclesFilterCycles: (payload) => dispatch(searchCyclesFilterCycles(payload)),
  searchUsersFilterUsers: (payload) => dispatch(searchUsersFilterUsers(payload)),
  loadTheoreticalPartTypes: (localizationService) => dispatch(loadTheoreticalPartTypes(localizationService)),
  searchTheoreticalPartFilterStudyPrograms: (payload) => dispatch(searchTheoreticalPartFilterStudyPrograms(payload))
});

registerForLocalization(TheoreticalPartListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(TheoreticalPartListFilter);