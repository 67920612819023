import StudyProgramSkillAbilityListActionTypes from '../actions/study-program-skill-ability-list-action-types';

const initialState = {
  loading: false,
  studyProgramSkillAbilities: null,
  studyProgramSkillAbilitiesCancelToken: null,
  isConfirmDialogVisible: false,
  selectedStudyProgramSkillAbilityId: null,
  selectedStudyProgramSkillAbilityAssignedCount: null,
  canWrite: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyProgramSkillAbilityListActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyProgramSkillAbilityListActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_LIST_END:
      return {
        ...state,
        studyProgramSkillAbilities: action.payload.studyProgramSkillAbilities,
        loading: false,
        canWrite: action.payload.canWrite
      };
    case StudyProgramSkillAbilityListActionTypes.TOGGLE_STUDY_PROGRAM_SKILL_ABILITY_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedStudyProgramSkillAbilityId: action.payload?.id,
        selectedStudyProgramSkillAbilityAssignedCount: action.payload?.assignedCycleSkillAbilityCount,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    default:
      return state;
  }
};