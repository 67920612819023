import * as React from 'react';
import { ListPage } from '../../../ui';
import { DepartmentListFilter, DepartmentList } from './index';

class DepartmentListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<DepartmentListFilter/>}>
        <DepartmentList/>
      </ListPage>
    );
  }
}

export default DepartmentListPage;