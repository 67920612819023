import StudentCompetencyOverviewPageActionTypes from '../actions/student-competency-overview-action-types';

const initialState = {
  loading: false,
  studentCompetency: null,
  studentCompetencyCancelToken: null,
  selectedTab: 0,
  isSummaryExpanded: false,

  isSubmitDialogVisible: false,
  isRequestEvaluationDialogVisible: false,
  isQrCodeDialogVisible: false,
  shouldShowQrCode: false,
  selectedCompetencySurvey: null,

  availableManagersLoading: false,
  availableManagersCancelToken: null,
  availableManagers: [],

  availableCoordinatorsLoading: false,
  availableCoordinatorsCancelToken: null,
  availableCoordinators: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        studentCompetency: action.payload?.studentCompetency,
        selectedTab: action.payload?.studentCompetency?.studentCompetencyCertificateId > 0 ? state.selectedTab : 0
      };
    case StudentCompetencyOverviewPageActionTypes.SELECT_STUDENT_COMPETENCY_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    case StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_REQUEST_EVALUATION_DIALOG:
      return {
        ...state,
        selectedCompetencySurvey: action.payload,
        isRequestEvaluationDialogVisible: !state.isRequestEvaluationDialogVisible
      };
    case StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_OVERVIEW_PAGE_SUMMARY_EXPANDED:
      return {
        ...state,
        isSummaryExpanded: !state.isSummaryExpanded
      };
    case StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_SUBMIT_DIALOG:
      return {
        ...state,
        isSubmitDialogVisible: !state.isSubmitDialogVisible
      };
    case StudentCompetencyOverviewPageActionTypes.SUBMIT_STUDENT_COMPETENCY_START:
      return state;
    case StudentCompetencyOverviewPageActionTypes.SUBMIT_STUDENT_COMPETENCY_END:
      return state;
    case StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_AVAILABLE_COORDINATORS_START:
      return {
        ...state,
        availableCoordinatorsLoading: true
      };
    case StudentCompetencyOverviewPageActionTypes.LOAD_STUDENT_COMPETENCY_AVAILABLE_COORDINATORS_END:
      return {
        ...state,
        availableCoordinatorsLoading: false,
        availableCoordinators: action.payload?.availableCoordinators
      };
    case StudentCompetencyOverviewPageActionTypes.TOGGLE_STUDENT_COMPETENCY_QR_CODE_DIALOG:
      return {
        ...state,
        isQrCodeDialogVisible: action.payload.isQrCodeDialogVisible
      };
    case StudentCompetencyOverviewPageActionTypes.SET_STUDENT_COMPETENCY_SHOW_QR_CODE_SETTING:
      return {
        ...state,
        shouldShowQrCode: action.payload.shouldShowQrCode
      };
    case StudentCompetencyOverviewPageActionTypes.GET_COMPETENCY_REPORT_START:
      return state;
    case StudentCompetencyOverviewPageActionTypes.GET_COMPETENCY_REPORT_END:
      return state;
    default:
      return state;
  }
};