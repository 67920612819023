import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, TextBox, WrapLayout, CheckBox, ComboBox, StackLayout } from '../../../ui';
import { filterCycles, clearCyclesFilter, searchCyclesFilterStudyPrograms } from '../actions/cycle-list-page-actions';

class CycleListFilter extends React.PureComponent {

  render() {
    const {
      filter,
      clearCyclesFilter,
      filterCycles,
      filterStudyProgramsLoading,
      filterStudyPrograms,
      searchCyclesFilterStudyPrograms
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterCycles}
        onClear={clearCyclesFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='studyProgram'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('cycle.coordinatingStudyProgram')}
                textField='name'
                dataItemKey='id'
                loading={filterStudyProgramsLoading}
                data={filterStudyPrograms}
                onFilter={searchCyclesFilterStudyPrograms}
              />
              <StackLayout orientation='horizontal'>
                <Field
                  name='year'
                  width='170px'
                  component={ComboBox}
                  data={[
                    { text: '1', id: 1 },
                    { text: '2', id: 2 },
                    { text: '3', id: 3 },
                    { text: '4', id: 4 },
                    { text: '5', id: 5 },
                    { text: '6', id: 6 },
                    { text: '7', id: 7 }
                  ]}
                  textField='text'
                  dataItemKey='id'
                  label={localizationService.toLanguageString('cycle.years')}
                />
                <Field
                  name='showInvalid'
                  component={CheckBox}
                  label={localizationService.toLanguageString('cycle.showInvalid')}
                />
              </StackLayout>
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.cycleListPage.filter,
  filterStudyProgramsLoading: state.cycleListPage.filterStudyProgramsLoading,
  filterStudyPrograms: state.cycleListPage.filterStudyPrograms
});

const mapDispatchToProps = dispatch => ({
  filterCycles: (payload) => dispatch(filterCycles(payload)),
  clearCyclesFilter: () => dispatch(clearCyclesFilter()),
  searchCyclesFilterStudyPrograms: (payload) => dispatch(searchCyclesFilterStudyPrograms(payload))
});

registerForLocalization(CycleListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(CycleListFilter);