import TheoreticalPartOverviewPageActionTypes from '../actions/theoretical-part-overview-page-action-types';

const initialState = {
  loading: false,
  theoreticalPart: null,
  theoreticalPartCancelToken: null,
  selectedTab: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TheoreticalPartOverviewPageActionTypes.LOAD_THEORETICAL_PART_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case TheoreticalPartOverviewPageActionTypes.LOAD_THEORETICAL_PART_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        theoreticalPart: action.payload?.theoreticalPart
      };
    case TheoreticalPartOverviewPageActionTypes.SELECT_THEORETICAL_PART_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    case TheoreticalPartOverviewPageActionTypes.CLEAR_THEORETICAL_PART_OVERVIEW_PAGE:
      return initialState;
    default:
      return state;
  }
};