import * as React from 'react';
import { DatePicker as KendoDatePicker, Calendar } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';

const YearPicker = (props) => {
  const { value, valid, validationMessage, ...rest } = props;
  return (
    <fieldset className={'k-form-fieldset'}>
      <KendoDatePicker
        {...rest}
        value={value && new Date(value, 0)}
        format='yyyy'
        calendar={(props) => { return (<Calendar topView='decade' bottomView='decade' value={props.value} onChange={props.onChange}/>); }}
        onChange={e => props.onChange({
          ...e,
          value: e.value?.getFullYear()
        })}
        valid={valid}
      />
      {!valid && <Error>{validationMessage}</Error>}
    </fieldset>
  );
};

export default YearPicker;