import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, WrapLayout, DropDown, StackLayout, TextBox } from '../../../ui';
import { loadCycleStudentStatuses, filterManagedStudents, clearManagedStudentsFilter } from '../actions/managed-student-list-page-actions';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { CYCLE_STUDENT_STATUS } from '../../../resources/studyPlanCycleDepartmentStudentStatus';
import styled from 'styled-components';

const DropdownItemContainer = styled.div`
  height: 24px;
  padding-top: 8px;
`;

const StyledBadge = styled(Badge)`
  opacity: 75%;
  font-size: small;
`;

class ManagedStudentListFilter extends React.PureComponent {

  constructor(props) {
    super(props);
    this.statusDropdownItemRender = this.statusDropdownItemRender.bind(this);
  }

  async componentDidMount() {
    await this.props.loadCycleStudentStatuses(provideLocalizationService(this));
  }

  render() {
    const {
      filter,
      clearManagedStudentsFilter,
      filterManagedStudents,
      cycleStudentStatuses
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterManagedStudents}
        onClear={clearManagedStudentsFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='cycleStudentStatus'
                label={localizationService.toLanguageString('managedStudents.status')}
                component={DropDown}
                itemRender={this.statusDropdownItemRender}
                data={cycleStudentStatuses}
                textField='name'
                valueField='id'
                width='340px'
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }

  statusDropdownItemRender(li, itemProps) {
    let color;
    switch (itemProps.dataItem.id) {
      case CYCLE_STUDENT_STATUS.NOT_STARTED:
        color = 'inverse';
        break;
      case CYCLE_STUDENT_STATUS.STARTED:
        color = 'primary';
        break;
      case CYCLE_STUDENT_STATUS.SUBMITTED:
        color = 'warning';
        break;
      case CYCLE_STUDENT_STATUS.RETURNED:
        color = 'error';
        break;
      case CYCLE_STUDENT_STATUS.FINISHED:
        color = 'success';
        break;
      default:
        color = 'inverse';
        break;
    }
    const itemChildren = (
      <DropdownItemContainer>
        <BadgeContainer>
          <StyledBadge rounded={'medium'} size={'large'} themeColor={color} position={'outside'} align={{ vertical: 'top', horizontal: 'end' }}>
            {li.props.children}
          </StyledBadge>
        </BadgeContainer>
      </DropdownItemContainer>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }
}

const mapStateToProps = state => ({
  filter: state.managedStudentListPage.filter,
  cycleStudentStatuses: state.managedStudentListPage.cycleStudentStatuses
});

const mapDispatchToProps = dispatch => ({
  filterManagedStudents: (paylaod) => dispatch(filterManagedStudents(paylaod)),
  clearManagedStudentsFilter: () => dispatch(clearManagedStudentsFilter()),
  loadCycleStudentStatuses: (payload) => dispatch(loadCycleStudentStatuses(payload))
});

registerForLocalization(ManagedStudentListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(ManagedStudentListFilter);