import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { StudentCycleOverview } from '../../shared/student-cycle';

class MyStudiesStudentCycleOverviewPage extends React.PureComponent {

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <StudentCycleOverview
        studentId={this.props.studentId}
        breadcrumbItems={[
          {
            id: 'cycles',
            text: localizationService.toLanguageString('studentCycle.cycles')
          },
          {
            id: 'cycle',
            text: localizationService.toLanguageString('studentCycle.cycle'),
            disabled: true
          }
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  studentId: state.app.currentStudies?.studentId
});

const mapDispatchToProps = dispatch => ({
});

registerForLocalization(MyStudiesStudentCycleOverviewPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyStudiesStudentCycleOverviewPage));
