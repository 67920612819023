export const ltMessages = {
  validation: {
    required: 'Privalomas laukas',
    negativeValue: 'Neigiama vertė',
    impossibleSequence: 'Neįmanoma seka',
    userNotSelected: 'Nepasirinktas vartotojas',
    studyPlanCycleDepartmentCredits: 'Bazė negali būti sudaryta iš daugiau kreditų nei ciklas',
    studyPlanCycleDepartmentResidents: 'Minimalus rezidentų skaičius negali būti didesnis nei maksimalus',
    cycleNotSelected: 'Nepasirinktas ciklas',
    departmentNotSelected: 'Nepasirinkta bazė',
    invalidEmailFormat: 'Netinkamas el. pašto formatas',
    exceedsMaximumLength: 'Viršytas maksimalus simbolių skaičius',
    selectOrFillBelow: 'Pasirinkite arba užpildykite laukus esančius žemiau',
    invalidLinkFormat: 'Neteisingas nuorodos formatas',
    atLeastOneRequired: 'Būtina pasirinkti bent vieną',
    dateIsRequiredWithTime: 'Nurodžius laiką reikia nurodyti ir datą',
    timeIsRequiredWithDate: 'Nurodžius datą reikia nurodyti ir laiką',
    wrongFormat: 'Neteisingai nurodytas formatas'
  },
  common: {
    somethingWentWrong: 'Įvyko sistemos klaida',
    yes: 'Taip',
    no: 'Ne',
    total: 'Iš viso'
  },
  monthsShort: {
    jan: 'sau',
    feb: 'vas',
    mar: 'kov',
    apr: 'bal',
    may: 'geg',
    jun: 'bir',
    jul: 'lie',
    aug: 'rgp',
    sep: 'rgs',
    oct: 'spa',
    nov: 'lap',
    dec: 'grd'
  },
  authorization: {
    logout: 'Atsijungti',
    userNotFound: 'Toks vartotojas buvo nerastas. Bandykite prisijungti iš naujo.',
    loginAgain: 'Jungtis dar kartą',
    forbiddenTitle: 'Prieiga negalima',
    forbiddenMessage: 'Jūs neturite prieigos nurodytu adresu.',
    returnHome: 'Grįžti į pagrindinį'
  },
  studyPlanCycleDepartmentStudentStatus: {
    Started: 'Pradėtas',
    NotStarted: 'Nepradėtas',
    Submitted: 'Pateiktas',
    Returned: 'Grąžintas',
    Finished: 'Baigtas'
  },
  competencyStatus: {
    NotStarted: 'Nepradėta',
    InProgress: 'Vykdoma',
    Completed: 'Užbaigta',
    Submitted: 'Pateikta',
    Acquired: 'Įgyta',
    Rejected: 'Atmesta'
  },
  studentCompetencyStatus: {
    NotStarted: 'Nepradėta',
    Started: 'Pradėta',
    ToBeApproved: 'Tvirtinama',
    NotApproved: 'Nepatvirtinta',
    Returned: 'Grąžinta',
    ToBeSigned: 'Pasirašoma',
    Acquired: 'Įgyta'
  },
  cycleTheoreticalPartType: {
    Lecture: 'Paskaita',
    Seminar: 'Seminaras'
  },
  cycleType: {
    Optional: 'Pasirenkamas',
    Required: 'Privalomas'
  },
  surveyQuestionType: {
    Text: 'Laisvai įvedamas',
    NumericZeroThroughFive: 'Skaitinis [0:5]',
    NumericOneThroughFive: 'Skaitinis [1:5]',
    NumericOneThroughTen: 'Skaitinis [1:10]'
  },
  roleType: {
    Administrator: 'Administratorius',
    Manager: 'Rezidento vadovas',
    Student: 'Rezidentas',
    Mentor: 'Mentorius'
  },
  surveyType: {
    ResidentEvaluationSurvey: 'Rezidento įvertinimo anketa',
    CycleEvaluationSurvey: 'Ciklo įvertinimo anketa',
    ManagerEvaluationSurvey: 'Vadovo įvertinimo anketa',
    ClinicalCaseSurvey: 'Klinikinio atvejo klausimynas',
    TechnicalSkillSurvey: 'Techninio įgūdžio klausimynas',
    Survey360: '360 klausimynas'
  },
  surveyTypeAbbreviation: {
    ResidentEvaluationSurvey: 'Rezidento',
    CycleEvaluationSurvey: 'Ciklo',
    ManagerEvaluationSurvey: 'Vadovo',
    ClinicalCaseSurvey: 'Klin. atv.',
    TechnicalSkillSurvey: 'Tech. įg.',
    Survey360: '360'
  },
  competencySurveyStudentStatus: {
    Submitted: 'Pateikta',
    Evaluated: 'Įvertinta'
  },
  evaluatorRoles: {
    Resident: 'Rezidentas',
    Manager: 'Vadovas',
    Nurse: 'Slaugytojas',
    Pacient: 'Pacientas',
    Lecturer: 'Dėstytojas'
  },
  userStatus: {
    Unapproved: 'Nepatvirtintas',
    Active: 'Aktyvus',
    Deactivated: 'Deaktyvuotas'
  },
  cycleTeachingType: {
    Local: 'Dėstoma tik koordinuojančioje programoje',
    External: 'Dėstoma tik kitose programose',
    LocalAndExternal: 'Dėstoma ir koordinuojančioje programoje ir kitose'
  },
  studentStatus: {
    Studying: 'Mokosi',
    TemporarilyNotStudying: 'Laikinai nesimoko',
    RemovedFromList: 'Išbrauktas iš sąrašų',
    Graduated: 'Baigė studijas'
  },
  studyPlanCycleStudentApprovingUserStatus: {
    Submitted: 'Pateiktas',
    Unapproved: 'Netvirtintas',
    Returned: 'Grąžintas',
    Supplemented: 'Papildytas',
    Approved: 'Patvirtintas'
  },
  buttons: {
    save: 'Saugoti',
    cancel: 'Atšaukti',
    reset: 'Atstatyti',
    close: 'Uždaryti',
    edit: 'Redaguoti',
    delete: 'Pašalinti',
    saveAndCreateNew: 'Saugoti ir sukurti naują',
    submit: 'Pateikti',
    register: 'Registruoti',
    add: 'Pridėti',
    return: 'Grąžinti',
    request: 'Prašyti',
    confirm: 'Patvirtinti',
    evaluate: 'Įvertinti',
    yes: 'Taip',
    no: 'Ne',
    download: 'Atsisiųsti'
  },
  conjunctions: {
    outOf: 'iš'
  },
  studyProgram: {
    formTitle: 'Studijų programa',
    studyProgram: 'Programa',
    studyPrograms: 'Studijų programos',
    coordinator: 'Prog. koordinatorius',
    coordinators: 'Programos koordinatoriai',
    administrators: 'Studijų administratoriai',
    administrator: 'Studijų administratorius',
    committeeMembers: 'Komisijos nariai',
    committeeMember: 'Komisijos narys',
    studyPlans: 'Studijų planai',
    years: 'Trukmė metais',
    name: 'Pavadinimas',
    code: 'Valst. kodas',
    credits: 'Trukmė kreditais',
    validFrom: 'Galioja nuo',
    validTo: 'Galioja iki',
    cycleCode: 'Kodas',
    cycleCredits: 'Kreditai',
    gridDetailsError: 'Nepavyko užkrauti studijų programos informacijos',
    gridError: 'Įvyko klaida, nepavyko užkrauti studijų programų sąrašo',
    loadError: 'Įvyko klaida, nepavyko užkrauti studijų programos informacijos',
    saveError: 'Įvyko klaida, nepavyko išsaugoti studijų programos informacijos',
    saveSuccess: 'Studijų programos informacija sėkmingai išsaugota',
    deleteError: 'Įvyko klaida, nepavyko ištrinti studijų programos',
    addStudyProgram: 'Pridėti studijų programą',
    tabstripCyclesTitle: 'Ciklai',
    tabstripCasesTitle: 'Atvejai',
    tabstripAbilitiesTitle: 'Įgūdžiai',
    tabstripTheoreticalPartsTitle: 'Teorinės dalys',
    studyProgramCycleDialogMessage: 'Ar tikrai norite pašalinti ciklą iš studijų programos?',
    studyProgramCaseDialogMessage: 'Ar tikrai norite pašalinti atvejį iš studijų programos? Priskirtų ciklo atvejų skaičius: ',
    studyProgramSkillAbilityDialogMessage: 'Ar tikrai norite pašalinti įgūdį iš studijų programos? Priskirtų ciklo įgūdžių skaičius: ',
    studyProgramTheoreticalPartDialogMessage: 'Ar tikrai norite pašalinti teorinę dalį iš studijų programos? Priskirtų ciklo teorinių dalių skaičius: ',
    studyProgramCase: 'Studijų programos atvejis',
    studyProgramSkillAbility: 'Studijų programos įgūdis',
    studyProgramTheoreticalPart: 'Studijų programos teorinė dalis',
    assignedCycleCaseCount: 'Priskirta ciklo atvejų',
    assignedCycleSkillAbilityCount: 'Priskirta ciklo įgūdžių',
    assignedCycleTheoreticalPartCount: 'Priskirta ciklo teorinių dalių',
    cycleCount: 'Ciklų skaičius',
    descriptionLink: 'Aprašymo nuoroda',
    division: 'Padalinys'
  },
  department: {
    name: 'Pavadinimas',
    formTitle: 'Bazė',
    department: 'Bazė',
    departments: 'Bazės',
    code: 'Įmonės kodas',
    accreditationFrom: 'Akreditacija nuo',
    accreditationTo: 'Akreditacija iki',
    accreditedFrom: 'Akredituota nuo',
    accreditedTo: 'Akredituota iki',
    assignedResidents: 'Priskirti rezidentai',
    cycles: 'Ciklai',
    address: 'Adresas',
    manager: 'Vadovas',
    phone: 'Vadovo tel. nr.',
    email: 'Vadovo el. paštas',
    gridDetailsError: 'Įvyko klaida, nepavyko užkrauti bazės informacijos',
    gridError: 'Įvyko klaida, nepavyko užkrauti bazių sąrašo',
    loadError: 'Įvyko klaida, nepavyko užkrauti bazės informacijos',
    saveError: 'Įvyko klaida, nepavyko išsaugoti bazės informacijos',
    saveSuccess: 'Bazės informacija sėkmingai išsaugota',
    deleteSuccess: 'Bazė sėkmingai pašalinta',
    deleteError: 'Įvyko klaida, nepavyko pašalinti bazės',
    showInvalid: 'Rodyti negaliojančius',
    addDepartment: 'Pridėti bazę',
    createNewDepartment: 'Saugoti ir pridėti naują bazę',
    departmentCycles: 'Bazės ciklai',
    isForeign: 'Užsienio bazė',
    departmentDialogMessage: 'Ar tikrai norite pašalinti bazę?'
  },
  DepartmentCycle: {
    gridError: 'Įvyko klaida, nepavyko užkrauti bazės ciklų sąrašo',
    studyProgram: 'Programa',
    cycle: 'Ciklas',
    department: 'Bazė',
    studyYear: 'Studijų metai',
    accreditationTo: 'Akreditacija iki',
    minResidentCount: 'Min. rezidentų skaičius',
    maxResidentCount: 'Max. rezidentų skaičius',
    cycleCredits: 'Ciklo kreditai',
    departmentCredits: 'Bazės kreditai',
    addCycle: 'Priskirti ciklą'
  },
  cycle: {
    formTitle: 'Ciklas',
    name: 'Pavadinimas',
    englishName: 'Pavadinimas angliškai',
    studyProgram: 'Programa',
    coordinatingStudyProgram: 'Koordinuojanti programa',
    code: 'Kodas',
    cycle: 'Ciklas',
    cycles: 'Ciklai',
    credits: 'Kreditai',
    compulsory: 'Privalomas',
    studyYears: 'Studijų metai',
    department: 'Bazė',
    showInvalid: 'Rodyti negaliojančius',
    departmentCountGrid: 'Bazės',
    departmentCount: 'Priskirtos bazės',
    cycleDepartments: 'Ciklo bazės',
    abilityCount: 'Įgūdžiai',
    caseCount: 'Atvejai',
    theoreticalPartCount: 'Teorinė dalis',
    years: 'Studijų metai',
    gridDetailsError: 'Įvyko klaida, nepavyko užkrauti ciklo informacijos',
    gridError: 'Įvyko klaida, nepavyko užkrauti ciklų sąrašo',
    loadError: 'Įvyko klaida, nepavyko užkrauti ciklo informacijos',
    saveError: 'Įvyko klaida, nepavyko išsaugoti ciklo informacijos',
    saveSuccess: 'Ciklo informacija sėkmingai išsaugota',
    deleteError: 'Įvyko klaida, nepavyko ištrinti ciklo',
    type: 'Ciklo tipas',
    description: 'Aprašymas',
    departments: 'Ciklo bazės',
    validFrom: 'Galioja nuo',
    validTo: 'Galioja iki',
    firstYear: '1 metai',
    secondYear: '2 metai',
    thirdYear: '3 metai',
    fourthYear: '4 metai',
    fifthYear: '5 metai',
    sixthYear: '6 metai',
    seventhYear: '7 metai',
    cycleCase: 'Ciklo atvejis',
    cases: 'Ciklo atvejai',
    case: 'Atvejis',
    caseDiagnoses: 'Diagnozių',
    caseDiagnosis: 'Diagnozė',
    caseDiagnosisCode: 'Kodas',
    minCaseRequiredNumber: 'Min. skaičius',
    cycleSkill: 'Ciklo įgūdis',
    skills: 'Ciklo įgūdžiai',
    skill: 'Įgūdis',
    minSkillRequiredLevelA: 'A lygis',
    minSkillRequiredLevelB: 'B lygis',
    minSkillRequiredLevelC: 'C lygis',
    minSkillRequiredLevelD: 'D lygis',
    minSkillRequiredLevelE: 'E lygis',
    minSkillRequiredLevelATitle: 'A',
    minSkillRequiredLevelBTitle: 'B',
    minSkillRequiredLevelCTitle: 'C',
    cycleTheoreticalPart: 'Ciklo teorinė dalis',
    theoreticalPart: 'Teorinė dalis',
    theoreticalParts: 'Ciklo teorinės dalys',
    theoreticalPartType: 'Tipas',
    theoreticalPartName: 'Pavadinimas',
    theoreticalPartTeacher: 'Dėstytojas',
    theoreticalPartTeachers: 'Dėstytojai',
    validationYears: 'Būtina pasirinkti bent vienerius studijų metus.',
    validationEmptyCase: 'Užpildykite tuščio ciklo atvejo duomenis arba jį pašalinkite iš sąrašo.',
    validationMinSkillsLevel: 'Bendras A, B ir C lygių skaičius turi būti didesnis nei 0.',
    validationEmptySkillAbility: 'Užpildykite tuščio ciklo įgūdžio duomenis arba jį pašalinkite iš sąrašo.',
    validationEmptyTheoreticalPart: 'Užpildykite tuščio ciklo teorinės dalies duomenis arba ja pašalinkite iš sąrašo.',
    cycleCaseDialogMessage: 'Ar tikrai norite pašalinti atvejį iš ciklo?',
    cycleCaseDiagnosisDialogMessage: 'Ar tikrai norite pašalinti diagnozę iš atvejo?',
    cycleSkillAbilityDialogMessage: 'Ar tikrai norite pašalinti įgūdį iš ciklo?',
    cycleTheoreticalPartDialogMessage: 'Ar tikrai norite pašalinti teorinę dalį iš ciklo?',
    tabstripCasesTitle: 'Atvejai',
    tabstripAbilitiesTitle: 'Įgūdžiai',
    tabstripTheoreticalPartsTitle: 'Teorinės dalys',
    cycleDialogMessage: 'Ar tikrai norite pašalinti ciklą?',
    capitalCaseError: 'Pavadinimas negali būti sudarytas tik iš didžiųjų raidžių',
    abbreviation: 'Trumpinys',
    studyProgramCode: 'Programos kodas',
    cycleNumber: 'Ciklo eilės nr.',
    allowedSymbols: 'Leistini simboliai yra skaičiai ir .',
    cannotBeginWithDot: 'Kodas negali prasidėti tašku',
    cannotEndWithDot: 'Kodas negali baigtis tašku',
    cannotHaveMultipleDots: 'Kodas negali turėti keletos taškų',
    assignedToCompetency: 'Ciklas yra priskirtas prie kompetencijos',
    assignedToStudyPlan: 'Ciklas yra priskirtas prie studijų plano',
    cycleChildren: 'Dėstoma studijų programose',
    teachingType: 'Dėstoma',
    childStudyProgramValidationDuplicate: 'Studijų programa pasirinkta keletą kartų',
    taughtIn: 'Į kiek programų įtraukta',
    cycleIsBeingCopied: 'Ciklas yra kopijuojamas',
    requiresManagersConfirmation: 'Reikalingas įgūdžių ir atvejų tvirtinimas'
  },
  residentcycles: {
    cycle: 'Ciklas',
    cycleType: 'Tipas',
    cycleCredits: 'Ciklo kreditai',
    studentCredits: 'Bazės kreditai/Atliks',
    departmentName: 'Bazė',
    assignedCredits: 'priskirta kreditų',
    finishedCredits: 'surinkta kreditų',
    dateFromTo: 'Nuo - Iki',
    status: 'Būsena',
    studyYear: 'Studijų metai',
    saveError: 'Įvyko klaida, nepavyko išsaugoti residento ciklo informacijos',
    saveSuccess: 'Rezidento ciklo informacija sėkmingai išsaugota',
    credits: 'Kreditai',
    department: 'Bazė',
    departmentCredits: 'Bazės kreditai',
    creditsWillBeExecuted: 'Bus vykdoma kreditų',
    formTitle: 'Rezidento ciklai',
    student: 'Rezidentas',
    studyPlanYear: 'Studijų plano metai',
    notSpecify: 'Nenurodyti ',
    loadError: 'Nepavyko pakrauti rezidentų ciklo sąrašo',
    badDateFromInterval: 'Data nuo nėra studijų plano intervale',
    yearFirstLetter: ' m. ',
    NotStarted: 'Nepradėtas',
    Started: 'Pradėtas',
    Submitted: 'Pateiktas',
    Evaluating: 'Vertinamas',
    Finished: 'Baigtas',
    deleteSuccess: 'Rezidento ciklas sėkmingai pašalintas',
    deleteError: 'Įvyko klaida, nepavyko pašalinti rezidento ciklo'
  },
  student: {
    studyProgram: 'Programa',
    resident: 'Rezidentas',
    year: 'Studijų metai',
    progress: 'Progresas',
    firstName: 'Vardas',
    lastName: 'Pavardė',
    gridError: 'Įvyko klaida. Nepavyko užkrauti rezidentų sąrašo.',
    studentNumber: 'Bil. numeris',
    activeCycles: 'Aktyvūs ciklai',
    credits: 'Kreditai',
    studyProgramLabel: 'Studijų programa',
    number: 'Numeris',
    cycles: 'Ciklai',
    cycle: 'Ciklas',
    resident: 'Rezidentas',
    residents: 'Rezidentai',
    status: 'Būsena',
    competencies: 'PK'
  },
  StudyPlan: {
    studyProgram: 'Programa',
    studyPlan: 'Studijų planas',
    studyPlans: 'Studijų planai',
    isApproved: 'Būsena',
    credits: 'Kreditai',
    studyYear: 'Studijų metai',
    areCreditsMatching: 'Rodyti tik su neatitinkančiais kreditais',
    confirmed: 'Patvirtintas',
    notConfirmed: 'Nepatvirtintas',
    shorthandYear: 'm.',
    cycles: 'Ciklai',
    year: 'Studijų pradžios metai'
  },
  StudyPlanCycle: {
    gridTitle: 'Studijų plano ciklai',
    cycle: 'Ciklas',
    code: 'Kodas',
    status: 'Būsena',
    year: 'Metai',
    studyYear: 'Studijų metai',
    credits: 'Kreditai',
    studyDuration: 'Studijų trukmė',
    studyProgram: 'Programa',
    assignedResidents: 'Priskirti rezidentai',
    departments: 'Bazės',
    type: 'Tipas',
    gridError: 'Įvyko klaida! Nepavyko užkrauti ciklų informacijos.',
    gridDetailsError: 'Įvyko klaida! Nepavyko užkrauti ciklo bazių informacijos.',
    assignSuccess: 'Ciklas priskirtas sėkmingai.',
    assignError: 'Įvyko klaida! Nepavyko priskirti ciklo.',
    approve: 'Patvirtinti',
    approveError: 'Įvyko klaida! Nepavyko patvirtinti studijų plano.',
    studyPlan: 'Studijų planas',
    studyPlans: 'Studijų planai',
    studyPlanCycle: 'Studijų plano ciklas',
    studyPlanCycleStudent: 'Rezidentas',
    name: 'Pavadinimas',
    students: 'Rezidentai',
    minMaxStudents: 'Min.-Maks. rez. sk.',
    cycleAssignation: 'Ciklų pridėjimas',
    studyPlanCycleRemoveDialogMessage: 'Ar tikrai norite pašalinti ciklą iš studijų plano?',
    findCycles: 'Ieškoti ciklų',
    addSelectedCycles: 'Pridėti pasirinktus ciklus',
    selectedStudents: 'Pasirinkta studentų: ',
    theoreticalParts: 'Teorinės dalys',
    removeTheoreticalPartDialogMessage: 'Ar tikrai norite pašalinti teorinę dalį iš studijų plano ciklo',
    plannedDate: 'Planuojama data',
    plannedTime: 'Planuojamas laikas',
    link: 'Nuoroda',
    cycleManagers: 'Ciklo vadovai',
    cycleManager: 'Vadovas',
    cycleManagerAlreadyExists: 'Toks vadovas jau yra nurodytas anksčiau',
    firstName: 'Vardas',
    lastName: 'Pavardė',
    addCycleManager: 'Pridėti ciklo vadovą',
    confirmDeleteManager: 'Ar tikrai norite pašalinti pasirinktą ciklo vadovą?'
  },
  StudyPlanCycleStudent: {
    keyword: 'Vardas, pavardė',
    firstName: 'Vardas',
    lastName: 'Pavardė',
    department: 'Bazė',
    dateFrom: 'Data nuo',
    dateTo: 'Data iki',
    credits: 'Kreditai',
    year: 'Studijų metai',
    isAlreadyAdded: 'Priskirtas cikle',
    status: 'Būsena',
    student: 'Rezidentas',
    students: 'Rezidentai',
    addWithManager: 'Pridėti su vadovu',
    addWithoutManager: 'Pridėti be vadovo',
    exceedingDepartmentCreditsValidation: 'Nurodyti kreditai viršija pasirinktos bazės kreditus',
    exceedingDepartmentStudentCountValidation: 'Pasirinktoje bazėje nėra pakankamai vietų pasirinktiems rezidentams',
    studentCyclePeriodCollision: 'Ciklo laikotarpis kertasi su kitais rezidento ciklais',
    failedToAddStudents: 'Nepavyko pridėti studentų:',
    cycle: 'Ciklas',
    saveDialogTitle: 'Pasirinkite ciklo vadovą',
    cycleManager: 'Ciklo vadovas',
    failedToChangeManager: 'Nepavyko pakeisti ciklo vadovo studentams:'
  },
  StudyPlanCycleStudentOverviewPage: {
    status: 'Būsena',
    cycle: 'Ciklas',
    credits: 'Kreditai',
    cycleManager: 'Ciklo vadovas',
    studentDepartments: 'Bazės',
    department: 'Bazė',
    dateFrom: 'Nuo',
    dateTo: 'Iki',
    credits: 'Kreditai',
    year: 'Metai',
    deleteConfirmDialogTitle: 'Ištrinti rezidento ciklo bazę',
    deleteConfirmDialogText: 'Ar tikrai norite ištrinti pasirinktą rezidento ciklo bazę?',
    addNew: 'Naujas'
  },
  User: {
    gridError: 'Įvyko klaida. Nepavyko užkrauti vartotojų teisių sąrašo.',
    user: 'Asmuo',
    users: 'Asmenys',
    role: 'Rolė',
    notStudy: 'Rodyti nestudijuojančius',
    firstName: 'Vardas',
    lastName: 'Pavardė',
    department: 'Įstaiga',
    lastActionDate: 'Paskutinio veiksmo data',
    firstLogin: 'Pirmas prisijungimas',
    loadError: 'Įvyko klaida, nepavyko užkrauti naudotojo informacijos',
    saveError: 'Įvyko klaida, nepavyko išsaugoti naudotojo informacijos',
    saveSuccess: 'Naudotojo informacija sėkmingai išsaugota',
    formTitle: 'Naudotojai',
    notStudy: 'Nestudijuoja',
    administrator: 'Dienyno tvarkytojas',
    manager: 'Vadovas',
    student: 'Studentas',
    description: 'Aprašymas',
    deleteError: 'Nepavyko ištrinti vartotojo',
    userDialogMessage: 'Ar tikrai norite pašalinti vartotoją?',
    confirmImpersonateDialogMessage: 'Ar tikrai norite prisijungti šiuo vartotoju?',
    personalCode: 'Asmens kodas',
    searchUser: 'Ieškoti LSMUSIS',
    userAddedFrom: 'Pridėtas iš LSMUSIS',
    key: 'raktas',
    hasActiveStudy: 'Turi aktyvių studijų',
    stampNumber: 'Spaudo nr',
    userHasNoUsername: 'Vartotojas neturi prisijungimo vardo',
    email: 'El. paštas',
    type: 'Tipas',
    externalUser: 'Išorinis vertintojas',
    status: 'Būsena',
    showExternal: 'Rodyti išorinius',
    showMentor: 'Rodyti mentorius',
    showManager: 'Rodyti rezidento vadovus',
    person: 'Asmuo',
    externalUserAbbreviation: 'Išor. Vert.',
    activate: 'Aktyvuoti',
    deactivate: 'Deaktyvuoti',
    approve: 'Patvirtinti',
    logInAs: 'Prisijungti kaip',
    requests: 'Prašymai',
    invalidStampNumber: 'Neteisingas spaudo numeris',
    employee: 'Universiteto darbuotojas'
  },
  UserSearchDialog: {
    search: 'Paieška',
    add: 'Pridėti',
    close: 'Uždaryti',
    title: 'Paieška LSMUSIS'
  },
  TlkSearchDialog: {
    title: 'Tlk kodų paieška',
    search: 'Paieška'
  },
  StudyPlanCycleDepartment: {
    formTitle: 'Studijų plano ciklo bazė',
    studyPlanCycleDepartment: 'Ciklo bazė',
    studyProgram: 'Programa',
    cycle: 'Ciklas',
    credits: 'Kreditai',
    studyYear: 'Studijų metai',
    department: 'Bazė',
    cycleCredits: 'Ciklo kreditai',
    departmentCredits: 'Bazės kreditai',
    cycleManager: 'Ciklo vadovas',
    residentManagers: 'Rezidento vadovai',
    residentManager: 'Rezidento vadovas',
    minResidentCount: 'Min. rezidentų sk.',
    maxResidentCount: 'Maks. rezidentų sk.',
    confirmDeleteDialog: 'Ar tikrai norite pašalinti šią studijų plano ciklo bazę?',
    validationMinMax: 'Min. rezidentų sk. didesnis už maks. rezidentų sk.',
    validationDepartmentCredits: 'Bazės kreditų negali būti daugiau nei ciklo kreditų',
    validationStudentManagerAlreadyExists: 'Toks vadovas jau yra nurodytas anksčiau',
    parentDepartmentWarning: 'Prašome pasirinkti konkretų padalinį ar konkrečią kliniką'
  },
  StudyPlanCycleResident: {
    gridError: 'Įvyko klaida. Nepavyko užkrauti studijų plano ciklo rezidentų sąrašo.',
    status: 'Būsena',
    resident: 'Rezidentas',
    date: 'Data',
    dateFrom: 'Nuo',
    dateTo: 'Iki',
    dateRange: 'Nuo - Iki',
    year: 'Kursas',
    studyYear: 'Studijų metai',
    studyProgram: 'Studijų programa',
    department: 'Bazė',
    departmentCredits: 'Bazės kreditai',
    creditsForCompletion: 'Bus vykdoma kreditų',
    gridStatus: 'Būsena (bazės)',
    loadError: 'Įvyko klaida, nepavyko užkrauti studijų plano ciklo rezidento informacijos',
    saveError: 'Įvyko klaida, nepavyko išsaugoti studijų plano ciklo rezidento informacijos',
    saveSuccess: 'Studijų plano ciklo rezidento informacija sėkmingai išsaugota',
    formTitle: 'Studijų plano ciklo rezidentas',
    credits: 'Kreditai',
    dialogConfirmDeleteStudent: 'Ar tikrai norite pašalinti pasirinktus rezidentus iš šio studijų plano ciklo?',
    assign: 'Priskirti',
    assigned: 'Priskirta',
    notAssigned: 'Nepriskirta',
    cycleManager: 'Ciklo vadovas',
    changeCycleManager: 'Pakeisti ciklo vadovą',
    change: 'Pakeisti'
  },
  myStudies: {
    tabstripStudyProgramsTitle: 'Studijų programos',
    studyProgram: 'Studijų programa',
    durationInYears: 'Trukmė metais',
    studyProgramShorthand: 'Studijų programa',
    studyYearShorthand: 'Studijų metai',
    number: 'Numeris',
    cycles: 'Ciklai',
    assignedCredits: 'priskirta kreditų',
    finishedCredits: 'surinkta kreditų',
    notSpecify: 'nenurodyti',
    studyYear: 'studijų metai',
    cycle: 'Ciklas',
    cycleCredits: 'Ciklo kreditai',
    dateFrom: 'Nuo',
    dateTo: 'Iki',
    myCycles: 'Mano ciklai',
    status: 'Būsena',
    myStudies: 'Mano studijos',
    studyPrograms: 'Studijų programos',
    studyProgram: 'Studijų programa',
    studyStatus: 'Studijų būsena',
    credits: 'Kreditai',
    noStudiesHeader: 'Nerastos studijos',
    noStudiesMessage: 'Jūs neturite jokių studijų'
  },
  studentCycleCompletion: {
    manager: 'Rezidento vadovas',
    date: 'Data',
    evaluate: 'Įvertinti',
    evaluation: 'Bendras įvertinimas',
    managerEvaluations: 'Kaip rezidentas vertina su juo dirbusius rezidento vadovus?',
    evaluateCycle: 'Įvertinti ciklą',
    submitCycle: 'Pateikti įvertinimui',
    cycleQualityEvaluation: 'Kaip rezidentas vertina įvykdyto ciklo kokybę?',
    cycleManagerEvaluation: 'Kaip ciklo vadovas įvertino įvykdytą rezidento ciklą?',
    previewReport: 'Peržiūrėti ataskaitą',
    submitReport: 'Pateikti ataskaitą',
    evaluatedOn: 'Įvertinta',
    surveyTooltipInfo: 'Užbaigiant ciklą, rezidentas įvertina su juo dirbusius rezidento vadovus, ciklo kokybę ir ciklo vadovą. Siekiant užtikrinti vadovų objektyvumą, šie rezidento įvertinimai jiems pateikiami nuasmeninti.',
    preview: 'Peržiūrėti',
    notEvaluated: 'Neįvertinta',
    finishCycle: 'Užbaigti ciklą',
    confirmSubmitCycleComplete: 'Ar tikrai norite pateikti ataskaitą?',
    confirmSubmitCycleIncomplete: 'Jūs užregistravote ne visus reikalaujamus įgūdžius ir atvejus. Ar tikrai norite pateikti ciklo ataskaitą vadovo įvertinimui?',
    statusHistory: 'Būklių istorija',
    managerApprovals: 'Prašyti rezidento vadovo patvirtinti su juo įgytus įgūdžius ir atvejus?',
    cycleManagerMissingWarningMessage: 'Šiam ciklui nėra priskirtas ciklo vadovas, kreipkitės į programos studijų administratorių.',
    requestSkillsAndCasesApprovalTitle: 'Patvirtinkite',
    requestSkillsAndCasesApprovalMessage: 'Prašyti, kad rezidento vadovas (-ė) {0} patvirtintų su juo (-a) įgytus įgūdžius ir atvejus?',
    requestApproval: 'Prašyti patvirtinti',
    approvalRequestTooltipInfo: 'Užbaigiant ciklą, rezidentas gali prašyti rezidento vadovo patvirtinti su juo įgytus įgūdžius ir atvejus.',
    cycleCompletionTooltip: 'Norėdami pateikti ciklą ciklo vadovo vertinimui, turite gauti įgūdžių/atvejų patvirtinimą iš bent vieno jūsų rezidentų vadovų.',
    missingDepartmentError: 'Nėra parinktos bazės, kreipkitės į studijų administratorių'
  },
  studentCycleTheoreticalPart: {
    haveCompleted: 'Įvykdžiau',
    completed: 'Įvykdytas',
    incomplete: 'Neįvykdytas',
    linkToMoreInformation: 'Nuoroda į detalesnę informaciją',
    completedAfterCycleSubmission: 'Įvykdytas po ciklo pateikimo'
  },
  studentCycle: {
    dateFrom: 'Data nuo',
    dateTo: 'Data iki',
    period: 'Laikotarpis',
    department: 'Bazė',
    departments: 'Bazės',
    cycleType: 'Tipas',
    cycleCredits: 'Ciklo kreditai',
    cycleStatus: 'Ciklo būsena',
    cycleStatusComment: 'Vadovo pastaba',
    cycle: 'Ciklas',
    cycles: 'Ciklai',
    skill: 'Įgūdis',
    case: 'Atvejis',
    tabSkills: 'Įgūdžiai',
    tabCases: 'Atvejai',
    tabTheoreticalParts: 'Teorija',
    tabCycleCompletion: 'Užbaigimas',
    studentManager: 'Rezidento vadovas',
    medicalHistoryNumber: 'Ligos istorijos nr.',
    date: 'Data',
    skillConfirmDialogTitle: 'Pasirinktų įgūdžių registravimas',
    skillEditDialogTitle: 'Įgūdžio redagavimas',
    skillDeleteDialogMessage: 'Ar tikrai norite pašalinti pasirinktą įgūdį?',
    skillRegisterErrorMessage: 'Nepavyko pridėti {0} iš {1} įgūdžių. Sėkmingai pridėti {2}',
    skillDeleteErrorMessage: 'Nepavyko ištrinti pasirinkto įgūdžio',
    caseConfirmDialogTitle: 'Pasirinktų atvejų registravimas',
    caseEditDialogTitle: 'Atvejo redagavimas',
    caseDeleteDialogMessage: 'Ar tikrai norite pašalinti pasirinktą atvejį?',
    caseRegisterErrorMessage: 'Nepavyko pridėti {0} iš {1} atvejų. Sėkmingai pridėti {2}',
    caseDeleteErrorMessage: 'Nepavyko ištrinti pasirinkto atvejo',
    cycleSkillDeleteDialogMessage: 'Ar tikrai norite pašalinti pasirinktus papildomus įgūdžius?',
    cycleSkillDeleteErrorMessage: 'Nepavyko ištrinti papildomų įgūdžių',
    cycleCaseDeleteDialogMessage: 'Ar tikrai norite pašalinti pasirinktus papildomus atvejus?',
    cycleCaseDeleteErrorMessage: 'Nepavyko ištrinti papildomų atvejų',
    validationDateOutOfCycleRange: 'Data išeina iš ciklo ribų',
    validationNumberTooLong: 'Numeris yra per ilgas',
    addSkillFromAnotherCycle: 'Pridėti įgūdį iš kito ciklo',
    addCaseFromAnotherCycle: 'Pridėti atvejį iš kito ciklo',
    creditsShort: 'kr.',
    theoreticalPartRegisterDialogMessage: 'Ar tikrai norite fiksuoti teorinės dalies įvykdymą?',
    enterNewMentor: 'Įvesti naują mentorių',
    chooseExisting: 'Rinktis egzistuojantį',
    stampNumber: 'Spaudo numeris',
    name: 'Vardas',
    surname: 'Pavardė',
    cycleCode: 'Ciklo kodas',
    competencies: 'Kompetencijos',
    levelATooltip: 'A – gydytojas rezidentas gerai moka atlikti savarankiškai.',
    levelBTooltip: 'B – gydytojas rezidentas gerai moka atlikti su priežiūra.',
    levelCTooltip: 'C – gydytojas rezidentas yra matęs ar dalyvavęs atliekant.',
    noAssignedCasesMessage: 'Šiame cikle nėra priskirtų atvejų',
    email: 'El. paštas',
    invalidStampNumber: 'Neteisingas spaudo numeris',
    invalidString: 'Netinkamai užpildytas laukas'
  },
  managedStudents: {
    resident: 'Rezidentas',
    status: 'Ciklo būsena',
    cycle: 'Ciklas',
    generalMark: 'Įvertinimas',
    year: 'metai',
    creditsShort: 'kr.'
  },
  managedStudent: {
    myResidents: 'Mano rezidentai',
    myResident: 'Mano rezidentas',
    resident: 'Rezidentas',
    dateFromTo: 'Laikotarpis',
    department: 'Bazė',
    departments: 'Bazės',
    type: 'Tipas',
    cycleCredits: 'Ciklo kreditai',
    studyProgram: 'Studijų programa',
    year: 'Kursas',
    evaluation: 'Įvertinimas',
    cycleStatus: 'Ciklo būsena',
    evaluate: 'Įvertinti',
    tabstripSkillsTitle: 'Įgūdžiai',
    tabstripCasesTitle: 'Atvejai',
    tabstripTheoreticalPartsTitle: 'Teorija',
    residentPortfolio: 'Rezidento dienynas',
    returnForCorrection: 'Grąžinti taisymui',
    comment: 'Komentaras',
    residentsCycle: 'Rezidento ciklas',
    approve: 'Tvirtinti',
    return: 'Grąžinti',
    approvalRequests: 'Prašymai patvirtinti',
    skillsAndCasesStatuses: 'Įgūdžių ir atvejų būsenos',
    approveDialogMessage: 'Ar tikrai norite patvirtinti įgūdžius ir atvejus?'
  },
  studyPlanCycleTheoreticalPart: {
    formTitle: 'Studijų plano ciklo teorinė dalis',
    studyPlans: 'Studijų planai',
    studyPlan: 'Studijų planas',
    studyPlanCycle: 'Studijų plano ciklas',
    studyPlanCycleTheoreticalPart: 'Teorinė dalis',
    theoreticalPart: 'Teorinė dalis',
    plannedDate: 'Planuojama data',
    plannedTime: 'Planuojamas laikas',
    link: 'Nuoroda',
    invalidLink: 'Blogai įvesta nuoroda',
    lecturer: 'Dėstytojas',
    tabstripStudentsTitle: 'Rezidentai',
    name: 'Vardas',
    surname: 'Pavardė',
    status: 'Būsena',
    date: 'Data',
    location: 'Vieta',
    studyPrograms: 'Studijų programos',
    studyProgramChecklistInfo: 'Jeigu teorinė dalis yra dėstoma ir kitose programose, pažymėkite kuriose programose turėtų būti atvaizduojama'
  },
  theoreticalPart: {
    gridError: 'Įvyko klaida. Nepavyko užkrauti teorinių dalių sąrašo.',
    approve: 'Patvirtinti',
    type: 'Tipas',
    name: 'Pavadinimas',
    resident: 'Rezidentas',
    lecturer: 'Dėstytojas',
    lecturers: 'Dėstytojai',
    studyProgram: 'Studijų programa',
    year: 'Studijų metai',
    status: 'Būsena',
    studyProgram: 'Programa',
    showUnapproved: 'Rodyti nepatvirtintus',
    unknownType: 'Blogas tipas',
    unknownStatus: 'Blogas statusas',
    plannedDate: 'Planuojama data',
    plannedTime: 'Planuojamas laikas',
    link: 'Nuoroda',
    cycle: 'Ciklas',
    theoreticalParts: 'Teorinės dalys',
    theoreticalPart: 'Teorinė dalis',
    tabstripStudentsTitle: 'Rezidentai',
    forename: 'Vardas',
    surname: 'Pavardė',
    date: 'Data',
    completed: 'Įvykdytas',
    incomplete: 'Neįvykdytas',
    location: 'Vieta',
    dateFrom: 'Nuo',
    dateTo: 'Iki',
    locationUnknown: 'Nenurodyta',
    linkToMoreInformation: 'Nuoroda į detalesnę informaciją',
    isPlanned: 'Planuojama'
  },
  survey: {
    manager: 'Vadovas',
    survey: 'Klausimynas',
    surveys: 'Klausimynai',
    managerEvaluation: 'Kaip rezidentas vertina ciklo metu su juo dirbusį rezidento vadovą?',
    cycleEvaluation: 'Kaip rezidentas vertina įvykdyto ciklo kokybę, savybes ir praktinį darbą ciklo metu?',
    residentEvaluation: 'Kaip ciklo vadovas įvertino įvykdytą rezidento ciklą?',
    credits: 'Kreditai',
    code: 'Kodas',
    from: 'Nuo',
    to: 'Iki',
    name: 'Pavadinimas',
    resident: 'Rezidentas',
    cycleCompletion: 'Ciklo užbaigimas',
    reportTitle: 'Savybių ir praktinio darbo įvertinimas',
    grade: 'Pažymys',
    managerEvaluationSurvey: 'Rezidentas vertina vadovą',
    completedCycleQualityEvaluation: 'Rezidentas vertina ciklo kokybę',
    residentEvaluationSurvey: 'Vadovas vertina rezidento ciklą',
    residentPortfolio: 'Rezidento dienynas',
    competencyAssignation: 'Kompetencijų priskyrimas',
    minCount: 'Minimalus skaičius',
    competencies: 'Kompetencijos',
    tieredCompetencies: 'Pakopinės kompetencijos',
    competency: 'Kompetencija',
    cycle: 'Ciklas',
    myResidents: 'Mano rezidentai',
    residentCycle: 'Rezidento ciklas'
  },
  cyclePlan: {
    resident: 'Rezidentas',
    department: 'Bazė',
    studyYear: 'Studijų metai',
    studyProgram: 'Studijų programa',
    emptyList: 'Įrašų nerasta arba nepasirinkta studijų programa.',
    studyYear: 'Studijų metai',
    creditsTotal: 'Viso kreditų'
  },
  subcompetency: {
    code: 'Kodas',
    name: 'Pavadinimas',
    group: 'Kompetencijų sritis',
    subcompetency: 'Subkompetencija',
    subcompetencies: 'Subkompetencijos',
    studyProgram: 'Studijų programa',
    competencies: 'Kompetencijos',
    description: 'Aprašymas',
    subcompetencyDialogMessage: 'Ar tikrai norite pašalinti subkompetenciją?'
  },
  competency: {
    abbreviation: 'Kodas',
    name: 'Pavadinimas',
    studyProgram: 'Studijų programa',
    competency: 'Kompetencija',
    formTitle: 'Kompetencija',
    competencies: 'Kompetencijos',
    subcompetency: 'Subkompetencija',
    subcompetencies: 'Subkompetencijos',
    description: 'Aprašymas',
    survey: 'Klausimynas',
    surveys: 'Klausimynai',
    note: 'Pastaba',
    number: 'Numeris',
    question: 'Klausimas',
    questions: 'Klausimai',
    note: 'Pastaba',
    status: 'Būsena',
    type: 'Tipas',
    minCount: 'Minimalus kiekis',
    competencySurveyDialogMessage: 'Ar tikrai norite pašalinti apklausą iš kompetencijos?',
    competencySurveyQuestionDialogMessage: 'Ar tikrai norite pašalinti klausimą iš anketos?',
    competencyCycleDialogMessage: 'Ar tikrai norite pašalinti ciklą iš kompetencijos?',
    surveyQuestion: 'Klausimas',
    cycle: 'Ciklas',
    cycles: 'Ciklai',
    credits: 'Kreditai',
    addSelectedCycles: 'Pridėti pasirinktus ciklus',
    addSelectedSubcompetencies: 'Pridėti pasirinktas subkompetencijas',
    code: 'Kodas',
    group: 'Sritis',
    subcompetencyAssignation: 'Subkompetencijų pridėjimas',
    surveyDialogMessage: 'Ar tikrai norite pašalinti apklausą?',
    invalidSubcompetencyWarningMessage: 'Nurodyta subkompetencija nėra priskirta prie klausimyno kompetencijos.',
    invalidSubcompetencyWarningMessage2: 'Nurodyta subkompetencija nėra priskirta prie nei vienos iš klausimyno kompetencijų.',
    subcompetencyMismatch: 'Ši subkompetencija nebėra priskirta',
    duplicateCompetenciesSelected: 'Kompetencija pasirinkta keletą kartų',
    competencyDialogMessage: 'Ar tikrai norite pašalinti kompetenciją?',
    tieredCompetency: 'Pakopinė kompetencija',
    tieredCompetencies: 'Pakopinės kompetencijos',
    tabstripQuestionsTitle: 'Klausimai',
    surveyQuestionDialogMessage: 'Ar tikrai norite pašalinti klausimą iš anketos?',
    surveyType: 'Klausimyno tipas',
    surveyCode: 'Klausimyno kodas',
    minEvaluationCount: 'Min. įvertinimų skaičius',
    tieredCompetency: 'Pakopinė kompetencija',
    greatestExistingQuestionNumber: 'Didžiausias jau egzistuojantis klausimo numeris',
    questionCount: 'Klausimų skaičius'
  },
  studentCompetency: {
    code: 'Kodas',
    name: 'Pavadinimas',
    subcompetencies: 'Subkompetencijos',
    cycles: 'Ciklai',
    status: 'Būsena',
    progress: 'Progresas',
    completedCyclesLabel: 'Baigta ciklų:',
    completedSubcompetenciesLabel: 'Baigta subkompetencijų:',
    overallProgress: 'Bendras progresas',
    myCompetencies: 'Mano kompetencijos',
    myCompetency: 'Mano kompetencija',
    tabstripCyclesTitle: 'Ciklai',
    tabstripSubcompetenciesTitle: 'Subkompetencijos',
    tabstripSurveysTitle: 'Vertinimai',
    tabstripCertificateTitle: 'Pažymėjimas',
    abbreviation: 'Kodas',
    studyProgram: 'Studijų programa',
    type: 'Tipas',
    credits: 'Kreditai',
    cycleStatus: 'Ciklo būsena',
    group: 'Sritis',
    description: 'Aprašymas',
    generalMark: 'Pažymys',
    markDate: 'Pažymio data',
    isCompleted: 'Baigta',
    requestEvaluationDialogTitle: 'Prašyti įvertinimo',
    comment: 'Komentaras',
    manager: 'Vadovas',
    completion: 'Pateikta/Įvertinta/Reikalaujama',
    submitted: 'Pateikta',
    survey: 'Vertinimas',
    surveys: 'Įvertinimai',
    competency: 'Kompetencija',
    minCount: 'Minimalus kiekis',
    request: 'Prašyti',
    evaluation: 'Įvertinimas',
    evaluation2: 'Vertinimas',
    requestDate: 'Pateikta (data)',
    submitForEvaluation: 'Pateikti įgijimui',
    required: 'Reikalaujama',
    submittedEvaluated: 'Pateikta/Įvertinta',
    submitForEvaluationDialogMessage: 'Ar tikrai norite pateikti prašymą suteikti pakopinę kompetenciją?',
    tieredCompetencyAbbr: 'PK',
    myTieredCompetency: 'Mano PK',
    tieredCompetency: 'Pakopinė kompetencija',
    completed: 'Užbaigta',
    required: 'Reikalaujama',
    evaluations: 'Vertinimai',
    evaluationDate: 'Įvertinta (data)',
    submittedForEvaluation: 'Pateikta vertinimui',
    firstName: 'Vardas',
    surname: 'Pavardė',
    email: 'El. paštas',
    requestExternalUser: 'Prašyti išorinio vertintojo',
    requestInternalUser: 'Prašyti vidinio vertintojo',
    evaluator: 'Vertintojas',
    department: 'Bazė',
    role: 'Rolė',
    externalUser: 'Išorinis vartotojas',
    coordinator: 'Koordinatorius',
    commentFieldLengthExceeds: 'Laukas negali viršyti 300 simbolių',
    qrCode: 'QR Kodas',
    shoqQrCode: 'Rodyti QR kodą',
    competencyCertificate: 'Kompetencijos sertifikatas',
    noCertificate: 'Sertifikatas nerastas',
    stampNumber: 'Spaudo numeris',
    getReport: 'Ataskaita',
    invalidStampNumber: 'Neteisingas spaudo numeris',
    parentDepartmentWarning: 'Prašome pasirinkti konkretų padalinį ar konkrečią kliniką'
  },
  mySurvey: {
    resident: 'Rezidentas',
    surveyType: 'Tipas',
    requestDate: 'Pateikta vertinimui',
    comment: 'Komentaras',
    surveyStatus: 'Būsena',
    status: 'Būsena',
    mySurveys: 'Prašymai įvertinti',
    mySurvey: 'Prašymas įvertinti',
    evaluation: 'Įvertinimas',
    surveyCode: 'Klausimyno kodas',
    name: 'Pavadinimas',
    code: 'Kodas',
    resident: 'Rezidentas',
    evaluationsDescription: 'Vertinimų paaiškinimas',
    evaluation0Description: 'Nėra atsakymo',
    evaluation1Description: 'Stebi kaip atliekama',
    evaluation2Description: 'Atlieka su visapusiška rezidento vadovo pagalba',
    evaluation3Description: 'Atlieka su minimalia rezidento vadovo pagalba',
    evaluation4Description: 'Atlieka  savarankiškai',
    evaluation5Description: 'Moko jaunesniuosius gydytojus rezidentus',
    survey360evaluation0Description: 'Nevertinama',
    survey360evaluation1Description: 'Visiškai nesutinku',
    survey360evaluation2Description: 'Nesutinku',
    survey360evaluation3Description: 'Iš dalies sutinku',
    survey360evaluation4Description: 'Sutinku',
    survey360evaluation5Description: 'Visiškai sutinku',
    tieredCompetency: 'Pakopinė kompetencija'
  },
  competencyRequest: {
    competency: 'Kompetencija',
    studyProgram: 'Studijų programa',
    resident: 'Rezidentas',
    requestDate: 'Prašymo data',
    status: 'Būsena',
    competencyRequests: 'PK prašymai',
    competencyRequest: 'PK prašymas',
    code: 'Kodas',
    name: 'Pavadinimas',
    surveys: 'Klausimynai',
    cycles: 'Ciklai',
    student: 'Studentas',
    type: 'Tipas',
    submitted: 'Pateikta',
    evaluated: 'Įvertinta',
    required: 'Reikalaujama',
    completed: 'Baigta',
    evaluatedPastTense: 'įvertino',
    register: 'Registruoti',
    return: 'Grąžinti',
    deny: 'Nesuteikti',
    sign: 'Pasirašyti',
    evaluations: 'Vertinimai',
    resident: 'Rezidentas',
    showAcquired: 'Rodyti įgytus',
    comment: 'Komentaras',
    returnDialogTitle: 'Grąžinti kompetenciją.',
    getReport: 'Ataskaita',
    competencyGrantMessage: 'Ar tikrai norite pasirašyti rezidento pakopinę kompetenciją elektroniniu parašu?',
    competenciesGrantMessage: 'Ar tikrai norite pasirašyti pakopines kompetencijas elektroniniu parašu?'
  },
  approvalRequest: {
    status: 'Būsena',
    resident: 'Rezidentas',
    cycle: 'Ciklas',
    requestDate: 'Pateikimo data'
  },
  userRequest: {
    userRequestDialogMessage: 'Ar tikrai norite pašalinti šį prašymą įvertinti?',
    survey: 'Klausimynas',
    resident: 'Rezidentas',
    role: 'Rolė',
    evaluated: 'įvertino'
  },
  systemMessages: {
    isSuccessfullyAdded: 'yra sėkmingai pridėti',
    isNotAddedBecauseOfErrors: 'yra nepridėti, nes yra klaidų.',
    successfullyAddedRecords: 'Sėkmingai priskirta įrašų:',
    successfullyAdded: 'Sėkmingai priskirta:'
  },
  drawer: {
    home: 'Pagrindinis',
    departments: 'Bazės',
    profile: 'Mano profilis',
    settings: 'Nustatymai',
    cycles: 'Ciklai',
    studyPrograms: 'Studijų programos',
    students: 'Rezidentai',
    studyPlans: 'Studijų planai',
    theoreticalParts: 'Teorinės dalys',
    users: 'Asmenys',
    myStudies: 'Mano studijos',
    evaluateCycle: 'Įvertinti ciklą',
    requests: 'Prašymai',
    cyclePlans: 'Ciklų planai',
    subcompetencies: 'Subkompetencijos',
    competencies: 'Pakopinės',
    competenciesList: 'Pakopinės',
    surveys: 'Klausimynai',
    myCompetencies: 'Mano PK',
    mySurveys: 'Įvertinti kompetencijas',
    competencyRequests: 'Suteikti PK',
    evaluators: 'Vertintojai',
    approvalRequests: 'Patvirtinti įgūdžius, atvejus',
    notifications: 'Pranešimai',
    tooltips: {
      home: 'Pagrindinis',
      departments: 'Bazės',
      profile: 'Mano profilis',
      settings: 'Nustatymai',
      cycles: 'Ciklai',
      studyPrograms: 'Studijų programos',
      students: 'Rezidentai',
      studyPlans: 'Studijų planai',
      theoreticalParts: 'Teorinės dalys',
      users: 'Asmenys',
      myStudies: 'Mano studijos',
      evaluateCycle: 'Įvertinti ciklą',
      requests: 'Prašymai',
      cyclePlans: 'Ciklų planai',
      subcompetencies: 'Subkompetencijos',
      competencies: 'Pakopinės',
      competenciesList: 'Pakopinės',
      surveys: 'Klausimynai',
      myCompetencies: 'Mano PK',
      mySurveys: 'Įvertinti kompetencijas',
      competencyRequests: 'Suteikti PK',
      evaluators: 'Vertintojai',
      approvalRequests: 'Prašymai patvirtinti įgūdžius ir atvejus',
      notifications: 'Pranešimų sąrašas'
    }
  },
  userDrawer: {
    studyPrograms: 'Studijų programos',
    logOut: 'Atsijungti'
  },
  custom: {
    add: 'Pridėti',
    close: 'Uždaryti',
    edit: 'Redaguoti',
    delete: 'Ištrinti',
    loading: 'Kraunama',
    teamEfficiency: 'Komandos efektyvumas',
    teamMembers: 'Komandos nariai',
    dashboard: 'Valdymo skydas',
    planning: 'Planavimas',
    info: 'Informacija',
    language: 'Kalba',
    trend: 'Tendencijos',
    volume: 'Apimtis',
    myTeam: 'Mano komanda',
    allTeams: 'Visos komandos',
    teamCalendar: 'Komandos kalendorius',
    saveChanges: 'Išsaugoti pakeitimus',
    save: 'Saugoti',
    cancel: 'Atšaukti',
    title: 'Rezidentūros dienynas',
    startFreeTrial: 'Aktyvuoti nemokamą versiją',
    buyNow: 'Pirkti dabar',
    getSource: 'Gauti pirminį kodą',
    demoInfo: 'Ši aplikacija sukurta pasinaudojant KendoReact komponentais',
    firstName: 'Vardas',
    middleName: 'Antras vardas',
    lastName: 'Pavardė',
    email: 'Elektroninis paštas',
    phoneNumber: 'Telefono numeris',
    country: 'Šalis',
    biography: 'Biografija',
    public: 'Viešinamas',
    team: 'Komanda',
    reset: 'Atstatyti',
    employee: 'Darbuotojas',
    contactName: 'Kontakto vardas',
    jobTitle: 'Pareigybė',
    status: 'Statusas',
    settings: 'Nustatymai',
    performance: 'Našumas',
    rating: 'Reitingas',
    engagement: 'Įsitraukimas',
    budget: 'Biudžetas',
    contacts: 'Kontaktai',
    address: 'Adresas',
    phone: 'Telefonas',
    gridSearch: 'Sąrašo paieška',
    exportExcel: 'Eksportuoti į Excel',
    exportPdf: 'Eksportuoti į PDF',
    copy: 'Kopijuoti',
    search: 'Paieška',
    logout: 'Atsijungti',
    confirm: 'Patvirtinkite',
    clearFilter: 'Išvalyti filtrą',
    approve: 'Patvirtinti',
    notSpecified: 'Nenurodyta'
  },
  datepicker: {
    toggleCalendar: 'Kalendorius'
  },
  calendar: {
    today: 'Šiandien'
  },
  dateinput: {
    increment: 'Padidinti',
    decrement: 'Sumažinti'
  },
  datetimepicker: {
    date: 'Data',
    time: 'Laikas',
    cancel: 'Atšaukti',
    set: 'Pasirinkti'
  },
  numerictextbox: {
    increment: 'Padidinti',
    decrement: 'Sumažinti'
  },
  timepicker: {
    now: 'Dabar',
    set: 'Pasirinkti',
    cancel: 'Atšaukti'
  },
  grid: {
    groupPanelEmpty: 'Atitempkite stulpelio pavadinima pagal kurį norite grupuoti',
    noRecords: 'Įrašų nerasta.',
    pagerFirstPage: 'Pirmas puslapis',
    pagerPreviousPage: 'Atgal',
    pagerNextPage: 'Pirmyn',
    pagerLastPage: 'Paskutinis puslapis',
    pagerPage: 'Puslapis',
    pagerOf: 'iš',
    pagerItems: 'Įrašas',
    pagerInfo: '{0} - {1} iš {2} įrašų',
    pagerItemsPerPage: 'Įrašų per puslapį',
    filterEqOperator: 'Lygu',
    filterNotEqOperator: 'Nelygu',
    filterIsNullOperator: 'Įvestas',
    filterIsNotNullOperator: 'Neįvestas',
    filterIsEmptyOperator: 'Tuščias',
    filterIsNotEmptyOperator: 'Netuščias',
    filterStartsWithOperator: 'Prasideda su',
    filterContainsOperator: 'Turi',
    filterNotContainsOperator: 'Neturi',
    filterEndsWithOperator: 'Baigiasi su',
    filterGteOperator: 'Didesnis už arba lygus',
    filterGtOperator: 'Didesnis už',
    filterLteOperator: 'Mažesnis už arba lygus',
    filterLtOperator: 'Mažesnis už',
    filterIsTrue: 'Yra',
    filterIsFalse: 'Nėra',
    filterBooleanAll: '(Visi)',
    filterAfterOrEqualOperator: 'Yra po arba lygus',
    filterAfterOperator: 'Yra po',
    filterBeforeOperator: 'Yra prieš',
    filterBeforeOrEqualOperator: 'Yra prieš arba lygus',
    filterSubmitButton: 'Filtruoti',
    filterClearButton: 'Išvalyti filtrą',
    filterAndLogic: 'ir',
    filterOrLogic: 'arba',
    filterTitle: 'Filtras',
    sortAscending: 'Rykiuoti didėjančia tvarka',
    sortDescending: 'Rykiuoti mažėjančia tvarka',
    view: 'Peržiūrėti',
    edit: 'Redaguoti',
    delete: 'Trinti'
  },
  scheduler: {
    editorValidationRequired: 'Laukas yra privalomas',
    editorValidationStart: 'Pradžios data negali buti didesnė už pabaigos datą',
    editorValidationEnd: 'Pabaigos data negali buti mažesnė už pabaigos datą',
    allEvents: 'Visi įvykiai',
    allDay: 'visą dieną',
    today: 'šiandiena',
    dayViewTitle: 'Diena',
    weekViewTitle: 'Savaitė',
    monthViewTitle: 'Mėnesis',
    workWeekViewTitle: 'Darbo savaitė',
    timelineViewTitle: 'Laiko juosta',
    agendaViewTitle: 'Legenda',
    deleteTitle: 'Trinti pavadinimą',
    previousTitle: 'Senas pavadinimas',
    nextTitle: 'Sekantis',
    showFullDay: 'Rodyti visą dieną',
    showWorkDay: 'Rodyti darbo valandas',
    editorOccurrence: 'Redaguoti dabartinį įvykį',
    editorSeries: 'Redaguoti grupę įvykių',
    editorRecurringConfirmation: 'Ar norite redaguoti vieną įvykį ar jų grupę?',
    editorRecurringDialogTitle: 'Redaguoti pasikartojantį įvykį',
    editorSave: 'Saugoti',
    editorCancel: 'Atšaukti',
    editorDelete: 'Pašalinti',
    editorTitle: 'Įvykis',
    editorEventTitle: 'Pavadinimas',
    editorEventStart: 'Pradžia',
    editorEventStartTimeZone: 'Pradžios laiko zona',
    editorEventEnd: 'Pabaiga',
    editorEventEndTimeZone: 'Pabaigos laiko zona',
    editorEventAllDay: 'Visos dienos įvykis',
    editorEventDescription: 'Aprašymas',
    editorEventSeparateTimeZones: 'Pabaiga skirtingoje laiko zonoje',
    editorEventTimeZone: 'Nurodyti laiko zoną',
    recurrenceEditorRepeat: 'Pasikartojantis',
    recurrenceEditorDailyInterval: 'Diena(-os)',
    recurrenceEditorDailyRepeatEvery: 'Kartoti kas dieną',
    recurrenceEditorWeeklyInterval: 'Savaitė(-ės)',
    recurrenceEditorWeeklyRepeatEvery: 'Kartoti kas savaitę',
    recurrenceEditorWeeklyRepeatOn: 'Kartoti kai',
    recurrenceEditorMonthlyDay: 'Diena',
    recurrenceEditorMonthlyInterval: 'Mėnesis(-iai)',
    recurrenceEditorMonthlyRepeatEvery: 'Kartoti kas mėnesį',
    recurrenceEditorMonthlyRepeatOn: 'Kartoti kai',
    recurrenceEditorYearlyOf: 'iš',
    recurrenceEditorYearlyRepeatEvery: 'Kartoti kas metus',
    recurrenceEditorYearlyRepeatOn: 'Kartoti kai',
    recurrenceEditorYearlyInterval: 'Metai',
    recurrenceEditorFrequenciesDaily: 'Kasdieninis',
    recurrenceEditorFrequenciesMonthly: 'Mėnesinis',
    recurrenceEditorFrequenciesNever: 'Niekada',
    recurrenceEditorFrequenciesWeekly: 'Savaitinis',
    recurrenceEditorFrequenciesYearly: 'Metinis',
    recurrenceEditorOffsetPositionsFirst: 'Pirmas',
    recurrenceEditorOffsetPositionsSecond: 'Antras',
    recurrenceEditorOffsetPositionsThird: 'Trečias',
    recurrenceEditorOffsetPositionsFourth: 'Ketvirtas',
    recurrenceEditorOffsetPositionsLast: 'Paskutinis',
    recurrenceEditorWeekdaysDay: 'Diena',
    recurrenceEditorWeekdaysWeekday: 'Savaitės diena',
    recurrenceEditorWeekdaysWeekendday: 'Savaitgalio diena',
    recurrenceEditorEndAfter: 'Po',
    recurrenceEditorEndOccurrence: 'Įvykiai',
    recurrenceEditorEndLabel: 'Pabaiga',
    recurrenceEditorEndNever: 'Niekada',
    recurrenceEditorEndOn: 'Kai',
    deleteConfirmation: 'Ar tikrai norite ištrinti šį įvykį?',
    deleteRecurringConfirmation: 'Ar norite pašalinti tik šį įvykį ar jų grupę?',
    deleteOccurrence: 'Pašaltini pasirinktą įvykį',
    deleteSeries: 'Pašalinti įvykių grupę',
    deleteDialogTitle: 'Pašalinti įvykį',
    deleteRecurringDialogTitle: 'Pašalinti pasikartojantį įvykį',
    dateTitle: 'Data',
    timeTitle: 'Laikas',
    eventTitle: 'Įvykis',
    noEvents: 'įvykių nėra'
  },
  filter: {
    eqOperator: 'Lygus',
    notEqOperator: 'Nelygus',
    isNullOperator: 'Neužpildytas',
    isNotNullOperator: 'Užpildytas',
    isEmptyOperator: 'Tuščias',
    isNotEmptyOperator: 'Netuščias',
    startsWithOperator: 'Prasideda su',
    containsOperator: 'Turi',
    notContainsOperator: 'Neturi',
    endsWithOperator: 'Baigiasi su',
    gteOperator: 'Didesnis nei arba lygus',
    gtOperator: 'Didesnis nei',
    lteOperator: 'Mažesnis nei arba lygus',
    ltOperator: 'Mažesnis nei',
    isTrue: 'Yra',
    isFalse: 'Nėra',
    afterOrEqualOperator: 'Yra po arba lygus',
    afterOperator: 'Yra po',
    beforeOperator: 'Yra prieš',
    beforeOrEqualOperator: 'Yra prieš arba lygus',
    andLogic: 'Ir',
    orLogic: 'Arba',
    addExpression: 'Pridėti išraišką',
    addGroup: 'Pridėti grupę',
    close: 'Uždaryti'
  },
  editor: {
    bold: 'Paryškintas',
    italic: 'Kursyvas',
    underline: 'Pabrauktas',
    strikethrough: 'Perbrauktas',
    subscript: 'Apatinis indeksas',
    superscript: 'Viršutinis indeksas',
    unlink: 'Pašalinti nuorodą',
    undo: 'Anuliuoti',
    redo: 'Perdaryti',
    fontSize: 'Šrifto dydis',
    fontName: 'Šrifto pavadinimas',
    format: 'Formatas',
    alignLeft: 'Lygiuoti kairėje',
    alignRight: 'Lygiuoti dešinėje',
    alignCenter: 'Lygiuoti centre',
    alignJustify: 'Abipuse lygiuotė',
    indent: 'Įtrauka',
    outdent: 'Ištrauka',
    orderedList: 'Įterpti rykiuotą sąršą',
    bulletList: 'Įterpti sąršą',
    createTable: 'Sukurti lentelę',
    insertTableHint: 'Sukurti {0} x {1} lentelę',
    addRowBefore: 'Įterpti eilutę virš',
    addRowAfter: 'Įterpti eilutę po',
    addColumnBefore: 'Įterpti stulepį kairėje',
    addColumnAfter: 'Įterpti stulpelį dešinėje',
    deleteRow: 'Pašalinti eilutę',
    deleteColumn: 'Pašalinti stulpelį',
    deleteTable: 'Pašalinti lentelę',
    mergeCells: 'Sujungti langelius',
    splitCell: 'Atskirti langelius',
    hyperlink: 'Įterpti nuorodą',
    'hyperlink-dialog-title': 'Įterpti nuorodą',
    'hyperlink-dialog-content-address': 'Adresas',
    'hyperlink-dialog-content-title': 'Pavadinimas',
    'hyperlink-dialog-content-newwindow': 'Atidaryti nuorodo kitame lange',
    'hyperlink-dialog-cancel': 'Atšaukti',
    'hyperlink-dialog-insert': 'Įterpti',
    image: 'Įterpti paveikslėlį',
    'image-dialog-title': 'Įterpti paveikslėlį',
    'image-address': 'Adresas',
    'image-title': 'Pavadinimas',
    'image-altText': 'Papildomas tekstas',
    'image-width': 'Plotis (px)',
    'image-height': 'Aukštis (px)',
    'image-cancel': 'Atšaukti',
    'image-insert': 'Įterpti',
    viewHtml: 'Peržiūrėti HTML',
    'viewHtml-dialog-title': 'Peržiūrėti HTML',
    'viewHtml-cancel': 'Atšaukti',
    'viewHtml-update': 'Atnaujinti'
  },
  dropdowns: {
    nodata: 'DUOMENŲ NERASTA.',
    clear: 'Valyti'
  },
  upload: {
    cancel: 'Atšaukti',
    clearSelectedFiles: 'Išvalyti',
    dropFilesHere: 'Įtempkite norimus įkelti failus.',
    headerStatusUploaded: 'Baigta',
    headerStatusUploading: 'Įkeliama...',
    invalidFileExtension: 'Netinkamas failo formatas.',
    invalidFiles: 'Netinkamas failas(-ai). Patikrinkite failų reikalavimus.',
    invalidMaxFileSize: 'Failas per didelis.',
    invalidMinFileSize: 'Failas per mažas.',
    remove: 'Pašalinti',
    retry: 'Bandyti iš naujo',
    select: 'Pasirinkti failus...',
    uploadSelectedFiles: 'Įkelti',
    total: 'Viso',
    files: 'Failai'
  },
  pager: {
    itemsPerPage: 'Įrašų per puslapį',
    info: '{0} - {1} iš {2} įrašų',
    firstPage: 'Eiti į pirmą puslapį',
    previousPage: 'Atgal',
    nextPage: 'Pirmyn',
    lastPage: 'Eiti į paskutinį puslapį',
    page: 'Puslapis',
    of: 'iš'
  },
  treelist: {
    filterClearButton: 'Valyti',
    filterEqOperator: 'Lygus',
    filterNotEqOperator: 'Nelygus',
    filterIsNullOperator: 'Neužpildytas',
    filterIsNotNullOperator: 'Užpildytas',
    filterIsEmptyOperator: 'Tuščias',
    filterIsNotEmptyOperator: 'Netuščias',
    filterStartsWithOperator: 'Prasideda su',
    filterContainsOperator: 'Turi',
    filterNotContainsOperator: 'Neturi',
    filterEndsWithOperator: 'Baigiasi su',
    filterGteOperator: 'Didesnis nei arba lygus',
    filterGtOperator: 'Didesnis nei',
    filterLteOperator: 'Mažesnis nei arba lygus',
    filterLtOperator: 'Mažesnis nei',
    filterIsTrue: 'Yra',
    filterIsFalse: 'Nėra',
    filterBooleanAll: '(Visi)',
    filterAfterOrEqualOperator: 'Yra po ar lygus',
    filterAfterOperator: 'Yra po',
    filterBeforeOperator: 'Yra prieš',
    filterBeforeOrEqualOperator: 'Yra prieš arba lygus',
    noRecords: 'Nėra įrašų'
  },
  daterangepicker: {
    swapStartEnd: 'Sukeisti pradžios ir pabaigos datas',
    start: 'Pradžia',
    end: 'Pabaiga',
    separator: ' '
  },
  sortable: {
    noData: 'Nėra duomenų'
  },
  notifications: {
    notifications: 'Pranešimai',
    notification: 'Pranešimas',
    creationDate: 'Įvedimo data',
    visibleFrom: 'Skelbti nuo',
    deleteNotification: 'Ištrinti pranešimą ',
    deleteNotificationConfirmation: 'Ar tikrai norite ištrinti šį pranešimą?',
    studyPrograms: 'Studijų programos',
    studyProgramsAll: 'Studijų programos',
    studyYears: 'Studijų metai',
    studyYearsAll: 'Studijų metai',
    studyStatuses: 'Studijų būsena',
    studyStatusesAll: 'Studijų būsena',
    title: 'Pavadinimas',
    text: 'Tekstas',
    deleted: 'Ištrintas'
  },
  myNotifications: {
    noRecords: 'Šiuo metu pranešimų nėra.'
  }
};
