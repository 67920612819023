import React from 'react';
import { connect } from 'react-redux';
import { GridToolbar } from '@progress/kendo-react-grid';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridSize } from '../../shared/components';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { loadStudyPlanCycleDepartmentList, openStudyPlanCycleDepartmentConfirmDialog, closeStudyPlanCycleDepartmentConfirmDialog, removeStudyPlanCycleDepartmentConfirmDialog } from '../actions';
import { Text } from '../../../ui';
import { withRouter } from '../../../components/withRouter';

class StudyPlanCycleDepartmentList extends React.Component {
  constructor(props) {
    super(props);
    this.expandChange = this.expandChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleConfirmDialogRemove = this.handleConfirmDialogRemove.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyPlanCycleDepartmentList({
      studyPlanId: this.props.studyPlanId,
      studyPlanCycleId: this.props.studyPlanCycleId
    });
  }

  render() {
    const { studyPlanCycleDepartments, loading, isConfirmDialogVisible } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={studyPlanCycleDepartments}
          isPageable={false}
          columns={[
            {
              field: 'departmentName',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycle.name')
            },
            {
              field: 'credits',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('StudyPlanCycle.credits'),
              minGridWidth: GridSize.small
            },
            {
              field: 'assignedStudents',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('StudyPlanCycle.minMaxStudents'),
              cell: this.renderStudentCount
            }
          ]}
          actions={[
            { icon: 'edit', onClick: this.handleEditClick },
            { icon: 'delete', onClick: this.handleRemoveClick, disabledOn: e => !e.dataItem.canBeDeleted }
          ]}
        >
          <GridToolbar>
            <Button themeColor='primary' onClick={this.handleAddClick}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>
        </Grid>
        {isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('StudyPlanCycleDepartment.confirmDeleteDialog')}</Text>
            <DialogActionsBar>
              <Button themeColor='primary' onClick={this.handleConfirmDialogRemove}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  expandChange(event) {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  };

  handleAddClick() {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycles/${this.props.studyPlanCycleId}/departments/create`);
  }

  handleEditClick(row) {
    this.props.navigate(`/study_plans/${this.props.studyPlanId}/cycles/${this.props.studyPlanCycleId}/departments/${row.dataItem.id}`);
  }

  handleRemoveClick(row) {
    this.props.openStudyPlanCycleDepartmentConfirmDialog(row.dataItem);
  }

  handleConfirmDialogRemove() {
    this.props.removeStudyPlanCycleDepartmentConfirmDialog({
      studyPlanId: this.props.studyPlanId,
      studyPlanCycleId: this.props.studyPlanCycleId
    });
  }

  handleConfirmDialogClose() {
    this.props.closeStudyPlanCycleDepartmentConfirmDialog();
  }

  renderStudentCount(e) {
    const { dataItem, className } = e;
    return (
      <td className={className}>
        {dataItem.maxStudentCount
          ? `${dataItem.minStudentCount}-${dataItem.maxStudentCount}`
          : dataItem.assignedStudents
        }
      </td>
    );
  }
}

const mapStateToProps = (state) => ({
  studyPlanCycleDepartments: state.studyPlanCycleDepartmentList.studyPlanCycleDepartments,
  loading: state.studyPlanCycleDepartmentList.loading,
  isConfirmDialogVisible: state.studyPlanCycleDepartmentList.isConfirmDialogVisible
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyPlanCycleDepartmentList: (payload) => dispatch(loadStudyPlanCycleDepartmentList(payload)),
  openStudyPlanCycleDepartmentConfirmDialog: (payload) => dispatch(openStudyPlanCycleDepartmentConfirmDialog(payload)),
  closeStudyPlanCycleDepartmentConfirmDialog: (payload) => dispatch(closeStudyPlanCycleDepartmentConfirmDialog(payload)),
  removeStudyPlanCycleDepartmentConfirmDialog: (payload) => dispatch(removeStudyPlanCycleDepartmentConfirmDialog(payload))
});

registerForIntl(StudyPlanCycleDepartmentList);
registerForLocalization(StudyPlanCycleDepartmentList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleDepartmentList));
