import * as React from 'react';
import { ListPage } from '../../../ui';
import { CyclePlanListFilter, CyclePlanList } from './index';

class CyclePlanListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<CyclePlanListFilter/>} minContentWidth={1024}>
        <CyclePlanList/>
      </ListPage>
    );
  }
}

export default CyclePlanListPage;