import { pagingSettings } from '../../../utils/GridPagingUtils';
import CompetencyListPageActionTypes from '../actions/competency-list-page-action-types';

const initialState = {
  loading: false,
  competencies: null,
  competenciesCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: null,
  canWrite: false,
  sorting: null,

  filterCompetenciesComboBoxLoading: false,
  filterCompetenciesComboBoxCancelToken: null,
  filterCompetenciesComboBox: null,
  isConfirmDialogVisible: false,
  selectedCompetencyId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencyListPageActionTypes.LOAD_COMPETENCY_LIST_START:
      return {
        ...state,
        loading: true
      };
    case CompetencyListPageActionTypes.LOAD_COMPETENCY_LIST_END:
      return {
        ...state,
        loading: false,
        competencies: action.payload.competencies,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take,
        canWrite: action.payload.canWrite
      };
    case CompetencyListPageActionTypes.FILTER_COMPETENCY_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case CompetencyListPageActionTypes.SEARCH_COMPETENCY_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterCompetenciesComboBoxLoading: true
      };
    case CompetencyListPageActionTypes.SEARCH_COMPETENCY_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterCompetenciesComboBoxLoading: false,
        filterCompetenciesComboBox: action.payload.studyPrograms
      };
    case CompetencyListPageActionTypes.TOGGLE_COMPETENCY_LIST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedCompetencyId: action.payload?.id,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    case CompetencyListPageActionTypes.SORT_COMPETENCY_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    default:
      return state;
  }
};