import React, { PureComponent } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { ErrorMessage, Form, SuccessMessage } from '../common';
import { FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';
import { SCREEN_SIZES as ScreenSizes } from '../../../resources/screenSize';
import { Loader } from '../../../features/shared/components';

const DialogActionsBar = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0 8px 0;
  column-gap: 8px;
`;

const DialogButton = styled(Button)`
  flex: 1;
`;

export default class FormDialog extends PureComponent {

  constructor() {
    super();
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  render() {
    const localizationService = provideLocalizationService(this);
    let errorMessage = '';
    if (this.props.validationResult && this.props.validationResult.errorMessage) {
      errorMessage = this.props.validationResult.errorMessage;
    }
    if (this.props.errorMessage) {
      errorMessage = this.props.errorMessage;
    }
    const successMessage = this.props.successMessage;
    const confirmButtonText = this.props.confirmButtonText || localizationService.toLanguageString('buttons.save');
    const closeButtonText = this.props.closeButtonText || localizationService.toLanguageString('buttons.close');
    const dialogWidth = window.innerWidth < ScreenSizes.XS ? window.innerWidth : this.props.width;
    return (
      <Dialog
        title={this.props.title}
        onClose={this.handleCloseClick}
        width={dialogWidth}
        height={this.props.height}
        minWidth={this.props.minWidth}
      >
        <Form
          onSubmit={this.props.onSubmit}
          onCancel={this.props.onCancel}
          key={JSON.stringify(this.props.initialValues)}
          initialValues={this.props.initialValues}
          validator={this.props.validator}
          localizationService={localizationService}
          validationResult={this.props.validationResult}
          ignoreModified={true}
          render={(formRenderProps) => (
            <>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
              <FormElement>
                {this.props.render(formRenderProps)}
                <DialogActionsBar>
                  <DialogButton type={'submit'} themeColor='primary' disabled={!formRenderProps.allowSubmit || this.props.loading}>{confirmButtonText}</DialogButton>
                  {!this.props.hideCloseButton &&
                    <DialogButton type={'button'} disabled={this.props.loading} onClick={this.handleCloseClick}>{closeButtonText}</DialogButton>
                  }
                </DialogActionsBar>
              </FormElement>
            </>
          )}
        />
        {this.props.loading && <Loader/> }
      </Dialog>
    );
  }

  handleCloseClick() {
    if (this.props.onCancel && !this.props.loading) {
      this.props.onCancel();
    }
  }
}

registerForLocalization(FormDialog);