import * as React from 'react';
import { TimePicker as KendoTimePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';

const TimePicker = (props) => {
  const { value, valid, validationMessage, ...rest } = props;
  let normalizedValue = value;
  if (value === '' || value === undefined) {
    normalizedValue = null;
  }
  return (
    <fieldset className={'k-form-fieldset'}>
      <KendoTimePicker
        {...rest}
        formatPlaceholder={'short'}
        value={normalizedValue}
        valid={valid}
      />
      {!valid && <Error>{validationMessage}</Error>}
    </fieldset>
  );
};

export default TimePicker;