import axios from 'axios';
import StudyProgramCycleListActionTypes from './study-program-cycle-list-action-types';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadStudyProgramCycleList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramCycleListActionTypes.LOAD_STUDY_PROGRAM_CYCLE_LIST_START });
  const state = getState().studyProgramCycleList;
  try {
    state.studyProgramCyclesCancelToken?.cancel();
    state.studyProgramCyclesCancelToken = axios.CancelToken.source();
    const studyProgramCycles = await api.get(`api/cycles`, { studyProgramId: payload.studyProgramId, showInvalid: false, includeExternalyTaught: true }, state.studyProgramCyclesCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.STUDY_PROGRAMS_WRITE);
    dispatch({
      type: StudyProgramCycleListActionTypes.LOAD_STUDY_PROGRAM_CYCLE_LIST_END,
      payload: {
        studyProgramCycles: studyProgramCycles ? studyProgramCycles.data : [],
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyProgramCycleListActionTypes.LOAD_STUDY_PROGRAM_CYCLE_LIST_END });
      throw error;
    }
  }
};