import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import { FormPage, TextBox, DropDown, TextArea, ComboBox } from '../../../ui';
import { loadSubcompetencyFormPage, saveSubcompetency, loadSubcompetencyGroups, loadStudyPrograms } from '../actions/subcompetency-form-page-actions';
import { withRouter } from '../../../components/withRouter';

class SubcompetencyFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadSubcompetencyFormPage({ subcompetencyId: this.props.params.subcompetencyId });
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('subcompetency.subcompetency')}
        breadcrumbItems={[
          {
            id: 'subcompetencies',
            text: localizationService.toLanguageString('subcompetency.subcompetencies')
          }, {
            id: 'subcompetency',
            text: localizationService.toLanguageString('subcompetency.subcompetency'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.subcompetency}
        localizationService={localizationService}
        renderBackButton={true}
        onBackClick={this.handleBackClick}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={(formRenderProps) => (
          <>
            <Field
              name='name'
              label={localizationService.toLanguageString('subcompetency.name')}
              component={TextArea}
              autoSize={true}
              width='320px'
            />
            <Field
              name='code'
              label={localizationService.toLanguageString('subcompetency.code')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='description'
              label={localizationService.toLanguageString('subcompetency.description')}
              id='description'
              component={TextArea}
              autoSize={true}
              width='320px'
            />
            <Field
              name='group'
              label={localizationService.toLanguageString('subcompetency.group')}
              component={DropDown}
              data={this.props.subcompetencyGroups}
              loading={this.props.subcompetencyGroupsLoading}
              textField='name'
              valueField='id'
              width='320px'
            />
            <Field
              name='studyProgram'
              label={localizationService.toLanguageString('competency.studyProgram')}
              component={ComboBox}
              loading={this.props.studyProgramsLoading}
              data={this.props.studyPrograms}
              onFilter={this.props.loadStudyPrograms}
              textField='name'
              valueField='id'
              width='320px'
            />
          </>
        )}
      />
    );
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};

    if (!values.group) {
      errors.group = localizationService.toLanguageString('validation.required');
    }
    if (!values.code) {
      errors.code = localizationService.toLanguageString('validation.required');
    } else if (values.code.length > 10) {
      errors.code = localizationService.toLanguageString('validation.exceedsMaximumLength');
    }
    if (!values.name) {
      errors.name = localizationService.toLanguageString('validation.required');
    }
    if (!values.studyProgram) {
      errors.studyProgram = localizationService.toLanguageString('validation.required');
    }
    if (!modified.group
      && !modified.code
      && !modified.name
      && !modified.studyProgram) {
      if (validationResult?.errors.group) {
        errors.group = validationResult?.errors?.group;
      }
      if (validationResult?.errors.code) {
        errors.code = validationResult?.errors.code;
      }
      if (validationResult?.errors.name) {
        errors.name = validationResult?.errors.name;
      }
      if (validationResult?.errors.studyProgramId) {
        errors.studyProgram = validationResult?.errors.studyProgramId;
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'subcompetencies') {
      this.props.navigate(`/subcompetencies`);
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveSubcompetency(payload);
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(SubcompetencyFormPage);

const mapStateToProps = state => ({
  loading: state.subcompetencyFormPage.loading,
  subcompetency: state.subcompetencyFormPage.subcompetency,
  validationResult: state.subcompetencyFormPage.validationResult,
  subcompetencyGroups: state.subcompetencyFormPage.subcompetencyGroups,
  subcompetencyGroupsLoading: state.subcompetencyFormPage.subcompetencyGroupsLoading,
  studyPrograms: state.subcompetencyFormPage.studyPrograms,
  studyProgramsLoading: state.subcompetencyFormPage.studyProgramsLoading
});

const mapDispatchToProps = dispatch => ({
  loadSubcompetencyFormPage: (payload) => dispatch(loadSubcompetencyFormPage(payload)),
  saveSubcompetency: (payload) => dispatch(saveSubcompetency(payload)),
  loadSubcompetencyGroups: (payload) => dispatch(loadSubcompetencyGroups(payload)),
  loadStudyPrograms: (payload) => dispatch(loadStudyPrograms(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubcompetencyFormPage));