import React from 'react';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '../../../ui';

const AddButton = styled(Button)`
  max-width: 33px
`;

const Label = styled.div`
  font-size: 2em;
  font-weight: 400;
`;

export default class FormArrayContainer extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StackLayout
        orientation='vertical'
        rowGap='16px'
      >
        <Label>{this.props.label}</Label>
        {this.props.children}
        <AddButton
          type='button'
          onClick={() => this.props.fields.push()}
          icon='add'
        />
      </StackLayout>
    );
  }
}