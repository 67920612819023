
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-width: 1px 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
`;

export default class FormActions extends React.PureComponent {

  render() {

    return (
      <Container>
        {this.props.children}
      </Container>
    );
  }

};