import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

const Orientation = {
  Horizontal: 'horizontal',
  Vertical: 'vertical'
};

const HorizontalAlignment = {
  Stretch: 'stretch',
  Left: 'left',
  Center: 'center',
  Right: 'right',
  SpaceBetween: 'space-between',
  SpaceAround: 'space-around',
  SpaceEvenly: 'space-evenly'
};

const VerticalAlignment = {
  Stretch: 'stretch',
  Top: 'top',
  Center: 'center',
  Bottom: 'bottom',
  SpaceBetween: 'space-between',
  SpaceAround: 'space-around',
  SpaceEvenly: 'space-evenly'
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-wrap: wrap;
  gap: ${props => `${props.rowGap}`} ${props => `${props.columnGap}`};
  ${props => props.$width && css`
    flex-grow: 0;
    flex-basis: auto;
    width: ${props.$width};
  `}
  ${props => props.$height && css`
    height: ${props.$height};
  `}
  ${props => props.backgroundColor && css`
    background-color: ${props.backgroundColor};
  `}
  ${props => props.column && css`
    grid-column-start: ${props => props.column};
  `}
  ${props => props.columnSpan && css`
    grid-column-end: span ${props => props.columnSpan};
  `}
  ${props => props.row && css`
    grid-row-start: ${props => props.row};
  `}
  ${props => props.rowSpan && css`
    grid-row-end: span ${props => props.rowSpan};
  `}
  ${props => props.margin && css`
    margin: ${props => props.margin};
  `}
`;

const Horizontal = styled(Container)`
  flex-direction: row;
  ${props => props.align === HorizontalAlignment.Stretch && css`
    justify-content: flex-start;
    & > * {
      flex-grow: 1;
    }
  `}
  ${props => props.align === HorizontalAlignment.Left && css`
    justify-content: flex-start;
  `}
  ${props => props.align === HorizontalAlignment.Center && css`
    justify-content: center;
  `}
  ${props => props.align === HorizontalAlignment.Right && css`
    justify-content: flex-end;
  `}
  ${props => props.align === HorizontalAlignment.SpaceBetween && css`
    justify-content: space-between;
  `}
  ${props => props.align === HorizontalAlignment.SpaceAround && css`
    justify-content: space-around;
  `}
  ${props => props.align === HorizontalAlignment.SpaceEvenly && css`
    justify-content: space-evenly;
  `}
  ${props => props.valign === VerticalAlignment.Top && css`
    align-content: stretch;
  `}
  ${props => props.valign === VerticalAlignment.Top && css`
    align-content: flex-start;
  `}
  ${props => props.valign === VerticalAlignment.Center && css`
    align-content: center;
  `}
  ${props => props.valign === VerticalAlignment.Bottom && css`
    align-content: flex-end;
  `}
  ${props => props.valign === VerticalAlignment.SpaceBetween && css`
    align-content: space-between;
  `}
  ${props => props.valign === VerticalAlignment.SpaceAround && css`
    align-content: space-around;
  `}
  ${props => props.valign === VerticalAlignment.SpaceEvenly && css`
    align-content: space-evenly;
  `}
`;

const Vertical = styled(Container)`
  flex-direction: column;
  flex-flow: column wrap;
  align-content: flex-start;
  ${props => props.align === HorizontalAlignment.Stretch && css`
    align-content: stretch;
  `}
  ${props => props.align === HorizontalAlignment.Left && css`
    align-content: flex-start;
  `}
  ${props => props.align === HorizontalAlignment.Center && css`
    align-content: center;
  `}
  ${props => props.align === HorizontalAlignment.Right && css`
    align-content: flex-end;
  `}
  ${props => props.align === HorizontalAlignment.SpaceBetween && css`
    align-content: space-between;
  `}
  ${props => props.align === HorizontalAlignment.SpaceAround && css`
    align-content: space-around;
  `}
  ${props => props.align === HorizontalAlignment.SpaceEvenly && css`
    align-content: space-evenly;
  `}
  ${props => props.valign === VerticalAlignment.Stretch && css`
    justify-content: flex-start;
    & > * {
      flex-grow: 1;
    }
  `}
  ${props => props.valign === VerticalAlignment.Top && css`
    justify-content: flex-start;
  `}
  ${props => props.valign === VerticalAlignment.Center && css`
    justify-content: center;
  `}
  ${props => props.valign === VerticalAlignment.Bottom && css`
    justify-content: flex-end;
  `}
  ${props => props.valign === VerticalAlignment.SpaceBetween && css`
    justify-content: space-between;
  `}
  ${props => props.valign === VerticalAlignment.SpaceAround && css`
    justify-content: space-around;
  `}
  ${props => props.valign === VerticalAlignment.SpaceEvenly && css`
    justify-content: space-evenly;
  `}
`;

export default class WrapLayout extends PureComponent {

  static defaultProps = {
    rowGap: '8px',
    columnGap: '8px',
    orientation: Orientation.Horizontal,
    align: HorizontalAlignment.Stretch,
    valign: VerticalAlignment.Stretch
  }

  render() {
    if (this.props.orientation === Orientation.Horizontal) {
      return (
        <Horizontal
          ref={this.props.forwardedRef}
          className={this.props.className}
          margin={this.props.margin}
          rowGap={this.props.rowGap}
          columnGap={this.props.columnGap}
          align={this.props.align}
          valign={this.props.valign}
          $width={this.props.width}
          $height={this.props.height}
          backgroundColor={this.props.backgroundColor}
          column={this.props.column}
          columnSpan={this.props.columnSpan}
          row={this.props.row}
          rowSpan={this.props.rowSpan}
        >
          {this.props.children}
        </Horizontal>
      );
    } else if (this.props.orientation === Orientation.Vertical) {
      return (
        <Vertical
          ref={this.props.forwardedRef}
          className={this.props.className}
          margin={this.props.margin}
          rowGap={this.props.rowGap}
          columnGap={this.props.columnGap}
          align={this.props.align}
          valign={this.props.valign}
          $width={this.props.width}
          $height={this.props.height}
          backgroundColor={this.props.backgroundColor}
          column={this.props.column}
          columnSpan={this.props.columnSpan}
          row={this.props.row}
          rowSpan={this.props.rowSpan}
        >
          {this.props.children}
        </Vertical>
      );
    }
    return null;
  }

}