import CompetencySubcompetencySelectPageActionTypes from '../actions/competency-subcompetency-select-page-action-types';
import { pagingSettings } from '../../../utils/GridPagingUtils';

const initialState = {
  loading: false,
  competencyId: null,
  studyProgramId: null,
  subcompetencyIds: null,
  availableSubcompetenciesCancelToken: null,
  availableSubcompetencies: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: null,
  selectedIds: [],
  selectedAll: false,
  savingSelectedSubcompetencies: false,
  errorMessage: null,
  successMessage: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencySubcompetencySelectPageActionTypes.LOAD_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START:
      return {
        ...state,
        competencyId: action.payload?.competencyId,
        studyProgramId: action.payload?.studyProgramId,
        subcompetencyIds: action.payload?.subcompetencyIds,
        errorMessage: null,
        successMessage: null
      };
    case CompetencySubcompetencySelectPageActionTypes.FIND_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case CompetencySubcompetencySelectPageActionTypes.FIND_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_END:
      return {
        ...state,
        loading: false,
        availableSubcompetencies: action.payload?.subcompetencies,
        total: action.payload?.total,
        skip: action.payload?.skip !== undefined ? action.payload.skip : state.skip,
        take: action.payload?.take !== undefined ? action.payload.take : state.take
      };
    case CompetencySubcompetencySelectPageActionTypes.FILTER_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE:
      return {
        ...state,
        filter: action.payload
      };
    case CompetencySubcompetencySelectPageActionTypes.SELECT_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE:
      return {
        ...state,
        availableSubcompetencies: action.payload?.availableSubcompetencies,
        selectedIds: action.payload?.selectedIds,
        selectedAll: false
      };
    case CompetencySubcompetencySelectPageActionTypes.SELECT_ALL_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE:
      return {
        ...state,
        availableSubcompetencies: action.payload?.availableSubcompetencies,
        selectedIds: action.payload?.selectedIds,
        selectedAll: !state.selectedAll
      };
    case CompetencySubcompetencySelectPageActionTypes.ADD_SELECTED_SUBCOMPETENCIES_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_START:
      return {
        ...state,
        savingSelectedSubcompetencies: true,
        errorMessage: null,
        successMessage: null
      };
    case CompetencySubcompetencySelectPageActionTypes.ADD_SELECTED_SUBCOMPETENCIES_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE_END:
      return {
        ...state,
        savingSelectedSubcompetencies: false,
        errorMessage: action.payload?.errorMessage,
        successMessage: action.payload?.successMessage,
        subcompetencyIds: action.payload?.subcompetencyIds
      };
    case CompetencySubcompetencySelectPageActionTypes.CLEANUP_COMPETENCY_SUBCOMPETENCY_SELECT_PAGE:
      return initialState;
    default:
      return state;
  }
};