import CompetencyOverviewPageActionTypes from './competency-overview-page-action-types';
import axios from 'axios';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';
import { canWriteStudyProgram } from '../../common/actions';

export const loadCompetencyOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencyOverviewPageActionTypes.LOAD_COMPETENCY_OVERVIEW_PAGE_START });
  const state = getState().competencyOverviewPage;
  try {
    state.competencyCancelToken?.cancel();
    state.competencyCancelToken = axios.CancelToken.source();
    const competency = await api.get(`api/competencies/${payload.competencyId}`, null, state.competencyCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.COMPETENCIES_WRITE)
      && canWriteStudyProgram(competency.studyProgramId, getState().app);
    dispatch({
      type: CompetencyOverviewPageActionTypes.LOAD_COMPETENCY_OVERVIEW_PAGE_END,
      payload: {
        competency,
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CompetencyOverviewPageActionTypes.LOAD_COMPETENCY_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const selectCompetencyOverviewPageTab = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: CompetencyOverviewPageActionTypes.SELECT_COMPETENCY_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};