import axios from 'axios';
import StudyPlanCycleSelectPageActionTypes from './study-plan-cycle-select-page-action-types';
import { formatNumber } from '../../../utils';

export const loadStudyPlanCycleSelectPage = (payload) => async(dispatch, getState, { api }) => {
  const [studyPlan, studyPlanCycles] = await Promise.all([
    api.get(`api/study_plans/${payload.studyPlanId}`),
    api.get(`api/study_plans/${payload.studyPlanId}/cycles`)
  ]);
  dispatch({
    type: StudyPlanCycleSelectPageActionTypes.LOAD_STUDY_PLAN_CYCLE_SELECT_PAGE_START,
    payload: {
      studyPlanId: payload.studyPlanId,
      studyProgramId: studyPlan.studyProgramId,
      studyPlandCycleIds: studyPlanCycles?.data?.map(x => (x.cycleId))
    }
  });
  dispatch(findStudyPlanCycleSelectPage(payload));
};

export const findStudyPlanCycleSelectPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_SELECT_PAGE_START });
  const state = getState().studyPlanCycleSelectPage;
  const query = {
    skip: payload?.skip ?? state.skip,
    limit: payload?.take ?? state.take,
    studyProgramId: state.studyProgramId,
    keyword: state.filter?.keyword,
    cycleType: state.filter?.cycleType?.value,
    showInvalid: state.filter?.showInvalid,
    includeExternalyTaught: true,
    includeUnaffiliated: true
  };
  try {
    state.availableCyclesCancelToken?.cancel();
    state.availableCyclesCancelToken = axios.CancelToken.source();
    const cycles = await api.get(`api/cycles`, query, state.availableCyclesCancelToken.token);
    cycles?.data?.map(cycle => ({
      ...cycle,
      isSelected: state.selectedIds?.includes(cycle.id)
    }));
    dispatch({
      type: StudyPlanCycleSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_SELECT_PAGE_END,
      payload: {
        cycles: cycles
          ?
          cycles.data?.map(cycle => ({
            ...cycle,
            isDisabled: state.studyPlandCycleIds?.includes(cycle.id),
            isSelected: state.studyPlandCycleIds?.includes(cycle.id) ? true : state.selectedAll ? true : state.selectedIds?.includes(cycle.id)
          }))
          : [],
        total: cycles ? cycles.total : 0,
        skip: query.skip,
        take: query.limit
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_SELECT_PAGE_END });
      throw error;
    }
  }
};

export const filterStudyPlanCycleSelectPage = (payload) => (dispatch) => {
  dispatch({
    type: StudyPlanCycleSelectPageActionTypes.FILTER_STUDY_PLAN_CYCLE_SELECT_PAGE,
    payload: payload
  });
  dispatch(findStudyPlanCycleSelectPage());
};

export const clearStudyPlanCycleSelectPage = () => (dispatch) => {
  dispatch({
    type: StudyPlanCycleSelectPageActionTypes.FILTER_STUDY_PLAN_CYCLE_SELECT_PAGE,
    payload: {}
  });
  dispatch(findStudyPlanCycleSelectPage());
};

export const selectStudyPlanCycleSelectPage = (payload) => (dispatch, getState) => {
  const state = getState().studyPlanCycleSelectPage;
  let selectedIds = [...state.selectedIds];
  const availableCycles = state.availableCycles?.map(cycle => {
    let isSelected = cycle.isSelected;
    if (cycle.id === payload.id) {
      const studyPlanIncludes = state.studyPlandCycleIds?.includes(cycle.id);
      isSelected = studyPlanIncludes ? true : !isSelected;
      if (!studyPlanIncludes) {
        if (isSelected) {
          if (selectedIds.indexOf(cycle.id) === -1) {
            selectedIds.push(cycle.id);
          }
        } else {
          const index = selectedIds.indexOf(cycle.id);
          if (index !== -1) {
            selectedIds.splice(index, 1);
          }
        }
      }
    }
    return {
      ...cycle,
      isSelected: isSelected
    };
  });
  dispatch({
    type: StudyPlanCycleSelectPageActionTypes.SELECT_STUDY_PLAN_CYCLE_SELECT_PAGE,
    payload: {
      availableCycles: availableCycles,
      selectedIds: selectedIds
    }
  });
};

export const selectAllStudyPlanCycleSelectPage = () => (dispatch, getState) => {
  const state = getState().studyPlanCycleSelectPage;
  const availableCycles = state.availableCycles?.map(cycle => ({
    ...cycle,
    isSelected: state.studyPlandCycleIds?.includes(cycle.id) ? true : !state.selectedAll
  }));
  const selectedIds = availableCycles.filter(cycle => cycle.isSelected == true).map(cycle => cycle.id);
  dispatch({
    type: StudyPlanCycleSelectPageActionTypes.SELECT_ALL_STUDY_PLAN_CYCLE_SELECT_PAGE,
    payload: {
      availableCycles: availableCycles,
      selectedIds
    }
  });
};

export const addSelectedStudyPlanCycleSelectPage = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleSelectPage;
  dispatch({
    type: StudyPlanCycleSelectPageActionTypes.ADD_SELECTED_CYCLES_STUDY_PLAN_CYCLE_SELECT_PAGE_START
  });
  let errorMessage = null;
  let successMessage = null;
  let errorsCount = 0;
  let studyPlandCycleIds = null;
  let totalCount = 0;
  try {
    const apiModel = state.selectedIds.filter(x => !state.studyPlandCycleIds?.includes(x)).map(item => ({
      cycleId: item,
      studyPlanId: state.studyPlanId
    }));
    if (apiModel?.length > 0) {
      totalCount = apiModel.length;
      const result = await api.post(`api/study_plans/${state.studyPlanId}/cycles`, apiModel);
      if (result) {
        errorsCount = result?.filter(x => x.statusCode === 400 || x.body?.errors?.length > 0)?.length || 0;
      }
    }
    const studyPlanCycles = await api.get(`api/study_plans/${state.studyPlanId}/cycles`);
    studyPlandCycleIds = studyPlanCycles?.data?.map(x => (x.cycleId));
  } finally {
    errorMessage = null;
    if (errorsCount === 0) {
      successMessage = `${formatCycleMessage(totalCount)} ${payload.localizationService.toLanguageString(`systemMessages.isSuccessfullyAdded`)}`;
    } else {
      const successfulCarriersCount = totalCount - errorsCount;
      if (successfulCarriersCount > 0) {
        errorMessage = `${formatCycleMessage(successfulCarriersCount)} ${payload.localizationService.toLanguageString(`systemMessages.isSuccessfullyAdded`)} 
        ${formatCycleMessage(errorsCount)} ${payload.localizationService.toLanguageString(`systemMessages.isNotAddedBecauseOfErrors`)}`;
      } else {
        errorMessage = `${formatCycleMessage(errorsCount)} ${payload.localizationService.toLanguageString(`systemMessages.isNotAddedBecauseOfErrors`)}`;
      }
    }
    dispatch({
      type: StudyPlanCycleSelectPageActionTypes.ADD_SELECTED_CYCLES_STUDY_PLAN_CYCLE_SELECT_PAGE_END,
      payload: {
        errorMessage: errorMessage,
        successMessage: successMessage,
        studyPlandCycleIds: studyPlandCycleIds
      }
    });
    dispatch(filterStudyPlanCycleSelectPage());
  }
};

const formatCycleMessage = (value) => {
  return formatNumber(value, { unitSingular: 'ciklas', unitPlural: 'ciklai' });
};

export const cleanupStudyPlanCycleSelectPage = () => (dispatch) => {
  dispatch({ type: StudyPlanCycleSelectPageActionTypes.CLEANUP_STUDY_PLAN_CYCLE_SELECT_PAGE });
};
