import DepartmentFormPageActionTypes from './department-form-page-action-types';
import ValidationError from '../../../utils/validation-error';

export const loadDepartmentFormPage = ({ departmentId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_START });
  try {
    if (departmentId > 0) {
      const department = await api.get(`api/departments/${departmentId}`);
      dispatch({
        type: DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_END,
        payload: {
          department: {
            ...department,
            accreditationFrom: department.accreditationFrom ? new Date(department.accreditationFrom) : null,
            accreditationTo: department.accreditationTo ? new Date(department.accreditationTo) : null
          }
        }
      });
    } else {
      dispatch({ type: DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_END });
    }
  } catch (error) {
    dispatch({ type: DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_END });
    throw error;
  }
};

export const saveDepartment = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_START });
  const state = getState().departmentFormPage;
  const apiModel = {
    ...payload
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/departments/${id}`, apiModel);
    } else {
      id = await api.post('api/departments', apiModel);
    }
    dispatch({
      type: DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_END,
      payload: {
        department: {
          ...state.department,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_END,
      payload: {
        validationResult,
        department: state.department
      }
    });
  }
};

export const clearDepartmentFormPage = () => async(dispatch) => {
  dispatch({ type: DepartmentFormPageActionTypes.CLEAR_DEPARTMENT_FORM_PAGE });
};