import cycleOverviewPageActionTypes from '../actions/cycle-overview-page-action-types';

const initialState = {
  loading: false,
  cycle: null,
  cycleCancelToken: null,
  selectedTab: 0,
  canWrite: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case cycleOverviewPageActionTypes.LOAD_CYCLE_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case cycleOverviewPageActionTypes.LOAD_CYCLE_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        cycle: action.payload?.cycle,
        canWrite: action?.payload.canWrite
      };
    case cycleOverviewPageActionTypes.SELECT_CYCLE_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    default:
      return state;
  }
};