import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { FilterForm, WrapLayout, StackLayout, TextBox, ComboBox, YearPicker } from '../../../ui';
import {
  filterCyclePlans,
  clearCyclePlansFilter,
  searchCyclePlansFilterDepartments,
  searchCyclePlansFilterResidents,
  searchCyclePlansFilterStudyPrograms
} from '../actions/cycle-plan-list-page-actions';

class ManagedStudentListFilter extends React.PureComponent {

  async componentDidMount() {
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const {
      filter,
      filterCyclePlans,
      clearCyclePlansFilter,
      filterDepartmentsLoading,
      filterDepartments,
      searchCyclePlansFilterDepartments,
      filterResidentsLoading,
      filterResidents,
      searchCyclePlansFilterResidents,
      filterStudyProgramsLoading,
      filterStudyPrograms,
      searchCyclePlansFilterStudyPrograms
    } = this.props;
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterCyclePlans}
        onClear={clearCyclePlansFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='400px'>
              <Field
                name='studyProgram'
                width='348px'
                component={ComboBox}
                label={`${localizationService.toLanguageString('cyclePlan.studyProgram')}*`}
                textField='name'
                dataItemKey='id'
                loading={filterStudyProgramsLoading}
                data={filterStudyPrograms}
                onFilter={searchCyclePlansFilterStudyPrograms}
              />
              <Field
                name='department'
                width='348px'
                component={ComboBox}
                label={localizationService.toLanguageString('cyclePlan.department')}
                textField='name'
                dataItemKey='id'
                loading={filterDepartmentsLoading}
                data={filterDepartments}
                onFilter={searchCyclePlansFilterDepartments}
              />
              <Field
                name='year'
                width='174px'
                component={YearPicker}
                label={localizationService.toLanguageString('cyclePlan.studyYear')}
                defaultValue={new Date()}
              />
            </StackLayout>
            <StackLayout width='400px'>
              <Field
                name='keyword'
                width='348px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
              <Field
                name='resident'
                width='348px'
                component={ComboBox}
                label={localizationService.toLanguageString('cyclePlan.resident')}
                textField='fullName'
                dataItemKey='id'
                loading={filterResidentsLoading}
                data={filterResidents}
                onFilter={searchCyclePlansFilterResidents}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.cyclePlanListPage.filter,
  filterDepartmentsLoading: state.cyclePlanListPage.filterDepartmentsLoading,
  filterDepartments: state.cyclePlanListPage.filterDepartments,
  filterResidentsLoading: state.cyclePlanListPage.filterResidentsLoading,
  filterResidents: state.cyclePlanListPage.filterResidents,
  filterStudyProgramsLoading: state.cyclePlanListPage.filterStudyProgramsLoading,
  filterStudyPrograms: state.cyclePlanListPage.filterStudyPrograms
});

const mapDispatchToProps = dispatch => ({
  filterCyclePlans: (payload) => dispatch(filterCyclePlans(payload)),
  clearCyclePlansFilter: () => dispatch(clearCyclePlansFilter()),
  searchCyclePlansFilterDepartments: (payload) => dispatch(searchCyclePlansFilterDepartments(payload)),
  searchCyclePlansFilterResidents: (payload) => dispatch(searchCyclePlansFilterResidents(payload)),
  searchCyclePlansFilterStudyPrograms: (payload) => dispatch(searchCyclePlansFilterStudyPrograms(payload))
});

registerForLocalization(ManagedStudentListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(ManagedStudentListFilter);