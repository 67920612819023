import CompetencySurveyOverviewPageActionTypes from '../actions/competency-survey-overview-page-action-types';

const initialState = {
  loading: false,
  survey: null,
  competencySurveyCancelToken: null,
  selectedTab: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencySurveyOverviewPageActionTypes.LOAD_COMPETENCY_SURVEY_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case CompetencySurveyOverviewPageActionTypes.LOAD_COMPETENCY_SURVEY_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        survey: action.payload?.survey
      };
    case CompetencySurveyOverviewPageActionTypes.SELECT_COMPETENCY_SURVEY_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    case CompetencySurveyOverviewPageActionTypes.CLEAR_COMPETENCY_SURVEY_OVERVIEW_PAGE:
      return initialState;
    default:
      return state;
  }
};