import MyStudiesSurveyFormPageActionTypes from './my-studies-survey-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import axios from 'axios';

export const loadSurveyFormPage = (payload) => async(dispatch, getState, { api }) => {
  const { studyPlanCycleStudentId, surveyType, managerUserId, surveyAnswerStudentId } = payload;
  dispatch({ type: MyStudiesSurveyFormPageActionTypes.LOAD_SURVEY_FORM_PAGE_START });
  try {
    let survey;
    const surveyAnswers = [];
    const state = getState().myStudiesSurveyFormPage;
    state.myStudiesSurveyFormCancelToken?.cancel();
    state.myStudiesSurveyFormCancelToken = axios.CancelToken.source();
    if (surveyAnswerStudentId) {
      survey = await api.get(`api/survey_answer_students/${surveyAnswerStudentId}`, null, state.myStudiesSurveyFormCancelToken.token);
      let lastQuestionNumber = 0;
      survey?.surveyQuestions?.map(element => element.number > lastQuestionNumber && (lastQuestionNumber = element.number));
      for (let i = 0; i <= lastQuestionNumber; i++) {
        const questionId = survey?.surveyQuestions?.find(element => element.number === i)?.id;
        const surveyAnswer = survey?.surveyAnswers?.find(element => element.surveyQuestionId === questionId);
        surveyAnswers.push(surveyAnswer?.answer);
      }
    } else {
      survey = await api.post(`api/students/${studyPlanCycleStudentId}/surveys/${surveyType}`, { subjectUserId: managerUserId }, state.myStudiesSurveyFormCancelToken.token);
    }
    dispatch({
      type: MyStudiesSurveyFormPageActionTypes.LOAD_SURVEY_FORM_PAGE_END,
      payload: {
        survey: {
          ...survey,
          surveyAnswers
        }
      }
    });
  } catch (error) {
    dispatch({ type: MyStudiesSurveyFormPageActionTypes.LOAD_SURVEY_FORM_PAGE_END });
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const saveSurvey = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: MyStudiesSurveyFormPageActionTypes.LOAD_SURVEY_FORM_PAGE_START });
  const state = getState().myStudiesSurveyFormPage;
  const surveyAnswers = payload.surveyAnswers.map((element, index) => {
    if (element != null) {
      return {
        answer: element,
        surveyQuestionId: payload.surveyQuestions.find(element => element.number === index).id
      };
    }
  });
  const { studyPlanCycleStudentId } = payload;
  const apiModel = {
    ...payload,
    surveyAnswers: surveyAnswers.filter(element => element != null)
  };
  try {
    state.myStudiesSurveyFormCancelToken?.cancel();
    state.myStudiesSurveyFormCancelToken = axios.CancelToken.source();
    await api.post(`api/students/${studyPlanCycleStudentId}/surveys`, apiModel, state.myStudiesSurveyFormCancelToken.token);
    dispatch({
      type: MyStudiesSurveyFormPageActionTypes.LOAD_SURVEY_FORM_PAGE_END,
      payload: {
        survey: state.survey
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    } else if (!(error instanceof axios.Cancel)) {
      throw error;
    }
    dispatch({
      type: MyStudiesSurveyFormPageActionTypes.LOAD_SURVEY_FORM_PAGE_END,
      payload: {
        validationResult,
        survey: state.survey
      }
    });
  }
};