import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, provideIntlService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { loadStudentCompetencySubmittedSurveyList, clearStudentCompetencySubmittedSurveyList, sortStudentCompetencySubmittedSurveys } from '../../student-competency/actions/student-competency-submitted-survey-list-actions';
import { withRouter } from '../../../../components/withRouter';
import { getCompetencySurveyStudentStatusColor } from '../../../../utils/competencySurveyStudentStatusColor';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { COMPETENCY_SURVEY_STUDENT_STATUS } from '../../../../resources/competencySurveyStudentStatus';
import { Tooltip } from '@progress/kendo-react-tooltip';
import styled from 'styled-components';
import { Colors, Text } from '../../../../ui';
import { SCREEN_SIZES } from '../../../../resources/screenSize';
import { STUDENT_COMPETENCY_STATUS } from '../../../../resources/studentCompetencyStatus';
import { getEvaluatorRole } from '../../../../utils/evaluatorRoleFormatter';
import { DateFormatter, DecimalFormatter, EmptyFormatter } from '../../components/Formatter';
import Grid from '../../components/Grid';
import GridDateCell from '../../components/GridDateCell';
import GridSize from '../../components/GridSize';
import InlineBadge from '../../components/InlineBadge';

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`;

const CellDataContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoIcon = styled.span`
  margin-left: 4px;
`;

const CommentCell = styled.td`
  overflow-wrap: anywhere;
`;

class StudentCompetencySubmittedSurveyList extends React.Component {
  constructor(props) {
    super(props);
    this.renderTooltipContent = this.renderTooltipContent.bind(this);
    this.renderSurveyEvaluation = this.renderSurveyEvaluation.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.renderManager = this.renderManager.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.competencyId > 0 && this.props.surveyId > 0 && this.props.studentId > 0) {
      await this.props.loadStudentCompetencySubmittedSurveyList({
        competencyId: this.props.competencyId,
        studentId: this.props.studentId,
        surveyId: this.props.surveyId
      });
    }
  }

  componentWillUnmount() {
    this.props.clearStudentCompetencySubmittedSurveyList();
  }

  render() {
    const { studentCompetencySubmittedSurveys, loading, handleRequestEvaluationClick, studentCompetencyStatus } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <Grid
        loading={loading}
        data={studentCompetencySubmittedSurveys}
        isPageable={false}
        onRowClick={this.handleRowClick}
        sort={this.props.sorting}
        onSortChange={this.handleSort}
        columns={[
          {
            field: 'requestDate',
            className: 'align-center',
            headerClassName: 'align-center',
            title: localizationService.toLanguageString('studentCompetency.requestDate'),
            cell: GridDateCell,
            minGridWidth: GridSize.small
          }, {
            field: 'surveyEvaluationDate',
            className: 'align-center',
            headerClassName: 'align-center',
            title: localizationService.toLanguageString('studentCompetency.evaluationDate'),
            minGridWidth: GridSize.small,
            cell: GridDateCell
          }, {
            field: 'manager',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.evaluator'),
            cell: this.renderManager
          }, {
            field: 'surveyEvaluation',
            className: 'align-right',
            headerClassName: 'align-right',
            title: localizationService.toLanguageString('studentCompetency.evaluation'),
            cell: this.renderSurveyEvaluation
          }, {
            field: 'comment',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.comment'),
            minGridWidth: GridSize.medium,
            sortable: false,
            cell: ({ className, dataItem }) =>
              <CommentCell className={className}>
                {dataItem.comment}
              </CommentCell>
          }, {
            field: 'status',
            className: 'align-left',
            headerClassName: 'align-left',
            title: localizationService.toLanguageString('studentCompetency.status'),
            cell: this.renderStatus
          }
        ]}
      >
        {!this.props.preview &&
          <GridToolbar>
            <Button
              themeColor='primary'
              onClick={handleRequestEvaluationClick}
              disabled={studentCompetencyStatus === STUDENT_COMPETENCY_STATUS.ACQUIRED || studentCompetencyStatus === STUDENT_COMPETENCY_STATUS.NOT_APPROVED}
            >
              {localizationService.toLanguageString('studentCompetency.request')}
            </Button>
          </GridToolbar>
        }
      </Grid>
    );
  }

  handleRowClick(e) {
    if (!this.props.disableRowClick) {
      if (e.dataItem.status === COMPETENCY_SURVEY_STUDENT_STATUS.EVALUATED) {
        this.props.navigate(`/my_competencies/${this.props.params.competencyId}/surveys/${this.props.surveyId}/preview_survey/${e.dataItem.id}`);
      }
    }
  }

  renderManager({ className, dataItem }) {
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className}>
        {dataItem.manager || `${dataItem.externalUser} (${localizationService.toLanguageString('studentCompetency.externalUser')})`}
      </td>
    );
  }

  renderStatus({ className, dataItem }) {
    const tooltipContent = { requestDate: dataItem.requestDate, surveyEvaluationDate: dataItem.surveyEvaluationDate, comment: dataItem.comment, role: dataItem.role };
    const localizationService = provideLocalizationService(this);
    const isSmallerScreen = window.innerWidth < SCREEN_SIZES.XS;
    return (
      <td className={className}>
        <Tooltip anchorElement='target' position='left' parentTitle={true} content={(props) => this.renderTooltipContent({ ...props, tooltipContent })}>
          <CellDataContainer title={' '}>
            <InlineBadge themeColor={getCompetencySurveyStudentStatusColor(dataItem.status)}>
              {localizationService.toLanguageString(`competencySurveyStudentStatus.${dataItem.status}`)}
            </InlineBadge>
            {isSmallerScreen &&
              <InfoIcon className='k-icon k-i-information'/>
            }
          </CellDataContainer>
        </Tooltip>
      </td>
    );
  }

  renderSurveyEvaluation({ className, dataItem }) {
    const value = dataItem.status === COMPETENCY_SURVEY_STUDENT_STATUS.EVALUATED
      ? DecimalFormatter(EmptyFormatter(dataItem.surveyEvaluation, 0))
      : null;
    return (
      <td className={className}>
        {value}
      </td>
    );
  }

  renderTooltipContent({ tooltipContent }) {
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <TooltipContainer>
        {tooltipContent.requestDate &&
          <span>
            <Text variant='body2' textColor={Colors.WHITE}>{`${localizationService.toLanguageString('studentCompetency.requestDate')}: `}</Text>
            <Text variant='caption' textColor={Colors.WHITE}>{DateFormatter(tooltipContent.requestDate, intlService)}</Text>
          </span>
        }
        {tooltipContent.surveyEvaluationDate &&
          <span>
            <Text variant='body2' textColor={Colors.WHITE}>{`${localizationService.toLanguageString('studentCompetency.evaluationDate')}: `}</Text>
            <Text variant='caption' textColor={Colors.WHITE}>{DateFormatter(tooltipContent.surveyEvaluationDate, intlService)}</Text>
          </span>
        }
        {tooltipContent.comment &&
          <span>
            <Text variant='body2' textColor={Colors.WHITE}>{`${localizationService.toLanguageString('studentCompetency.comment')}: `}</Text>
            <Text variant='caption' textColor={Colors.WHITE} overflowWrap>{tooltipContent.comment}</Text>
          </span>
        }
        {
          tooltipContent.role &&
          <span>
            <Text variant='body2' textColor={Colors.WHITE}>{`${localizationService.toLanguageString('studentCompetency.role')}: `}</Text>
            <Text variant='caption' textColor={Colors.WHITE} overflowWrap>{getEvaluatorRole(tooltipContent.role, localizationService)}</Text>
          </span>
        }
      </TooltipContainer>
    );
  }

  handleSort(e) {
    this.props.sortStudentCompetencySubmittedSurveys({
      sort: e.sort,
      competencyId: this.props.competencyId,
      surveyId: this.props.surveyId
    });
  }
}

const mapStateToProps = (state) => ({
  studentCompetencySubmittedSurveys: state.studentCompetencySubmittedSurveyList.studentCompetencySubmittedSurveys,
  loading: state.studentCompetencySubmittedSurveyList.loading,
  sorting: state.studentCompetencySubmittedSurveyList.sorting
});

const mapDispatchToProps = (dispatch) => ({
  loadStudentCompetencySubmittedSurveyList: (payload) => dispatch(loadStudentCompetencySubmittedSurveyList(payload)),
  clearStudentCompetencySubmittedSurveyList: () => dispatch(clearStudentCompetencySubmittedSurveyList()),
  sortStudentCompetencySubmittedSurveys: (payload) => dispatch(sortStudentCompetencySubmittedSurveys(payload))
});

registerForIntl(StudentCompetencySubmittedSurveyList);
registerForLocalization(StudentCompetencySubmittedSurveyList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCompetencySubmittedSurveyList));
