import { pagingSettings } from '../../../utils/GridPagingUtils';
import CompetencyRequestListPageActionTypes from '../actions/competency-request-list-page-action-types';

const initialState = {
  loading: false,
  competencyRequests: null,
  competencyRequestsCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: null,

  studyProgramsComboBoxLoading: false,
  studyProgramsComboBoxData: null,
  studyProgramsComboBoxCancelToken: null,

  residentsComboBoxLoading: false,
  residentsComboBoxData: null,
  residentsComboBoxCancelToken: null,

  selectedAll: false,
  selectedIds: null,
  signingRedirectUrl: null,
  isGrantConfirmDialogVisible: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CompetencyRequestListPageActionTypes.LOAD_COMPETENCY_REQUEST_LIST_START:
      return {
        ...state,
        loading: true
      };
    case CompetencyRequestListPageActionTypes.LOAD_COMPETENCY_REQUEST_LIST_END:
      return {
        ...state,
        loading: false,
        competencyRequests: action.payload.competencyRequests,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case CompetencyRequestListPageActionTypes.FILTER_COMPETENCY_REQUEST_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case CompetencyRequestListPageActionTypes.SEARCH_RESIDENTS_COMBOBOX_START:
      return {
        ...state,
        residentsComboBoxLoading: true
      };
    case CompetencyRequestListPageActionTypes.SEARCH_RESIDENTS_COMBOBOX_END:
      return {
        ...state,
        residentsComboBoxLoading: false,
        residentsComboBoxData: action.payload.residents
      };
    case CompetencyRequestListPageActionTypes.SEARCH_STUDY_PROGRAMS_COMBOBOX_START:
      return {
        ...state,
        studyProgramsComboBoxLoading: true
      };
    case CompetencyRequestListPageActionTypes.SEARCH_STUDY_PROGRAMS_COMBOBOX_END:
      return {
        ...state,
        studyProgramsComboBoxLoading: false,
        studyProgramsComboBoxData: action.payload.studyPrograms
      };
    case CompetencyRequestListPageActionTypes.SELECT_COMPETENCY_REQUEST_LIST:
      return {
        ...state,
        competencyRequests: action.payload?.competencyRequests,
        selectedIds: action.payload?.selectedIds,
        selectedAll: action.payload?.selectedAll
      };
    case CompetencyRequestListPageActionTypes.SELECT_ALL_COMPETENCY_REQUEST_LIST:
      return {
        ...state,
        competencyRequests: action.payload?.competencyRequests,
        selectedIds: action.payload?.selectedIds,
        selectedAll: !state.selectedAll
      };
    case CompetencyRequestListPageActionTypes.INIT_MULTI_SIGN_START:
      return {
        ...state,
        loading: true
      };
    case CompetencyRequestListPageActionTypes.INIT_MULTI_SIGN_END:
      return {
        ...state,
        loading: false,
        signingRedirectUrl: action.payload.signingRedirectUrl
      };
    case CompetencyRequestListPageActionTypes.TOGGLE_GRANT_CONFIRM_DIALOG:
      return {
        ...state,
        isGrantConfirmDialogVisible: !state.isGrantConfirmDialogVisible
      };
    default:
      return state;
  }
};