import StudentOverviewPageActionTypes from '../actions/student-overview-page-action-types';

const initialState = {
  loading: false,
  student: null,
  studentCancelToken: null,
  selectedTab: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentOverviewPageActionTypes.LOAD_STUDENT_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case StudentOverviewPageActionTypes.LOAD_STUDENT_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        student: action.payload
      };
    case StudentOverviewPageActionTypes.SELECT_STUDENT_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selected
      };
    default:
      return state;
  }
};