import { NotificationFormPageActiontypes } from '../actions';

const initialState = {
  loading: false,
  notification: null,
  validationResult: null,
  notificationFormPageCancelToken: null,
  availableStudyPrograms: [],
  availableStudyProgramsLoading: false,
  availableStudyProgramsCancelToken: null,
  studyYears: [],
  studyStatuses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        notification: action.payload ? action.payload.notification : null,
        availableStudyPrograms: action.payload ? action.payload.availableStudyPrograms : [],
        studyYears: action.payload ? action.payload.studyYears : [],
        studyStatuses: action.payload ? action.payload.studyStatuses : [],
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_STUDY_PROGRAMS_START:
      return {
        ...state,
        availableStudyProgramsLoading: true
      };
    case NotificationFormPageActiontypes.LOAD_NOTIFICATIONS_FORM_PAGE_STUDY_PROGRAMS_END:
      return {
        ...state,
        availableStudyProgramsLoading: false,
        availableStudyPrograms: action.payload?.availableStudyPrograms ?? []
      };
    case NotificationFormPageActiontypes.CLEAR_NOTIFICATIONS_FORM_PAGE:
      return initialState;
    default:
      return state;
  }
};