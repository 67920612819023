export default {
  FILTER_COMPETENCY_REQUEST_LIST: 'FilterCompetencfyRequestList',
  LOAD_COMPETENCY_REQUEST_LIST_START: 'LoadCompetencyRequestListStart',
  LOAD_COMPETENCY_REQUEST_LIST_END: 'LoadCompetencyRequestListEnd',

  SEARCH_STUDY_PROGRAMS_COMBOBOX_START: 'SearchStudyProgramsComboBoxStart',
  SEARCH_STUDY_PROGRAMS_COMBOBOX_END: 'SearchStudyProgramsComboBoxEnd',

  SEARCH_RESIDENTS_COMBOBOX_START: 'SearchResidentsComboBoxStart',
  SEARCH_RESIDENTS_COMBOBOX_END: 'SearchResidentsComboBoxEnd',

  SELECT_COMPETENCY_REQUEST_LIST: 'SelectCompetencyRequestList',
  SELECT_ALL_COMPETENCY_REQUEST_LIST: 'SelectAllCompetencyRequestList',
  INIT_MULTI_SIGN_START: 'InitMultiSignStart',
  INIT_MULTI_SIGN_END: 'InitMultiSignEnd',
  TOGGLE_GRANT_CONFIRM_DIALOG: 'ToggleGrantConfirmDialog'
};