export default {
  LOAD_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START: 'LoadStudyPlanCycleStudentSelectPageStart',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_STUDY_YEARS: 'LoadStudyPlanCycleStudentStudyYears',
  FIND_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START: 'FindStudyPlanCycleStudentSelectPageStart',
  FIND_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_END: 'FindStudyPlanCycleStudentSelectPageEnd',
  FILTER_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE: 'FilterStudyPlanCycleStudentSelectPage',
  SELECT_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE: 'SelectStudyPlanCycleStudentSelectPage',
  SELECT_ALL_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE: 'SelectAllStudyPlanCycleStudentSelectPage',
  ADD_SELECTED_STUDENTS_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_START: 'AddSelectedStudentsStudyPlanCycleStudentSelectPageStart',
  ADD_SELECTED_STUDENTS_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_END: 'AddSelectedStudentsStudyPlanCycleStudentSelectPageEnd',
  OPEN_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_SAVE_DIALOG: 'OpenStudyPlanCycleStudentSelectPageSaveDialog',
  CLOSE_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_SAVE_DIALOG: 'CloseStudyPlanCycleStudentSelectPageSaveDialog',
  CLEANUP_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE: 'CleanupStudyPlanCycleStudentSelectPage',
  LOAD_STUDY_PLAN_CYCLE_STUDENT_SELECT_PAGE_STUDENT_STATUSES: 'LoadStudyPlanCycleStudentSelectPageStudentStatuses'
};