import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field, FieldArray } from '@progress/kendo-react-form';
import { CheckBox, ComboBox, DropDown, FormPage, NumericBox, TextBox, WrapLayout, TextArea, Text, DatePicker, StackLayout } from '../../../ui';
import { CYCLE_TYPES } from '../../../resources/cycleType';
import { loadCycleFormPage, saveCycle, searchAvailableStudyPrograms, loadCycleChildrenAvailableStudyPrograms } from '../actions/cycle-form-page-actions';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import FIELD_NAMES from '../actions/cycle-form-field-names';
import { CYCLE_TEACHING_TYPE } from '../../../resources/cycleTeachingType';

const Spacer = styled.div`
  height: 24px
`;

const CodeSeperatorContainer = styled.div`
  font-size: x-large;
  height: 38px;
  margin-top: 8px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CycleChildrenContainer = styled(WrapLayout).attrs(props => ({
  orientation: 'vertical',
  rowgap: '2px',
  width: '35px'
}))`
  margin-top: 2px;
`;

const CycleChildrenTitle = styled(Text).attrs(props => ({
  variant: 'h6',
  textColor: props.theme.gray80,
  truncate: true
}))`
  margin-top: 20px;
`;

const DeleteButton = styled(Button).attrs(props => ({
  type: 'button',
  icon: 'delete'
}))`
  margin-top: 28px;
`;

class CycleFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderCycleChildren = this.renderCycleChildren.bind(this);
    this.state = {
      selectedStudyProgramDuration: null
    };
  }

  async componentDidMount() {
    const localizationService = provideLocalizationService(this);
    await this.props.loadCycleFormPage({
      [this.props.copy ? 'targetCycleId' : 'cycleId']: this.props.params.cycleId,
      localizationService
    });
    this.setState({ selectedStudyProgramDuration: this.props.cycle?.studyProgramDuration });
  }

  render() {
    const { selectedStudyProgramDuration } = this.state;
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('cycle.formTitle')}
        breadcrumbItems={[
          {
            id: 'cycles',
            text: localizationService.toLanguageString('cycle.cycles')
          },
          {
            id: 'cycle',
            text: localizationService.toLanguageString('cycle.cycle'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.cycle}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={(renderFormProps) => {
          return (
            <>
              <Field
                name='name'
                label={localizationService.toLanguageString('cycle.name')}
                component={TextBox}
                width='320px'
              />
              <Field
                name='englishName'
                label={localizationService.toLanguageString('cycle.englishName')}
                component={TextBox}
                width='320px'
              />
              <Field
                name='abbreviation'
                label={localizationService.toLanguageString('cycle.abbreviation')}
                component={TextBox}
                width='320px'
              />
              <Field
                name='studyProgram'
                label={localizationService.toLanguageString('cycle.coordinatingStudyProgram')}
                component={ComboBox}
                data={this.props.availableStudyPrograms}
                onFilter={this.props.searchAvailableStudyPrograms}
                onChange={e => {
                  this.setState({ selectedStudyProgramDuration: e.value?.durationInYears });
                  renderFormProps.onChange(FIELD_NAMES.STUDY_PROGRAM_CODE, { value: e.value ? e.value.code : '' });
                  (!e.value || e.value.durationInYears < 7) && renderFormProps.onChange('seventhYear', { value: false });
                  (!e.value || e.value.durationInYears < 6) && renderFormProps.onChange('sixthYear', { value: false });
                  (!e.value || e.value.durationInYears < 5) && renderFormProps.onChange('fifthYear', { value: false });
                  (!e.value || e.value.durationInYears < 4) && renderFormProps.onChange('fourthYear', { value: false });
                  (!e.value || e.value.durationInYears < 3) && renderFormProps.onChange('thirdYear', { value: false });
                  (!e.value || e.value.durationInYears < 2) && renderFormProps.onChange('secondYear', { value: false });
                  (!e.value || e.value.durationInYears < 1) && renderFormProps.onChange('firstYear', { value: false });
                }}
                textField='name'
                valueField='id'
                width='320px'
              />
              <WrapLayout width='320px'>
                <Field
                  name={FIELD_NAMES.STUDY_PROGRAM_CODE}
                  label={localizationService.toLanguageString('cycle.studyProgramCode')}
                  component={TextBox}
                  width={'148px'}
                  disabled
                />
                <CodeSeperatorContainer>-</CodeSeperatorContainer>
                <Field
                  name='partialCycleCode'
                  label={localizationService.toLanguageString('cycle.cycleNumber')}
                  component={TextBox}
                  width={'148px'}
                />
              </WrapLayout>
              <Field
                name='teachingType'
                label={localizationService.toLanguageString('cycle.teachingType')}
                component={DropDown}
                data={[
                  {
                    value: CYCLE_TEACHING_TYPE.LOCAL,
                    text: localizationService.toLanguageString('cycleTeachingType.Local')
                  }, {
                    value: CYCLE_TEACHING_TYPE.EXTERNAL,
                    text: localizationService.toLanguageString('cycleTeachingType.External')
                  }, {
                    value: CYCLE_TEACHING_TYPE.LOCAL_AND_EXTERNAL,
                    text: localizationService.toLanguageString('cycleTeachingType.LocalAndExternal')
                  }
                ]}
                textField='text'
                valueField='value'
                width='320px'
                onChange={e => e.value?.value === CYCLE_TEACHING_TYPE.LOCAL && renderFormProps.onChange(FIELD_NAMES.CYCLE_CHILDREN, { value: null })}
              />
              {this.shouldRenderCycleChildrenFieldArray(renderFormProps.valueGetter('teachingType')?.value) &&
                <FieldArray
                  name={FIELD_NAMES.CYCLE_CHILDREN}
                  component={this.renderCycleChildren}
                  renderFormProps={renderFormProps}
                />
              }
              <Field
                name='description'
                label={localizationService.toLanguageString('cycle.description')}
                id='description'
                component={TextArea}
                autoSize={true}
                width='320px'
              />
              <Field
                name='credits'
                label={localizationService.toLanguageString('cycle.credits')}
                component={NumericBox}
                min={0}
                width='155px'
              />
              <Field
                name='cycleType'
                label={localizationService.toLanguageString('cycle.type')}
                component={DropDown}
                data={[
                  {
                    value: CYCLE_TYPES.OPTIONAL,
                    text: localizationService.toLanguageString('cycleType.Optional')
                  }, {
                    value: CYCLE_TYPES.REQUIRED,
                    text: localizationService.toLanguageString('cycleType.Required')
                  }
                ]}
                textField='text'
                valueField='value'
                width='320px'
              />
              <WrapLayout width='320px'>
                <Field
                  name='validFrom'
                  label={localizationService.toLanguageString('cycle.validFrom')}
                  component={DatePicker}
                  width='155px'
                />
                <Field
                  name='validTo'
                  label={localizationService.toLanguageString('cycle.validTo')}
                  component={DatePicker}
                  width='155px'
                />
              </WrapLayout>
              <WrapLayout width='320px' align='left' columnGap='16px' rowGap='0px'>
                <Field
                  name='requiresManagersConfirmation'
                  label={localizationService.toLanguageString('cycle.requiresManagersConfirmation')}
                  component={CheckBox}
                  marginTop='8px'
                />
              </WrapLayout>
              <>
                <Spacer/>
                <Text>
                  {localizationService.toLanguageString('cycle.years')}
                </Text>
                <WrapLayout width='320px' align='left' columnGap='16px' rowGap='0px'>
                  {(selectedStudyProgramDuration > 0 || !selectedStudyProgramDuration) &&
                    <Field
                      name='firstYear'
                      label={localizationService.toLanguageString('cycle.firstYear')}
                      component={CheckBox}
                      marginTop='0px'
                    />
                  }
                  {(selectedStudyProgramDuration > 1 || !selectedStudyProgramDuration) &&
                    <Field
                      name='secondYear'
                      label={localizationService.toLanguageString('cycle.secondYear')}
                      component={CheckBox}
                      marginTop='0px'
                    />
                  }
                  {(selectedStudyProgramDuration > 2 || !selectedStudyProgramDuration) &&
                    <Field
                      name='thirdYear'
                      label={localizationService.toLanguageString('cycle.thirdYear')}
                      component={CheckBox}
                      marginTop='0px'
                    />
                  }
                  {(selectedStudyProgramDuration > 3 || !selectedStudyProgramDuration) &&
                    <Field
                      name='fourthYear'
                      label={localizationService.toLanguageString('cycle.fourthYear')}
                      component={CheckBox}
                      marginTop='0px'
                    />
                  }
                  {(selectedStudyProgramDuration > 4 || !selectedStudyProgramDuration) &&
                    <Field
                      name='fifthYear'
                      label={localizationService.toLanguageString('cycle.fifthYear')}
                      component={CheckBox}
                      marginTop='0px'
                    />
                  }
                  {(selectedStudyProgramDuration > 5 || !selectedStudyProgramDuration) &&
                    <Field
                      name='sixthYear'
                      label={localizationService.toLanguageString('cycle.sixthYear')}
                      component={CheckBox}
                      marginTop='0px'
                    />
                  }
                  {(selectedStudyProgramDuration > 6 || !selectedStudyProgramDuration) &&
                    <Field
                      name='seventhYear'
                      label={localizationService.toLanguageString('cycle.seventhYear')}
                      component={CheckBox}
                      marginTop='0px'
                    />
                  }
                </WrapLayout>
              </>
            </>
          );
        }}
      />
    );
  }

  shouldRenderCycleChildrenFieldArray(teachingType) {
    return teachingType === CYCLE_TEACHING_TYPE.LOCAL_AND_EXTERNAL || teachingType === CYCLE_TEACHING_TYPE.EXTERNAL;
  }

  renderCycleChildren(fieldArrayRenderProps) {
    const localizationService = provideLocalizationService(this);
    return (
      <CycleChildrenContainer>
        <CycleChildrenTitle>{localizationService.toLanguageString('cycle.cycleChildren')}</CycleChildrenTitle>
        {fieldArrayRenderProps.value?.map((item, index) => {
          const fieldName = FIELD_NAMES.CYCLE_CHILDREN + '[' + index + ']';
          const cycleChild = this.props.cycleChildren[index];
          return (
            <div key={index}>
              <StackLayout orientation='horizontal' align='left' valign='top'>
                <Field
                  name={`${fieldName}.studyProgram`}
                  label={localizationService.toLanguageString('cycle.studyProgram')}
                  component={ComboBox}
                  loading={cycleChild?.loading}
                  data={cycleChild?.availableStudyPrograms}
                  onFilter={(e) => this.props.loadCycleChildrenAvailableStudyPrograms({ keyword: e.keyword, index })}
                  onChange={e => fieldArrayRenderProps.renderFormProps.onChange(`${fieldName}.studyProgramCode`, { value: e.value ? e.value.code : '' })}
                  textField='name'
                  valueField='id'
                  width='276px'
                />
                <DeleteButton
                  onClick={() => this.handleRemoveChildCycle(index, fieldArrayRenderProps)}
                />
              </StackLayout>
              <WrapLayout width='320px'>
                <Field
                  name={`${fieldName}.studyProgramCode`}
                  label={localizationService.toLanguageString('cycle.studyProgramCode')}
                  component={TextBox}
                  width={'148px'}
                  disabled
                />
                <CodeSeperatorContainer>-</CodeSeperatorContainer>
                <Field
                  name={`${fieldName}.partialCycleCode`}
                  label={localizationService.toLanguageString('cycle.cycleNumber')}
                  component={TextBox}
                  width={'148px'}
                />
              </WrapLayout>
            </div>
          );
        })}
        <WrapLayout orientation='horizontal' align='left'>
          <Button
            type='button'
            icon='plus'
            onClick={() => this.handleAddChildCycle(fieldArrayRenderProps)}
          />
        </WrapLayout>
      </CycleChildrenContainer>
    );
  }

  handleAddChildCycle(fieldArrayRenderProps) {
    fieldArrayRenderProps.onPush({
      value: { }
    });
    this.props.loadCycleChildrenAvailableStudyPrograms({ index: fieldArrayRenderProps?.value?.length ?? 0 });
  }

  handleRemoveChildCycle(index, fieldArrayRenderProps) {
    fieldArrayRenderProps.onRemove({
      index: index
    });
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.name) {
      errors.name = localizationService.toLanguageString('validation.required');
    } else {
      if (values.name.localeCompare(values.name.toUpperCase()) === 0) {
        errors.name = localizationService.toLanguageString('cycle.capitalCaseError');
      }
    }
    if (!values.abbreviation) {
      errors.abbreviation = localizationService.toLanguageString('validation.required');
    }
    if (values.credits == null || values.credits == 0) {
      errors.credits = localizationService.toLanguageString('validation.required');
    } else if (values.credits < 0) {
      errors.credits = localizationService.toLanguageString('validation.negativeValue');
    }
    if (values.validFrom && values.validTo && values.validFrom > values.validTo) {
      errors.validFrom = localizationService.toLanguageString('validation.impossibleSequence');
      errors.validTo = localizationService.toLanguageString('validation.impossibleSequence');
    }
    if (!values.cycleType) {
      errors.cycleType = localizationService.toLanguageString('validation.required');
    }
    if (!values.studyProgram) {
      errors.studyProgram = localizationService.toLanguageString('validation.required');
    }
    if (!values.partialCycleCode) {
      errors.partialCycleCode = localizationService.toLanguageString('validation.required');
    } else if ([...values.partialCycleCode].some(value => !(value >= 0 && value <= 9) && value !== '.')) {
      errors.partialCycleCode = localizationService.toLanguageString('cycle.allowedSymbols');
    } else {
      if ([...values.partialCycleCode].filter(value => value === '.').length > 1) {
        errors.partialCycleCode = localizationService.toLanguageString('cycle.cannotHaveMultipleDots');
      } else if (values.partialCycleCode[values.partialCycleCode.length - 1] === '.') {
        errors.partialCycleCode = localizationService.toLanguageString('cycle.cannotEndWithDot');
      } else if (values.partialCycleCode[0] === '.') {
        errors.partialCycleCode = localizationService.toLanguageString('cycle.cannotBeginWithDot');
      }
    }
    if (!values.teachingType) {
      errors.teachingType = localizationService.toLanguageString('validation.required');
    }
    if (values.cycleChildren) {
      for (let i = 0; i < values.cycleChildren.length; i++) {
        const cycleChildStudyProgramFieldName = `${FIELD_NAMES.CYCLE_CHILDREN}[${i}].studyProgram`;
        if (values.cycleChildren[i]?.studyProgram?.id === values.studyProgram?.id) {
          errors[cycleChildStudyProgramFieldName] = localizationService.toLanguageString('cycle.childStudyProgramValidationDuplicate');
        }
        if (values.cycleChildren.some((element, index) => index !== i && element.studyProgram?.id === values.cycleChildren[i]?.studyProgram?.id)) {
          errors[cycleChildStudyProgramFieldName] = localizationService.toLanguageString('cycle.childStudyProgramValidationDuplicate');
        }
        const cycleChildPartialCode = values.cycleChildren[i]?.partialCycleCode;
        const cycleChildPartialCodeFieldName = `${FIELD_NAMES.CYCLE_CHILDREN}[${i}].partialCycleCode`;
        if (!cycleChildPartialCode) {
          errors[cycleChildPartialCodeFieldName] = localizationService.toLanguageString('validation.required');
        } else if ([...cycleChildPartialCode].some(value => !(value >= 0 && value <= 9) && value !== '.')) {
          errors[cycleChildPartialCodeFieldName] = localizationService.toLanguageString('cycle.allowedSymbols');
        } else {
          if ([...cycleChildPartialCode].filter(value => value === '.').length > 1) {
            errors[cycleChildPartialCodeFieldName] = localizationService.toLanguageString('cycle.cannotHaveMultipleDots');
          } else if (cycleChildPartialCode[cycleChildPartialCode.length - 1] === '.') {
            errors[cycleChildPartialCodeFieldName] = localizationService.toLanguageString('cycle.cannotEndWithDot');
          } else if (cycleChildPartialCode[0] === '.') {
            errors[cycleChildPartialCodeFieldName] = localizationService.toLanguageString('cycle.cannotBeginWithDot');
          }
        }

        const cycleChildStudyProgram = values.cycleChildren[i]?.studyProgram;
        if (!cycleChildStudyProgram) {
          errors[cycleChildStudyProgramFieldName] = localizationService.toLanguageString('validation.required');
        }
      }
    }

    if (!values.firstYear && !values.secondYear && !values.thirdYear && !values.fourthYear && !values.fifthYear && !values.sixthYear && !values.seventhYear) {
      errors.firstYear = localizationService.toLanguageString('cycle.validationYears');
      errors.secondYear = localizationService.toLanguageString('cycle.validationYears');
      errors.thirdYear = localizationService.toLanguageString('cycle.validationYears');
      errors.fourthYear = localizationService.toLanguageString('cycle.validationYears');
      errors.fifthYear = localizationService.toLanguageString('cycle.validationYears');
      errors.sixthYear = localizationService.toLanguageString('cycle.validationYears');
      errors.seventhYear = localizationService.toLanguageString('cycle.validationYears');
    }

    if (!modified.name
      && !modified.abbreviation
      && !modified.credits
      && !modified.validFrom
      && !modified.validTo
      && !modified.cycleType
      && !modified.studyProgram
      && !modified.partialCycleCode
      && !modified.teachingType
      && !modified.firstYear
      && !modified.secondYear
      && !modified.thirdYear
      && !modified.fourthYear
      && !modified.fifthYear
      && !modified.sixthYear
      && !modified.seventhYear
      && (!values.cycleChildren || values.cycleChildren?.every((element, index) => {
        const fieldName = `${FIELD_NAMES.CYCLE_CHILDREN}[${index}].partialCycleCode`;
        return !modified[fieldName];
      }))) {
      if (validationResult?.errors.name) {
        errors.name = validationResult?.errors?.name;
      }
      if (validationResult?.errors.abbreviation) {
        errors.abbreviation = validationResult.errors.abbreviation;
      }
      if (validationResult?.errors.credits) {
        errors.credits = validationResult.errors.credits;
      }
      if (validationResult?.errors.validFrom) {
        errors.validFrom = validationResult.errors.validFrom;
      }
      if (validationResult?.errors.validTo) {
        errors.validTo = validationResult.errors.validTo;
      }
      if (validationResult?.errors.type) {
        errors.cycleType = validationResult.errors.type;
      }
      if (validationResult?.errors.studyProgramId) {
        errors.studyProgram = validationResult.errors.studyProgram;
      }
      if (validationResult?.errors.code) {
        errors.partialCycleCode = validationResult.errors.code;
      }
      if (validationResult?.errors.teachingType) {
        errors.teachingType = validationResult.errors.teachingType;
      }
      if (validationResult?.errors.firstYear) {
        errors.firstYear = validationResult.errors.firstYear;
      }
      if (validationResult?.errors.secondYear) {
        errors.secondYear = validationResult.errors.secondYear;
      }
      if (validationResult?.errors.thirdYear) {
        errors.thirdYear = validationResult.errors.thirdYear;
      }
      if (validationResult?.errors.fourthYear) {
        errors.fourthYear = validationResult.errors.fourthYear;
      }
      if (validationResult?.errors.fifthYear) {
        errors.fifthYear = validationResult.errors.fifthYear;
      }
      if (validationResult?.errors.sixthYear) {
        errors.sixthYear = validationResult.errors.sixthYear;
      }
      if (validationResult?.errors.seventhYear) {
        errors.seventhYear = validationResult.errors.seventhYear;
      }
      if (values.cycleChildren) {
        for (let i = 0; i < values.cycleChildren.length; i++) {
          const apiFieldName = `studyPrograms[${i}].code`;
          const formFieldName = `${FIELD_NAMES.CYCLE_CHILDREN}[${i}].partialCycleCode`;
          if (validationResult?.errors[apiFieldName]) {
            errors[formFieldName] = validationResult.errors[apiFieldName];
          }
        }
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'cycles') {
      this.props.navigate('/cycles');
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    payload = { ...payload, targetId: this.props.copy ? this.props.params.cycleId : null };
    this.props.saveCycle(payload);
  }
}

registerForLocalization(CycleFormPage);

const mapStateToProps = state => ({
  loading: state.cycleFormPage.loading,
  cycle: state.cycleFormPage.cycle,
  availableStudyPrograms: state.cycleFormPage.availableStudyPrograms,
  availableStudyProgramsLoading: state.cycleFormPage.availableStudyProgramsLoading,
  validationResult: state.cycleFormPage.validationResult,
  cycleChildren: state.cycleFormPage.cycleChildren
});

const mapDispatchToProps = dispatch => ({
  loadCycleFormPage: (payload) => dispatch(loadCycleFormPage(payload)),
  saveCycle: (payload) => dispatch(saveCycle(payload)),
  searchAvailableStudyPrograms: (payload) => dispatch(searchAvailableStudyPrograms(payload)),
  loadCycleChildrenAvailableStudyPrograms: (payload) => dispatch(loadCycleChildrenAvailableStudyPrograms(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleFormPage));
