import StudyProgramCycleListActionTypes from '../actions/study-program-cycle-list-action-types';

const initialState = {
  loading: false,
  studyProgramCycles: null,
  studyProgramCyclesCancelToken: null,
  selectedStudyProgramCycleId: null,
  canWrite: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyProgramCycleListActionTypes.LOAD_STUDY_PROGRAM_CYCLE_LIST_START:
      return {
        ...state,
        loading: true
      };
    case StudyProgramCycleListActionTypes.LOAD_STUDY_PROGRAM_CYCLE_LIST_END:
      return {
        ...state,
        studyProgramCycles: action.payload.studyProgramCycles,
        loading: false,
        canWrite: action.payload.canWrite
      };
    default:
      return state;
  }
};
