import ManagedStudentOverviewPageActionTypes from './managed-student-overview-page-action-types';
import axios from 'axios';
import ValidationError from '../../../utils/validation-error';

const compareLevelsAndDates = (a, b) => {
  const levelCompare = a.level.localeCompare(b.level);
  if (levelCompare === 0) {
    return a.date.getTime() - b.date.getTime();
  }
  return levelCompare;
};

const compareDates = (a, b) => b.date.getTime() - a.date.getTime();

export const loadManagedStudentCycleSkills = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().managedStudentOverviewPage;
  if (!state.studentSkillsLoaded) {
    const { studyPlanCycleStudentId } = payload;
    dispatch({ type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_SKILLS_TAB_START });
    try {
      state.managedStudentCancelToken?.cancel();
      state.managedStudentCancelToken = axios.CancelToken.source();
      let managedStudent = state.managedStudent;
      if (!managedStudent) {
        managedStudent = await api.get(`api/managed_students/${studyPlanCycleStudentId}`, null, state.managedStudentCancelToken.token);
      }
      const [ studentSkills, additionalCycleSkills, cycleSkills ] = await Promise.all([
        api.get(`api/students/${managedStudent.studentId}/cycles/${studyPlanCycleStudentId}/skills`, null, state.managedStudentCancelToken.token),
        api.get(`api/students/${managedStudent.studentId}/cycles/${studyPlanCycleStudentId}/additional_skills`, null, state.managedStudentCancelToken.token),
        api.get(`api/cycles/${managedStudent.cycleId}/skill_abilities`, null, state.managedStudentCancelToken.token)
      ]);
      const studentHistorySkills = studentSkills?.data
        .map(item => ({
          ...item,
          date: new Date(item.date)
        }))
        .sort(compareLevelsAndDates);
      const skills = cycleSkills?.data.map(skill => {
        const history = studentHistorySkills?.filter(item => item.cycleSkillAbilityId === skill.id);
        return {
          id: skill.id,
          name: skill.studyProgramSkillAbilityName,
          levelA: history?.filter(item => item.level === 'A').length || 0,
          requiredA: skill.minRequiredLevelA,
          levelB: history?.filter(item => item.level === 'B').length || 0,
          requiredB: skill.minRequiredLevelB,
          levelC: history?.filter(item => item.level === 'C').length || 0,
          requiredC: skill.minRequiredLevelC,
          newLevelA: 0,
          newLevelB: 0,
          newLevelC: 0,
          isExpanded: false,
          history
        };
      });
      const additionalSkills = additionalCycleSkills?.data.map(additionalSkill => {
        const history = studentHistorySkills?.filter(item => item.cycleSkillAbilityId === additionalSkill.cycleSkillAbilityId);
        return {
          id: additionalSkill.id,
          cycleSkillAbilityId: additionalSkill.cycleSkillAbilityId,
          additionalStudyPlanCycleStudentId: additionalSkill.additionalStudyPlanCycleStudentId,
          studyPlanCycleStudentId: additionalSkill.studyPlanCycleStudentId,
          name: additionalSkill.cycleSkillAbilityName,
          cycleName: additionalSkill.cycleName,
          levelA: history?.filter(item => item.level === 'A').length || 0,
          requiredA: additionalSkill.minRequiredLevelA,
          levelB: history?.filter(item => item.level === 'B').length || 0,
          requiredB: additionalSkill.minRequiredLevelB,
          levelC: history?.filter(item => item.level === 'C').length || 0,
          requiredC: additionalSkill.minRequiredLevelC,
          newLevelA: 0,
          newLevelB: 0,
          newLevelC: 0,
          isExpanded: false,
          history
        };
      });
      dispatch({
        type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_SKILLS_TAB_END,
        payload: {
          studentSkillsLoaded: true,
          skills,
          additionalSkills,
          managedStudent
        }
      });
    } catch (error) {
      dispatch({
        type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_SKILLS_TAB_END,
        payload: {
          studentSkillsLoaded: false
        }
      });
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  }
};

export const loadManagedStudentCycleCases = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().managedStudentOverviewPage;
  if (!state.studentCasesLoaded) {
    const { studyPlanCycleStudentId } = payload;
    dispatch({ type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_CASES_TAB_START });
    try {
      state.managedStudentCancelToken?.cancel();
      state.managedStudentCancelToken = axios.CancelToken.source();
      let managedStudent = state.managedStudent;
      if (!managedStudent) {
        managedStudent = await api.get(`api/managed_students/${studyPlanCycleStudentId}`, null, state.managedStudentCancelToken.token);
      }
      const [ studentCases, additionalCycleCases, cycleCases ] = await Promise.all([
        api.get(`api/students/${managedStudent.studentId}/cycles/${studyPlanCycleStudentId}/cases`, null, state.managedStudentCancelToken.token),
        api.get(`api/students/${managedStudent.studentId}/cycles/${studyPlanCycleStudentId}/additional_cases`, null, state.managedStudentCancelToken.token),
        api.get(`api/cycles/${managedStudent.cycleId}/cases`, null, state.managedStudentCancelToken.token)
      ]);
      const studentHistoryCases = studentCases?.data
        .map(item => ({
          ...item,
          date: new Date(item.date)
        }))
        .sort(compareDates);
      const cases = cycleCases?.data.map(cycleCase => {
        const history = studentHistoryCases?.filter(item => item.cycleCaseId == cycleCase.id);
        return {
          id: cycleCase.id,
          name: cycleCase.studyProgramCaseName,
          studentCases: history?.length || 0,
          required: cycleCase.minRequiredNumber,
          newCases: 0,
          isExpanded: false,
          diagnoses: cycleCase.diagnoses?.map(diagnosis => diagnosis.code).join(', '),
          history
        };
      });
      const additionalCases = additionalCycleCases?.data.map(additionalCycleCase => {
        const history = studentHistoryCases?.filter(item => item.cycleCaseId === additionalCycleCase.cycleCaseId);
        return {
          id: additionalCycleCase.id,
          cycleCaseId: additionalCycleCase.cycleCaseId,
          name: additionalCycleCase.cycleCaseName,
          cycleName: additionalCycleCase.cycleName,
          additionalStudyPlanCycleStudentId: additionalCycleCase.additionalStudyPlanCycleStudentId,
          studyPlanCycleStudentId: additionalCycleCase.studyPlanCycleStudentId,
          studentCases: history?.length || 0,
          required: additionalCycleCase.minRequiredNumber,
          newCases: 0,
          isExpanded: false,
          diagnoses: additionalCycleCase.diagnoses?.join(', '),
          history
        };
      });
      dispatch({
        type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_CASES_TAB_END,
        payload: {
          studentCasesLoaded: true,
          cases,
          additionalCases,
          managedStudent
        }
      });
    } catch (error) {
      dispatch({
        type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_CASES_TAB_START,
        payload: {
          studentCasesLoaded: false
        }
      });
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  }
};

export const loadManagedStudentCycleTheoreticalParts = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().managedStudentOverviewPage;
  if (!state.studentTheoreticalPartsLoaded) {
    const { studyPlanCycleStudentId } = payload;
    dispatch({ type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_THEORETICAL_PARTS_TAB_START });
    try {
      state.managedStudentCancelToken?.cancel();
      state.managedStudentCancelToken = axios.CancelToken.source();
      let managedStudent = state.managedStudent;
      if (!managedStudent) {
        managedStudent = await api.get(`api/managed_students/${studyPlanCycleStudentId}`, null, state.managedStudentCancelToken.token);
      }
      const theoreticalParts = await api.get(`api/study_plans/${managedStudent.studyPlanId}/cycles/${managedStudent.studyPlanCycleId}/students/${studyPlanCycleStudentId}/theoretical_parts`, null, state.managedStudentCancelToken.token);
      dispatch({
        type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_THEORETICAL_PARTS_TAB_END,
        payload: {
          studentTheoreticalPartsLoaded: true,
          theoreticalParts: theoreticalParts ? theoreticalParts.data : [],
          managedStudent
        }
      });
    } catch (error) {
      dispatch({
        type: ManagedStudentOverviewPageActionTypes.LOAD_MANAGED_STUDENTS_THEORETICAL_PARTS_TAB_END,
        payload: {
          studentTheoreticalPartsLoaded: false
        }
      });
      if (!(error instanceof axios.Cancel)) {
        throw error;
      }
    }
  }
};

export const selectManagedStudentOverviewPageTab = (payload) => async(dispatch) => {
  dispatch({
    type: ManagedStudentOverviewPageActionTypes.SELECT_MANAGED_STUDENTS_OVERVIEW_PAGE_TAB,
    payload: {
      selected: payload.selected
    }
  });
};

export const expandSkill = (payload) => (dispatch, getState) => {
  const { skillId, isAdditional } = payload;
  const state = getState().managedStudentOverviewPage;
  let skills, additionalSkills;
  if (isAdditional) {
    additionalSkills = state.additionalSkills?.map(skill => ({
      ...skill,
      isExpanded: skill.id === skillId ? !skill.isExpanded : skill.isExpanded
    }));
  } else {
    skills = state.skills?.map(skill => ({
      ...skill,
      isExpanded: skill.id === skillId ? !skill.isExpanded : skill.isExpanded
    }));
  }
  dispatch({
    type: ManagedStudentOverviewPageActionTypes.UPDATE_MANAGED_STUDENTS_OVERVIEW_PAGE_SKILLS,
    payload: {
      skills,
      additionalSkills
    }
  });
};

export const expandCase = (payload) => (dispatch, getState) => {
  const { cycleCaseId, isAdditional } = payload;
  const state = getState().managedStudentOverviewPage;
  let cases, additionalCases;
  if (isAdditional) {
    additionalCases = state.additionalCases?.map(cycleCase => ({
      ...cycleCase,
      isExpanded: cycleCase.id === cycleCaseId ? !cycleCase.isExpanded : cycleCase.isExpanded
    }));
  } else {
    cases = state.cases?.map(cycleCase => ({
      ...cycleCase,
      isExpanded: cycleCase.id === cycleCaseId ? !cycleCase.isExpanded : cycleCase.isExpanded
    }));
  }
  dispatch({
    type: ManagedStudentOverviewPageActionTypes.UPDATE_MANAGED_STUDENTS_OVERVIEW_PAGE_CASES,
    payload: {
      cases,
      additionalCases
    }
  });
};

export const openReturnDialog = (payload) => (dispatch, getState) => {
  dispatch({ type: ManagedStudentOverviewPageActionTypes.OPEN_MANAGED_STUDENTS_OVERVIEW_PAGE_RETURN_DIALOG, payload });
};

export const closeReturnDialog = () => (dispatch, getState) => {
  dispatch({ type: ManagedStudentOverviewPageActionTypes.CLOSE_MANAGED_STUDENTS_OVERVIEW_PAGE_RETURN_DIALOG });
};

export const openApproveDialog = () => (dispatch) => {
  dispatch({ type: ManagedStudentOverviewPageActionTypes.OPEN_MANAGED_STUDENTS_OVERVIEW_PAGE_APPROVE_DIALOG });
};

export const closeApproveDialog = () => (dispatch) => {
  dispatch({ type: ManagedStudentOverviewPageActionTypes.CLOSE_MANAGED_STUDENTS_OVERVIEW_PAGE_APPROVE_DIALOG });
};

export const returnCycle = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().managedStudentOverviewPage;
  const { studentId, id } = state.managedStudent;
  const apiModel = {
    comment: payload?.comment
  };
  try {
    await api.post(`api/students/${studentId}/cycles/${id}/actions/return`, apiModel);
    const managedStudent = await api.get(`api/managed_students/${id}`);
    dispatch({
      type: ManagedStudentOverviewPageActionTypes.CLOSE_MANAGED_STUDENTS_OVERVIEW_PAGE_RETURN_DIALOG,
      payload: {
        managedStudent
      }
    });
  } catch (error) {
    if (error instanceof ValidationError) {
      dispatch({
        type: ManagedStudentOverviewPageActionTypes.OPEN_MANAGED_STUDENTS_OVERVIEW_PAGE_RETURN_DIALOG,
        payload: {
          returnCycleDialogErrorMessage: error.errors ? Object.keys(error.errors).map(key => error.errors[key]).join(', ') : error.message
        }
      });
    } else {
      throw error;
    }
  }
};

export const clearManagedStudentsOverviewPage = () => (dispatch) => {
  dispatch({ type: ManagedStudentOverviewPageActionTypes.CLEAR_MANAGED_STUDENTS_OVERVIEW_PAGE });
};

export const approveStudyPlanCycleStudentSkillsAndCases = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: ManagedStudentOverviewPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_START });
  const { studyPlanCycleStudentId } = payload;
  try {
    await api.post(`api/managed_students/${studyPlanCycleStudentId}/approve_cycle_parts`);
    dispatch({ type: ManagedStudentOverviewPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_END });
    history.push('/approval_requests');
  } catch (error) {
    dispatch({ type: ManagedStudentOverviewPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_END });
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const returnStudyPlanCycleStudentSkillsAndCases = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: ManagedStudentOverviewPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_START });
  const { studyPlanCycleStudentId, comment } = payload;
  try {
    await api.post(`api/managed_students/${studyPlanCycleStudentId}/return_cycle_parts`, { comment });
    dispatch({ type: ManagedStudentOverviewPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_END });
    history.push('/approval_requests');
  } catch (error) {
    dispatch({ type: ManagedStudentOverviewPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_STUDENT_PART_STATUS_END });
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};