import { StudyPlanCycleDepartmentFormPageActionTypes } from '../actions';

const initialState = {
  loading: false,
  studyPlanId: null,
  studyPlanCycleId: null,
  studyPlanCycleDepartment: null,
  saveSuccess: null,
  validationResult: null,

  availableDepartments: [],
  availableDepartmentsLoading: false,
  availableDepartmentsCancelToken: null,

  availableCycleManagersLoading: false,
  availableCycleManagersCancelToken: null,
  availableCycleManagers: [],

  managers: [],
  cancellationTokenSources: new Map(),
  hideWarningMessage: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        studyPlanId: action.payload?.studyPlanId,
        studyPlanCycleId: action.payload?.studyPlanCycleId,
        studyPlanCycleDepartment: action.payload?.studyPlanCycleDepartment,
        validationResult: action.payload?.validationResult,
        hideWarningMessage: action.payload?.studyPlanCycleDepartment?.hasChildren == 0
      };
    case StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_DEPARTMENTS_START:
      return {
        ...state,
        availableDepartmentsLoading: true
      };
    case StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_DEPARTMENTS_END:
      return {
        ...state,
        availableDepartmentsLoading: false,
        availableDepartments: action.payload?.availableDepartments ? action.payload.availableDepartments : []
      };
    case StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_CYCLE_MANAGERS_START:
      return {
        ...state,
        availableCycleManagersLoading: true
      };
    case StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_CYCLE_MANAGERS_END:
      return {
        ...state,
        availableCycleManagersLoading: false,
        availableCycleManagers: action.payload?.availableCycleManagers ? action.payload.availableCycleManagers : null
      };
    case StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_USERS_START:
      return {
        ...state,
        managers: action.payload?.managers ? action.payload.managers : []
      };
    case StudyPlanCycleDepartmentFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_AVAILABLE_USERS_END:
      return {
        ...state,
        managers: action.payload?.managers ? action.payload.managers : []
      };
    case StudyPlanCycleDepartmentFormPageActionTypes.CHANGE_STUDY_PLAN_CYCLE_DEPARTMENT_WARNING_STATUS:
      return {
        ...state,
        hideWarningMessage: !action.payload?.disabled
      };
    default:
      return state;
  }
};