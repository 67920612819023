import * as React from 'react';
import { ListPage } from '../../../ui';
import { CompetencyRequestListFilter, CompetencyRequestList } from './index';

class CompetencyRequestListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<CompetencyRequestListFilter/>}>
        <CompetencyRequestList/>
      </ListPage>
    );
  }
}

export default CompetencyRequestListPage;