import axios from 'axios';
import CycleCaseFormPageActionTypes from './cycle-case-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import cycleCaseFieldArrayNames from './cycle-case-field-array-names';

export const loadCycleCaseFormPage = ({ cycleId, cycleCaseId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_START });
  try {
    const cycle = await api.get(`api/cycles/${cycleId}`);
    const dataConsumers = [];
    if (cycleCaseId > 0) {
      const cycleCase = await api.get(`api/cycles/${cycleId}/cases/${cycleCaseId}`);
      dispatch({
        type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_END,
        payload: {
          studyProgramId: cycle.studyProgramId,
          cycleCase: {
            ...cycleCase,
            studyProgramCase: cycleCase?.studyProgramCaseId ? { id: cycleCase.studyProgramCaseId, name: cycleCase.studyProgramCaseName } : null,
            diagnoses: cycleCase?.diagnoses?.map((element, index) => {
              dataConsumers.push({ index: index, fieldArrayName: cycleCaseFieldArrayNames.DIAGNOSES });
              return { id: element.id, name: element.code + ' ' + element.name };
            })
          }
        }
      });
    } else if (cycleId) {
      dispatch({
        type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_END,
        payload: {
          studyProgramId: cycle.studyProgramId,
          cycleCase: {
            cycleId: cycleId
          }
        }
      });
    }
    dispatch(loadInputData({ dataConsumers }));
  } catch (error) {
    dispatch({ type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_END });
    throw error;
  }
};

export const saveCycleCase = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_START });
  const state = getState().cycleCaseFormPage;
  const { studyProgramCase, ...rest } = payload;
  const apiModel = {
    ...rest,
    studyProgramCaseId: studyProgramCase?.id,
    studyProgramCaseName: studyProgramCase?.name
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/cycles/${payload.cycleId}/cases/${id}`, apiModel);
    } else {
      id = await api.post(`api/cycles/${payload.cycleId}/cases`, apiModel);
    }
    dispatch({
      type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_END,
      payload: {
        cycleCase: {
          ...state.cycleCase,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_END,
      payload: {
        validationResult,
        cycleCase: state.cycleCase
      }
    });
  }
};

export const loadAvailableCases = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_CASES_START });
  const state = getState().cycleCaseFormPage;
  try {
    state.availableCasesCancelToken?.cancel();
    state.availableCasesCancelToken = axios.CancelToken.source();
    const query = { skip: 0, limit: 50, keyword: payload?.keyword };
    const studyProgramCases = await api.get(`api/study_programs/${state.studyProgramId}/cases`, query, state.availableCasesCancelToken.token);
    dispatch({
      type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_CASES_END,
      payload: {
        availableCases: studyProgramCases?.data
      }
    });
  } catch (error) {
    dispatch({ type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_CASES_END });
  }
};

export const loadAvailableDiagnoses = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().cycleCaseFormPage;
  let stateDiagnoses = [...state.diagnoses];
  stateDiagnoses[payload.index] = {
    loading: true,
    availableDiagnoses: stateDiagnoses[payload.index]?.availableDiagnoses ? [...stateDiagnoses[payload.index].availableDiagnoses] : []
  };
  dispatch({
    type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_DIAGNOSES_START,
    payload: {
      diagnoses: stateDiagnoses
    }
  });
  try {
    const cancellationTokenKey = `diagnoses[${payload.index}]`;
    state.cancellationTokenSources.get(cancellationTokenKey)?.cancel();
    state.cancellationTokenSources.set(cancellationTokenKey, axios.CancelToken.source());
    const query = { skip: 0, limit: 50, keyword: payload.keyword, extendedSearch: true };
    const diagnoses = await api.get(`api/tlk`, query, state.cancellationTokenSources.get(cancellationTokenKey).token);
    stateDiagnoses = [...getState().cycleCaseFormPage.diagnoses];
    stateDiagnoses[payload.index] = {
      loading: false,
      availableDiagnoses: diagnoses.data.map(element => ({ id: element.id, name: element.code + ' ' + element.name }))
    };
    dispatch({
      type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_DIAGNOSES_END,
      payload: {
        diagnoses: stateDiagnoses
      }
    });
  } catch (error) {
    dispatch({ type: CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_DIAGNOSES_END });
  }
};

export const loadInputData = (payload) => async(dispatch, getState, { api }) => {
  dispatch(loadAvailableCases());
  if (payload?.dataConsumers?.length > 0) {
    payload.dataConsumers.forEach(element => {
      const { index, fieldArrayName } = element;
      if (index >= 0) {
        switch (fieldArrayName) {
          case cycleCaseFieldArrayNames.DIAGNOSES:
            dispatch(loadAvailableDiagnoses({ index }));
            break;
          default:
            break;
        }
      }
    });
  }
};
