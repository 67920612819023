import CycleCaseFormPageActionTypes from '../actions/cycle-case-form-page-action-types';

const initialState = {
  loading: false,
  studyProgramId: null,
  cycleCase: {},
  availableCasesLoading: false,
  availableCasesCancelToken: null,
  availableCases: [],
  diagnoses: [],
  saveSuccess: false,
  validationResult: null,
  cancellationTokenSources: new Map()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        studyProgramId: action.payload?.studyProgramId,
        cycleCase: action.payload ? action.payload.cycleCase : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_CASES_START:
      return {
        ...state,
        availableCasesLoading: true
      };
    case CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_CASES_END:
      return {
        ...state,
        availableCasesLoading: false,
        availableCases: action.payload ? action.payload.availableCases : []
      };
    case CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_DIAGNOSES_START:
      return {
        ...state,
        diagnoses: action.payload?.diagnoses ? action.payload?.diagnoses : []
      };
    case CycleCaseFormPageActionTypes.LOAD_CYCLE_CASE_AVAILABLE_DIAGNOSES_END:
      return {
        ...state,
        diagnoses: action.payload?.diagnoses ? action.payload?.diagnoses : []
      };
    default:
      return state;
  }
};