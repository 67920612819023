import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import { FormPage, ComboBox, NumericBox } from '../../../ui';
import { loadAvailableCases, loadAvailableDiagnoses, loadCycleCaseFormPage, saveCycleCase, loadInputData } from '../actions/cycle-case-form-page-actions';
import { withRouter } from '../../../components/withRouter';
import cycleCaseFieldArrayNames from '../actions/cycle-case-field-array-names';

class CycleCaseFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLoadAvailableDiagnoses = this.handleLoadAvailableDiagnoses.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.cycleId) {
      await this.props.loadCycleCaseFormPage({
        cycleId: this.props.params.cycleId,
        cycleCaseId: this.props.params.cycleCaseId
      });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);

    return (
      <FormPage
        title={localizationService.toLanguageString('cycle.cycleCase')}
        breadcrumbItems={[
          {
            id: 'cycles',
            text: localizationService.toLanguageString('cycle.cycles')
          },
          {
            id: 'cycle',
            text: localizationService.toLanguageString('cycle.cycle')
          },
          {
            id: 'cycle-case',
            text: localizationService.toLanguageString('cycle.cycleCase'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.cycleCase}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <>
            <Field
              name='studyProgramCase'
              label={localizationService.toLanguageString('cycle.case')}
              component={ComboBox}
              loading={this.props.availableCasesLoading}
              data={this.props.availableCases}
              onFilter={this.props.loadAvailableCases}
              textField='name'
              valueField='id'
              allowCustom={true}
              width='340px'
            />
            <Field
              name='minRequiredNumber'
              label={localizationService.toLanguageString('cycle.minCaseRequiredNumber')}
              component={NumericBox}
              min={0}
              width='200px'
            />
          </>
        )}
      />
    );
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.studyProgramCase) {
      errors.studyProgramCase = localizationService.toLanguageString('validation.required');
    }
    if (!values.minRequiredNumber) {
      errors.minRequiredNumber = localizationService.toLanguageString('validation.required');
    }
    if (!modified.minRequiredNumber && !modified.studyProgramCase) {
      if (validationResult?.errors?.minRequiredNumber) {
        errors.minRequiredNumber = validationResult?.errors?.minRequiredNumber;
      }
      if (validationResult?.errors?.studyProgramCaseName) {
        errors.studyProgramCase = validationResult?.errors?.studyProgramCaseName;
      }
    }
    if (values.diagnoses) {
      for (let i = 0; i < values.diagnoses.length; i++) {
        if (!values.diagnoses[i]?.id) {
          errors[`${cycleCaseFieldArrayNames.DIAGNOSES}[${i}]`] = localizationService.toLanguageString('validation.required');
        }
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'cycles') {
      this.props.navigate('/cycles');
    }
    if (e.id === 'cycle') {
      this.props.navigate(`/cycles/${this.props.params.cycleId}/overview`);
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleAddCaseDiagnosis(fieldArrayRenderProps) {
    fieldArrayRenderProps.onPush({
      value: {}
    });
    this.props.loadInputData({ dataConsumers: [{ index: fieldArrayRenderProps?.value?.length ?? 0, fieldArrayName: fieldArrayRenderProps?.name }] });
  }

  handleRemoveCaseDiagnosis(index, fieldArrayRenderProps) {
    fieldArrayRenderProps.onRemove({
      index: index
    });
  }

  handleSubmit(payload) {
    this.props.saveCycleCase(payload);
  }

  handleLoadAvailableDiagnoses(e, index) {
    this.props.loadAvailableDiagnoses({
      keyword: e.keyword,
      index: index
    });
  }

}

registerForLocalization(CycleCaseFormPage);

const mapStateToProps = state => ({
  loading: state.cycleCaseFormPage.loading,
  cycleCase: state.cycleCaseFormPage.cycleCase,
  availableCasesLoading: state.cycleCaseFormPage.availableCasesLoading,
  availableCases: state.cycleCaseFormPage.availableCases,
  diagnoses: state.cycleCaseFormPage.diagnoses,
  validationResult: state.cycleCaseFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadCycleCaseFormPage: (payload) => dispatch(loadCycleCaseFormPage(payload)),
  saveCycleCase: (payload) => dispatch(saveCycleCase(payload)),
  loadAvailableCases: (payload) => dispatch(loadAvailableCases(payload)),
  loadAvailableDiagnoses: (payload) => dispatch(loadAvailableDiagnoses(payload)),
  loadInputData: (payload) => dispatch(loadInputData(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleCaseFormPage));
