import UserFormPageActionTypes from '../actions/user-form-page-action-types';

const initialState = {
  loading: false,
  user: null,
  validationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserFormPageActionTypes.LOAD_USER_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case UserFormPageActionTypes.LOAD_USER_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        user: action.payload ? action.payload.user : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case UserFormPageActionTypes.CLEAR_USER_FORM_PAGE:
      return initialState;
    default:
      return state;
  }
};