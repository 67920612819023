import * as React from 'react';
import { ListPage } from '../../../ui';
import { TheoreticalPartListFilter, TheoreticalPartList } from './index';

class TheoreticalParListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<TheoreticalPartListFilter/>}>
        <TheoreticalPartList/>
      </ListPage>
    );
  }
}

export default TheoreticalParListPage;