import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { StudentCompetencySurveyList } from '../../shared/student-competency';

class MyCompetencySurveyList extends React.Component {

  render() {
    return (
      <StudentCompetencySurveyList/>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyCompetencySurveyList));
