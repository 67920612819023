import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { FormPage, TextBox, CheckBox, Text, StackLayout } from '../../../ui';
import {
  loadUserFormPage,
  saveUser,
  clearUserFormPage
} from '../actions/user-form-page-actions';
import { openUserSearchDialog } from '../actions/user-search-dialog-actions';
import { isStringNullOrEmpty } from '../../common/util/is-string-null-or-empty';
import { withRouter } from '../../../components/withRouter';
import { validateEmail } from '../../../utils/emailValidationHelper';
import { validateStampNumber } from '../../../utils/stampNumberValidationHelper';

class UserFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOpenUserSearchDialog = this.handleOpenUserSearchDialog.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.userId) {
      await this.props.loadUserFormPage({
        userId: this.props.params.userId
      });
    }
  }

  componentWillUnmount() {
    this.props.clearUserFormPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        breadcrumbItems={[
          {
            id: 'users',
            text: localizationService.toLanguageString('User.users')
          }, {
            id: 'user',
            text: localizationService.toLanguageString('User.user'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.user}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={(formRenderProps) => (
          <>
            {formRenderProps.valueGetter('key')
              ?
              <StackLayout rowGap='2'>
                <Text>{localizationService.toLanguageString('User.userAddedFrom')}</Text>
                <Text variant='body2'>{localizationService.toLanguageString('User.key')}: {formRenderProps.valueGetter('key')}</Text>
              </StackLayout>
              :
              (!this.props.params.userId > 0 &&
                <Button onClick={() => this.handleOpenUserSearchDialog(formRenderProps)} type='Button'>{localizationService.toLanguageString('User.searchUser')}</Button>
              )
            }
            <Field
              name='name'
              label={localizationService.toLanguageString('User.firstName')}
              component={TextBox}
              width='320px'
              disabled={!isStringNullOrEmpty(formRenderProps.valueGetter('key'))}
            />
            <Field
              name='surname'
              label={localizationService.toLanguageString('User.lastName')}
              component={TextBox}
              width='320px'
              disabled={!isStringNullOrEmpty(formRenderProps.valueGetter('key'))}
            />
            <Field
              name='personalCode'
              label={localizationService.toLanguageString('User.personalCode')}
              component={TextBox}
              width='320px'
              disabled={!isStringNullOrEmpty(formRenderProps.valueGetter('key'))}
            />
            <Field
              name='stampNumber'
              label={localizationService.toLanguageString('User.stampNumber')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='email'
              label={localizationService.toLanguageString('User.email')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='administrator'
              label={localizationService.toLanguageString('roleType.Administrator')}
              component={CheckBox}
            />
            <Field
              name='manager'
              label={localizationService.toLanguageString('roleType.Manager')}
              component={CheckBox}
              marginTop='0px'
            />
            <Field
              name='mentor'
              label={localizationService.toLanguageString('roleType.Mentor')}
              component={CheckBox}
              marginTop='0px'
            />
            <Field
              name='isExternal'
              label={localizationService.toLanguageString('User.externalUser')}
              component={CheckBox}
              marginTop='0px'
            />
            <Field
              name='isEmployee'
              label={localizationService.toLanguageString('User.employee')}
              component={CheckBox}
              marginTop='0px'
            />
          </>
        )}
      />
    );
  }

  handleOpenUserSearchDialog(formRenderProps) {
    this.props.openUserSearchDialog({
      onAdd: (payload) => {
        formRenderProps.onChange('name', { value: payload.name });
        formRenderProps.onChange('surname', { value: payload.surname });
        formRenderProps.onChange('personalCode', { value: payload.personalCode });
        formRenderProps.onChange('key', { value: payload.key });
        formRenderProps.onChange('id', { value: payload.id });
        formRenderProps.onChange('username', { value: payload.username });
        formRenderProps.onChange('email', { value: payload.email });
      }
    });
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};

    if (!values.name) {
      errors.name = localizationService.toLanguageString('validation.required');
    }
    if (!values.surname) {
      errors.surname = localizationService.toLanguageString('validation.required');
    }
    if (!values.email) {
      errors.email = localizationService.toLanguageString('validation.required');
    } else if (!validateEmail(values.email)) {
      errors.email = localizationService.toLanguageString('validation.invalidEmailFormat');
    }
    if (values.stampNumber && !validateStampNumber(values.stampNumber)) {
      errors.stampNumber = localizationService.toLanguageString('User.invalidStampNumber');
    }
    if (!modified.name
      && !modified.surname
      && !modified.personalCode
      && !modified.email
      && !modified.administrator
      && !modified.manager
      && !modified.mentor
      && !modified.isExternal
      && !modified.stampNumber) {
      if (validationResult?.errors.name) {
        errors.name = validationResult?.errors?.name;
      }
      if (validationResult?.errors.surname) {
        errors.surname = validationResult?.errors.surname;
      }
      if (validationResult?.errors.personalCode) {
        errors.personalCode = validationResult?.errors.personalCode;
      }
      if (validationResult?.errors.email) {
        errors.email = validationResult?.errors.email;
      }
      if (validationResult?.errors.stampNumber) {
        errors.stampNumber = validationResult?.errors.stampNumber;
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'users') {
      this.props.navigate('/users');
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveUser(payload);
  }
}

registerForLocalization(UserFormPage);

const mapStateToProps = state => ({
  loading: state.userFormPage.loading,
  user: state.userFormPage.user,
  lsmusisUsers: state.userFormPage.lsmusisUsers,
  lsmusisUsersLoading: state.userFormPage.lsmusisUsersLoading,
  validationResult: state.userFormPage.validationResult,
  fieldsDisabled: state.userFormPage.fieldsDisabled,
  searchActive: state.userFormPage.searchActive,
  searchOpen: state.userFormPage.searchOpen
});

const mapDispatchToProps = dispatch => ({
  loadUserFormPage: (payload) => dispatch(loadUserFormPage(payload)),
  saveUser: (payload) => dispatch(saveUser(payload)),
  openUserSearchDialog: (payload) => dispatch(openUserSearchDialog(payload)),
  clearUserFormPage: () => dispatch(clearUserFormPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserFormPage));