import StudyProgramSkillAbilityFormPageActionTypes from './study-program-skill-ability-form-page-action-types';
import ValidationError from '../../../utils/validation-error';

export const loadStudyProgramSkillAbilityFormPage = ({ studyProgramId, studyProgramSkillAbilityId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyProgramSkillAbilityFormPageActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_FORM_PAGE_START });
  try {
    const studyProgram = await api.get(`api/study_programs/${studyProgramId}`);
    if (studyProgramSkillAbilityId > 0) {
      const studyProgramSkillAbility = await api.get(`api/study_programs/${studyProgramId}/skill_abilities/${studyProgramSkillAbilityId}`);
      dispatch({
        type: StudyProgramSkillAbilityFormPageActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_FORM_PAGE_END,
        payload: {
          studyProgramId: studyProgram.id,
          studyProgramSkillAbility: studyProgramSkillAbility
        }
      });
    } else if (studyProgramId) {
      dispatch({
        type: StudyProgramSkillAbilityFormPageActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_FORM_PAGE_END,
        payload: {
          studyProgramId: studyProgram.id,
          studyProgramSkillAbility: {
            studyProgramId: studyProgram.id
          }
        }
      });
    }
  } catch (error) {
    dispatch({ type: StudyProgramSkillAbilityFormPageActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_FORM_PAGE_END });
    throw error;
  }
};

export const saveStudyProgramSkillAbility = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyProgramSkillAbilityFormPageActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_FORM_PAGE_START });
  const state = getState().studyProgramSkillAbilityFormPage;
  const apiModel = {
    ...payload
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/study_programs/${payload.studyProgramId}/skill_abilities/${id}`, apiModel);
    } else {
      id = await api.post(`api/study_programs/${payload.studyProgramId}/skill_abilities`, apiModel);
    }
    dispatch({
      type: StudyProgramSkillAbilityFormPageActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_FORM_PAGE_END,
      payload: {
        studyProgramSkillAbility: {
          ...state.studyProgramSkillAbility,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: StudyProgramSkillAbilityFormPageActionTypes.LOAD_STUDY_PROGRAM_SKILL_ABILITY_FORM_PAGE_END,
      payload: {
        validationResult,
        studyProgramSkillAbility: state.studyProgramSkillAbility
      }
    });
  }
};
