import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field//,
  //FieldArray
} from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import {
  loadAvailableDepartments,
  loadStudyPlanCycleDepartmentFormPage,
  saveStudyPlanCycleDepartment,
  loadAvailableManagers,
  changeWarningStatus
} from '../actions/study-plan-cycle-department-form-page-actions';
import { openUserSearchDialog } from '../../users/actions/user-search-dialog-actions';
import { ComboBox, FormPage, NumericBox, StackLayout, Text, WrapLayout } from '../../../ui';
import { withRouter } from '../../../components/withRouter';
import { SCREEN_SIZES } from '../../../resources/screenSize';
import { Hint } from '@progress/kendo-react-labels';

const ManagersContainer = styled(WrapLayout).attrs(props => ({
  orientation: 'vertical',
  rowgap: '2px',
  width: '35px'
}))`
  margin-top: 2px;
`;

const ManagersTitle = styled(Text).attrs(props => ({
  variant: 'h6',
  textColor: props.theme.gray80,
  truncate: true
}))`
  margin-top: 20px;
`;

const DeleteButton = styled(Button).attrs(props => ({
  type: 'button',
  icon: 'delete'
}))`
  margin-top: 28px;
`;

class StudyPlanCycleDepartmentFormPage extends React.PureComponent {

  constructor() {
    super();
    this.renderManagers = this.renderManagers.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.renderManagers = this.renderManagers.bind(this);
    this.handleLoadAvailableManagers = this.handleLoadAvailableManagers.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOpenUserSearchDialog = this.handleOpenUserSearchDialog.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyPlanId) {
      await this.props.loadStudyPlanCycleDepartmentFormPage({
        studyPlanId: this.props.params.studyPlanId,
        studyPlanCycleId: this.props.params.studyPlanCycleId,
        studyPlanCycleDepartmentId: this.props.params.studyPlanCycleDepartmentId
      });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <FormPage
          title={localizationService.toLanguageString('StudyPlanCycleDepartment.formTitle')}
          breadcrumbItems={[
            {
              id: 'study-plans',
              text: localizationService.toLanguageString('StudyPlanCycle.studyPlans')
            },
            {
              id: 'study-plan',
              text: localizationService.toLanguageString('StudyPlanCycle.studyPlan')
            },
            {
              id: 'study-plan-cycle',
              text: localizationService.toLanguageString('StudyPlanCycle.studyPlanCycle')
            },
            {
              id: 'study-plan-cycle-department',
              text: localizationService.toLanguageString('StudyPlanCycleDepartment.studyPlanCycleDepartment'),
              disabled: true
            }
          ]}
          onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
          validationResult={this.props.validationResult}
          loading={this.props.loading}
          initialValues={this.props.studyPlanCycleDepartment}
          localizationService={localizationService}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          validator={this.handleValidate}
          render={(formRenderProps) => (
            <>
              <Field
                name='department'
                label={localizationService.toLanguageString('StudyPlanCycleDepartment.department')}
                component={ComboBox}
                loading={this.props.availableDepartmentsLoading}
                data={this.props.availableDepartments}
                onFilter={this.props.loadAvailableDepartments}
                textField='name'
                textValue='id'
                width='340px'
                onChange={e => this.handleDepartmentChange(e, formRenderProps)}
              />
              {!this.props.hideWarningMessage && <Hint>{localizationService.toLanguageString('StudyPlanCycleDepartment.parentDepartmentWarning')}</Hint>}
              <Field
                name='credits'
                label={localizationService.toLanguageString('StudyPlanCycleDepartment.credits')}
                component={NumericBox}
                min={0}
                width='185px'
              />
              <WrapLayout orientation='horizontal'>
                <Field
                  name='minStudentCount'
                  label={localizationService.toLanguageString('StudyPlanCycleDepartment.minResidentCount')}
                  component={NumericBox}
                  min={0}
                  width='165px'
                />
                <Field
                  name='maxStudentCount'
                  label={localizationService.toLanguageString('StudyPlanCycleDepartment.maxResidentCount')}
                  component={NumericBox}
                  min={0}
                  width='165px'
                />
              </WrapLayout>
              {/* <FieldArray
                name='managers'
                component={this.renderManagers}
              >
              </FieldArray> */}
            </>
          )}
        />
      </>
    );
  }

  renderManagers(fieldArrayRenderProps, tt) {
    const localizationService = provideLocalizationService(this);
    return (
      <ManagersContainer>
        <ManagersTitle>{localizationService.toLanguageString('StudyPlanCycleDepartment.residentManagers')}</ManagersTitle>
        {fieldArrayRenderProps.value?.map((item, index) => {
          const fieldName = 'managers[' + index + ']';
          const manager = this.props.managers[index];
          const comboboxWidth = window.innerWidth > SCREEN_SIZES.XS ? '340px' : '292px';
          return (
            <StackLayout key={index} orientation='horizontal' align='left' valign='top'>
              <Field
                name={fieldName}
                label={localizationService.toLanguageString('StudyPlanCycleDepartment.residentManager')}
                component={ComboBox}
                loading={manager?.loading}
                data={manager?.availableManagers}
                onFilter={async(e) => await this.handleLoadAvailableManagers(e, index)}
                onAddClick={() => this.handleOpenUserSearchDialog(fieldArrayRenderProps, index)}
                textField='name'
                valueField='userId'
                width={comboboxWidth}
              />
              <DeleteButton onClick={() => this.handleRemoveManager(index, fieldArrayRenderProps)}/>
            </StackLayout>
          );
        })}
        <WrapLayout orientation='horizontal' align='left'>
          <Button
            type='button'
            icon='plus'
            onClick={() => this.handleAddManager(fieldArrayRenderProps)}
          />
        </WrapLayout>
      </ManagersContainer>
    );
  }

  handleDepartmentChange(e, formRenderProps) {
    this.props.changeWarningStatus(e.value);
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};

    if (!values.department) {
      errors.department = localizationService.toLanguageString('validation.required');
    }
    if (!values.credits) {
      errors.credits = localizationService.toLanguageString('validation.required');
    } else if (values.credits < 0) {
      errors.credits = localizationService.toLanguageString('validation.negativeValue');
    } else if (values.credits > values.cycleCredits) {
      errors.credits = localizationService.toLanguageString('StudyPlanCycleDepartment.validationDepartmentCredits');
    }
    if (values.minStudentCount < 0) {
      errors.minStudentCount = localizationService.toLanguageString('validation.negativeValue');
    }
    if (values.maxStudentCount < 0) {
      errors.maxStudentCount = localizationService.toLanguageString('validation.negativeValue');
    }
    if (values.minStudentCount > values.maxStudentCount) {
      errors.minStudentCount = localizationService.toLanguageString('StudyPlanCycleDepartment.validationMinMax');
      errors.maxStudentCount = localizationService.toLanguageString('StudyPlanCycleDepartment.validationMinMax');
    }

    if (values.managers) {
      const managerUserIds = [];
      for (let i = 0; i < values.managers.length; i++) {
        if (!values.managers[i]) {
          errors[`managers[${i}]`] = localizationService.toLanguageString('validation.required');
        } else {
          if (managerUserIds.indexOf(values.managers[i].userId) >= 0) {
            errors[`managers[${i}]`] = localizationService.toLanguageString('StudyPlanCycleDepartment.validationStudentManagerAlreadyExists');
          }
          managerUserIds.push(values.managers[i].userId);
        }
      }
    }

    if (!modified.department && !modified.credits && !modified.minStudentCount && !modified.maxStudentCount) {
      if (validationResult?.errors.departmentId) {
        errors.department = validationResult?.errors?.departmentId;
      }
      if (validationResult?.errors.credits) {
        errors.credits = validationResult?.errors?.credits;
      }
      if (validationResult?.errors.minStudentCount) {
        errors.minStudentCount = validationResult?.errors?.minStudentCount;
      }
      if (validationResult?.errors.maxStudentCount) {
        errors.maxStudentCount = validationResult?.errors?.maxStudentCount;
      }
    }

    return errors;
  }

  async handleLoadAvailableManagers(e, index) {
    await this.props.loadAvailableManagers({
      keyword: e.keyword,
      index
    });
  }

  handleAddManager(fieldArrayRenderProps) {
    fieldArrayRenderProps.onPush({
      value: null
    });
  }

  handleRemoveManager(index, fieldArrayRenderProps) {
    fieldArrayRenderProps.onRemove({
      index
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-plans') {
      this.props.navigate('/study_plans');
    }
    if (e.id === 'study-plan') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/overview`);
    }
    if (e.id === 'study-plan-cycle') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/cycles/${this.props.params.studyPlanCycleId}/overview`);
    }
  }

  handleSubmit(payload) {
    this.props.saveStudyPlanCycleDepartment(payload);
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleOpenUserSearchDialog(fieldArrayRenderProps, index) {
    this.props.openUserSearchDialog({
      onAdd: (payload) => fieldArrayRenderProps.onReplace({ index: index, value: { userId: payload.id, name: payload.text } })
    });
  }

}

const mapStateToProps = state => ({
  loading: state.studyPlanCycleDepartmentFormPage.loading,
  studyPlanId: state.studyPlanCycleDepartmentFormPage.studyPlanId,
  studyPlanCycleId: state.studyPlanCycleDepartmentFormPage.studyPlanCycleId,
  studyPlanCycleDepartment: state.studyPlanCycleDepartmentFormPage.studyPlanCycleDepartment,
  managers: state.studyPlanCycleDepartmentFormPage.managers,
  availableDepartments: state.studyPlanCycleDepartmentFormPage.availableDepartments,
  availableDepartmentsLoading: state.studyPlanCycleDepartmentFormPage.availableDepartmentsLoading,
  validationResult: state.studyPlanCycleDepartmentFormPage.validationResult,
  availableCycleManagers: state.studyPlanCycleDepartmentFormPage.availableCycleManagers,
  availableCycleManagersLoading: state.studyPlanCycleDepartmentFormPage.availableCycleManagersLoading,
  hideWarningMessage: state.studyPlanCycleDepartmentFormPage.hideWarningMessage
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanCycleDepartmentFormPage: (payload) => dispatch(loadStudyPlanCycleDepartmentFormPage(payload)),
  saveStudyPlanCycleDepartment: (payload) => dispatch(saveStudyPlanCycleDepartment(payload)),
  loadAvailableDepartments: (payload) => dispatch(loadAvailableDepartments(payload)),
  loadAvailableManagers: (payload) => dispatch(loadAvailableManagers(payload)),
  openUserSearchDialog: (payload) => dispatch(openUserSearchDialog(payload)),
  changeWarningStatus: (payload) => dispatch(changeWarningStatus(payload))
});

registerForLocalization(StudyPlanCycleDepartmentFormPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleDepartmentFormPage));