import React from 'react';
import { TimeFormatter } from '.';
import { provideIntlService, registerForIntl } from '@progress/kendo-react-intl';

export default class GridTimeCell extends React.PureComponent {

  render() {
    const intlService = provideIntlService(this);
    const value = this.props.dataItem[this.props.field]
      ? TimeFormatter(new Date(this.props.dataItem[this.props.field]), intlService)
      : '';
    return (
      <td className={this.props.className}>{value}</td>
    );
  }

}

registerForIntl(GridTimeCell);