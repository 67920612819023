import axios from 'axios';
import ValidationError from '../../../utils/validation-error';
import StudyPlanCycleTheoreticalPartFormPageActionTypes from './study-plan-cycle-theoretical-part-form-page-action-types';
import { CYCLE_TEACHING_TYPE } from '../../../resources/cycleTeachingType';

export const loadStudyPlanCycleTheoreticalPartFormPage = ({ studyPlanId, studyPlanCycleId, studyPlanCycleTheoreticalPartId, localizationService }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_START });
  try {
    const studyPlanCycle = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}`);
    const cycle = await api.get(`api/cycles/${studyPlanCycle.cycleId}`);
    if (studyPlanCycleTheoreticalPartId > 0) {
      const studyPlanCycleTheoreticalPart = await api.get(`api/theoretical_parts/${studyPlanCycleTheoreticalPartId}`);

      const cycleStudyPrograms = cycle?.studyPrograms?.map(element => ({
        studyProgramId: element.studyProgramId,
        studyProgramName: element.studyProgramName,
        isChecked: studyPlanCycleTheoreticalPart?.studyPrograms?.find(theoreticalPartStudyProgram => theoreticalPartStudyProgram.studyProgramId === element.studyProgramId) != null
      })) ?? [];

      if (cycle.teachingType === CYCLE_TEACHING_TYPE.LOCAL || cycle.teachingType === CYCLE_TEACHING_TYPE.LOCAL_AND_EXTERNAL) {
        cycleStudyPrograms.splice(0, 0, {
          studyProgramId: cycle.studyProgramId,
          studyProgramName: cycle.studyProgramName,
          isChecked: studyPlanCycleTheoreticalPart?.studyPrograms?.find(theoreticalPartStudyProgram => theoreticalPartStudyProgram.studyProgramId === cycle.studyProgramId) != null
        });
      }

      const theoreticalPartPrograms = studyPlanCycleTheoreticalPart?.studyPrograms
        ?.filter(theoreticalPartStudyProgram => cycle?.studyPrograms?.find(cycleStudyProgram => cycleStudyProgram.studyProgramId === theoreticalPartStudyProgram.studyProgramId) === undefined && cycle.studyProgramId !== theoreticalPartStudyProgram.studyProgramId)
        ?.map(element => ({
          studyProgramId: element.studyProgramId,
          studyProgramName: element.studyProgramName,
          isChecked: true
        })) ?? [];
      const concatenatedStudyPrograms = [ ...cycleStudyPrograms, ...theoreticalPartPrograms ];

      dispatch({
        type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_END,
        payload: {
          cycle,
          studyPlanId,
          studyPlanCycleId,
          studyPlanCycleTheoreticalPart: {
            ...studyPlanCycleTheoreticalPart,
            cycleTheoreticalPart:
              studyPlanCycleTheoreticalPart?.cycleTheoreticalPartId
                ? {
                  id: studyPlanCycleTheoreticalPart.cycleTheoreticalPartId,
                  studyProgramTheoreticalPartName: `${studyPlanCycleTheoreticalPart.studyProgramTheoreticalPartName} (${localizationService.toLanguageString(`cycleTheoreticalPartType.${studyPlanCycleTheoreticalPart.cycleTheoreticalPartType}`)})`
                }
                : null,
            plannedDate: studyPlanCycleTheoreticalPart?.plannedDate ? new Date(studyPlanCycleTheoreticalPart.plannedDate) : null,
            plannedTime: studyPlanCycleTheoreticalPart?.plannedTime ? new Date(studyPlanCycleTheoreticalPart.plannedTime) : null,
            lecturer: studyPlanCycleTheoreticalPart?.userId
              ? {
                id: studyPlanCycleTheoreticalPart.userId,
                fullName: studyPlanCycleTheoreticalPart.fullName
              }
              : null,
            studyPrograms: concatenatedStudyPrograms
          }
        }
      });
      dispatch(loadAvailableLecturers({
        cycleTheoreticalPartId: studyPlanCycleTheoreticalPart.cycleTheoreticalPartId,
        value: {
          id: studyPlanCycleTheoreticalPart.cycleTheoreticalPartId,
          studyProgramTheoreticalPartName: studyPlanCycleTheoreticalPart.studyProgramTheoreticalPartName
        },
        lecturer: studyPlanCycleTheoreticalPart?.userId
          ? {
            id: studyPlanCycleTheoreticalPart.userId,
            fullName: studyPlanCycleTheoreticalPart.fullName
          }
          : null
      }));
    } else if (studyPlanId) {

      const cycleStudyPrograms = cycle?.studyPrograms?.map(element => ({
        studyProgramId: element.studyProgramId,
        studyProgramName: element.studyProgramName,
        isChecked: false
      })) ?? [];

      if (cycle.teachingType === CYCLE_TEACHING_TYPE.LOCAL || cycle.teachingType === CYCLE_TEACHING_TYPE.LOCAL_AND_EXTERNAL) {
        cycleStudyPrograms.splice(0, 0, {
          studyProgramId: cycle.studyProgramId,
          studyProgramName: cycle.studyProgramName,
          isChecked: false
        });
      }

      dispatch({
        type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_END,
        payload: {
          cycle,
          studyPlanId,
          studyPlanCycleId,
          studyPlanCycleTheoreticalPart: {
            studyPlanCycleId,
            studyPrograms: cycleStudyPrograms
          }
        }
      });
    }
  } catch (error) {
    dispatch({ type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_END });
    throw error;
  }
  dispatch(loadAvailableTheoreticalParts());
};

export const saveStudyPlanCycleTheoreticalPart = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_START });
  const state = getState().studyPlanCycleTheoreticalPartFormPage;
  const { ...rest } = payload;
  const apiModel = {
    ...rest,
    cycleTheoreticalPartId: payload.cycleTheoreticalPart?.id,
    userId: payload.lecturer?.id,
    studyPrograms: payload.studyPrograms?.filter(element => element.isChecked)?.map(element => ({ studyProgramId: element.studyProgramId }))
  };
  let id = payload?.id;
  try {
    if (id > 0) {
      await api.put(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/theoretical_parts/${id}`, apiModel);
    } else {
      id = await api.post(`api/study_plans/${state.studyPlanId}/cycles/${state.studyPlanCycleId}/theoretical_parts`, apiModel);
    }
    dispatch({
      type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_END,
      payload: {
        studyPlanCycleTheoreticalPart: {
          ...state.studyPlanCycleTheoreticalPart,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_FORM_PAGE_END,
      payload: {
        validationResult,
        studyPlanCycleTheoreticalPart: state.studyPlanCycleTheoreticalPart
      }
    });
  }
};

export const loadAvailableTheoreticalParts = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_START });
  const state = getState().studyPlanCycleTheoreticalPartFormPage;
  try {
    state.availableTheoreticalPartsCancelToken?.cancel();
    state.availableTheoreticalPartsCancelToken = axios.CancelToken.source();
    const query = { keyword: payload?.keyword };
    const theoreticalParts = await api.get(`api/cycles/${state.cycle.id}/theoretical_parts`, query, state.availableTheoreticalPartsCancelToken.token);
    dispatch({
      type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_END,
      payload: {
        availableTheoreticalParts: theoreticalParts?.data?.map(element => (
          {
            id: element.id,
            studyProgramTheoreticalPartName: `${element.studyProgramTheoreticalPartName} (${payload.localizationService.toLanguageString(`cycleTheoreticalPartType.${element.type}`)})`
          }
        ))
      }
    });
  } catch (error) {
    dispatch({ type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_THEORETICAL_PARTS_END });
  }
};

export const loadAvailableLecturers = (payload) => async(dispatch, getState, { api }) => {
  const initialValue = {
    id: payload.value ? payload.value.id : null,
    text: payload.value ? payload.value.studyProgramTheoreticalPartName : null
  };
  dispatch({ type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_LECTURERS_START });
  try {
    const state = getState().studyPlanCycleTheoreticalPartFormPage;
    state.availableLecturersCancelToken?.cancel();
    state.availableLecturersCancelToken = axios.CancelToken.source();
    const cycleTheoreticalPart = payload?.cycleTheoreticalPartId
      ? await api.get(`api/cycles/${state.cycle.id}/theoretical_parts/${payload.cycleTheoreticalPartId}`, state.availableLecturersCancelToken.token)
      : [];
    dispatch({
      type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_LECTURERS_END,
      payload: {
        cycleTheoreticalPartId: payload.cycleTheoreticalPartId,
        users: cycleTheoreticalPart ? cycleTheoreticalPart.teachers?.map(teacher => ({ id: teacher.userId, fullName: teacher.name })) : [],
        studyPlanCycleTheoreticalPart: {
          ...state.studyPlanCycleTheoreticalPart,
          plannedDate: payload.studyPlanCycleTheoreticalPart?.plannedDate ?? state.studyPlanCycleTheoreticalPart.plannedDate,
          plannedTime: payload.studyPlanCycleTheoreticalPart?.plannedTime ?? state.studyPlanCycleTheoreticalPart.plannedTime,
          link: payload.studyPlanCycleTheoreticalPart?.link ?? state.studyPlanCycleTheoreticalPart.link,
          location: payload.studyPlanCycleTheoreticalPart?.location ?? state.studyPlanCycleTheoreticalPart.location,
          studyPrograms: payload.studyPlanCycleTheoreticalPart?.studyPrograms ?? state.studyPlanCycleTheoreticalPart.studyPrograms,
          cycleTheoreticalPart: initialValue.id != null
            ? { id: initialValue.id, studyProgramTheoreticalPartName: initialValue.text }
            : null,
          lecturer: payload?.lecturer
            ? payload.lecturer
            : cycleTheoreticalPart.teachers?.length === 1
              ? { id: cycleTheoreticalPart.teachers[0].userId, fullName: cycleTheoreticalPart.teachers[0].name }
              : null
        }
      }
    });
  } catch (error) {
    dispatch({
      type: StudyPlanCycleTheoreticalPartFormPageActionTypes.LOAD_STUDY_PLAN_CYCLE_THEORETICAL_PART_AVAILABLE_LECTURERS_END
    });
    throw error;
  }
};

export const clearStore = () =>(dispatch) => {
  dispatch({ type: StudyPlanCycleTheoreticalPartFormPageActionTypes.CLEAR_STUDY_PLAN_CYCLE_THEORETICAL_PART_CLEAR_STORE });
};