import CycleCaseOverviewPageActionTypes from './cycle-case-overview-page-action-types';
import axios from 'axios';

export const loadCycleCaseOverviewPage = ({ cycleId, cycleCaseId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CycleCaseOverviewPageActionTypes.LOAD_CYCLE_CASE_OVERVIEW_PAGE_START });
  const state = getState().cycleCaseOverviewPage;
  try {
    state.cycleCaseCancelToken?.cancel();
    state.cycleCaseCancelToken = axios.CancelToken.source();
    if (cycleCaseId > 0) {
      const cycleCase = await api.get(`api/cycles/${cycleId}/cases/${cycleCaseId}`);
      dispatch({
        type: CycleCaseOverviewPageActionTypes.LOAD_CYCLE_CASE_OVERVIEW_PAGE_END,
        payload: cycleCase
      });
    }
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CycleCaseOverviewPageActionTypes.LOAD_CYCLE_CASE_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};