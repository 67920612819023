import ComptetencyCycleListActionTypes from './competency-cycle-list-action-types';
import axios from 'axios';

export const loadCompetencyCycleList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: ComptetencyCycleListActionTypes.LOAD_COMPETENCY_CYCLE_LIST_START });
  const state = getState().competencyCycleList;
  try {
    state.competencyCyclesCancelToken?.cancel();
    state.competencyCyclesCancelToken = axios.CancelToken.source();
    const competencyCycles = await api.get(`api/competencies/${payload.competencyId}/cycles`, null, state.competencyCyclesCancelToken.token);
    dispatch({
      type: ComptetencyCycleListActionTypes.LOAD_COMPETENCY_CYCLE_LIST_END,
      payload: {
        competencyCycles: competencyCycles ? competencyCycles.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: ComptetencyCycleListActionTypes.LOAD_COMPETENCY_CYCLE_LIST_END });
      throw error;
    }
  }
};

export const openCompetencyCycleConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: ComptetencyCycleListActionTypes.TOGGLE_COMPETENCY_CYCLE_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCompetencyCycleConfirmDialog = () => async(dispatch) => {
  dispatch({ type: ComptetencyCycleListActionTypes.TOGGLE_COMPETENCY_CYCLE_LIST_CONFIRM_DIALOG });
};

export const removeCompetencyCycleConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().competencyCycleList;
  if (state.selectedCompetencyCycleId > 0) {
    dispatch({ type: ComptetencyCycleListActionTypes.LOAD_COMPETENCY_CYCLE_LIST_START });
    try {
      await api.remove(`api/competencies/${payload.competencyId}/cycles/${state.selectedCompetencyCycleId}`);
      dispatch(loadCompetencyCycleList({ competencyId: payload.competencyId }));
    } catch (error) {
      dispatch({ type: ComptetencyCycleListActionTypes.LOAD_COMPETENCY_CYCLE_LIST_END });
    }
  }
  dispatch({ type: ComptetencyCycleListActionTypes.TOGGLE_COMPETENCY_CYCLE_LIST_CONFIRM_DIALOG });
};