import MySurveyFormPageActionTypes from './my-survey-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import axios from 'axios';
import ForbiddenError from '../../../utils/forbidden-error';

export const loadMySurveyFormPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: MySurveyFormPageActionTypes.LOAD_MY_SURVEY_FORM_PAGE_START });
  const state = getState().mySurveyFormPage;
  try {
    state.mySurveyFormPageCancelToken?.cancel();
    state.mySurveyFormPageCancelToken = axios.CancelToken.source();
    const mySurveyQuestions = await api.get(`api/surveys/${payload.surveyId}/questions`, state.mySurveyFormPageCancelToken.token);
    const competencySurveyStudent = await api.get(`api/competency_survey_student/${payload.competencySurveyStudentId}`, state.mySurveyFormPageCancelToken.token);
    let survey;
    const surveyAnswers = [];
    if (competencySurveyStudent.surveyAnswerStudentId) {
      survey = await api.get(`api/survey_answer_students/${competencySurveyStudent.surveyAnswerStudentId}`, null, state.mySurveyFormPageCancelToken.token);
      let lastQuestionNumber = 0;
      survey?.surveyQuestions?.map(element => element.number > lastQuestionNumber && (lastQuestionNumber = element.number));
      for (let i = 0; i <= lastQuestionNumber; i++) {
        const questionId = survey?.surveyQuestions?.find(element => element.number === i)?.id;
        const surveyAnswer = survey?.surveyAnswers?.find(element => element.surveyQuestionId === questionId);
        const parsedAnswer = parseInt(surveyAnswer?.answer);
        surveyAnswers.push(parsedAnswer !== Number.NaN ? parsedAnswer : surveyAnswer?.answer);
      }
    }
    dispatch({
      type: MySurveyFormPageActionTypes.LOAD_MY_SURVEY_FORM_PAGE_END,
      payload: {
        mySurvey: {
          ...competencySurveyStudent,
          id: competencySurveyStudent?.surveyId,
          student: competencySurveyStudent?.student,
          name: competencySurveyStudent?.surveyName,
          code: competencySurveyStudent?.surveyCode,
          evaluation: competencySurveyStudent?.surveyEvaluation,
          competencySurveyId: competencySurveyStudent?.competencySurveyId,
          mySurveyQuestions: mySurveyQuestions,
          mySurveyAnswers: surveyAnswers
        }
      }
    });
  } catch (error) {
    dispatch({ type: MySurveyFormPageActionTypes.LOAD_MY_SURVEY_FORM_PAGE_END });
    throw error;
  }
};

export const saveMySurvey = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: MySurveyFormPageActionTypes.LOAD_MY_SURVEY_FORM_PAGE_START });
  const state = getState().mySurveyFormPage;
  const mySurveyAnswers = payload.mySurveyAnswers.map((element, index) => {
    if (element != null) {
      return {
        answer: element,
        surveyQuestionId: payload.mySurveyQuestions.find(element => element.number === index).id
      };
    }
  });

  const apiModel = {
    ...payload,
    surveyId: payload.id,
    surveyAnswers: mySurveyAnswers.filter(element => element != null),
    surveyQuestions: payload.mySurveyQuestions
  };
  try {
    state.mySurveyFormPageCancelToken?.cancel();
    state.mySurveyFormPageCancelToken = axios.CancelToken.source();
    await api.post(`api/survey_answer_students`, apiModel, state.mySurveyFormPageCancelToken.token);
    dispatch({
      type: MySurveyFormPageActionTypes.LOAD_MY_SURVEY_FORM_PAGE_END,
      payload: {
        mySurvey: state.mySurvey
      }
    });
    history.replace(`/my_surveys`);
  } catch (error) {
    if (error instanceof ForbiddenError) {
      throw error;
    }
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: MySurveyFormPageActionTypes.LOAD_MY_SURVEY_FORM_PAGE_END,
      payload: {
        validationResult,
        mySurvey: state.mySurvey
      }
    });
  }
};