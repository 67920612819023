import * as React from 'react';
import styled from 'styled-components';
import { LoadingPanel, StackLayout } from '../../../../ui';
import { SCREEN_SIZES as ScreenSizes } from '../../../../resources/screenSize';
import { Button, FloatingActionButton } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import StudentCycleSkillItem from './student-cycle-skill-item';

const SkillsPageContainer = styled.div`
  margin-bottom: 76px;
`;

const AddButtonContainer = styled.div`
  margin: 16px 0;
  text-align: right;
`;

const AddButton = styled(Button)`
  margin-right: ${props => props.$isSmallerScreen ? '8px' : '0'};
`;

const Spacer = styled.div`
  height: 16px;
`;

export default class StudentCycleSkillsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      x1: null,
      x2: null
    };
    this.skillsPageContainerRef = React.createRef();
    this.adjustButtonOffset = this.adjustButtonOffset.bind(this);
  };

  async componentDidMount() {
    await this.props.onLoadStudentCycleSkills();
    window.addEventListener('resize', this.adjustButtonOffset);
    this.adjustButtonOffset();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustButtonOffset);
  }

  render() {
    const isSmallerScreen = window.innerWidth < ScreenSizes.XS;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <SkillsPageContainer ref={this.skillsPageContainerRef}>
          {this.props.loading && <LoadingPanel/>}
          {!this.props.readOnly &&
            <div>
              <FloatingActionButton
                hidden={this.props.totalNew === 0 || this.props.loading}
                rounded='large'
                icon='check'
                size='small'
                shape='rectangle'
                text={localizationService.toLanguageString('buttons.register')}
                themeColor='primary'
                alignOffset={{
                  x: this.state.x1,
                  y: 68
                }}
                align={{
                  vertical: 'top',
                  horizontal: 'end'
                }}
                onClick={this.props.onRegisterSkillsDialogOpen}
              />
              <FloatingActionButton
                hidden={this.props.totalNew === 0 || this.props.loading}
                rounded='large'
                size='small'
                shape='rectangle'
                text={localizationService.toLanguageString('buttons.cancel')}
                themeColor='light'
                alignOffset={{
                  x: this.state.x2,
                  y: 68
                }}
                align={{
                  vertical: 'top',
                  horizontal: 'end'
                }}
                onClick={this.props.onClear}
              />
            </div>
          }
          <StackLayout>
            {this.props.data?.map((skill, skillIndex) => (
              <StudentCycleSkillItem
                key={skill.id}
                name={skill.name}
                readOnly={this.props.readOnly}
                isExpanded={skill.isExpanded}
                isAdditional={false}
                history={skill.history}
                levelA={skill.levelA}
                newLevelA={skill.newLevelA}
                requiredA={skill.requiredA}
                levelB={skill.levelB}
                newLevelB={skill.newLevelB}
                requiredB={skill.requiredB}
                levelC={skill.levelC}
                newLevelC={skill.newLevelC}
                requiredC={skill.requiredC}
                onSkillExpand={() => this.props.onSkillExpand(skill.id, false)}
                onIncrease={(level) => this.props.onIncrease(skill.id, level, false)}
                onEditDialogOpen={(historySkillIndex) => this.props.onEditDialogOpen(skillIndex, historySkillIndex, false)}
                onDeleteDialogOpen={(historySkillIndex) => this.props.onDeleteDialogOpen(skillIndex, historySkillIndex, false)}
                userId={this.props.userId}
                cycleManagerOverview={this.props.cycleManagerOverview}
              />
            ))}
            {!this.props.readOnly &&
              <AddButtonContainer>
                <AddButton onClick={this.props.onOpenAdditionalSkillAddDialog} $isSmallerScreen={isSmallerScreen}>{localizationService.toLanguageString('studentCycle.addSkillFromAnotherCycle')}</AddButton>
              </AddButtonContainer>
            }
            {this.props.readOnly &&
              <Spacer/>
            }
            {this.props.additionalData?.map((skill, skillIndex) => (
              <StudentCycleSkillItem
                key={skill.id}
                name={skill.name}
                cycleName={skill.cycleName}
                readOnly={this.props.readOnly}
                isExpanded={skill.isExpanded}
                isAdditional={true}
                history={skill.history}
                levelA={skill.levelA}
                newLevelA={skill.newLevelA}
                requiredA={skill.requiredA}
                levelB={skill.levelB}
                newLevelB={skill.newLevelB}
                requiredB={skill.requiredB}
                levelC={skill.levelC}
                newLevelC={skill.newLevelC}
                requiredC={skill.requiredC}
                onSkillExpand={() => this.props.onSkillExpand(skill.id, true)}
                onIncrease={(level) => this.props.onIncrease(skill.id, level, true)}
                onEditDialogOpen={(historySkillIndex) => this.props.onEditDialogOpen(skillIndex, historySkillIndex, true)}
                onDeleteDialogOpen={(historySkillIndex) => this.props.onDeleteDialogOpen(skillIndex, historySkillIndex, true)}
                onSkillDelete={() => this.props.onCycleSkillDeleteDialogOpen(skillIndex)}
                userId={this.props.userId}
                cycleManagerOverview={this.props.cycleManagerOverview}
              />
            ))}
          </StackLayout>
        </SkillsPageContainer>
      </>
    );
  }

  adjustButtonOffset() {
    const offsetValues = this.calculateFloatingButtonOffset();
    this.setState({ ...this.state, x1: offsetValues.x1, x2: offsetValues.x2 });
  }

  calculateFloatingButtonOffset() {
    const isSmallerScreen = window.innerWidth < ScreenSizes.XS;
    const skillsPageContainerRightCorner = this.skillsPageContainerRef?.current?.getBoundingClientRect().right;
    const buttonOffsetBase = window.innerWidth - skillsPageContainerRightCorner;
    const x1 = isSmallerScreen ? 8 : buttonOffsetBase + 8;
    const x2 = x1 + 128;
    return { x1, x2 };
  }
}

registerForLocalization(StudentCycleSkillsPage);