import * as React from 'react';
import { ListPage } from '../../../ui';
import { CycleListFilter, CycleList } from './index';

class CycleListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={< CycleListFilter/>}>
        < CycleList/>
      </ListPage>
    );
  }
}

export default CycleListPage;