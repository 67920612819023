import CompetencySurveyQuestionFormPageActionTypes from './competency-survey-question-form-page-action-types';
import ValidationError from '../../../utils/validation-error';
import axios from 'axios';

export const loadCompetencySurveyQuestionFormPage = ({ competencyId, competencySurveyId, surveyQuestionId, surveyId, localizationService }) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_START });
  let surveyQuestions;
  const findGreatestSurveyQuestionNumber = (surveyQuestions) => {
    return surveyQuestions?.reduce((greatestQuestionNumber, currentQuestion) => greatestQuestionNumber = currentQuestion.number >= greatestQuestionNumber ? currentQuestion.number : greatestQuestionNumber, 0);
  };

  try {
    if (surveyQuestionId > 0) {
      let availableSubcompetencyIds;
      let subcompetencies;
      let survey;
      let competencySurvey;

      const competencySurveyQuestion = await api.get(`api/survey_questions/${surveyQuestionId}`);

      if (surveyId > 0) {
        subcompetencies = await api.get(`api/surveys/${surveyId}/subcompetencies`);
        survey = await api.get(`api/surveys/${surveyId}`);
        surveyQuestions = await api.get(`api/surveys/${surveyId}/questions`);
      } else {
        subcompetencies = await api.get(`api/competencies/${competencyId}/subcompetencies`);
        competencySurvey = await api.get(`api/competencies/${competencyId}/surveys/${competencySurveyId}`);
        surveyQuestions = await api.get(`api/surveys/${competencySurvey.surveyId}/questions`);
      }

      availableSubcompetencyIds = subcompetencies?.data ? subcompetencies.data.map(element => (element.id)) : [];

      dispatch({
        type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_END,
        payload: {
          isSubcompetencyMatching: availableSubcompetencyIds.includes(competencySurveyQuestion.subcompetencyId),
          greatestExistingSurveyQuestionNumber: findGreatestSurveyQuestionNumber(surveyQuestions),
          competencySurveyQuestion: {
            ...competencySurveyQuestion,
            surveyType: survey ? survey.type : competencySurvey.surveyType,
            surveyQuestionType: competencySurveyQuestion.type
              ? { value: competencySurveyQuestion.type, text: localizationService.toLanguageString(`surveyQuestionType.${competencySurveyQuestion.type}`) }
              : null,
            subcompetency: competencySurveyQuestion.subcompetencyId
              ? { id: competencySurveyQuestion.subcompetencyId, name: competencySurveyQuestion.subcompetencyCode + ' ' + competencySurveyQuestion.subcompetencyName }
              : null
          }
        }
      });
    } else {
      let competencySurveyQuestion;

      if (surveyId > 0) {
        competencySurveyQuestion = { surveyId: surveyId };
        surveyQuestions = await api.get(`api/surveys/${surveyId}/questions`);
      } else {
        const competencySurvey = await api.get(`api/competencies/${competencyId}/surveys/${competencySurveyId}`);
        competencySurveyQuestion = { surveyId: competencySurvey.surveyId };
        surveyQuestions = await api.get(`api/surveys/${competencySurvey.surveyId}/questions`);
      }

      const survey = await api.get(`api/surveys/${competencySurveyQuestion.surveyId}`);

      dispatch({
        type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_END,
        payload: {
          isSubcompetencyMatching: true,
          greatestExistingSurveyQuestionNumber: findGreatestSurveyQuestionNumber(surveyQuestions),
          competencySurveyQuestion: { ...competencySurveyQuestion, surveyType: survey.type }
        }
      });
    }
  } catch (error) {
    dispatch({ type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_END });
    throw error;
  }
  dispatch(loadSubcompetencies({ competencyId, surveyId }));
};

export const saveCompetencySurveyQuestion = (payload) => async(dispatch, getState, { api, history }) => {
  dispatch({ type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_START });
  const state = getState().competencySurveyQuestionFormPage;
  const apiModel = {
    ...payload,
    subcompetencyId: payload?.subcompetency?.id,
    type: payload?.surveyQuestionType?.value
  };
  let { id } = payload;
  try {
    if (id > 0) {
      await api.put(`api/survey_questions/${id}`, apiModel);
    } else {
      id = await api.post(`api/survey_questions`, apiModel);
    }
    dispatch({
      type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_END,
      payload: {
        competencySurveyQuestion: {
          ...state.competencySurveyQuestion,
          id: id
        }
      }
    });
    history.back();
  } catch (error) {
    let validationResult = null;
    if (error instanceof ValidationError) {
      validationResult = {
        errorMessage: error.message,
        errors: error.errors
      };
    }
    dispatch({
      type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_FORM_PAGE_END,
      payload: {
        validationResult,
        competencySurveyQuestion: state.competencySurveyQuestion,
        isSubcompetencyMatching: state.isSubcompetencyMatching,
        greatestExistingSurveyQuestionNumber: state.greatestExistingSurveyQuestionNumber
      }
    });
  }
};

export const loadSubcompetencies = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_AVAILABLE_SUBCOMPETENCIES_START });
  try {
    if (payload.surveyId > 0 || payload.competencyId > 0) {
      const state = getState().competencySurveyQuestionFormPage;
      state.availableSubcompetenciesCancelToken?.cancel();
      state.availableSubcompetenciesCancelToken = axios.CancelToken.source();
      const query = {
        skip: 0,
        limit: 50,
        keyword: payload?.keyword,
        extendedSearch: true
      };
      let subcompetencies;
      if (payload.surveyId > 0) {
        subcompetencies = await api.get(`api/surveys/${payload.surveyId}/subcompetencies`, query, state.availableSubcompetenciesCancelToken.token);
      } else {
        subcompetencies = await api.get(`api/competencies/${payload.competencyId}/subcompetencies`, query, state.availableSubcompetenciesCancelToken.token);
      }
      dispatch({
        type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_AVAILABLE_SUBCOMPETENCIES_END,
        payload: {
          availableSubcompetencies: subcompetencies ? subcompetencies.data.map(element => ({ ...element, name: element.code + ' ' + element.name })) : []
        }
      });
    } else {
      dispatch({ type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_AVAILABLE_SUBCOMPETENCIES_END });
    }
  } catch (error) {
    dispatch({ type: CompetencySurveyQuestionFormPageActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_AVAILABLE_SUBCOMPETENCIES_END });
    throw error;
  }
};

export const setSubcompetencyMatchFlag = (payload) => (dispatch) => {
  dispatch({
    type: CompetencySurveyQuestionFormPageActionTypes.SET_COMPETENCY_SURVEY_QUESTION_SUBCOMPETENCY_MATCH_FLAG,
    payload: payload.value
  });
};

export const clearCompetencySurveyQuestionFormPage = () => async(dispatch) => {
  dispatch({ type: CompetencySurveyQuestionFormPageActionTypes.CLEAR_COMPETENCY_SURVEY_QUESTION_FORM_PAGE });
};