
import React, { PureComponent } from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Field } from '@progress/kendo-react-form';
import { TextBox, DropDown, FilterForm, WrapLayout, SelectListPage } from '../../../ui';
import { Grid } from '../../shared/components';
import {
  loadStudyPlanCycleSelectPage,
  findStudyPlanCycleSelectPage,
  selectStudyPlanCycleSelectPage,
  selectAllStudyPlanCycleSelectPage,
  addSelectedStudyPlanCycleSelectPage,
  filterStudyPlanCycleSelectPage,
  clearStudyPlanCycleSelectPage,
  cleanupStudyPlanCycleSelectPage
} from '../actions';
import { GridToolbar } from '@progress/kendo-react-grid';
import { CYCLE_TYPES } from '../../../resources/cycleType';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const CycleNameContainer = styled.span`
  font-weight: bolder;
`;

class StudyPlanCycleSelectPage extends PureComponent {

  constructor() {
    super();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleAddSelectedButtonClick = this.handleAddSelectedButtonClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderType = this.renderType.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyPlanId) {
      this.props.loadStudyPlanCycleSelectPage({ studyPlanId: this.props.params.studyPlanId });
    }
  }

  componentWillUnmount() {
    this.props.cleanupStudyPlanCycleSelectPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <SelectListPage
          id='study-plan-cycle-select'
          errorMessage={this.props.errorMessage}
          successMessage={this.props.successMessage}
          breadcrumbItems={[
            {
              id: 'study-plans',
              text: localizationService.toLanguageString('StudyPlanCycle.studyPlans')
            },
            {
              id: 'study-plan',
              text: localizationService.toLanguageString('StudyPlanCycle.studyPlan')
            },
            {
              id: 'study-plan-cycle-select',
              text: localizationService.toLanguageString('StudyPlanCycle.cycleAssignation'),
              disabled: true
            }
          ]}
          onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
          onBackClick={this.handleCancel}
          filter={
            <FilterForm
              initialValues={this.props.filter}
              onSubmit={this.props.filterStudyPlanCycleSelectPage}
              onClear={this.props.clearStudyPlanCycleSelectPage}
              localizationService={localizationService}
              title={localizationService.toLanguageString('grid.filterTitle')}
              uniqueKey={JSON.stringify(this.props.filter)}
              render={() => (
                <WrapLayout>
                  <Field
                    name='keyword'
                    width='320px'
                    component={TextBox}
                    label={localizationService.toLanguageString('custom.search')}
                  />
                  <Field
                    name='cycleType'
                    label={localizationService.toLanguageString('StudyPlanCycle.type')}
                    component={DropDown}
                    data={[
                      {
                        value: CYCLE_TYPES.OPTIONAL,
                        text: localizationService.toLanguageString('cycleType.Optional')
                      }, {
                        value: CYCLE_TYPES.REQUIRED,
                        text: localizationService.toLanguageString('cycleType.Required')
                      }
                    ]}
                    textField='text'
                    valueField='value'
                    width='320px'
                  />
                </WrapLayout>
              )}
            />
          }
        >
          <Grid
            loading={this.props.loading}
            data={this.props.availableCycles}
            skip={this.props.skip}
            take={this.props.take}
            pageSize={this.props.take}
            total={this.props.total}
            onPageChange={this.handlePageChange}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
              mode: 'multiple'
            }}
            dataItemKey='id'
            selectedField='isSelected'
            onSelectionChange={this.handleSelect}
            onHeaderSelectionChange={this.handleSelectAll}
            columns={[
              {
                field: 'isSelected',
                width: '50px',
                headerSelectionValue: this.props.selectedAll,
                cell: this.renderSelected
              }, {
                field: 'name',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('StudyPlanCycle.name'),
                cell: ({ dataItem, className }) =>
                  <td className={className}>
                    {this.props.studyProgramId === dataItem.studyProgramId
                      ? `${dataItem.code} ${dataItem.name}`
                      : <CycleNameContainer>{`${dataItem.code} ${dataItem.name} (${dataItem.studyProgramName})`}</CycleNameContainer>
                    }
                  </td>
              }, {
                field: 'type',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('StudyPlanCycle.type'),
                cell: this.renderType
              }, {
                field: 'credits',
                className: 'align-right',
                headerClassName: 'align-right',
                title: localizationService.toLanguageString('StudyPlanCycle.credits')
              }
            ]}
          >
            <GridToolbar>
              <Button
                type='button'
                themeColor='primary'
                onClick={this.handleAddSelectedButtonClick}
              >
                {localizationService.toLanguageString('StudyPlanCycle.addSelectedCycles')}
              </Button>
            </GridToolbar>
          </Grid>
        </SelectListPage>
        {this.props.savingSelectedCycles && this.renderLoader(localizationService)}
      </>
    );
  }

  renderSelected(e) {
    return (
      <td role='gridcell'>
        <Checkbox disabled={e.dataItem.isDisabled} checked={e.dataItem.isSelected} onChange={() => e.selectionChange(e)} />
      </td>
    );
  }

  renderType(e) {
    const localizationService = provideLocalizationService(this);
    let localizedTypeValue;
    switch (e.dataItem?.type) {
      case CYCLE_TYPES.OPTIONAL:
        localizedTypeValue = localizationService.toLanguageString('cycleType.Optional');
        break;
      case CYCLE_TYPES.REQUIRED:
        localizedTypeValue = localizationService.toLanguageString('cycleType.Required');
        break;
    }
    return <td>{localizedTypeValue}</td>;
  }

  async handlePageChange(e) {
    await this.props.findStudyPlanCycleSelectPage({ skip: e.page.skip, take: e.page.take });
  }

  handleSelect(e) {
    if (e.dataItem) {
      this.props.selectStudyPlanCycleSelectPage(e.dataItem);
    }
  }

  handleSelectAll() {
    this.props.selectAllStudyPlanCycleSelectPage();
  }

  handleAddSelectedButtonClick() {
    const localizationService = provideLocalizationService(this);
    this.props.addSelectedStudyPlanCycleSelectPage({ localizationService });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-plans') {
      this.props.navigate('/study_plans');
    } else if (e.id === 'study-plan') {
      this.props.navigate(`/study_plans/${this.props.params.studyPlanId}/overview`);
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  renderLoader(localizationService) {
    const loadingPanel = (
      <div className='k-loading-mask'>
        <span className='k-loading-text'>{localizationService.toLanguageString('custom.loading')}</span>
        <div className='k-loading-image' />
        <div className='k-loading-color' />
      </div>
    );
    const gridContent = document && document.querySelector('#study-plan-cycle-select');
    return gridContent
      ? ReactDOM.createPortal(loadingPanel, gridContent)
      : loadingPanel;
  }
}

registerForLocalization(StudyPlanCycleSelectPage);

const mapStateToProps = state => ({
  loading: state.studyPlanCycleSelectPage.loading,
  availableCycles: state.studyPlanCycleSelectPage.availableCycles,
  skip: state.studyPlanCycleSelectPage.skip,
  take: state.studyPlanCycleSelectPage.take,
  total: state.studyPlanCycleSelectPage.total,
  selectedAll: state.studyPlanCycleSelectPage.selectedAll,
  selectedIds: state.studyPlanCycleSelectPage.selectedIds,
  savingSelectedCycles: state.studyPlanCycleSelectPage.savingSelectedCycles,
  errorMessage: state.studyPlanCycleSelectPage.errorMessage,
  successMessage: state.studyPlanCycleSelectPage.successMessage,
  filter: state.studyPlanCycleSelectPage.filter,
  studyProgramId: state.studyPlanCycleSelectPage.studyProgramId
});

const mapDispatchToProps = dispatch => ({
  loadStudyPlanCycleSelectPage: (payload) => dispatch(loadStudyPlanCycleSelectPage(payload)),
  findStudyPlanCycleSelectPage: (payload) => dispatch(findStudyPlanCycleSelectPage(payload)),
  selectStudyPlanCycleSelectPage: (payload) => dispatch(selectStudyPlanCycleSelectPage(payload)),
  selectAllStudyPlanCycleSelectPage: (payload) => dispatch(selectAllStudyPlanCycleSelectPage(payload)),
  addSelectedStudyPlanCycleSelectPage: (payload) => dispatch(addSelectedStudyPlanCycleSelectPage(payload)),
  filterStudyPlanCycleSelectPage: (payload) => dispatch(filterStudyPlanCycleSelectPage(payload)),
  clearStudyPlanCycleSelectPage: () => dispatch(clearStudyPlanCycleSelectPage()),
  cleanupStudyPlanCycleSelectPage: () => dispatch(cleanupStudyPlanCycleSelectPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleSelectPage));