import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import { FormPage, TextBox, DatePicker, WrapLayout, CheckBox } from '../../../ui';
import { loadDepartmentFormPage, saveDepartment, clearDepartmentFormPage } from '../actions/department-form-page-actions';
import { withRouter } from '../../../components/withRouter';
import { validateEmail } from '../../../utils/emailValidationHelper';

class DepartmentFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.departmentId) {
      await this.props.loadDepartmentFormPage({
        departmentId: this.props.params.departmentId
      });
    }
  }

  componentWillUnmount() {
    this.props.clearDepartmentFormPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('department.formTitle')}
        breadcrumbItems={[
          {
            id: 'departments',
            text: localizationService.toLanguageString('department.departments')
          },
          {
            id: 'department',
            text: localizationService.toLanguageString('department.department'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.department}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={() => (
          <>
            <Field
              name='name'
              label={localizationService.toLanguageString('department.name')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='code'
              label={localizationService.toLanguageString('department.code')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='address'
              label={localizationService.toLanguageString('department.address')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='managerName'
              label={localizationService.toLanguageString('department.manager')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='managerPhone'
              label={localizationService.toLanguageString('department.phone')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='managerEmail'
              label={localizationService.toLanguageString('department.email')}
              component={TextBox}
              width='320px'
            />
            <WrapLayout width='320px'>
              <Field
                name='accreditationFrom'
                label={localizationService.toLanguageString('department.accreditedFrom')}
                component={DatePicker}
                width='155px'
              />
              <Field
                name='accreditationTo'
                label={localizationService.toLanguageString('department.accreditedTo')}
                component={DatePicker}
                width='155px'
              />
              <Field
                name='isForeign'
                label={localizationService.toLanguageString('department.isForeign')}
                component={CheckBox}
                disabled
                width='155px'
              />
            </WrapLayout>
          </>
        )}
      />
    );
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.name) {
      errors.name = localizationService.toLanguageString('validation.required');
    }
    if (!values.code) {
      errors.code = localizationService.toLanguageString('validation.required');
    }
    if (values.code && !values.code.match(/^[A-Za-z0-9]*$/)) {
      errors.code = localizationService.toLanguageString('validation.wrongFormat');
    }
    if (!values.accreditationFrom) {
      errors.accreditationFrom = localizationService.toLanguageString('validation.required');
    }
    if (values.accreditationFrom && values.accreditationTo && values.accreditationFrom > values.accreditationTo) {
      errors.accreditationTo = localizationService.toLanguageString('validation.impossibleSequence');
      errors.accreditationFrom = localizationService.toLanguageString('validation.impossibleSequence');
    }
    if (values.managerEmail) {
      if (!validateEmail(values.managerEmail)) {
        errors.managerEmail = localizationService.toLanguageString('validation.invalidEmailFormat');
      }
    }
    if (!modified.name
      && !modified.code
      && !modified.accreditationFrom
      && !modified.accreditationTo) {
      if (validationResult?.errors?.name) {
        errors.name = validationResult?.errors?.name;
      }
      if (validationResult?.errors?.code) {
        errors.code = validationResult?.errors?.code;
      }
      if (validationResult?.errors?.accreditationFrom) {
        errors.accreditationFrom = validationResult?.errors?.accreditationFrom;
      }
      if (validationResult?.errors?.accreditationTo) {
        errors.accreditationTo = validationResult?.errors?.accreditationTo;
      }
    }
    return errors;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'departments') {
      this.props.navigate('/departments');
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveDepartment(payload);
  }
}

registerForLocalization(DepartmentFormPage);

const mapStateToProps = state => ({
  loading: state.departmentFormPage.loading,
  department: state.departmentFormPage.department,
  validationResult: state.departmentFormPage.validationResult
});

const mapDispatchToProps = dispatch => ({
  loadDepartmentFormPage: (payload) => dispatch(loadDepartmentFormPage(payload)),
  saveDepartment: (payload) => dispatch(saveDepartment(payload)),
  clearDepartmentFormPage: () => dispatch(clearDepartmentFormPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DepartmentFormPage));
