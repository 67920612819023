import { STUDENT_STATUSES } from '../resources/studentStatus';

export const getStudentStatusColor = (status) => {
  switch (status) {
    case STUDENT_STATUSES.STUDYING:
      return 'primary';
    case STUDENT_STATUSES.TEMPORARILY_NOT_STUDYING:
      return 'warning';
    case STUDENT_STATUSES.REMOVED_FROM_LIST:
      return 'error';
    case STUDENT_STATUSES.GRADUATED:
      return 'success';
    default:
      return 'inverse';
  }
};