import * as React from 'react';
import { ListPage } from '../../../ui';
import { CompetencyListFilter, CompetencyList } from './index';

class CompetencyListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<CompetencyListFilter/>}>
        <CompetencyList/>
      </ListPage>
    );
  }
}

export default CompetencyListPage;