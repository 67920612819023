import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field, FieldArray } from '@progress/kendo-react-form';
import { DropDown, FormPage, NumericBox, TextBox, TextArea, StackLayout, WrapLayout, Text, ComboBox } from '../../../ui';
import { loadCompetencySurveyFormPage, saveCompetencySurvey, loadAvailableCompetencies, loadAvailableStudyPrograms, selectStudyProgram } from '../actions/competency-survey-form-page-actions';
import { SURVEY_TYPES } from '../../../resources/surveyType';
import { withRouter } from '../../../components/withRouter';
import { Button } from '@progress/kendo-react-buttons';

const SURVEY_COMPETENCIES_FIELD_ARRAY_NAME = 'competencies';

const SurveyCompetencyContainer = styled(WrapLayout).attrs(props => ({
  orientation: 'vertical',
  rowGap: '0px',
  width: '35px'
}))`
  margin-top: 2px;
`;

const SurveyCompetencyTitle = styled(Text).attrs(props => ({
  variant: 'h6',
  textColor: props.theme.gray80,
  truncate: true
}))`
  margin-top: 20px;
`;

const DeleteButton = styled(Button).attrs(props => ({
  type: 'button',
  icon: 'delete'
}))`
  margin-top: 20px;
`;

class CompetencySurveyFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.renderSurveyCompetencies = this.renderSurveyCompetencies.bind(this);
    this.handleStudyProgramChange = this.handleStudyProgramChange.bind(this);
  }

  async componentDidMount() {
    if (this.props.params) {
      const localizationService = provideLocalizationService(this);
      await this.props.loadCompetencySurveyFormPage({
        competencyId: this.props.params.competencyId,
        surveyId: this.props.params.surveyId,
        localizationService
      });
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('competency.survey')}
        breadcrumbItems={this.getBreadcrumbs()}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        renderBackButton={true}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.competencySurvey}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={(formRenderProps) => (
          <>
            <Field
              name='surveyType'
              label={localizationService.toLanguageString('competency.type')}
              component={DropDown}
              data={[
                {
                  value: SURVEY_TYPES.CLINICAL_CASE_SURVEY,
                  text: localizationService.toLanguageString('surveyType.ClinicalCaseSurvey')
                }, {
                  value: SURVEY_TYPES.TECHNICAL_SKILL_SURVEY,
                  text: localizationService.toLanguageString('surveyType.TechnicalSkillSurvey')
                }, {
                  value: SURVEY_TYPES.SURVEY_360,
                  text: localizationService.toLanguageString('surveyType.Survey360')
                }
              ]}
              textField='text'
              valueField='value'
              width='320px'
            />
            <Field
              name='surveyName'
              label={localizationService.toLanguageString('competency.name')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='surveyCode'
              label={localizationService.toLanguageString('competency.code')}
              component={TextBox}
              width='320px'
            />
            <Field
              name='description'
              label={localizationService.toLanguageString('competency.description')}
              component={TextArea}
              autoSize={true}
              width='320px'
            />
            <Field
              name='studyProgram'
              label={localizationService.toLanguageString('competency.studyProgram')}
              component={ComboBox}
              data={this.props.availableStudyPrograms}
              onFilter={this.props.loadAvailableStudyPrograms}
              onChange={(e) => this.handleStudyProgramChange(e.value, formRenderProps)}
              textField='name'
              valueField='id'
              width='320px'
              disabled={Boolean(this.props.params?.competencyId)}
            />
            <FieldArray
              name={SURVEY_COMPETENCIES_FIELD_ARRAY_NAME}
              component={this.renderSurveyCompetencies}
            />
          </>
        )}
      />
    );
  }

  renderSurveyCompetencies(fieldArrayRenderProps) {
    const localizationService = provideLocalizationService(this);
    return (
      <SurveyCompetencyContainer>
        <SurveyCompetencyTitle>{localizationService.toLanguageString('survey.tieredCompetencies')}</SurveyCompetencyTitle>
        {fieldArrayRenderProps.value?.map((item, index) => {
          const fieldName = SURVEY_COMPETENCIES_FIELD_ARRAY_NAME + '[' + index + ']';
          const competency = this.props.competencies[index];
          return (
            <div key={index}>
              <Field
                name={fieldName + '.competency'}
                label={localizationService.toLanguageString('survey.competency')}
                component={ComboBox}
                loading={competency?.loading}
                data={competency?.availableCompetencies}
                onFilter={(e) => this.handleLoadAvailableCompetencies(e, index)}
                textField='name'
                valueField='id'
                width='320px'
                disabled={!this.props.studyProgramId}
                margin={'8px 0px 0px 0px'}
              />
              <StackLayout orientation='horizontal' align='left' valign='top' >
                <Field
                  name={fieldName + '.minCount'}
                  label={localizationService.toLanguageString('survey.minCount')}
                  component={NumericBox}
                  min={0}
                  width='273px'
                  margin={0}
                />
                <DeleteButton onClick={() => this.handleRemoveSurveyCompetency(index, fieldArrayRenderProps)}/>
              </StackLayout>
            </div>
          );
        })}
        <WrapLayout orientation='horizontal' align='left' margin={'8px 0px 0px 0px'}>
          <Button
            type='button'
            icon='plus'
            onClick={() => this.handleAddSurveyCompetency(fieldArrayRenderProps)}
          />
        </WrapLayout>
      </SurveyCompetencyContainer>
    );
  }

  async handleStudyProgramChange(studyProgram, formRenderProps) {
    await this.props.selectStudyProgram({ studyProgram: studyProgram });
    const competencies = formRenderProps.valueGetter(SURVEY_COMPETENCIES_FIELD_ARRAY_NAME);
    competencies?.forEach((element, index) => {
      const fieldName = SURVEY_COMPETENCIES_FIELD_ARRAY_NAME + '[' + index + '].competency';
      formRenderProps.onChange(fieldName, { value: null });
      this.props.loadAvailableCompetencies({ index: index });
    });
  }

  handleLoadAvailableCompetencies(e, index) {
    this.props.loadAvailableCompetencies({
      keyword: e.keyword,
      index: index
    });
  }

  handleAddSurveyCompetency(fieldArrayRenderProps) {
    fieldArrayRenderProps.onPush({ value: {} });
    this.props.loadAvailableCompetencies({
      index: fieldArrayRenderProps?.value?.length ?? 0
    });
  }

  handleRemoveSurveyCompetency(index, fieldArrayRenderProps) {
    fieldArrayRenderProps.onRemove({
      index: index
    });
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.surveyName) {
      errors.surveyName = localizationService.toLanguageString('validation.required');
    }
    if (!values.surveyType) {
      errors.surveyType = localizationService.toLanguageString('validation.required');
    }
    if (!values.surveyCode) {
      errors.surveyCode = localizationService.toLanguageString('validation.required');
    }
    if (values.competencies) {
      for (let i = 0; i < values.competencies.length; i++) {
        if (!values.competencies[i]?.minCount) {
          errors[`${SURVEY_COMPETENCIES_FIELD_ARRAY_NAME}[${i}].minCount`] = localizationService.toLanguageString('validation.required');
        }
        if (!values.competencies[i]?.competency) {
          errors[`${SURVEY_COMPETENCIES_FIELD_ARRAY_NAME}[${i}].competency`] = localizationService.toLanguageString('validation.required');
        } else {
          const competencyDuplicate = values.competencies.find((element, index) => index !== i && element.competency?.id == values.competencies[i].competency.id);
          if (competencyDuplicate) {
            errors[`${SURVEY_COMPETENCIES_FIELD_ARRAY_NAME}[${i}].competency`] = localizationService.toLanguageString('competency.duplicateCompetenciesSelected');
          }
        }
      }
    }
    if (!modified.surveyName && !modified.surveyType && !modified.studyProgram && !modified.surveyCode) {
      if (validationResult?.errors?.name) {
        errors.surveyName = validationResult?.errors?.name;
      }
      if (validationResult?.errors?.type) {
        errors.surveyType = validationResult?.errors?.type;
      }
      if (validationResult?.errors?.studyProgramId) {
        errors.studyProgram = validationResult?.errors?.studyProgramId;
      }
      if (validationResult?.errors?.surveyCode) {
        errors.surveyCode = validationResult?.errors?.surveyCode;
      }
    }
    return errors;
  }

  getBreadcrumbs() {
    const localizationService = provideLocalizationService(this);
    const breadcrumbs = [];
    if (this.props.params.competencyId > 0) {
      breadcrumbs.push(
        {
          id: 'competencies',
          text: localizationService.toLanguageString('competency.competencies')
        },
        {
          id: 'competency',
          text: localizationService.toLanguageString('competency.competency')
        }
      );
    } else {
      breadcrumbs.push(
        {
          id: 'surveys',
          text: localizationService.toLanguageString('competency.surveys')
        }
      );
    }

    breadcrumbs.push(
      {
        id: 'survey',
        text: localizationService.toLanguageString('competency.survey'),
        disabled: true
      }
    );

    return breadcrumbs;
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'competencies') {
      this.props.navigate('/competencies');
    }
    if (e.id === 'competency') {
      this.props.navigate(`/competencies/${ this.props.params.competencyId }/overview`);
    }
    if (e.id === 'surveys') {
      this.props.navigate('/surveys');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    const { competencyId } = this.props.params;
    this.props.saveCompetencySurvey({
      ...payload,
      competencyId,
      afterSubmit: (competencySurveyId) => this.props.navigate(competencyId > 0 && competencySurveyId > 0 ? `/competencies/${competencyId}/surveys/${competencySurveyId}/overview` : -1)
    });
  }
}

registerForLocalization(CompetencySurveyFormPage);

const mapStateToProps = state => ({
  loading: state.competencySurveyFormPage.loading,
  competencySurvey: state.competencySurveyFormPage.competencySurvey,
  validationResult: state.competencySurveyFormPage.validationResult,
  competencies: state.competencySurveyFormPage.competencies,
  availableStudyPrograms: state.competencySurveyFormPage.availableStudyPrograms,
  availableStudyProgramsLoading: state.competencySurveyFormPage.availableStudyProgramsLoading,
  studyProgramId: state.competencySurveyFormPage.studyProgramId
});

const mapDispatchToProps = dispatch => ({
  loadCompetencySurveyFormPage: (payload) => dispatch(loadCompetencySurveyFormPage(payload)),
  saveCompetencySurvey: (payload) => dispatch(saveCompetencySurvey(payload)),
  loadAvailableCompetencies: (payload) => dispatch(loadAvailableCompetencies(payload)),
  loadAvailableStudyPrograms: (payload) => dispatch(loadAvailableStudyPrograms(payload)),
  selectStudyProgram: (payload) => dispatch(selectStudyProgram(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompetencySurveyFormPage));