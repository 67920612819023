import SubcompetencyFormPageActionTypes from '../actions/subcompetency-form-page-action-types';

const initialState = {
  loading: false,
  subcompetency: null,
  validationResult: null,

  subcompetencyGroups: [],
  subcompetencyGroupsLoading: false,
  subcompetencyGroupsCancelToken: null,

  studyPrograms: [],
  studyProgramsLoading: false,
  studyProgramsCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        subcompetency: action.payload ? action.payload.subcompetency : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_GROUPS_START:
      return {
        ...state,
        subcompetencyGroupsLoading: true
      };
    case SubcompetencyFormPageActionTypes.LOAD_SUBCOMPETENCY_GROUPS_END:
      return {
        ...state,
        subcompetencyGroupsLoading: false,
        subcompetencyGroups: action.payload ? action.payload.subcompetencyGroups : []
      };
    case SubcompetencyFormPageActionTypes.LOAD_STUDY_PROGRAMS_START:
      return {
        ...state,
        studyProgramsLoading: true
      };
    case SubcompetencyFormPageActionTypes.LOAD_STUDY_PROGRAMS_END:
      return {
        ...state,
        studyProgramsLoading: false,
        studyPrograms: action.payload ? action.payload.studyPrograms : []
      };
    default:
      return state;
  }
};