import ComptetencySurveyQuestionListActionTypes from './competency-survey-question-list-action-types';
import axios from 'axios';

export const loadCompetencySurveyQuestionList = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: ComptetencySurveyQuestionListActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_LIST_START });
  const state = getState().competencySurveyQuestionList;
  try {
    state.competencySurveyQuestionsCancelToken?.cancel();
    state.competencySurveyQuestionsCancelToken = axios.CancelToken.source();
    let competencySurveyQuestions;
    let subcompetencies;
    let availableSubcompetencyIds;
    if (payload.surveyId > 0) {
      competencySurveyQuestions = await api.get(`api/surveys/${payload.surveyId}/questions`, null, state.competencySurveyQuestionsCancelToken.token);
      subcompetencies = await api.get(`api/surveys/${payload.surveyId}/subcompetencies`, null, state.competencySurveyQuestionsCancelToken.token);
    } else {
      competencySurveyQuestions = await api.get(`api/competencies/${payload.competencyId}/surveys/${payload.competencySurveyId}/questions`, null, state.competencySurveyQuestionsCancelToken.token);
      subcompetencies = await api.get(`api/competencies/${payload.competencyId}/subcompetencies`);
    }
    availableSubcompetencyIds = subcompetencies?.data ? subcompetencies.data.map(element => (element.id)) : [];
    competencySurveyQuestions = competencySurveyQuestions?.map(element => ({ ...element, isSubcompetencyMatching: !element.subcompetencyId || availableSubcompetencyIds.includes(element.subcompetencyId) }));
    dispatch({
      type: ComptetencySurveyQuestionListActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_LIST_END,
      payload: {
        competencySurveyQuestions: competencySurveyQuestions != null
          ? competencySurveyQuestions
          : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: ComptetencySurveyQuestionListActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_LIST_END });
      throw error;
    }
  }
};

export const openCompetencySurveyQuestionConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: ComptetencySurveyQuestionListActionTypes.TOGGLE_COMPETENCY_SURVEY_QUESTION_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeCompetencySurveyQuestionConfirmDialog = () => async(dispatch) => {
  dispatch({ type: ComptetencySurveyQuestionListActionTypes.TOGGLE_COMPETENCY_SURVEY_QUESTION_LIST_CONFIRM_DIALOG });
};

export const removeCompetencySurveyQuestionConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().competencySurveyQuestionList;
  if (state.selectedCompetencySurveyQuestionId > 0) {
    dispatch({ type: ComptetencySurveyQuestionListActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_LIST_START });
    try {
      await api.remove(`api/survey_questions/${state.selectedCompetencySurveyQuestionId}`);
      dispatch(loadCompetencySurveyQuestionList({ competencyId: payload.competencyId, competencySurveyId: payload.competencySurveyId, surveyId: payload.surveyId }));
    } catch (error) {
      dispatch({ type: ComptetencySurveyQuestionListActionTypes.LOAD_COMPETENCY_SURVEY_QUESTION_LIST_END });
    }
  }
  dispatch({ type: ComptetencySurveyQuestionListActionTypes.TOGGLE_COMPETENCY_SURVEY_QUESTION_LIST_CONFIRM_DIALOG });
};

export const clearCompetencySurveyQuestionList = () => async(dispatch) => {
  dispatch({ type: ComptetencySurveyQuestionListActionTypes.CLEAR_COMPETENCY_SURVEY_QUESTION_LIST });
};