import Colors from './colors';

const theme = {

  transparent: 'transparent',

  primary00: Colors.COLOR_00,
  primary10: Colors.COLOR_10,
  primary20: Colors.COLOR_20,
  primary30: Colors.COLOR_30,
  primary40: Colors.COLOR_40,
  primary50: Colors.COLOR_50,
  primary70: Colors.COLOR_70,

  neutral00: Colors.COLOR_NEUTRAL_00,
  neutral10: Colors.COLOR_NEUTRAL_10,
  neutral20: Colors.COLOR_NEUTRAL_20,
  neutral30: Colors.COLOR_NEUTRAL_30,
  neutral40: Colors.COLOR_NEUTRAL_40,
  neutral50: Colors.COLOR_NEUTRAL_50,
  neutral70: Colors.COLOR_NEUTRAL_70,

  gray10: Colors.GRAY_10,
  gray15: Colors.GRAY_15,
  gray20: Colors.GRAY_20,
  gray30: Colors.GRAY_30,
  gray40: Colors.GRAY_40,
  gray50: Colors.GRAY_50,
  gray60: Colors.GRAY_60,
  gray70: Colors.GRAY_70,
  gray75: Colors.GRAY_75,
  gray80: Colors.GRAY_80,

  white: Colors.WHITE,
  black: Colors.BLACK,

  error10: Colors.ERROR_10,
  error20: Colors.ERROR_20,
  error30: Colors.ERROR_30,
  error40: Colors.ERROR_40,
  error50: Colors.ERROR_50,
  error70: Colors.ERROR_70,

  warning10: Colors.WARNING_10,
  warning20: Colors.WARNING_20,
  warning30: Colors.WARNING_30,
  warning40: Colors.WARNING_40,
  warning50: Colors.WARNING_50,
  warning70: Colors.WARNING_70,

  info10: Colors.INFO_10,
  info20: Colors.INFO_20,
  info30: Colors.INFO_30,
  info40: Colors.INFO_40,
  info50: Colors.INFO_50,
  info70: Colors.INFO_70,

  success10: Colors.SUCCESS_10,
  success20: Colors.SUCCESS_20,
  success30: Colors.SUCCESS_30,
  success40: Colors.SUCCESS_40,
  success50: Colors.SUCCESS_50,
  success70: Colors.SUCCESS_70
};

export default theme;