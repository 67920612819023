import DepartmentFormPageActionTypes from '../actions/department-form-page-action-types';

const initialState = {
  loading: false,
  department: null,
  saveSuccess: false,
  validationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case DepartmentFormPageActionTypes.LOAD_DEPARTMENT_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        department: action.payload ? action.payload.department : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case DepartmentFormPageActionTypes.CLEAR_DEPARTMENT_FORM_PAGE:
      return initialState;
    default:
      return state;
  }
};