import StudyPlanCycleOverviewPageActionTypes from '../actions/study-plan-cycle-overview-page-action-types';

const initialState = {
  loading: false,
  studyPlanCycle: null,
  studyPlanCycleCancelToken: null,
  selectedTab: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        studyPlanCycle: action.payload
      };
    case StudyPlanCycleOverviewPageActionTypes.SELECT_STUDY_PLAN_CYCLE_OVERVIEW_PAGE_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selectedTab
      };
    default:
      return state;
  }
};