import * as React from 'react';
import { FilterForm, StackLayout, TextBox, WrapLayout } from '../../../ui';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { clearNotificationsFilter, filterNotifications } from '../actions';

class NotificationsListFilter extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { filter, filterNotifications, clearNotificationsFilter } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterNotifications}
        onClear={clearNotificationsFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  filter: state.notificationsListPage.filter
});

const mapDispatchToProps = dispatch => ({
  filterNotifications: (payload) => dispatch(filterNotifications(payload)),
  clearNotificationsFilter: () => dispatch(clearNotificationsFilter())
});

registerForLocalization(NotificationsListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsListFilter);