import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout } from '../../../ui';
import { ValueItem } from '../../shared/components';
import { loadCycleCaseOverviewPage } from '../actions';
import { withRouter } from '../../../components/withRouter';
import { CycleCaseDiagnosisList } from '.';

class CycleCaseOverviewPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.cycleId && this.props.params?.cycleCaseId) {
      await this.props.loadCycleCaseOverviewPage({
        cycleId: this.props.params.cycleId,
        cycleCaseId: this.props.params.cycleCaseId
      });
    }
  }

  render() {
    const { cycleCase, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${cycleCase?.studyProgramCaseName}`
        }
        headerActions={
          <Button
            id='edit-button'
            type='button'
            themeColor='primary'
            onClick={this.handleEditClick}
          >
            {localizationService.toLanguageString('buttons.edit')}
          </Button>
        }
        breadcrumbItems={[
          {
            id: 'cycles',
            text: localizationService.toLanguageString('cycle.cycles')
          },
          {
            id: 'cycle',
            text: localizationService.toLanguageString('cycle.cycle')
          },
          {
            id: 'cycle-case',
            text: localizationService.toLanguageString('cycle.cycleCase'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('cycle.minCaseRequiredNumber')}
              labelWidth='108px'
              loading={loading}
            >
              {cycleCase?.minRequiredNumber}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={0}>
            <TabStripTab title={localizationService.toLanguageString('cycle.caseDiagnoses')} />
          </TabStrip>
        }
      >
        <CycleCaseDiagnosisList cycleId={this.props.params.cycleId} cycleCaseId={this.props.params.cycleCaseId}/>
      </OverviewPage>
    );
  }

  handleEditClick() {
    this.props.navigate(`/cycles/${this.props.params.cycleId}/cases/${this.props.params.cycleCaseId}`);
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'cycles') {
      this.props.navigate('/cycles');
    }
    if (e.id === 'cycle') {
      this.props.navigate(`/cycles/${this.props.params.cycleId}/overview`);
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(CycleCaseOverviewPage);

const mapStateToProps = state => ({
  cycleCase: state.cycleCaseOverviewPage.cycleCase,
  loading: state.cycleCaseOverviewPage.loading
});

const mapDispatchToProps = dispatch => ({
  loadCycleCaseOverviewPage: (payload) => dispatch(loadCycleCaseOverviewPage(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleCaseOverviewPage));