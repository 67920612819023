import axios from 'axios';
import StudyPlanCycleListActionTypes from './study-plan-cycle-list-action-types';

export const loadStudyPlanCycleList = (studyPlanId) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleListActionTypes.LOAD_STUDY_PLAN_CYCLE_LIST_START });
  const state = getState().studyPlanCycleList;
  try {
    state.studyPlanCyclesCancelToken?.cancel();
    state.studyPlanCyclesCancelToken = axios.CancelToken.source();
    const studyPlanCycles = await api.get(`api/study_plans/${studyPlanId}/cycles`, null, state.studyPlanCyclesCancelToken.token);
    dispatch({
      type: StudyPlanCycleListActionTypes.LOAD_STUDY_PLAN_CYCLE_LIST_END,
      payload: studyPlanCycles?.data
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleListActionTypes.LOAD_STUDY_PLAN_CYCLE_LIST_END });
      throw error;
    }
  }
};

export const openStudyPlanCycleConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyPlanCycleListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeStudyPlanCycleConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_LIST_CONFIRM_DIALOG });
};

export const removeStudyPlanCycleConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleList;
  if (state.selectedStudyPlanCycleId > 0) {
    await api.remove(`api/study_plans/${payload.studyPlanId}/cycles/${state.selectedStudyPlanCycleId}`);
    dispatch(loadStudyPlanCycleList(payload.studyPlanId));
  }
  dispatch({ type: StudyPlanCycleListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_LIST_CONFIRM_DIALOG });
};