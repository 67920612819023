import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid, GridSize } from '../../shared/components';
import {
  loadCompetencySurveyQuestionList,
  openCompetencySurveyQuestionConfirmDialog,
  closeCompetencySurveyQuestionConfirmDialog,
  removeCompetencySurveyQuestionConfirmDialog,
  clearCompetencySurveyQuestionList
} from '../actions/competency-survey-question-list-actions';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Text, Colors } from '../../../ui';
import { withRouter } from '../../../components/withRouter';
import styled, { css } from 'styled-components';
import { Tooltip } from '@progress/kendo-react-tooltip';

const TableCell = styled.td`
  ${props => props.isCellInvalid && css`
    color: ${Colors.ERROR_40};
  `}
`;

const ActionsCell = styled.td`
  overflow: visible !important;
`;

const CellDataContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextContainer = styled.div`
  ${props => props.underlined && css`
    text-decoration: underline;
  `}
`;

const WarningIcon = styled.span`
  margin-left: 4px;
`;

const TooltipContainer = styled.div`
  width: 250px;
`;

class CompetencySurveyQuestionList extends React.PureComponent {

  constructor() {
    super();
    this.renderType = this.renderType.bind(this);
    this.renderSubcompetency = this.renderSubcompetency.bind(this);
    this.handleAddClicked = this.handleAddClicked.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleConfirmDialogClose = this.handleConfirmDialogClose.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
    this.renderActionsCell = this.renderActionsCell.bind(this);
    this.renderTooltipContent = this.renderTooltipContent.bind(this);
  }

  async componentDidMount() {
    if (this.props.competencySurveyId && this.props.competencySurveyId || this.props.surveyId) {
      await this.props.loadCompetencySurveyQuestionList({
        competencyId: this.props.competencyId,
        competencySurveyId: this.props.competencySurveyId,
        surveyId: this.props.surveyId
      });
    }
  }

  componentWillUnmount() {
    this.props.clearCompetencySurveyQuestionList();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Tooltip anchorElement='target' position='top' parentTitle={true} content={this.renderTooltipContent}>
          <Grid
            loading={this.props.loading}
            data={this.props.competencySurveyQuestions}
            isPageable={false}
            columns={[
              {
                field: 'number',
                className: 'align-center',
                headerClassName: 'align-center',
                title: '#',
                minGridWidth: GridSize.medium
              }, {
                field: 'questionText',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('competency.question')
              }, {
                field: 'subcompetencyName',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('competency.subcompetency'),
                cell: this.renderSubcompetency
              }, {
                field: 'type',
                className: 'align-left',
                headerClassName: 'align-left',
                title: localizationService.toLanguageString('competency.type'),
                minGridWidth: GridSize.large,
                cell: this.renderType
              }
            ]}
            renderActionsCell={this.renderActionsCell}
            actions={[
              {
                icon: 'edit',
                onClick: this.handleEditClick
              }, {
                icon: 'delete',
                onClick: this.handleRemoveClick
              }
            ]}
          >
            <GridToolbar>
              <Button themeColor='primary' onClick={this.handleAddClicked}>
                {localizationService.toLanguageString('custom.add')}
              </Button>
            </GridToolbar>
          </Grid>
        </Tooltip>
        {this.props.isConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('competency.competencySurveyQuestionDialogMessage')}</Text>
            <DialogActionsBar>
              <Button themeColor={'primary'} onClick={this.handleRemoveConfirm}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  handleRemoveClick(row) {
    this.props.openCompetencySurveyQuestionConfirmDialog(row.dataItem);
  }

  handleEditClick(row) {
    if (this.props.surveyId > 0) {
      this.props.navigate(`/surveys/${this.props.surveyId}/questions/${row.dataItem.id}`);
    } else {
      this.props.navigate(`/competencies/${this.props.competencyId}/surveys/${this.props.competencySurveyId}/questions/${row.dataItem.id}`);
    }
  }

  handleConfirmDialogClose() {
    this.props.closeCompetencySurveyQuestionConfirmDialog();
  }

  handleRemoveConfirm() {
    this.props.removeCompetencySurveyQuestionConfirmDialog({
      competencyId: this.props.competencyId,
      competencySurveyId: this.props.competencySurveyId,
      surveyId: this.props.surveyId
    });
  }

  handleAddClicked() {
    if (this.props.surveyId > 0) {
      this.props.navigate(`/surveys/${this.props.surveyId}/questions/create`);
    } else {
      this.props.navigate(`/competencies/${this.props.competencyId}/surveys/${this.props.competencySurveyId}/questions/create`);
    }
  }

  renderTooltipContent(props) {
    return (
      <TooltipContainer>
        {props.title}
      </TooltipContainer>
    );
  };

  renderActionsCell(buttons, e) {
    return (
      <ActionsCell className='align-right command-cell' isRowInvalid={e.dataItem.isSubcompetencyMatching !== true}>
        {buttons}
      </ActionsCell>
    );
  }

  renderSubcompetency(props) {
    const { dataItem, className } = props;
    const localizationService = provideLocalizationService(this);
    const isRowInvalid = dataItem.isSubcompetencyMatching !== true;
    const tooltipContent = isRowInvalid
      ? localizationService.toLanguageString(`competency.${this.props.surveyId > 0 ? 'invalidSubcompetencyWarningMessage2' : 'invalidSubcompetencyWarningMessage'}`)
      : '';
    return (
      <TableCell className={className} isCellInvalid={isRowInvalid}>
        <CellDataContainer title={tooltipContent}>
          <TextContainer underlined={isRowInvalid}>
            {dataItem.subcompetencyCode}
          </TextContainer>
          {isRowInvalid &&
            <WarningIcon className='k-icon k-i-warning'/>
          }
        </CellDataContainer>
      </TableCell>
    );
  }

  renderType(props) {
    const { dataItem, className } = props;
    const localizationService = provideLocalizationService(this);
    return (
      <td className={className}>
        {localizationService.toLanguageString(`surveyQuestionType.${dataItem.type}`)}
      </td>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.competencySurveyQuestionList.loading,
  competencySurveyQuestions: state.competencySurveyQuestionList.competencySurveyQuestions,
  isConfirmDialogVisible: state.competencySurveyQuestionList.isConfirmDialogVisible
});

const mapDispatchToProps = dispatch => ({
  loadCompetencySurveyQuestionList: (payload) => dispatch(loadCompetencySurveyQuestionList(payload)),
  openCompetencySurveyQuestionConfirmDialog: (payload) => dispatch(openCompetencySurveyQuestionConfirmDialog(payload)),
  closeCompetencySurveyQuestionConfirmDialog: () => dispatch(closeCompetencySurveyQuestionConfirmDialog()),
  removeCompetencySurveyQuestionConfirmDialog: (payload) => dispatch(removeCompetencySurveyQuestionConfirmDialog(payload)),
  clearCompetencySurveyQuestionList: () => dispatch(clearCompetencySurveyQuestionList())
});

registerForLocalization(CompetencySurveyQuestionList);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompetencySurveyQuestionList));