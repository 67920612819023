import { StudyPlanCycleStudentOverviewPageActionTypes } from '../actions';

const initialState = {
  loading: false,
  studyPlanId: null,
  studyPlanCycleId: null,
  studyPlanCycleStudentId: null,
  studyPlanCycleStudent: null,
  selectedTab: 0,
  studyPlanCycleStudentOverviewCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleStudentOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_OVERVIEW_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleStudentOverviewPageActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_OVERVIEW_PAGE_END:
      return {
        ...state,
        loading: false,
        studyPlanId: action.payload?.studyPlanId,
        studyPlanCycleId: action.payload?.studyPlanCycleId,
        studyPlanCycleStudentId: action.payload?.studyPlanCycleStudentId,
        studyPlanCycleStudent: action.payload?.studyPlanCycleStudent
      };
    default:
      return state;
  };
};
