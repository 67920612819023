import { pagingSettings } from '../../../utils/GridPagingUtils';
import MySurveyListPageActionTypes from '../actions/my-survey-list-page-action-types';

const initialState = {
  loading: false,
  mySurveys: null,
  mySurveysCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,

  residentsComboBoxLoading: false,
  residentsComboBoxData: null,
  residentsComboBoxCancelToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MySurveyListPageActionTypes.LOAD_MY_SURVEY_LIST_START:
      return {
        ...state,
        loading: true
      };
    case MySurveyListPageActionTypes.LOAD_MY_SURVEY_LIST_END:
      return {
        ...state,
        loading: false,
        mySurveys: action.payload.mySurveys,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case MySurveyListPageActionTypes.FILTER_MY_SURVEY_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case MySurveyListPageActionTypes.SORT_MY_SURVEY_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case MySurveyListPageActionTypes.SEARCH_MY_SURVEY_LIST_RESIDENTS_COMBOBOX_START:
      return {
        ...state,
        residentsComboBoxLoading: true
      };
    case MySurveyListPageActionTypes.SEARCH_MY_SURVEY_LIST_RESIDENTS_COMBOBOX_END:
      return {
        ...state,
        residentsComboBoxLoading: false,
        residentsComboBoxData: action.payload.residents
      };
    default:
      return state;
  }
};