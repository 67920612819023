import StudyPlanCycleSelectPageActionTypes from '../actions/study-plan-cycle-select-page-action-types';
import { pagingSettings } from '../../../utils/GridPagingUtils';

const initialState = {
  loading: false,
  studyPlanId: null,
  studyProgramId: null,
  studyPlandCycleIds: null,
  availableCyclesCancelToken: null,
  availableCycles: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  filter: {
    showInvalid: false
  },
  selectedIds: [],
  selectedAll: false,
  savingSelectedCycles: false,
  errorMessage: null,
  successMessage: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudyPlanCycleSelectPageActionTypes.LOAD_STUDY_PLAN_CYCLE_SELECT_PAGE_START:
      return {
        ...state,
        studyPlanId: action.payload?.studyPlanId,
        studyProgramId: action.payload?.studyProgramId,
        studyPlandCycleIds: action.payload?.studyPlandCycleIds,
        errorMessage: null,
        successMessage: null
      };
    case StudyPlanCycleSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_SELECT_PAGE_START:
      return {
        ...state,
        loading: true
      };
    case StudyPlanCycleSelectPageActionTypes.FIND_STUDY_PLAN_CYCLE_SELECT_PAGE_END:
      return {
        ...state,
        loading: false,
        availableCycles: action.payload?.cycles,
        total: action.payload?.total,
        skip: action.payload?.skip !== undefined ? action.payload.skip : state.skip,
        take: action.payload?.take !== undefined ? action.payload.take : state.take
      };
    case StudyPlanCycleSelectPageActionTypes.FILTER_STUDY_PLAN_CYCLE_SELECT_PAGE:
      return {
        ...state,
        filter: {
          ...action.payload,
          showInvalid: false
        },
        skip: initialState.skip,
        take: initialState.take
      };
    case StudyPlanCycleSelectPageActionTypes.SELECT_STUDY_PLAN_CYCLE_SELECT_PAGE:
      return {
        ...state,
        availableCycles: action.payload?.availableCycles,
        selectedIds: action.payload?.selectedIds,
        selectedAll: false
      };
    case StudyPlanCycleSelectPageActionTypes.SELECT_ALL_STUDY_PLAN_CYCLE_SELECT_PAGE:
      return {
        ...state,
        availableCycles: action.payload?.availableCycles,
        selectedIds: action.payload?.selectedIds,
        selectedAll: !state.selectedAll
      };
    case StudyPlanCycleSelectPageActionTypes.ADD_SELECTED_CYCLES_STUDY_PLAN_CYCLE_SELECT_PAGE_START:
      return {
        ...state,
        savingSelectedCycles: true,
        errorMessage: null,
        successMessage: null
      };
    case StudyPlanCycleSelectPageActionTypes.ADD_SELECTED_CYCLES_STUDY_PLAN_CYCLE_SELECT_PAGE_END:
      return {
        ...state,
        savingSelectedCycles: false,
        errorMessage: action.payload?.errorMessage,
        successMessage: action.payload?.successMessage,
        studyPlandCycleIds: action.payload?.studyPlandCycleIds
      };
    case StudyPlanCycleSelectPageActionTypes.CLEANUP_STUDY_PLAN_CYCLE_SELECT_PAGE:
      return initialState;
    default:
      return state;
  }
};