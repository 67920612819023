import React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { Grid } from '../../shared/components';
import { loadStudyProgramCycleList } from '../actions/study-program-cycle-list-actions';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const CycleNameContainer = styled.span`
  font-weight: bolder;
`;

class StudyProgramCycleList extends React.Component {
  constructor(props) {
    super(props);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyProgramCycleList({
      studyProgramId: this.props.studyProgramId
    });
  }

  render() {
    const { studyProgramCycles, loading, studyProgramId } = this.props;
    const parsedStudyProgramId = parseInt(studyProgramId);
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={studyProgramCycles}
          isPageable={false}
          onRowClick={this.handleRowClick}
          columns={[
            {
              field: 'name',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('studyProgram.name'),
              cell: (e) =>
                <td className={e.className} >
                  {parsedStudyProgramId === e.dataItem.studyProgramId
                    ? e.dataItem.name
                    : <CycleNameContainer onClick={() => this.handleRowClick(e)}>{`${e.dataItem.name} (${e.dataItem.studyProgramName})`}</CycleNameContainer>
                  }
                </td>
            }, {
              field: 'code',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('studyProgram.cycleCode'),
              cell: ({ dataItem, className }) =>
                <td className={className}>
                  {parsedStudyProgramId === dataItem.studyProgramId
                    ? dataItem.code
                    : dataItem.taughtInOtherStudyPrograms.find(e => parsedStudyProgramId === e.studyProgramId)?.code
                  }
                </td>
            }, {
              field: 'credits',
              className: 'align-right',
              headerClassName: 'align-right',
              title: localizationService.toLanguageString('studyProgram.cycleCredits')
            }
          ]}
        />
      </>
    );
  }

  handleRowClick(row) {
    if (this.props.canWrite) {
      this.props.navigate(`/cycles/${row.dataItem.id}/overview`);
    }
  }
}

const mapStateToProps = (state) => ({
  studyProgramCycles: state.studyProgramCycleList.studyProgramCycles,
  loading: state.studyProgramCycleList.loading,
  canWrite: state.studyProgramCycleList.canWrite
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyProgramCycleList: (payload) => dispatch(loadStudyProgramCycleList(payload))
});

registerForIntl(StudyProgramCycleList);
registerForLocalization(StudyProgramCycleList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyProgramCycleList));