import StudentCyclesActionTypes from './student-cycles-action-types';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';

export const loadStudentCycles = (studentId) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudentCyclesActionTypes.LOAD_STUDENT_CYCLES_START });
  const state = getState().studentCycles;
  try {
    state.studentCyclesCancelToken?.cancel();
    state.studentCyclesCancelToken = axios.CancelToken.source();
    const studentCycles = await api.get(`api/students/${studentId}/cycles`, null, state.studentCyclesCancelToken.token);
    const processedCycles = studentCycles
      ? process(
        studentCycles.map(element => (
          {
            ...element,
            credits: (element.cycleCredits ?? '-') + '/' + (element.studentCredits ?? '-'),
            year: element.year ?? 0
          }
        )),
        { group: [{ field: 'year' }] })
      : null;
    dispatch({
      type: StudentCyclesActionTypes.LOAD_STUDENT_CYCLES_END,
      payload: processedCycles ? { ...processedCycles, data: processedCycles.data?.map(e =>({ ...e, expanded: true })) } : null
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudentCyclesActionTypes.LOAD_STUDENT_CYCLES_END });
      throw error;
    }
  }
};
