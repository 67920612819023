import UserListPageActionTypes from './user-list-page-action-types';
import axios from 'axios';
import { USER_STATUS } from '../../../resources/userStatus';
import { ROLE_TYPES } from '../../../resources/roleType';
import { EVALUATOR_TYPES } from '../../../resources/evaluatorTypes';

export const loadUserListPage = () => async(dispatch) => {
  dispatch(findUsers());
};

export const findUsers = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserListPageActionTypes.LOAD_USER_LIST_START });
  const state = getState().userListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    keyword: state.filter?.keyword,
    status: state.filter?.status?.id,
    userTypes: state.filter?.type?.map(key => key.id)
  };
  try {
    state.usersCancelToken?.cancel();
    state.usersCancelToken = axios.CancelToken.source();
    const users = await api.get(`api/users`, query, state.usersCancelToken.token);
    dispatch({
      type: UserListPageActionTypes.LOAD_USER_LIST_END,
      payload: {
        users: users ? users.data : [],
        total: users ? users.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: UserListPageActionTypes.LOAD_USER_LIST_END });
      throw error;
    }
  }
};

export const filterUsers = (payload) => (dispatch) => {
  dispatch({
    type: UserListPageActionTypes.FILTER_USER_LIST,
    payload: payload
  });
  dispatch(findUsers());
};

export const clearUsersFilter = () => (dispatch) => {
  dispatch({
    type: UserListPageActionTypes.FILTER_USER_LIST,
    payload: {}
  });
  dispatch(findUsers());
};

export const loadUserStatuses = (localizationService) => async(dispatch) => {
  const statuses = Object.keys(USER_STATUS)
    .map(key => ({
      id: USER_STATUS[key],
      text: localizationService.toLanguageString(`userStatus.${USER_STATUS[key]}`)
    }));
  dispatch({
    type: UserListPageActionTypes.LOAD_USER_LIST_USER_STATUSES,
    payload: {
      userStatuses: statuses
    }
  });
};

export const loadUserRoles = (localizationService) => async(dispatch) => {
  const roles = Object.keys(ROLE_TYPES)
    .filter(element => ROLE_TYPES[element] != 'Student')
    .map(key => ({
      id: ROLE_TYPES[key],
      text: localizationService.toLanguageString(`roleType.${ROLE_TYPES[key]}`)
    }));
  roles.push({ id: EVALUATOR_TYPES.EXTERNAL_EVALUATOR, text: localizationService.toLanguageString('User.externalUser') });
  dispatch({
    type: UserListPageActionTypes.LOAD_USER_LIST_USER_ROLES,
    payload: {
      userRoles: roles
    }
  });
};

export const openUserConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: UserListPageActionTypes.TOGGLE_USER_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeUserConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: UserListPageActionTypes.TOGGLE_USER_CONFIRM_DIALOG });
};

export const removeUserConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().userListPage;
  if (state.selectedUserId > 0) {
    dispatch({ type: UserListPageActionTypes.LOAD_USER_LIST_START });
    try {
      await api.remove(`api/users/${state.selectedUserId}`);
      dispatch(loadUserListPage({ userId: payload.userId }));
    } catch (error) {
      dispatch({ type: UserListPageActionTypes.LOAD_USER_LIST_END });
      throw error;
    }
  }
  dispatch({ type: UserListPageActionTypes.TOGGLE_USER_CONFIRM_DIALOG });
};