import DepartmentListPageActionTypes from './department-list-page-action-types';
import axios from 'axios';
import { sortingToString } from '../../../utils/objectUtils';
import { ROLE_PERMISSIONS as Permissions } from '../../../resources/rolePermissions';

export const loadDepartmentListPage = (payload) => async(dispatch, getState) => {
  dispatch(findDepartments());
};

export const findDepartments = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: DepartmentListPageActionTypes.LOAD_DEPARTMENT_LIST_START });
  const state = getState().departmentListPage;
  const query = {
    skip: payload ? payload.skip : state.skip,
    limit: payload ? payload.take : state.take,
    sorting: sortingToString(state.sorting),
    keyword: state.filter?.keyword,
    extendedSearch: true
  };
  try {
    state.departmentsCancelToken?.cancel();
    state.departmentsCancelToken = axios.CancelToken.source();
    const departments = await api.get(`api/departments`, query, state.departmentsCancelToken.token);
    const canWrite = getState().app.userPermissions.includes(Permissions.DEPARTMENTS_WRITE);
    dispatch({
      type: DepartmentListPageActionTypes.LOAD_DEPARTMENT_LIST_END,
      payload: {
        departments: departments ? departments.data : [],
        total: departments ? departments.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take,
        canWrite
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DepartmentListPageActionTypes.LOAD_DEPARTMENT_LIST_END });
      throw error;
    }
  }
};

export const filterDepartments = (payload) => (dispatch) => {
  dispatch({
    type: DepartmentListPageActionTypes.FILTER_DEPARTMENT_LIST,
    payload: payload
  });
  dispatch(findDepartments());
};

export const sortDepartments = (payload) => (dispatch) => {
  dispatch({
    type: DepartmentListPageActionTypes.SORT_DEPARTMENT_LIST,
    payload: payload
  });
  dispatch(findDepartments());
};

export const clearDepartmentsFilter = () => (dispatch) => {
  dispatch({
    type: DepartmentListPageActionTypes.FILTER_DEPARTMENT_LIST,
    payload: {}
  });
  dispatch(findDepartments());
};

export const openDepartmentConfirmDialog = (payload) => async(dispatch) => {
  dispatch({
    type: DepartmentListPageActionTypes.TOGGLE_DEPARTMENT_LIST_CONFIRM_DIALOG,
    payload: payload
  });
};

export const closeDepartmentConfirmDialog = () => async(dispatch) => {
  dispatch({ type: DepartmentListPageActionTypes.TOGGLE_DEPARTMENT_LIST_CONFIRM_DIALOG });
};

export const removeDepartmentConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().departmentListPage;
  if (state.selectedDepartmentId > 0) {
    dispatch({ type: DepartmentListPageActionTypes.LOAD_DEPARTMENT_LIST_START });
    try {
      await api.remove(`api/departments/${state.selectedDepartmentId}`);
      dispatch(loadDepartmentListPage());
    } catch (error) {
      dispatch({ type: DepartmentListPageActionTypes.LOAD_DEPARTMENT_LIST_END });
    }
  }
  dispatch({ type: DepartmentListPageActionTypes.TOGGLE_DEPARTMENT_LIST_CONFIRM_DIALOG });
};